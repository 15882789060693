
<script>
import DatePicker from 'vue-datepicker-next'
import { computed, onBeforeUnmount, onMounted, ref, toRefs } from 'vue'
import { useDateStore } from '@/stores/date'
import dayjs from 'dayjs'

export default {
  name: 'RangeDate',
  components: { DatePicker },
  emits: ['update:dates'],
  props: {
    dates: {
      type: Array,
      required: true
    }
  },

  setup (props, { emit }) {
    const { dates } = toRefs(props)
    const rangePicker = ref(null)
    const inputPicker = ref(null)
    const { dictMonthRod } = useDateStore()

    const dateOrder = ref(0)
    const dateOne = ref(null)
    const dateTwo = ref(null)
    const separatorValue = ref(' - ')

    const datesProxy = computed({
      get () {
        return dates.value
      },

      set () {
        emit('update:dates', [dateOne.value, dateTwo.value])
      }
    })

    const formatDate = (date) => {
      return dayjs(date).format('YYYY-MM-DD')
    }

    const customFormatted = {
      stringify: (date) => {
        if (!date) return
        dateOrder.value += 1

        const newDate = new Date(date)
        const day = newDate.getDate()
        const month = dictMonthRod[newDate.getMonth()].toLowerCase()
        const year = newDate.getFullYear()

        separatorValue.value = ' - '

        if (datesProxy.value[0] === datesProxy.value[1]) {
          separatorValue.value = ' '
          if (dateOrder.value === 2) {
            dateOrder.value = 0
            dateTwo.value = formatDate(date)
            return ''
          } else {
            dateOne.value = formatDate(date)
            return `${day} ${month} ${year}`
          }
        } else {
          if (dateOrder.value === 2) {
            dateOrder.value = 0
            dateTwo.value = formatDate(date)
            return `${day} ${month} ${year}`
          } else {
            dateOne.value = formatDate(date)
            if (datesProxy.value[0].slice(0, 4) !== datesProxy.value[1].slice(0, 4)) return `${day} ${month} ${year}`
            return `${day} ${month}`
          }
        }
      }
    }

    const notAfterToday = (date) => {
      return date > new Date(new Date().setHours(0, 0, 0, 0))
    }

    const blurInput = (event) => {
      event.target.blur()
    }

    onMounted(() => {
      inputPicker.value = rangePicker.value.querySelector('input')
      inputPicker.value.addEventListener('focus', blurInput)
    })

    onBeforeUnmount(() => {
      inputPicker.value.removeEventListener('focus', blurInput)
    })

    return {
      datesProxy,
      rangePicker,
      customFormatted,
      separatorValue,
      notAfterToday
    }
  }
}
</script>

<template>
  <div class="range-date">
    <DatePicker
      class="range-date__calendar"
      v-model:value="datesProxy"
      range
      placeholder="Выберете даты"
      value-type="format"
      :formatter="customFormatted"
      type="date"
      :separator=separatorValue
      :disabled-date="notAfterToday"
      :monday-first="true"
      :multiple="false"
      :full-month-name="true"
      :lang="'ru'"
      ref="rangePicker"
    />
  </div>
</template>

<style scoped lang="scss">
.range-date:deep(*) {
  color: $blue;
}

.range-date:deep(.mx-datepicker) {
  width: 100%;
}

.range-date:deep(.mx-input) {
  border: 1px solid $blue;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  background-image: url("~@/assets/uploads/icon/calendar/calendar-icon.svg");
  background-repeat: no-repeat;
  background-position: 35px center;
}

.range-date:deep(.mx-input::placeholder) {
  color: $blue;
}

.range-date:deep(.mx-icon-calendar) {
  display: none;
}
</style>
