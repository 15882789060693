import { getCookie } from '@/utilits/getCookie'
import axios from 'axios'
import { postLog } from '@/api/postLog'

const postFirebaseToken = async (path, method = 'get', data = {}) => {
  const userToken = getCookie('user_token')

  try {
    const response = await axios({
      method: method,
      url: path,
      data: data,
      headers: {
        Authorization: `Bearer ${userToken}`,
        'Content-Type': 'application/json'
      }
    })

    if (response.data.status === 'ok') {
      return [null, response.data.data]
    } else {
      return [new Error(response.data.message), null]
    }
  } catch (err) {
    console.warn(err)
    return [err, null]
  }
}

const success = async (token) => {
  const data = {
    token: token,
    device: window?.device?.platform.toLowerCase()
  }
  const [error, notifyResponse] = await postFirebaseToken(
    '/v1/push/token',
    'post',
    data
  )

  return [error, notifyResponse]
}

export const getFirebaseToken = () => {
  const platform = window?.device?.platform.toLowerCase()

  if (platform !== 'ios' && platform !== 'android') {
    return
  }

  if (platform === 'ios') {
    window.cordova.plugins.firebase.messaging
      .requestPermission({ forceShow: true })
      .then(function () {
        postLog({
          m: 'запрос на apns-string'
        })
        return window.cordova.plugins.firebase.messaging.getToken(
          'apns-string'
        )
      })
      .then(function (token) {
        postLog({
          m: `токен: ${token}`
        })

        return window.cordova.plugins.firebase.messaging.getToken()
      })
      .then(function (token) {
        postLog({
          m: `getToken без аргументов, токен: ${token}`
        })
        success(token)
      })
      .catch((err) => {
        postLog({
          m: 'FIREBASE MESSAGING ERROR',
          err: `ошибка ${err}`
        })
      })

    return
  }

  window.cordova.plugins.firebase.messaging
    .requestPermission({ forceShow: false })
    .then(function () {
      return window.cordova.plugins.firebase.messaging.getToken()
    })
    .then(function (token) {
      success(token)
    })
}
