<template>
  <div class="white-block" :class="{top: top}">
  </div>
</template>

<script>
export default {
  name: 'WhiteBlockRounded',
  props: {
    visible: {
      type: Boolean,
      require: true,
      default: false
    },
    top: {
      type: Boolean,
      require: false,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.white-block {
  display: none;
  position: absolute;
  height: 10px;
  left: 0;
  right: 0;
  bottom: -10px;
  background: rgba(255, 255, 255, 0);
  border-radius: 16px;
  backdrop-filter: blur(0px);
  -webkit-backdrop-filter: blur(0px);

  @include tablets-md {
    display: block;
  }

  &:before, &:after {
    background-image: url('~@/assets/uploads/images/triangular-edges-opacity.png');
    display: inline-flex;
    content: '';
    background-size: 8px 8px;
    height: 8px;
    width: 8px;
    position: absolute;
    top: 0;
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
    transform: rotate(90deg);
  }
}

.white-block.top {
  top: 0;
}

</style>
