<template>
    <img v-if="isValidUrl(img)" :src="img" alt="фото профиля" :class="{contained: isCallCenter}"/>
    <img v-else-if="img" :src="require(`@/assets/uploads/icon/profile/${img}.svg`)" alt="фото" />
    <img v-else src="@/assets/uploads/icon/profile/man.svg" alt="фото"/>
</template>

<script>
import { isValidUrl } from '@/utilits/checks.js'

export default {
  props: {
    size: {
      type: String,
      default: '32px',
      required: false
    },
    img: String,
    isCallCenter: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  setup () {
    return { isValidUrl }
  }
}
</script>

<style lang="scss" scoped>
img {
width: v-bind(size);
height: v-bind(size);
background-color: #e0fee3;
border-radius: 100%;
object-fit: cover;
}

.contained {
object-fit: contain;
background-color: #ffffff;
}
</style>
