<template>
  <div class="radio-wrapper">
    <label class="wrapper flex items-center">
      <span :style="{ color: color }">
        {{ label }}
      </span>
      <input
        :name="name"
        class="checkbox"
        type="radio"
        :checked="isChecked"
        :value="value"
        @change="change"
      />
      <span class="checkmark"></span>
    </label>
  </div>
</template>

<script>
import { computed, toRefs } from 'vue'

export default {
  name: 'FormRadio',
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    label: {
      type: String,
      default: '',
      required: true
    },
    modelValue: {
      default: ''
    },
    value: {
      type: [Number, String, Boolean],
      default: undefined
    },
    name: {
      type: String,
      default: 'drone',
      required: false
    },
    color: {
      type: String,
      default: '',
      required: false
    }
  },

  setup (props, { emit }) {
    const { modelValue, value } = toRefs(props)

    const isChecked = computed(() => {
      return modelValue.value === value.value
    })

    const change = (event) => {
      const result = +event.target.value
      isNaN(result)
        ? emit('update:modelValue', event.target.value)
        : emit('update:modelValue', result)
    }
    return {
      isChecked,
      change
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  display: block;
  position: relative;
  padding-left: 22px;
  margin-bottom: 6px;
  cursor: pointer;
  user-select: none;
  font-size: 14px;
}

.wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #bac7de;
}

.wrapper:hover input ~ .checkmark {
  background-color: #ccc;
}

.wrapper input:checked ~ .checkmark {
  background: white;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.wrapper input:checked ~ .checkmark:after {
  display: block;
}

.wrapper .checkmark:after {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #002856;
}

//span {
//  font-size: 16px;
//  font-weight: 700;
//}
</style>
