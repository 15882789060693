<script>
import '@vuepic/vue-datepicker/dist/main.css'
import SectionWrapper from '@/components/ui/SectionWrapper.vue'
import ArrowTitle from '@/components/ui/typography/ArrowTitle.vue'
import ActivityChart from '@/components/pages/calendar/activity/ActivityChart.vue'
import ActivityCalendarModal from '@/components/pages/calendar/activity/ActivityCalendarModal.vue'
import { computed, onActivated, ref, watch, watchEffect } from 'vue'
import { getHealthSinceMonth } from '@/utilits/getHealth'
import axios from 'axios'
import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'
import TheLoader from '@/components/ui/TheLoader.vue'
import { useRouter } from 'vue-router'

export default {
  name: 'ActivityCalendarView',
  props: {
    isApp: {
      type: Boolean,
      required: true,
      default: false
    },
    user: {
      type: [Object, null],
      required: true,
      default: null
    }
  },
  components: {
    ArrowTitle,
    SectionWrapper,
    TheLoader,
    ActivityChart,
    ActivityCalendarModal
  },

  setup () {
    const currentDay = ref({
      date_at: '',
      active_time: 0,
      burned_cal: 0,
      steps: 0,
      distance: 0
    })
    const stepsGoal = ref(9500)
    const steps = ref(null)
    const distance = ref(null)
    const calories = ref(null)
    const activeTime = ref(null)
    const isCalendarModalOpen = ref(false)
    const platform = window?.device?.platform.toLowerCase()

    const userStore = useUserStore()
    const { userToken, getUserId } = storeToRefs(userStore)
    const activityHistory = ref(null)
    const router = useRouter()

    const isLoading = ref(false)
    const isError = ref(false)

    const openSettings = () => {
      router.push({ name: 'calendar-activity-settings' })
    }

    const formatDate = (date) => {
      const year = date.getFullYear()
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const day = date.getDate().toString().padStart(2, '0')
      return `${year}-${month}-${day}`
    }

    const addActivityDay = async (day) => {
      const params = new URLSearchParams({
        user_id: getUserId.value
      }).toString()
      try {
        await axios.post(
          `/v1/events/activity/add/?${params}`,
          {
            date_at: day.date_at,
            steps: Math.round(day.steps || 0),
            distance: Math.round((day.distance || 0)),
            burned_cal: Math.round(day.burned_cal || 0),
            active_time: Math.round(day.active_time || 0),
            heart_rate: 0,
            glucose: 0,
            ads: 0,
            add: 0,
            body_mass: 0,
            body_fat: 0,
            hint: 'emty'
          },
          {
            headers: {
              Authorization: `Bearer ${userToken.value}`
            }
          }
        )
      } catch (err) {}
    }

    const getActivityHistory = async () => {
      const monthFromToday = new Date(
        new Date().getTime() - 31 * 24 * 60 * 60 * 1000
      )

      const params = new URLSearchParams({
        user_id: getUserId.value,
        date_from: formatDate(monthFromToday)
      }).toString()
      try {
        if (platform !== 'ios' && platform !== 'android') isLoading.value = true
        const response = await axios.get(`/v1/events/activity/?${params}`, {
          headers: {
            Authorization: `Bearer ${userToken.value}`
          }
        })
        if (response.data.status === 'ok') {
          isLoading.value = false
          return response.data.data
        } else {
          isLoading.value = false
          return []
        }
      } catch (err) {}
    }

    const chartData = computed(() => {
      if (platform === 'android' || platform === 'ios') {
        const dataMap = {}
        if (
          steps.value &&
          distance.value &&
          calories.value &&
          activeTime.value
        ) {
          steps.value.forEach((item) => {
            const date = formatDate(new Date(item.startDate))
            if (!dataMap[date]) {
              dataMap[date] = {
                date_at: date,
                steps: 0,
                distance: 0,
                burned_cal: 0,
                active_time: 0
              }
            }
            dataMap[date].steps = item.value
          })

          distance.value.forEach((item) => {
            const date = formatDate(new Date(item.startDate))
            if (!dataMap[date]) {
              dataMap[date] = {
                date_at: date,
                steps: 0,
                distance: 0,
                burned_cal: 0,
                active_time: 0
              }
            }
            dataMap[date].distance = item.value
          })

          calories.value.forEach((item) => {
            const date = formatDate(new Date(item.startDate))
            if (!dataMap[date]) {
              dataMap[date] = {
                date_at: date,
                steps: 0,
                distance: 0,
                burned_cal: 0,
                active_time: 0
              }
            }
            dataMap[date].burned_cal = item.value
          })

          activeTime.value.forEach((item) => {
            const date = formatDate(new Date(item.startDate))
            let stillIos
            let walkingIos
            let overallAndroid
            if (!dataMap[date]) {
              dataMap[date] = {
                date_at: date,
                steps: 0,
                distance: 0,
                burned_cal: 0,
                active_time: 0
              }
            }
            if (platform === 'ios') {
              stillIos =
                item.value?.still?.duration > 0
                  ? item.value?.still?.duration
                  : 0
              walkingIos =
                item.value?.walking?.duration > 0
                  ? item.value?.walking?.duration
                  : 0
              dataMap[date].active_time = (stillIos + walkingIos) / 3600000
            }
            if (platform === 'android') {
              overallAndroid = isNaN(item.value) ? 0 : item.value
              dataMap[date].active_time = overallAndroid / 3600000
            }
          })

          return Object.values(dataMap).sort((a, b) => new Date(a.date_at) - new Date(b.date_at))
        } else {
          return []
        }
      } else {
        if (activityHistory.value?.list[0]?.items) {
          return activityHistory.value.list[0].items.map((item) => {
            const newItem = {}
            for (const key in item) {
              newItem[key] = item[key] === null ? 0 : item[key]
            }
            return newItem
          })
        } else {
          return []
        }
      }
    })

    const maxValue = computed(() => {
      const maxVal = Math.max(...chartData.value.map((i) => i.steps))
      return maxVal < stepsGoal.value ? stepsGoal.value : maxVal
    })

    const minValue = computed(() => {
      return Math.min(...chartData.value.map((i) => i.steps))
    })

    const chartNormalized = computed(() => {
      if (chartData.value) {
        return chartData.value.map((item) => ({
          ...item,
          height:
            2 +
            ((item.steps - minValue.value) * 98) /
              (maxValue.value - minValue.value)
        }))
      } else {
        return []
      }
    })

    const selectDay = (day) => {
      currentDay.value = chartNormalized.value.find(
        (item) => item.date_at === day
      )
    }

    let isComponentLoaded = false
    onActivated(async () => {
      activityHistory.value = await getActivityHistory()
      if (platform === 'ios' || platform === 'android') {
        steps.value = await getHealthSinceMonth('steps')
        distance.value = await getHealthSinceMonth('distance')
        calories.value = await getHealthSinceMonth('calories')
        activeTime.value = await getHealthSinceMonth('activity')
      }

      if (chartNormalized.value && !isComponentLoaded) {
        currentDay.value = chartNormalized.value.at(-1)
        isComponentLoaded = true
      }
    })

    watchEffect(async () => {
      if (activityHistory.value?.setup) {
        stepsGoal.value = activityHistory.value.setup.step_num
      }
    })

    watch(
      chartData,
      async () => {
        if (
          (platform === 'ios' || platform === 'android') &&
          chartData.value.length > 0 &&
          activityHistory.value &&
          steps.value &&
          distance.value &&
          calories.value &&
          activeTime.value
        ) {
          if (activityHistory.value.list.length === 0) {
            chartData.value.forEach(async (item) => {
              await addActivityDay(item)
            })
            activityHistory.value = await getActivityHistory()
          } else {
            chartData.value.forEach(async (item) => {
              if (
                !activityHistory.value.list[0].items.some(
                  (oldItem) => oldItem.date_at === item.date_at
                )
              ) {
                await addActivityDay(item)
              }
            })
            activityHistory.value = await getActivityHistory()
          }
        }
      },
      { immediate: true }
    )

    return {
      stepsGoal,
      isLoading,
      isError,
      chartNormalized,
      currentDay,
      isCalendarModalOpen,
      minValue,
      maxValue,
      selectDay,
      openSettings
    }
  }
}
</script>

<template>
  <ArrowTitle title="Моя активность" :is-app="isApp" />
  <SectionWrapper stretched>
    <TheLoader v-if="isLoading"></TheLoader>
    <ActivityChart
      v-if="!isLoading"
      @openSettings="openSettings"
      @openCalendarModal="isCalendarModalOpen = true"
      @selectDay="selectDay"
      :currentDay="currentDay"
      :stepsGoal="stepsGoal"
      :chartData="chartNormalized"
      :maxValue="maxValue"
      :minValue="minValue"
    />
    <Transition>
      <ActivityCalendarModal
        v-if="isCalendarModalOpen"
        @modalClose="isCalendarModalOpen = false"
        @selectDay="selectDay"
        :currentDay="currentDay"
        :chartData="chartNormalized"
        :modalIsActive="isCalendarModalOpen"
        :stepsGoal="stepsGoal"
      />
    </Transition>
  </SectionWrapper>
</template>

<style scoped lang="scss">
.v-enter-active,
.v-leave-active {
  transition: all 0.3s ease-in-out;
}

.v-enter-from,
.v-leave-to {
  transform: translateY(100%);
}
</style>
