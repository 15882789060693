import { computed } from 'vue'
import { getPlatform } from '@/utilits/getPlatform'
import { useAppInfoStore } from '@/stores/AppInfoStore'
import { storeToRefs } from 'pinia'

export const usePhoneLink = (
  iosVariable,
  androidVariable,
  webVariable,
  defaultPhone
) => {
  const appStore = useAppInfoStore()
  const { appInfo } = storeToRefs(appStore)

  const phone = computed(() => {
    const platform = getPlatform()
    if (!appInfo.value) return defaultPhone

    if (platform === 'ios') {
      return appInfo.value[iosVariable]
    }

    if (platform === 'android') {
      return appInfo.value[androidVariable]
    }

    return appInfo.value[webVariable]
  })

  return {
    phone
  }
}
