<template>
  <router-link :to="linkRedirect" class="specialists">
    <div class="specialists__item-row">
      <div class="specialists__column-left">
        <div class="specialists__item-desc">
          <div class="specialists__img">
            <img :src="doctorImg" :alt="doctorTitle" />

            <img
              class="specialists__chat-icon"
              src="@/assets/uploads/icon/chat/subscribtion-active-icon.svg"
              v-if="subscribtionStatus === true"
            />
            <img
              class="specialists__chat-icon"
              src="@/assets/uploads/icon/chat/subscribtion-unactive-icon.svg"
              v-if="subscribtionStatus === false"
            />
          </div>

          <div class="specialists__info">
            <h3 class="specialists__name">{{ doctorTitle }}</h3>
            <p class="specialists__desc">{{ doctorPosition }}</p>
          </div>
        </div>
        <img
          v-if="user && !doctor.is_favorite"
          src="@/assets/uploads/images/favorite-empty.svg"
          alt=""
          @click.prevent="addDoctorToFavorites"
        />
        <img
          v-if="user && doctor.is_favorite"
          src="@/assets/uploads/images/favorite-active.svg"
          alt=""
          @click.prevent="removeDoctorToFavorites"
        />
      </div>

      <div class="specialists__bottom">
        <div v-if="isChat && accessDates.length" class="speacialists__chat-dates">
          <h3>Оплачен на даты:</h3>
          <span v-for="date in accessDates" :key="date">
            {{ formatDateToText(date) }}
          </span>
        </div>
        <div v-if="!isChat" class="specialists__clinics">
          <template v-for="(clinics, index) in doctor.clinics" :key="index">
            <div class="specialists__clinic">
              <img
                src="@/assets/uploads/images/home-page/my-appointment/marker.svg"
                alt="знак метки на карте"
              />

              <div class="specialists__filials" v-if="doctor?.clinics">
                <p
                  class="specialists__filial"
                  :class="{ 'blue-light': clinics === 3, grey: clinics === 4 }"
                >
                  {{ getClinicsName(clinics) }}
                </p>
              </div>
            </div>
          </template>
        </div>
        <div
          v-if="doctor?.reception_address && !isChat"
          class="specialists__address"
        >
          <img
            src="@/assets/uploads/images/home-page/my-appointment/marker.svg"
            alt="знак метки на карте"
          />
          <p>
            {{ doctor.reception_address }}
          </p>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import { computed, ref, toRefs } from 'vue'
import axios from 'axios'
import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'
import { useToast } from 'vue-toastification'
import CustomToast from '@/components/ui/CustomToast.vue'
import { useDateStore } from '@/stores/date'
export default {
  name: 'SmallDoctorCard',
  emits: ['updateFavoriteList'],
  props: {
    doctor: Object,
    chatRoomId: {
      type: Number,
      required: false
    },
    isChat: {
      type: Boolean,
      required: false,
      default: false
    },
    accessDates: {
      type: [Array, null],
      required: false,
      default: null
    },
    subscribtionStatus: {
      type: Boolean,
      required: false,
      default: null
    },
    resetShedule: {
      type: Boolean,
      required: false,
      default: false
    },
    isTelemed: {
      type: Boolean,
      required: false,
      default: false
    },
    user: {
      type: [Object, null],
      required: true,
      default: null
    }
  },

  setup (props, { emit }) {
    const { doctor, isTelemed, isChat, chatRoomId } = toRefs(props)
    const userStore = useUserStore()
    const { userToken, getUserId } = storeToRefs(userStore)
    const { dictMonthRod } = useDateStore()
    const toast = useToast()
    const toastOptions = ref({
      position: 'bottom-center',
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 0.38,
      timeout: 2000,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: 'button',
      icon: false,
      rtl: false,
      toastClassName: 'custom-toast'
    })
    const doctorImg = computed(() => {
      if (doctor.value.img) {
        return doctor.value.img
      }

      if (doctor.value.photo) {
        return doctor.value.photo
      }

      return null
    })

    const doctorTitle = computed(() => {
      if (doctor.value.full_name) {
        return doctor.value.full_name
      }

      if (doctor.value.name) {
        return doctor.value.name
      }

      if (doctor.value.full_name) {
        return doctor.value.full_name
      }
      return null
    })

    const doctorPosition = computed(() => {
      if (doctor.value.display_work_title) {
        return doctor.value.display_work_title
      } else if (doctor.value.work_title) {
        return doctor.value.work_title
      }

      return null
    })
    const getClinicsName = (clinics) => {
      const filial = {
        1: 'К+31 на Лобачевского',
        3: 'К+31 Петровские Ворота',
        4: 'К+31 Запад',
        45: 'К+31 Сити'
      }

      return filial[clinics]
    }

    const formatDateToText = (dateString) => {
      const [year, month, day] = dateString.split('-')
      return `${day} ${dictMonthRod[month - 1].toLowerCase()} ${year}`
    }

    const addDoctorToFavorites = async () => {
      try {
        const response = await axios.post(
          `v1/favorite/doctor-add/?user_id=${getUserId.value}`,
          {
            res_id: resourceFavoritesId.value
          },
          {
            headers: {
              Authorization: `Bearer ${userToken.value}`,
              'Content-Type': 'multipart/form-data'
            }
          }
        )

        if (response.data.status === 'ok') {
          toast.success(
            {
              component: CustomToast,
              props: {
                title: 'Врач добавлен в «Избранноe»',
                icon: 'success'
              }
            },
            toastOptions.value
          )
          doctor.value.is_favorite = true
        } else {
          toast.success(
            {
              component: CustomToast,
              props: {
                title: 'Не удалось добавить врача в «Избранноe»',
                text: 'Попробуйте еще раз',
                icon: 'error'
              }
            },
            toastOptions.value
          )
        }
      } catch (err) {
        throw new Error()
      }
    }
    const removeDoctorToFavorites = async () => {
      try {
        const response = await axios.delete(
          `/v1/favorite/doctor-delete/?user_id=${getUserId.value}&res_id=${resourceFavoritesId.value}`,
          {
            headers: {
              Authorization: `Bearer ${userToken.value}`
            }
          }
        )

        if (response.data.status === 'ok') {
          toast.success(
            {
              component: CustomToast,
              props: {
                title: 'Врач удален из «Избранноe»',
                icon: 'success'
              }
            },
            toastOptions.value
          )
          doctor.value.is_favorite = false
        } else {
          toast.success(
            {
              component: CustomToast,
              props: {
                title: 'Не удалось удалить врача из «Избранноe»',
                text: 'Попробуйте еще раз',
                icon: 'error'
              }
            },
            toastOptions.value
          )
        }
      } catch (err) {
        throw new Error()
      }
    }
    const resourceFavoritesId = computed(() => {
      if (doctor.value.resource_ids) {
        return doctor.value.resource_ids[0]
      }

      if (doctor.value.res_list) {
        return doctor.value.res_list[0]
      }

      if (doctor.value.resource_list) {
        return doctor.value.resource_list[0]
      }

      return null
    })
    const resourceIds = computed(() => {
      if (doctor.value.resource_ids) {
        return doctor.value.resource_ids.join(':')
      }

      if (doctor.value.res_list) {
        return doctor.value.res_list.join(':')
      }

      if (doctor.value.resource_list) {
        return doctor.value.resource_list.join(':')
      }

      return null
    })

    const linkRedirect = computed(() => {
      if (isChat.value) return `/chats/${chatRoomId.value}/${doctor.value.id}`
      return isTelemed.value
        ? `/doctors/telemed/${resourceIds.value}`
        : `/doctors/${resourceIds.value}`
    })

    return {
      doctorImg,
      doctorTitle,
      doctorPosition,
      resourceIds,
      getClinicsName,
      linkRedirect,
      addDoctorToFavorites,
      removeDoctorToFavorites,
      formatDateToText
    }
  }
}
</script>

<style scoped lang="scss">
.specialists {
  display: block;
  border-radius: 6px;
  padding: 8px 8px 14px 8px;
  background: #fff;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
  margin-bottom: 12px;
  img {
    &.active {
      background: red;
    }
  }
  &__item-row {
    display: flex;
    gap: 12px;
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
  }

  &__column-right {
    width: 100%;
  }

  &__doctor-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, 86px);
    column-gap: 12px;
    row-gap: 12px;
    align-items: center;

    & li {
      margin: 0;
      border-radius: 100px;
      border: 1px solid $blue-light;
      padding: 10px 5px;
      text-align: center;
    }

    & li.active {
      background-color: $blue-light;
    }
  }

  &__column-left {
    display: flex;
    gap: 12px;
    align-items: flex-start;
    justify-content: space-between;
    img {
      cursor: pointer;
    }
  }
  &__item-desc {
    display: flex;
    gap: 12px;
    align-items: center;
  }

  &__img {
    position: relative;
    display: block;
    width: 100%;
    max-width: 64px;
    height: 64px;

    & img {
      border-radius: 50%;
      max-width: 64px;
      height: 64px;
    }
  }

  &__chat-icon {
    max-width: 20px !important;
    height: 20px !important;
    position: absolute;
    left: 0;
  }

  &__filials {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__filial {
    color: $blue;
    font-size: 10px;
    line-height: 12px;
    @include tablet {
      font-size: 12px;
    }

    &.blue-light {
      color: #004f61;
    }

    &.grey {
      color: #949494;
    }
  }

  &__column-right {
  }

  &__item-desc {
  }

  &__item-top {
  }

  &__name {
    display: block;
    margin-top: 0;
    margin-bottom: 3px;
    color: $blue;
  }

  &__desc {
    font-size: 12px;
    color: #7f8da9;
    line-height: 14px;
    margin-top: 5px;
  }

  &__times {
    margin-bottom: 0;
  }

  &__btn {
    margin-top: 20px;
  }

  &__clinics {
    display: flex;
    gap: 5px;
  }

  &__clinic {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  &__bottom {
    display: flex;
    gap: 8px;
    align-items: start;
    flex-direction: column;
  }
}

.specialists__address {
  display: flex;
  gap: 5px;
  align-items: center;
}

.speacialists__chat-dates {
  background: #e1e8f4;
  border-radius: 5px;
  padding: 16px 12px;
  width: 100%;

  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;

  display: flex;
  flex-direction: column;

  h3 {
    font-weight: 700;
    margin-bottom: 5px;
  }
}
</style>
