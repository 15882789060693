<script>
export default {
  name: 'TransitionWrapper',
  props: {
    duration: {
      type: String,
      default: '0.2s'
    },
    transform: {
      type: String,
      default: 'translateX(30px)'
    }
  }
}
</script>

<template>
  <TransitionGroup
    class="transition-wrapper"
    name="transition-wrapper"
    tag="div"
  >
    <slot></slot>
  </TransitionGroup>
</template>

<style lang="scss">
.transition-wrapper-enter-active,
.transition-wrapper-leave-active {
  transition: all v-bind(duration) ease;
}

.transition-wrapper-enter-from,
.transition-wrapper-leave-to {
  opacity: 0;
  transform: v-bind(transform);
}
</style>
