import { defineStore } from 'pinia'
import { computed, nextTick, ref, watch } from 'vue'
import axios from 'axios'
import { useRouter } from 'vue-router'
import { useMenuStore } from '@/stores/MenuStore'
import { getCookie } from '@/utilits/getCookie'
import { deleteCookieToken } from '@/utilits/deleteCookieToken'
import { getFirebaseToken } from '@/utilits/getFirebaseToken'
import { getAccess } from '@/utilits/getHealth'
import { postLog } from '@/api/postLog'

export const useUserStore = defineStore('user', () => {
  const user = ref(null)
  const userAppoints = ref(null)
  const userToken = ref(null)
  const userCards = ref(null)
  const userSmsCode = ref(null)
  const userPhone = ref(null)
  const userPrimaryId = ref(null)
  const hasPinCode = ref(null)
  const isBiometryActive = ref(null)
  const isActivityBannerAvailable = ref(null)
  const router = useRouter()
  const menuStore = useMenuStore()
  const { closeMenu } = menuStore

  const userTokenStorage = computed(() => {
    return getCookie('user_token')
  })

  const getUserId = computed(() => {
    if (!user.value) return null

    return user.value.id
  })

  const updateUserFamilyCards = (cards) => {
    user.value.family_cards = [...cards]
    user.value.family_cards.unshift({ ...user.value })
  }

  const changeUserToFamilyMember = (member) => {
    for (const memberKey in member) {
      user.value[memberKey] = member[memberKey]
    }
  }

  const userFullName = computed(() => {
    if (!user.value) return ''

    const lastName = user.value?.last_name || ''
    const firstName = user.value?.first_name || ''
    const middleName = user.value?.middle_name || ''
    return `${lastName} ${firstName} ${middleName}`
  })

  const isAdmin = computed(() => {
    if (!user.value) return false
    const adminIds = [8, 18401, 24682, 21564, 22644]

    return adminIds.includes(user.value.id)
  })

  const setUserCards = (cards) => {
    userCards.value = cards
  }
  const setUserSmsCode = (code) => {
    // [{code:, name:},{code:, name:}]
    userSmsCode.value = code
  }

  const setUserPhone = (phone) => {
    userPhone.value = phone
    window.localStorage.setItem('user_phone', phone)
  }

  const setHasPinCode = (val) => {
    hasPinCode.value = val
  }

  const setUserToken = (token) => {
    const twoWeeksInMs = 3600 * 24 * 14
    document.cookie = `user_token=${token}; max-age=${twoWeeksInMs}; path=/; secure`
    window.localStorage.setItem('user_token', token)
    userToken.value = token
  }

  const setBiometry = (value) => {
    if (value) {
      window.localStorage.setItem('is_biometry_active', 'true')
    } else {
      window.localStorage.setItem('is_biometry_active', 'false')
    }
    isBiometryActive.value = value
  }

  const logOut = () => {
    window.localStorage.removeItem('user_phone')
    setBiometry(false)
    deleteCookieToken()
    userToken.value = null
    user.value = null
    closeMenu()
    router.push({
      name: 'auth-phone'
    })
  }

  const setUser = async (token) => {
    try {
      const response = await axios.get('/v1/user/index', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      if (response.data.status === 'ok') {
        userPrimaryId.value = response.data.data.id
        user.value = { ...response.data.data }
        user.value.family_cards.unshift({ ...response.data.data })
      }
    } catch (err) {
      postLog({
        m: 'setUser ERROR inside UserStore',
        err: err
      })
      throw new Error(err)
    }
  }

  const setUserAvatar = (newPhoto) => {
    user.value.photo = newPhoto
  }

  watch(getUserId, async () => {
    if (getUserId.value) {
      isActivityBannerAvailable.value = await getAccess()
      await nextTick()
      getFirebaseToken()
    }
  }, { immediate: true })

  return {
    user,
    setUserToken,
    userToken,
    setUser,
    userTokenStorage,
    logOut,
    getUserId,
    setUserCards,
    setUserSmsCode,
    userCards,
    userSmsCode,
    setUserPhone,
    userPhone,
    setHasPinCode,
    hasPinCode,
    userFullName,
    setUserAvatar,
    changeUserToFamilyMember,
    updateUserFamilyCards,
    userAppoints,
    isAdmin,
    userPrimaryId,
    isActivityBannerAvailable,
    setBiometry,
    isBiometryActive
  }
})
