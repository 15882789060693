import { defineStore, storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import axios from 'axios'
import { getCookie } from '@/utilits/getCookie'
import CustomToast from '@/components/ui/CustomToast.vue'
import { useToast } from 'vue-toastification'
import { useUserStore } from '@/stores/UserStore'

export const useDoctorStore = defineStore('doctor', () => {
  const doctorsList = ref(null)
  const doctorIsFavorite = ref(false)
  const userStore = useUserStore()
  const { getUserId } = storeToRefs(userStore)
  const userTokenStorage = computed(() => {
    return getCookie('user_token')
  })
  const toast = useToast()
  const toastOptions = ref({
    position: 'bottom-center',
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.38,
    timeout: 2000,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: 'button',
    icon: false,
    rtl: false,
    toastClassName: 'custom-toast'
  })
  const getKeepAliveKey = computed(() => {
    return doctorIsFavorite.value
  })
  const setFavoriteDoctor = (object) => {
    doctorIsFavorite.value = object.is_favorite
  }
  const addDoctorToFavorites = async (resourceId) => {
    try {
      const response = await axios.post(
        `v1/favorite/doctor-add/?user_id=${getUserId.value}`,
        {
          res_id: resourceId
        },
        {
          headers: {
            Authorization: `Bearer ${userTokenStorage.value}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      )

      if (response.data.status === 'ok') {
        toast.success(
          {
            component: CustomToast,
            props: {
              title: 'Врач добавлен в «Избранноe»',
              icon: 'success'
            }
          },
          toastOptions.value
        )
        doctorIsFavorite.value = true
      } else {
        toast.success(
          {
            component: CustomToast,
            props: {
              title: 'Не удалось добавить врача в «Избранноe»',
              text: 'Попробуйте еще раз',
              icon: 'error'
            }
          },
          toastOptions.value
        )
      }
    } catch (err) {
      throw new Error()
    }
  }
  const removeDoctorToFavorites = async (resourceId) => {
    try {
      const response = await axios.delete(
        `/v1/favorite/doctor-delete/?user_id=${getUserId.value}&res_id=${resourceId}`,
        {
          headers: {
            Authorization: `Bearer ${userTokenStorage.value}`
          }
        }
      )

      if (response.data.status === 'ok') {
        toast.success(
          {
            component: CustomToast,
            props: {
              title: 'Врач удален из «Избранноe»',
              icon: 'success'
            }
          },
          toastOptions.value
        )
        doctorIsFavorite.value = false
      } else {
        toast.success(
          {
            component: CustomToast,
            props: {
              title: 'Не удалось удалить врача из «Избранноe»',
              text: 'Попробуйте еще раз',
              icon: 'error'
            }
          },
          toastOptions.value
        )
      }
    } catch (err) {
      throw new Error()
    }
  }

  return {
    userTokenStorage,
    getUserId,
    addDoctorToFavorites,
    removeDoctorToFavorites,
    doctorsList,
    setFavoriteDoctor,
    doctorIsFavorite,
    getKeepAliveKey
  }
})
