import { ref, watch } from 'vue'
import { useAppInfoStore } from '@/stores/AppInfoStore'
import { storeToRefs } from 'pinia'
import { postLog } from '@/api/postLog'

export const useUpdateBanner = () => {
  const bannerUpdateIsOpen = ref(false)
  const bannerLink = ref('')
  const appStore = useAppInfoStore()
  const { appInfo } = storeToRefs(appStore)
  const platform = window?.device?.platform.toLowerCase() // 'ios' || 'android' || 'browser'

  watch(
    () => appInfo.value,
    () => {
      postLog({
        inside_watch_app_info: appInfo.value
      })
      if (!appInfo.value) return

      if (platform === 'ios') {
        if (appInfo.value.ios_version_raw > process.env.VUE_APP_IOS_VERSION_RAW) {
          bannerUpdateIsOpen.value = Boolean(appInfo.value.is_banner_store)
          bannerLink.value = appInfo.value.ios_url_app
        }
      }

      if (platform === 'android') {
        postLog({
          inside_android_android_version: appInfo.value.android_version,
          inside_android_VUE_APP_ANDROID_VERSION:
            process.env.VUE_APP_ANDROID_VERSION_RAW
        })
        if (
          appInfo.value.android_version_raw > process.env.VUE_APP_ANDROID_VERSION_RAW
        ) {
          bannerUpdateIsOpen.value = Boolean(appInfo.value.is_banner_store)
          bannerLink.value = appInfo.value.android_url_app

          postLog({
            inside_android_bannerUpdateIsOpen: bannerUpdateIsOpen.value,
            inside_android_bannerLink: bannerLink.value
          })
        }
      }
    },
    {
      immediate: true
    }
  )

  return {
    bannerUpdateIsOpen,
    bannerLink
  }
}
