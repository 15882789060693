<template>
  <div class="form-group">
    <label v-if="labelTitle" :for="nameId">{{ labelTitle }}</label>
    <textarea
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value.trim())"
      :placeholder="placeholder"
      class="form-group__textarea"
      :name="nameId"
    ></textarea>
  </div>
</template>

<script>
export default {
  name: 'TextAreaUi',
  props: {
    modelValue: {
      type: [String, null],
      required: true,
      default: null
    },
    nameId: {
      type: String,
      required: true
    },
    labelTitle: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    }
  }
}
</script>

<style scoped lang="scss">
.form-group {
  display: flex;
  gap: 5px;
  flex-direction: column;

  &__textarea {
    padding: 12px;
    background-color: #ffffff;
    border: 1px solid #bac7de;
    border-radius: 8px;
    resize: none;
    height: 108px;

    &::placeholder {
      opacity: 0.5;
    }

    &:focus {
      border: 1px solid #002856;
      outline: 1px solid #002856;
    }

    &:focus-visible {
      border: 1px solid #002856;
      outline: 1px solid #002856;
    }

    &::placeholder {
      color: $blue !important;
    }
  }

  & label {
    align-self: start;
    color: #062e4f;
    font-size: 14px;
    font-weight: 350;
  }
}
</style>
