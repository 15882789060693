<script>
import { computed, inject, onActivated, onDeactivated, reactive, ref, watchEffect } from 'vue'
import { authApi } from '@/api/authApi'
import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { useUserGlucoseStore } from '@/stores/UserGlucoseStore'
import { useRouter } from 'vue-router'
import DatePicker from 'vue-datepicker-next'
import TextAreaUi from '@/components/ui/form/TextAreaUi.vue'
import CustomButton from '@/components/ui/buttons/CustomButton.vue'
import VueDatePicker from '@vuepic/vue-datepicker'
import SectionWrapper from '@/components/ui/SectionWrapper.vue'
import ArrowTitle from '@/components/ui/typography/ArrowTitle.vue'
import TextBannerWarning from '@/components/Common/TextBannerWarning.vue'
import axios from 'axios'

export default {
  name: 'GlucoseMealFormView',
  props: {
    isApp: {
      type: Boolean,
      required: true,
      default: false
    },
    user: {
      type: [Object, null],
      required: true,
      default: null
    }
  },
  components: {
    CustomButton,
    TextAreaUi,
    DatePicker,
    VueDatePicker,
    ArrowTitle,
    TextBannerWarning,
    SectionWrapper
  },

  setup (props, { emit }) {
    const dp = ref(null)
    const router = useRouter()
    const userGlucoseStore = useUserGlucoseStore()
    const { glucoseSetup, currentGlucose, glucoseMeasureUnit } =
      storeToRefs(userGlucoseStore)
    const userStore = useUserStore()
    const { userToken } = storeToRefs(userStore)
    const { getUserId } = storeToRefs(userStore)
    const toast = inject('toast')
    const errorMsg = ref('')
    const datePickerTime = ref(null)
    const files = ref([])

    const userInfo = reactive({
      dateMeasurement: null,
      timeMeasurement: null,
      comment: ''
    })

    const rules = {
      dateMeasurement: { required },
      timeMeasurement: { required }
    }

    const v$ = useVuelidate(rules, userInfo)

    const handleTime = (modelData) => {
      let hours = modelData.hours
      let minutes = modelData.minutes
      if (modelData.hours < 10) hours = '0' + hours
      if (modelData.minutes < 10) minutes = '0' + minutes
      userInfo.timeMeasurement = `${hours}:${minutes}`
    }

    const showHelloToaster = (body, title, type = 'success') => {
      toast.show(
        `
      ${body}
      ${title}
      `,
        {
          position: 'bottom',
          type
        }
      )
    }

    const data = computed(() => {
      return {
        status_id: 5,
        type_id: 2,
        date_at: `${userInfo.dateMeasurement} ${userInfo.timeMeasurement}`,
        hint: userInfo.comment
      }
    })

    const btnIsDisabled = computed(() => {
      const { dateMeasurement, timeMeasurement } = userInfo

      return !dateMeasurement || !timeMeasurement
    })

    const setGlucose = async () => {
      const path = `/v6/events/diabet/set/?user_id=${getUserId.value}`
      const idGlucose = currentGlucose.value ? currentGlucose.value.id : ''

      const queryId = idGlucose ? `&id=${idGlucose}` : ''
      const bodyFormData = new FormData()

      for (const key in data.value) {
        bodyFormData.append(key, data.value[key])
      }

      files.value.forEach((file, index) => {
        bodyFormData.append(`fileInput[${index}]`, file, file.name)
      })

      return await axios({
        method: 'post',
        url: path + queryId,
        data: bodyFormData,
        headers: {
          Authorization: `Bearer ${userToken.value}`
        }
      })
    }

    const submitForm = async () => {
      v$.value.$touch()

      if (v$.value.$error) {
        return
      }
      try {
        const response = await setGlucose()
        if (response.data.status !== 'ok') {
          showHelloToaster(response.data.message, '', 'error')
          return
        }

        if (response.data.status === 'ok') {
          showHelloToaster('', 'Измерения успешно добавлены!')
          router.push('calendar-glucose')
        }
      } catch (err) {
        showHelloToaster(err.response.data.message, '', 'error')
      } finally {
        resetForm()
      }
    }

    const deleteMeasurment = async () => {
      try {
        const [err, response] = await authApi(
          `/v6/events/diabet/delete?user_id=${getUserId.value}&id=${currentGlucose.value.id}`,
          'delete'
        )
        if (err) {
          showHelloToaster(err, '', 'error')
          return
        }
        if (response.status === 'ok') {
          showHelloToaster('', 'Успешно удалено')
          router.push('calendar-glucose')
        }
      } catch (err) {
        showHelloToaster(err, '', 'error')
      } finally {
        resetForm()
      }
    }

    const resetForm = () => {
      userInfo.dateMeasurement = null
      userInfo.timeMeasurement = null
      userInfo.comment = ''

      datePickerTime.value = null
      userGlucoseStore.setCurrentGlucose(null)
      v$.value.$reset()
    }

    const onFilesChange = (e) => {
      let newFiles
      if (e.target.files.length > 5) {
        alert('Можно добавить максимум 5 фото')
        e.preventDefault()
        newFiles = e.target.files.slice(0, 5)
      } else {
        newFiles = e.target.files
      }

      if (files.value.length) {
        newFiles.forEach((f) => {
          if (files.value.length < 5) {
            files.value.push(f)
          }
        })
      } else {
        files.value = Array.from(newFiles)
      }
    }

    const deleteOnePhoto = (name) => {
      files.value = files.value.filter(f => f.name !== name)
    }

    const selectDate = () => {
      dp.value.selectDate()
    }

    watchEffect(() => {
      if (currentGlucose.value) {
        const currentGlucoseTime = currentGlucose.value.time_at.split(' ')

        userInfo.dateMeasurement = currentGlucoseTime[0]
        userInfo.timeMeasurement = currentGlucose.value.time
        userInfo.comment = currentGlucose.value.hint
      }
    })

    onActivated(() => {
      if (currentGlucose.value) {
        const currentGlucoseTime = currentGlucose.value.time_at.split(' ')
        const hours = currentGlucoseTime[1].split(':')
        datePickerTime.value = { hours: +hours[0], minutes: +hours[1], seconds: 0 }
      } else {
        resetForm()
        files.value = []
      }
    })

    onDeactivated(() => {
      resetForm()
      files.value = []
    })

    return {
      errorMsg,
      userInfo,
      v$,
      dp,
      submitForm,
      showHelloToaster,
      currentGlucose,
      glucoseMeasureUnit,
      glucoseSetup,
      btnIsDisabled,
      deleteMeasurment,
      handleTime,
      datePickerTime,
      onFilesChange,
      files,
      deleteOnePhoto,
      selectDate
    }
  }
}
</script>

<template>
  <ArrowTitle title="Календарь глюкозы" :is-app="isApp" />
  <SectionWrapper stretched>
    <h5>Добавьте прием пищи</h5>

    <TextBannerWarning canClose text="Опишите то, что вы ели, а также прикрепите фото для врача" />

    <div class="arbitrary-form">
      <form @submit.prevent="submitForm" @keyup.enter.prevent="submitForm" class="arbitrary-form__form">
        <div class="arbitrary-form__arbitrary">

          <div class="arbitrary-form__date-block">
            <h6 class="arbitrary-form__label">Дата приема пищи</h6>
            <DatePicker class="text-input login vaccination-sugar-date" v-model:value="userInfo.dateMeasurement" :class="{
              'validation-error': v$.dateMeasurement.$error
            }" placeholder="Выберите" value-type="YYYY-MM-DD" format="DD.MM.YYYY" :monday-first="true"
              :full-month-name="true" :lang="'ru'" :editable="false" />
            <div v-if="v$.dateMeasurement.$error" class="error help-block">
              Необходимо заполнить поле. Пример: 01.01.2000
            </div>
          </div>

          <div class="arbitrary-form__date-block">
            <h6 class="arbitrary-form__label">Время приема пищи</h6>
            <VueDatePicker ref="dp" time-picker placeholder="Выберите" :teleport="true" position="right" :auto-position="false"
              locale="ru-RU" v-model="datePickerTime" @update:model-value="handleTime"
              :clearable="false" class="time-picker" :class="{
                'validation-error': v$.timeMeasurement.$error
              }">
              <template #input-icon> </template>

              <template #action-buttons>
                <p class="custom-select" @click="selectDate">Выбрать</p>
              </template>

            </VueDatePicker>
            <div v-if="v$.timeMeasurement.$error" class="error help-block">
              Необходимо заполнить поле.
            </div>
          </div>
        </div>

        <TextAreaUi class="arbitrary-form__comment" v-model="userInfo.comment" name-id="arbitrary-comment"
          label-title="Опишите то, что вы ели в свободной форме"
          placeholder="Например, запеканка с курицей 300 г, помидоры, огурцы и чай" />

        <ul class="arbitrary-form__photo-list">
          <li v-for="file in files" :key="file.name">
            <span>{{ file.name }}</span>
            <img @click="deleteOnePhoto(file.name)" src="@/assets/uploads/icon/profile/trash-bin-icon.svg"
              alt="удалить учетную запись" />
          </li>
        </ul>

        <div class="arbitrary-form__photo-btn">
          <label for="arbitrary-form__upload-btn" class="arbitrary-form__upload-btn">
          </label>
          <input id="arbitrary-form__upload-btn" type="file" multiple @change="onFilesChange"
            accept=".png, .jpg, .jpeg, .pdf" />

          <div class="arbitrary-form__photo-btn-text-wrapper">
            <img class="arbitrary-form__photo-btn-icon" src="@/assets/uploads/icon/common/plus-rounded.svg"
              alt="plus" />
            <span class="arbitrary-form__photo-btn-text">ДОБАВИТЬ ФОТО</span>
          </div>

        </div>

        <CustomButton :disabled="btnIsDisabled" tag="button" type="submit" marginBottom="0">
          сохранить
        </CustomButton>
        <CustomButton class="arbitrary-form__btn" v-if="currentGlucose" @click="deleteMeasurment" tag="button"
          type="button" marginBottom="0" white>
          удалить
        </CustomButton>
      </form>
    </div>
  </SectionWrapper>
</template>

<style scoped lang="scss">
h5 {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  margin-bottom: 12px;
}

.arbitarary-form {
  overflow-y: auto;
  background: #fff;
  border-radius: 8px 8px 0 0;
  padding: 12px 0;
}

.arbitarary-form__wrapper {
  height: 600px;
}

.arbitarary-form__title {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  margin-bottom: v-bind('mb');

  h3 {
    color: #082b53;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }

  img {
    filter: invert(11%) sepia(31%) saturate(5093%) hue-rotate(198deg) brightness(102%) contrast(103%);
  }
}

.arbitarary-form__content {
  text-align: center;

  img {
    margin-bottom: 32px;
  }

  a {
    color: #062e4f;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 120%;
    text-transform: uppercase;
    border-radius: 30px;
    background: var(--blue-1, #bac7de);
    padding: 11px;
    width: 100%;
    border: none;
    margin-top: 16px;
  }

  margin-top: 24px;
}

.arbitrary-form {}

.arbitrary-form__form {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.arbitrary-form__title {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
}

.arbitrary-form__text {
  text-align: center;
}

.arbitrary-form__dates {
  display: flex;
  gap: 8px;
}

.arbitrary-form__arbitrary {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.arbitrary-form__date-block {
  flex: 1;
}

.arbitrary-form__label {
  text-align: left;
}

.text-input {}

.login {}

.vaccination-add-date {}

.error {}

.help-block {}

.arbitrary-form__comment {}

.arbitrary-form__subtitle {
  margin-bottom: 16px;
  text-align: start;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.arbitrary-form__wrapper {
  display: flex;
  gap: 24px;
  margin-bottom: 16px;
}

.arbitrary-form-info {
  display: flex;
  padding: 16px 13.5px;
  gap: 12px;
  background-color: #e1e8f4;
  border-radius: 5px;
}

.arbitrary-form-info-img {
  align-self: start;
}

.arbitrary-form-info__text {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.arbitrary-form-info__text-main {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #002856;
}

.arbitrary-form__toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #002856;
  }
}

.arbitrary-form__btn {
  margin-bottom: 100px;
}

.time-picker {
  position: relative !important;
}

.time-picker::before {
  content: '';
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  background-image: url('@/assets/uploads/images/home-page/my-appointment/clock.svg');
  background-size: cover;
  pointer-events: none;
  filter: brightness(0) saturate(100%) invert(67%) sepia(6%) saturate(14%) hue-rotate(354deg) brightness(93%) contrast(85%);
  z-index: 10;
}

.arbitrary-form__photo-btn {
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 34px;
  border: 1px solid #002856;
  border-radius: 30px;
  background: #ffffff00;
}

#arbitrary-form__upload-btn {
  display: none;
  position: relative;
}

.arbitrary-form__upload-btn {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 11;
}

.arbitrary-form__photo-btn-text-wrapper {
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  width: 100%;
  height: 100%;
  gap: 5px;
  top: 0;
  z-index: 10;

  display: flex;
  align-items: center;
  justify-content: center;
}

.arbitrary-form__photo-btn-icon {}

.arbitrary-form__photo-btn-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: #002856;
}

.arbitrary-form__photo-list {
  display: flex;
  flex-direction: column;

  li {
    padding: 11px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    >span {
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
      text-align: left;
      color: #002856;
    }

    >img {
      &:hover {
        cursor: pointer;
        filter: brightness(0) saturate(100%) invert(24%) sepia(74%) saturate(2939%) hue-rotate(347deg) brightness(83%) contrast(113%);
      }
    }
  }
}

.custom-select {
  height: 30px;
  background-color: #002856;
  color: #FFF;
  font-size: 12px;
  font-weight: 400;
  padding: 4px 6px;
  text-align: center;
  border-radius: 30px;
  line-height: 20px;
  cursor: pointer;
}
</style>

<style>
.time-picker {
  input {
    font-family: Circe !important;
    color: #002856 !important;
    margin-bottom: 5px !important;
    background: #fff !important;
    border: 1px solid #bac7de !important;
    border-radius: 5px !important;
    padding: 12px !important;
    width: 100% !important;
    line-height: 1 !important;
    font-size: 14px !important;
    height: 100% !important;
  }

  input::placeholder {
    color: #343a40 !important;
  }
}
</style>
