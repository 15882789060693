export const scrollToFirstError = (extraTop = 70) => {
  const domRect = document
    .querySelector('.help-block.error')
    .getBoundingClientRect()

  window.scrollTo(
    domRect.left + document.documentElement.scrollLeft,
    domRect.top + document.documentElement.scrollTop - extraTop
  )
}
