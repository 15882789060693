<script>
import { ref, toRefs, watch } from 'vue'
import TextAreaUi from '@/components/ui/form/TextAreaUi.vue'
import axios from 'axios'
import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'
import CustomButton from '@/components/ui/buttons/CustomButton.vue'
import { required, sameAs } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import FormInputCheckbox from '@/components/ui/form/FormInputCheckbox.vue'
import ModalWrapper from '@/components/Common/ModalWrapper.vue'

export default {
  name: 'AppointmentReviewView',
  components: {
    ModalWrapper,
    FormInputCheckbox,
    CustomButton,
    TextAreaUi
  },
  props: {
    isOpen: {
      type: [Boolean, null],
      required: true,
      default: null
    },
    score: {
      type: [Number, null],
      required: true,
      default: null
    },
    appointment: {
      type: [Object, null],
      required: true,
      default: null
    },
    doctorInfo: {
      type: [Object, null],
      required: true,
      default: null
    }
  },
  emits: ['closeModal'],
  setup (props, { emit }) {
    const { appointment, doctorInfo, isOpen } = toRefs(props)
    const error = ref('')
    const review = ref({
      rate0: null,
      rate1: null,
      rate2: null,
      rate3: null,
      rate4: null,
      rate5: null,
      comment: '',
      rules: true,
      isTerms: true
    })
    const rules = {
      comment: { required },
      rules: { sameAs: sameAs(true) },
      isTerms: { sameAs: sameAs(true) }
    }
    const v$ = useVuelidate(rules, review)
    const modalIsOpen = ref(false)
    const userStore = useUserStore()
    const { userToken, getUserId } = storeToRefs(userStore)

    const closeReviewModal = () => {
      modalIsOpen.value = false
      emit('closeModal')
    }
    const submitForm = async () => {
      v$.value.$touch()
      if (v$.value.$invalid) {
        error.value = 'Заполните все поля.'
      } else {
        try {
          const response = await axios.post(
            `/v1/form/nps-doctor/?user_id=${getUserId.value}`,
            {
              doc_id: doctorInfo.value.ex_doctor_id,
              rec_id: appointment.value.id,
              comment: review.value.comment,
              rate_1: review.value.rate0,
              rate_2: review.value.rate1,
              rate_3: review.value.rate2,
              rate_4: review.value.rate3,
              rate_5: review.value.rate4,
              rate_6: review.value.rate5,
              is_terms: Number(review.value.rules),
              is_rules: Number(review.value.isTerms)
            },
            {
              headers: {
                Authorization: `Bearer ${userToken.value}`,
                'Content-Type': 'application/json'
              }
            }
          )

          if (response.data.status === 'ok') {
            modalIsOpen.value = true
            window.scrollTo(0, 0)
            v$.value.$reset()
          } else {
            error.value = response.data.message
            review.value.comment = ''
            review.value.rate0 = null
            review.value.rate1 = null
            review.value.rate2 = null
            review.value.rate3 = null
            review.value.rate4 = null
            review.value.rate5 = null
          }
        } catch (err) {
        }
      }
    }
    watch(isOpen,
      () => {
        review.value.comment = ''
        review.value.rate0 = null
        review.value.rate1 = null
        review.value.rate2 = null
        review.value.rate3 = null
        review.value.rate4 = null
        review.value.rate5 = null
        error.value = ''
      },
      {
        immediate: true,
        deep: true
      }
    )
    review.value.comment = ''
    review.value.rate0 = null
    review.value.rate1 = null
    review.value.rate2 = null
    review.value.rate3 = null
    review.value.rate4 = null
    review.value.rate5 = null
    error.value = ''

    return {
      review,
      submitForm,
      modalIsOpen,
      error,
      v$,
      closeReviewModal
    }
  }
}

</script>

<template>
  <div class="review__wrapper" v-if="appointment && doctorInfo">
    <div class="history__item">
      <div class="history__top">
        <img
          src="@/assets/uploads/images/home-page/my-appointment/clock.svg"
          alt="картинка часов"
        />
        <div class="history__time" v-if="appointment.time">
          {{ appointment.time }}
        </div>
        <div class="history__date">
          {{ appointment.date }}
        </div>
        <div class="history__type">
          {{ appointment.type }}
        </div>
      </div>
      <div class="history__middle">
        <div class="history__picture" v-if="appointment.doctorImg">
          <img :src="appointment.doctorImg" :alt="appointment.doctorName"/>
        </div>
        <div class="history__info">
          <p class="history__department">
            {{ doctorInfo.title }}
          </p>
          <h4 class="history__title">
            {{ doctorInfo.doctor_name }}
          </h4>
        </div>
      </div>
      <div class="history__bottom" v-if="doctorInfo.clinic_name">
        <img
          src="@/assets/uploads/images/home-page/my-appointment/marker.svg"
          alt="знак метки на карте"
        />
        <p class="history__filial">Филиал "{{ doctorInfo.clinic_name }}"</p>
      </div>
      <form class="review__form" @submit.prevent="submitForm">
        <div class="review__form-answers text-center">
          <div class="review__form-title" v-if="score > 3">
            <h2>Спасибо за обратную связь!</h2>
            <p>Расскажите, что именно вам понравилось</p>
          </div>
          <div class="review__form-title" v-else>
            <h2>Спасибо за обратную связь.</h2>
            <p>Расскажите подробнее, что нам стоит улучшить</p>
          </div>
          <div class="review__form-line">
            <p>Доктор поприветствовал вас и представился</p>
            <ul>
              <li v-for='i in 5' :key="i">
                <label :class="{'checked': review.rate0 >= i}">
                  <input type="radio" name="rate0" :id="`rate0-${i}`" :value="i" v-model="review.rate0">
                  <img src="@/assets/uploads/icon/common/star-light.svg" alt="star">
                </label>
              </li>
            </ul>
          </div>
          <div class="review__form-line">
            <p>Доктор принял Вас вовремя по расписанию</p>
            <ul>
              <li v-for='i in 5' :key="i">
                <label :class="{'checked': review.rate1 >= i}">
                  <input type="radio" name="rate1" :id="`rate1-${i}`" :value="i" v-model="review.rate1">
                  <img src="@/assets/uploads/icon/common/star-light.svg" alt="star">
                </label>
              </li>
            </ul>
          </div>
          <div class="review__form-line">
            <p>Доктор Вас внимательно выслушал и уделил достаточно времени для решения Вашего вопроса</p>
            <ul>
              <li v-for='i in 5' :key="i">
                <label :class="{'checked': review.rate2 >= i}">
                  <input type="radio" name="rate2" :id="`rate2-${i}`" :value="i" v-model="review.rate2">
                  <img src="@/assets/uploads/icon/common/star-light.svg" alt="star">
                </label>
              </li>
            </ul>
          </div>
          <div class="review__form-line">
            <p>Вы получили подробные ответы на ваши вопросы от&nbsp;доктора во время приема</p>
            <ul>
              <li v-for='i in 5' :key="i">
                <label :class="{'checked': review.rate3 >= i}">
                  <input type="radio" name="rate3" :id="`rate3-${i}`" :value="i" v-model="review.rate3">
                  <img src="@/assets/uploads/icon/common/star-light.svg" alt="star">
                </label>
              </li>
            </ul>
          </div>
          <div class="review__form-line">
            <p>Вы еще вернетесь к доктору по мере необходимости</p>
            <ul>
              <li v-for='i in 5' :key="i">
                <label :class="{'checked': review.rate4 >= i}">
                  <input type="radio" name="rate4" :id="`rate4-${i}`" :value="i" v-model="review.rate4">
                  <img src="@/assets/uploads/icon/common/star-light.svg" alt="star">
                </label>
              </li>
            </ul>
          </div>
          <div class="review__form-line">
            <p>Вы остались довольны визитом</p>
            <ul>
              <li v-for='i in 5' :key="i">
                <label :class="{'checked': review.rate5 >= i}">
                  <input type="radio" name="rate5" :id="`rate5-${i}`" :value="i" v-model="review.rate5">
                  <img src="@/assets/uploads/icon/common/star-light.svg" alt="star">
                </label>
              </li>
            </ul>
          </div>
          <div class="review__form-line">
            <text-area-ui
              label-title="Комментарий"
              name-id="comment"
              placeholder="Если есть, что еще добавить"
              v-model="review.comment"/>
          </div>
          <div class="review__checks">
            <FormInputCheckbox
              id="profile-personal"
              v-model="review.isTerms"
              label="Я согласен на"
              linkLabel="обработку моих персональных данных"
              href="https://www.k31.ru/info/soglasie/"
              :validError="v$.isTerms.$error"
            />
            <FormInputCheckbox
              id="profile-rules"
              v-model="review.rules"
              label="Я ознакомлен с"
              linkLabel="правилами внутреннего распорядка"
              href="https://www.k31.ru/upload/doc/useterms.pdf"
              :validError="v$.rules.$error"
            />
          </div>
          <div v-if="error" class="message" v-html="error"></div>
          <CustomButton tag="button" type="submit" marginBottom="20px">
            Отправить
          </CustomButton>
          <CustomButton white tag="router-link" to="/med-card" @click="closeReviewModal">
            Пропустить
          </CustomButton>
        </div>
      </form>
    </div>
  </div>
  <ModalWrapper
    :modal-is-open="modalIsOpen"
    @modal-close="closeReviewModal"
    title="Оставить отзыв"
  >
    <img src="@/assets/uploads/images/modal-review.svg" alt=""/>
    <p class="modal-text--success">
      Отзыв отправлен <br>
      <span>Обратная связь помогает нам стать лучше</span>
    </p>
    <CustomButton white type="button" tag="button" @click="closeReviewModal">
      Закрыть
    </CustomButton>
  </ModalWrapper>
</template>

<style scoped lang="scss">
.review__checks {
  text-align: left;
  margin-bottom: 24px;
}

.message {
  color: red;
  font-size: 14px;
  font-weight: 350;
  line-height: 100%;
  margin-bottom: 15px;
  margin-top: 10px;
  text-align: center;
}

.review {
  &__wrapper {
    margin-top: 24px;
  }

  &__form {
    padding-top: 24px;
    margin-top: 24px;
    border-top: 1px solid #BAC7DE;

    &-line {
      margin-bottom: 16px;

      label {
        width: 100%;
        text-align: left;
        color: #002856;
        font-size: 14px;
        font-weight: 350;
        line-height: 100%;
        margin-bottom: 8px;
      }

      p {
        color: #002856;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        margin-bottom: 8px;
      }

      ul {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;

        input {
          visibility: hidden;
          position: absolute;
          z-index: -10;
        }
      }

      img {
        filter: invert(96%) sepia(1%) saturate(5289%) hue-rotate(184deg) brightness(97%) contrast(98%);
      }

      .checked {
        img {
          filter: invert(13%) sepia(42%) saturate(2677%) hue-rotate(191deg) brightness(101%) contrast(107%);
        }
      }
    }
  }
}

.review__form-title {
  margin-bottom: 24px;

  h2 {
    color: #002856;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 6px;
  }

  p {
    color: #002856;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }
}

.review__form-line {
  .form-group {
    width: 100%;
  }

  textarea {
    width: 100%;
    border-radius: 8px;
    border: 1px solid #BAC7DE;
    background: #FFF;
    color: #7F8DA9;
    font-size: 14px;
    font-weight: 350;
    line-height: 100%;
  }
}

.history {
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 14px;
  color: #7f8da9;
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__item {
    padding: 8px;
    background: #ffffff;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    gap: 13px;
  }

  &__top {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &__time {
    margin-right: 10px;
    font-weight: 700;
    color: #222222;
  }

  &__date {
    margin-right: auto;
  }

  &__type {
    padding: 2px 8px;
    background: #002856;
    border-radius: 20px;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
  }

  &__link {
    display: flex;
    flex-direction: column;
    gap: 13px;
  }

  &__middle {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__picture {
    max-width: 45px;

    & img {
      border-radius: 50%;
    }
  }

  &__info {
  }

  &__department {
    margin-bottom: 4px;
  }

  &__title {
    color: #002856;
    text-align: left;
  }

  &__bottom {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__filial {
    margin-right: auto;
  }

  &__calendar {
    text-decoration-line: underline;
    color: #002856;
  }

  &__review {
    border-top: 1px solid $blue-light;
    text-align: center;
    padding: 12px 0 4px;
    font-weight: 700;

    h3 {
      margin-bottom: 12px;
    }

    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;

      li {
        img {
          filter: invert(96%) sepia(1%) saturate(5289%) hue-rotate(184deg) brightness(97%) contrast(98%);
        }
      }
    }
  }
}

.modal-text--success {
  color: #062e4f;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  margin-top: 24px;

  margin-bottom: 24px;

  span {
    display: block;
    color: #002856;
    font-size: 14px;
    margin-top: 5px;
    font-weight: 400;
  }
}
</style>
