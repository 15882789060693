<script>
import { ref, toRefs, watch, watchEffect } from 'vue'
import TransitionWrapper from '@/components/ui/TransitionWrapper.vue'
import TimePickerMobile from '@/components/ui/form/TimePickerMobile.vue'
import TimePickerDesktop from '@/components/ui/form/TimePickerDesktop.vue'

export default {
  name: 'TimePicker',
  components: { TimePickerDesktop, TimePickerMobile, TransitionWrapper },
  emits: ['changeTime'],
  props: {
    payLoadChangeCourse: {
      type: Object,
      required: false
    },
    listOfTime: {
      type: Array,
      required: false
    },
    title: {
      type: String,
      required: true,
      default: 'Время приема'
    }
  },

  setup (props, { emit }) {
    const { payLoadChangeCourse, listOfTime } = toRefs(props)
    const currentTime = ref(null)
    const showPopupTime = (event) => {
      event.target.showPicker()
    }

    const listOfTimeLocal = ref([])

    const addTime = (time) => {
      listOfTimeLocal.value.push(time)
      currentTime.value = null
      emit('changeTime', listOfTimeLocal.value)
    }

    const removeTime = (index) => {
      listOfTimeLocal.value.splice(index, 1)
      emit('changeTime', listOfTimeLocal.value)
    }

    watchEffect(() => {
      if (payLoadChangeCourse.value) {
        listOfTimeLocal.value = payLoadChangeCourse.value.time.map(
          (t) => t.time
        )
        emit('changeTime', listOfTimeLocal.value)
      }
    })

    watch(
      () => listOfTime.value,
      () => {
        if (listOfTime.value.length === 0) {
          listOfTimeLocal.value = []
        }
        if (listOfTime.value.length > 0) {
          listOfTimeLocal.value = listOfTime.value
        }
      }
    )

    return {
      showPopupTime,
      currentTime,
      listOfTimeLocal,
      addTime,
      removeTime
    }
  }
}
</script>

<template>
  <div class="time-picker">
    <h5 class="time-picker__title">{{ title }}</h5>
    <a
      class="time-picker__label mobile"
      data-bs-toggle="modal"
      data-bs-target="#time-picker-mobile"
    >
      <span>Выберите в списке</span>
    </a>
    <div class="time-picker__desktop">
      <TimePickerDesktop @addTime="addTime" />
    </div>

    <div v-if="listOfTimeLocal.length !== 0">
      <TransitionWrapper class="time-picker__list">
        <button
          v-for="(time, index) in listOfTimeLocal"
          :key="index"
          type="button"
          @click="removeTime(index)"
          class="time-picker__close"
        >
          <span class="time-picker__time">
            {{ time }}
          </span>
          <img
            src="@/assets/uploads/icon/calendar/close.svg"
            alt="кнопка закрытия"
            class="time-picker__picture"
          />
        </button>
      </TransitionWrapper>
    </div>
    <TimePickerMobile @addTime="addTime" />
  </div>
</template>

<style scoped lang="scss">
.time-picker {
}

.time-picker__title {
}

.time-picker__label {
  position: relative;
  display: block;
  border-radius: 8px;

  &.mobile {
    display: none;

    @include tablets-md {
      display: block;
    }
  }
}

.time-picker__desktop {
  display: block;

  @include tablets-md {
    display: none;
  }
}

.time-picker__label:before {
  position: absolute;
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background-image: url("~@/assets/uploads/icon/common/arrow-down.svg");
  background-repeat: no-repeat;
  top: 50%;
  z-index: 5;
  right: 7px;
  background-position: center;
  transform: translateY(-50%);
}

.time-picker__label input {
  // opacity: 0;
  // width: 0px;
  // height: 0px;
  // position: relative;
  // z-index: -1;
}

.time-picker__list {
  padding-top: 8px;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.time-picker__close {
  padding: 10px 8px;
  display: inline-flex;
  gap: 10px;
  align-items: center;
  border-radius: 100px;
  border: 1px solid $blue-light;
  background: linear-gradient(0deg, #e1e8f4 0%, #e1e8f4 100%), #fff;
  color: #323232;
  line-height: 100%;
}
</style>
