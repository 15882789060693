<script>
import { computed, inject, onMounted, toRefs } from 'vue'
import { useUserGlucoseStore } from '@/stores/UserGlucoseStore'
import { authApi } from '@/api/authApi'
import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'

export default {
  name: 'GlucoseListItem',
  emits: ['updateGlucose', 'openAddForm'],
  props: {
    glucose: {
      type: Object,
      required: true
    }
  },

  setup (props, { emit }) {
    const dayjs = inject('dayjs')
    const router = useRouter()
    const userGlucoseStore = useUserGlucoseStore()
    const { glucoseMeasureUnit } = storeToRefs(userGlucoseStore)
    const { glucose } = toRefs(props)
    const glucoseStatus = computed(() => {
      return glucose.value.status_id
    })
    const userStore = useUserStore()
    const { getUserId } = storeToRefs(userStore)

    const disabledBtn = () => {
      return new Date() < new Date(glucose.value.time_at.split(' ').join('T'))
    }

    // 1 - Внести измерения / Пропустить
    // 5 - Измерено / Отменить
    // 8 - Пропущено / Внести данные
    const btnConfirmText = (status) => {
      if (status === 1) {
        return 'Внести измерения'
      } else if (status === 5) {
        return 'Измерено'
      } else {
        return 'Пропущено'
      }
    }

    const btnCancelText = (status) => {
      if (status === 1) {
        return 'Пропустить'
      } else if (status === 5) {
        return 'Отменить'
      } else {
        return 'Внести данные'
      }
    }

    const changeCurrentGlucose = (typeId) => {
      userGlucoseStore.setCurrentGlucose(glucose.value)
      if (typeId === 1) router.push('calendar-glucose-measurment')
      if (typeId === 2) router.push('calendar-glucose-meal')
      if (typeId === 3) router.push('calendar-glucose-activity')
      if (typeId === 4) router.push('calendar-medications')
    }

    const setGlucose = async (statusId) => {
      const path = `/v6/events/diabet/set/?user_id=${getUserId.value}&id=${glucose.value.id}`
      const [err, response] = await authApi(path, 'post', {
        status_id: statusId,
        sugar: '1',
        when_id: 1
      })
      return [err, response]
    }

    const changeGlucoseInfo = (typeId) => {
      if (glucoseStatus.value === 1) {
        changeCurrentGlucose(typeId)
      }
    }

    const changeStatusGlucose = async (typeId) => {
      if (glucoseStatus.value === 5) {
        await setGlucose(1)
        emit('updateGlucose')
      }

      if (glucoseStatus.value === 1) {
        await setGlucose(8)
        emit('updateGlucose')
      }

      if (glucoseStatus.value === 8) {
        changeCurrentGlucose(typeId)
      }
    }

    const checkIfPastCurrentTime = (glucoseTime) => {
      return dayjs() > dayjs(glucoseTime)
    }

    onMounted(async () => {
      // менять статус на прошедший, если прошло 2часа
      if (glucoseStatus.value === 1) {
        const currentDateBeforeTwoHours = dayjs().subtract(2, 'hour')
        if (currentDateBeforeTwoHours > dayjs(glucose.value.time_at)) {
          await setGlucose(8)
          emit('updateGlucose')
        }
      }
    })

    return {
      glucoseStatus,
      btnConfirmText,
      btnCancelText,
      changeCurrentGlucose,
      disabledBtn,
      changeStatusGlucose,
      changeGlucoseInfo,
      glucoseMeasureUnit,
      checkIfPastCurrentTime
    }
  }
}
</script>

<template>
  <div v-if="checkIfPastCurrentTime(glucose.time_at)" class="glucose-item">
    <div class="glucose-item__top">
      <div class="glucose-item__date">
        <img
          src="@/assets/uploads/images/home-page/my-appointment/clock.svg"
          alt="картинка часов"
        />
        <h5>{{ glucose.time }}</h5>
      </div>

      <div v-if="glucose.status_id === 5" class="glucose-item__info">
        <div class="glucose-item__glucose">
          <div v-if="glucose.type_id === 1" class="glucose-item__number">
            {{ glucose.sugar }} {{ glucoseMeasureUnit.name }}
          </div>
          <div v-if="glucose.type_id === 2" class="glucose-item__number">
            Питание
          </div>
          <div v-if="glucose.type_id === 3" class="glucose-item__number">
            Активность
          </div>
          <div v-if="glucose.type_id === 4" class="glucose-item__number">
            Лекарства
          </div>
          <div
            v-if="glucose.when_id && glucose.type_id === 1"
            class="glucose-item__desc"
          >
            {{ glucose.when_id === 1 ? 'Натощак' : 'После приема пищи' }}
          </div>
        </div>
      </div>

      <img class="glucose-item__icon" v-if="glucose.type_id === 1" src="@/assets/uploads/images/calendar/glucose/menu/blood-test.svg" alt="иконка измерения">
      <img class="glucose-item__icon" v-if="glucose.type_id === 2" src="@/assets/uploads/images/calendar/glucose/menu/meal.svg" alt="иконка измерения">
      <img class="glucose-item__icon" v-if="glucose.type_id === 3" src="@/assets/uploads/images/calendar/glucose/menu/activity-icon.svg" alt="иконка измерения">
      <img class="glucose-item__icon" v-if="glucose.type_id === 4" src="@/assets/uploads/images/calendar/glucose/menu/tablets-icon.svg" alt="иконка измерения">

      <div v-if="glucoseStatus === 5" class="glucose-item__arrow-feel-wrapper">
        <button
          v-if="glucose.status_id === 5"
          @click="changeCurrentGlucose(glucose.type_id)"
          class="glucose-item__btn"
          type="button"
        >
          <img
            class="glucose-item__arrow"
            src="@/assets/uploads/icon/common/arrow-down.svg"
            alt="стрелка перехода"
          />
        </button>
      </div>
    </div>

    <div
      v-if="glucose.hint && glucose.status_id === 5"
      class="glucose-item__bottom"
    >
      {{ glucose.hint }}
    </div>

    <div v-if="glucose.files.length" class="glucose-item__files">
      <img src="@/assets/uploads/icon/chat/paperclip-icon.svg" alt="">
      <a v-for="file in glucose.files" :key="file.id" :href="file.url" target="_blank">{{ file.orig_name }}</a>
    </div>

    <div class="glucose-item__btns" v-if="glucose.type_id === 1">
      <button
        type="button"
        @click="changeGlucoseInfo(glucose.type_id)"
        class="glucose-item__confirm"
        :disabled="glucoseStatus === 5 || glucoseStatus === 8 || disabledBtn()"
        :class="{
          active: glucoseStatus === 1,
          cancel: glucoseStatus === 8 || disabledBtn(),
          complete: glucoseStatus === 5
        }"
      >
        <img
          v-if="glucoseStatus === 5"
          src="@/assets/uploads/icon/calendar/confirm-icon.svg"
          alt="галочка успеха"
        />
        <span>{{ btnConfirmText(glucoseStatus) }}</span>
      </button>
      <button
        @click="changeStatusGlucose(glucose.type_id)"
        class="glucose-item__cancel"
        type="button"
        :disabled="disabledBtn()"
        :class="{
          cancel: disabledBtn()
        }"
      >
        {{ btnCancelText(glucoseStatus) }}
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.glucose-item {
  padding: 16px 0;
  border-top: 1px solid $blue-extra-light;
}

.glucose-item__top {
  display: flex;
  align-items: center;
  min-height: 40px;
}

.glucose-item__date {
  margin-right: 36.5px;

  h5 {
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0.01em;
    text-align: left;
  }
}

.glucose-item__info {
  display: flex;
  gap: 16px;
}

// .glucose-item__glucose {
// }

.glucose-item__btn {
  margin-left: auto;
  padding: 5px;
  background: unset;
  border: unset;
}

.glucose-item__number {
  color: #002856;
  font-size: 24px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
}

.glucose-item__desc {
  text-align: end;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}

// .glucose-item__minute {
// }

.glucose-item-feel {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  display: flex;
  align-items: center;
}

// .glucose-item__minute-desc {
// }
.glucose-item__arrow-feel-wrapper {
  display: flex;
}

.glucose-item__bottom {
  margin-top: 16px;
}

.glucose-item__date {
  gap: 5px;
  color: #222;
  display: flex;
  align-items: center;
  line-height: 1;

  & h5 {
    font-weight: 700;
    font-size: 16px;
  }

  & p {
    font-size: 14px;
  }
}

.glucose-item__arrow {
  margin-top: 4px;
  rotate: -90deg;
}

.glucose-item__bottom {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  color: #002856;
}

.glucose-item__btns {
  padding-top: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 10px;

  & button {
    padding: 8px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    max-width: 200px;
    width: 100%;
  }
}

.glucose-item__confirm {
  border: unset;
  display: flex;
  gap: 8px;

  &.active {
    color: $blue;
    background: $blue-light;
    border: 1px solid $blue-light;
  }

  &.cancel {
    color: #fff;
    border: 1px solid #c4c4c4;
    background: #c4c4c4;
  }

  &.complete {
    border: 1px solid $blue;
    background: $blue;
    color: #fff;
  }
}

.glucose-item__more {
  border: unset;
  color: $blue;
  text-decoration-line: underline;
}

.glucose-item__cancel {
  border: unset;
  border: 1px solid $blue;

  &.cancel {
    color: #fff;
    border: 1px solid #c4c4c4;
    background: #c4c4c4;
  }
}

// .feel-red {
//     color: #FF1F00
// }
// .feel-green {
//     color: #43D854;
// }

.glucose-item__icon {
  margin-left: auto;
  margin-right: 20px;
}

.glucose-item__files {
  margin-top: 16px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;

  a {
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    text-align: left;
  }
}
</style>
