import { postLog } from '@/api/postLog'

const dataTypes = ['steps', 'distance', 'calories', 'weight', 'height', 'activity']
// const platform = window?.device?.platform.toLowerCase()
const endDate = new Date()
const startDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate())

export const checkIfAvailable = async () => {
  return await new Promise((resolve, reject) => {
    window?.cordova?.plugins?.health.isAvailable((res) => {
      postLog({ m: 'isAvailable success', res: JSON.stringify(res) })
      if (res === true || res.toLowerCase() === 'ok') {
        resolve(true)
      } else {
        resolve(res)
      }
    }, (err) => {
      postLog({
        m: 'isAvailable failed',
        error: err
      })
      reject(err)
    })
  })
}

export const checkIfAuthorized = async () => {
  return await new Promise((resolve, reject) => {
    window?.cordova?.plugins?.health.isAuthorized({ read: dataTypes }, (res) => {
      postLog({ m: 'isAuthorized success', res: JSON.stringify(res) })
      resolve(res)
    }, (error) => {
      postLog({
        m: 'isAuthorized failed',
        error: error
      })
      reject(error)
    })
  })
}

export const requestAuthorization = async () => {
  return await new Promise((resolve, reject) => {
    window?.cordova?.plugins?.health.requestAuthorization({ read: dataTypes }, (res) => {
      postLog({ m: 'requestAuthorization success', res: JSON.stringify(res) })
      const result = res === true
      resolve(result)
    }, (error) => {
      postLog({
        m: 'isAuthorized failed',
        error: error
      })
      reject(error)
    })
  })
}

export const getHealth = async (dataType) => {
  return await new Promise((resolve, reject) => {
    window.cordova?.plugins?.health.queryAggregated({
      startDate: startDate,
      endDate: endDate,
      dataType: dataType,
      bucket: 'day'
    }, (data) => {
      postLog({
        m: 'queryAggregated success',
        data: data,
        dataType: dataType
      })
      resolve(data)
    }, (err) => {
      postLog({
        m: 'queryAggregated failed',
        error: err,
        dataType: dataType
      })
      reject(err)
    })
  })
}

export const getHealthSinceMonth = async (dataType) => {
  return await new Promise((resolve, reject) => {
    window.cordova?.plugins?.health.queryAggregated({
      startDate: new Date(new Date().getTime() - 31 * 24 * 60 * 60 * 1000),
      endDate: new Date(),
      dataType: dataType,
      bucket: 'day'
    }, (data) => {
      postLog({
        m: 'queryAggregated success',
        data: data,
        dataType: dataType
      })
      resolve(data)
    }, (err) => {
      postLog({
        m: 'queryAggregated failed',
        error: err,
        dataType: dataType
      })
      reject(err)
    })
  })
}

export const getHealthQuery = async (dataType) => {
  return await new Promise((resolve, reject) => {
    window.cordova?.plugins?.health.query({
      startDate: new Date(new Date().getTime() - 31 * 24 * 60 * 60 * 1000),
      endDate: new Date(),
      dataType: dataType,
      limit: 1000
    }, (data) => {
      postLog({
        m: 'query success',
        data: data,
        dataType: dataType
      })
      resolve(data)
    }, (err) => {
      postLog({
        m: 'query failed',
        error: err,
        dataType: dataType
      })
      reject(err)
    })
  })
}

export const getAccess = async () => {
  try {
    const platform = window?.device?.platform.toLowerCase()

    if (platform === 'ios' || platform === 'android') {
      const isAvailable = await checkIfAvailable()
      const isAuthorized = await checkIfAuthorized()

      postLog({ m: 'onActivated home', data: { available: JSON.stringify(isAvailable), authorized: JSON.stringify(isAuthorized) } })

      if (platform === 'android') {
        if (isAvailable === true && isAuthorized === false) {
          const reqAuthorized = await requestAuthorization()
          postLog({ m: 'isAuthorized not true', data: JSON.stringify(reqAuthorized) })
          return reqAuthorized
        }

        return isAvailable && isAuthorized
      } else if (platform === 'ios') {
        await requestAuthorization()

        if (platform === 'ios') return isAvailable
      }
    } else {
      return false
    }
  } catch (err) {
    return false
  }
}
