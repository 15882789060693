<template>
  <Teleport to="body">
    <Transition name="overlay">
      <div v-if="isServicesModalOpen" class="modal-wrapper">
        <Transition name="wrapper" appear>
          <div v-if="isServicesModalOpen" class="pop-services__wrapper">
            <div class="pop-services__menu">
              <img @click="closeModal" class="pop-services__item-type-icon pop-services__exit-btn"
                src="@/assets/uploads/icon/common/exit-cross.svg" alt="выйти" />
            </div>
            <div class="pop-services__modal-content">
              <div class="pop-services__header">
                <h3 class="pop-services__header-title">
                  Добавление функционала в «Популярные сервисы»
                </h3>
                <p class="pop-services__header-text">
                  Выберите иконку функционала, чтобы создать быстрый доступ в
                  приложении
                </p>
              </div>
              <div class="pop-services__calendars">
                <h4>Календари</h4>
                <ul class="pop-services__list">
                  <li class="pop-services__item" v-for="service in calendars" @click="toggleService(service.id)"
                    :key="service.id">
                    <IconInCircle :class="[
                      selectedServices.includes(service.id)
                        ? 'pop-services__icon-selected'
                        : ''
                    ]" :bg-color="colors[service.preset]" width="64" height="64">
                      <img class="pop-services__item-type-icon" :src="require(`@/assets/uploads/images/home-page/popular-services/${service.icon_code}.svg`)
                        " alt="картинка стетоскопа" />
                    </IconInCircle>
                    <div class="pop-services__text">
                      <p>
                        {{ service.title }}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="pop-services__other">
                <h4>Другое</h4>
                <ul class="pop-services__list">
                  <li class="pop-services__item" v-for="service in other" :key="service.id"
                    @click="toggleService(service.id)">
                    <IconInCircle :class="[
                      selectedServices.includes(service.id)
                        ? 'pop-services__icon-selected'
                        : ''
                    ]" :bg-color="colors[service.preset]" width="64" height="64">
                      <img class="pop-services__item-type-icon" :src="require(`@/assets/uploads/images/home-page/popular-services/${service.icon_code}.svg`)
                        " alt="картинка стетоскопа" />
                    </IconInCircle>
                    <div class="pop-services__text">
                      <p>
                        {{ service.title }}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <CustomButton @click.prevent="saveSelectedServices" light-blue class="pop-services__btn">Сохранить
              </CustomButton>
            </div>
          </div>
        </Transition>
      </div>
    </Transition>
  </Teleport>
</template>

<script>
import { Transition, computed, ref, toRefs, watch, watchEffect } from 'vue'
import IconInCircle from '@/components/ui/IconInCircle.vue'
import CustomButton from '@/components/ui/buttons/CustomButton.vue'
import axios from 'axios'
import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'
import { useToast } from 'vue-toastification'
import CustomToast from '@/components/ui/CustomToast.vue'

export default {
  name: 'PopularServicesModal',
  components: { IconInCircle, CustomButton, Transition },
  emits: ['closeModal', 'updateServices'],
  props: {
    isServicesModalOpen: Boolean,
    allServices: {
      type: [Object] && null,
      required: true
    },
    enabledServices: {
      type: [Object] && null,
      required: true
    }
  },
  setup (props, { emit }) {
    const userStore = useUserStore()
    const { getUserId, userToken } = storeToRefs(userStore)
    const { allServices, enabledServices } = toRefs(props)
    const selectedServices = ref([])
    const colors = {
      blue: '#EFF4FD',
      beige: '#FBE1CD',
      yellow: '#F5FBCD',
      mint: '#E4FCE6',
      green: '#E4FCE6',
      orange: '#F1842C',
      oasis: '#FFEDC5',
      pink: '#FBCDCD',
      purple: '#F5E6FC',
      gray: '#E4E4E4'
    }
    const toast = useToast()

    const toastOptions = ref({
      position: 'bottom-center',
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 0.38,
      timeout: 2000,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: 'button',
      icon: false,
      rtl: false,
      toastClassName: 'custom-toast'
    })

    const calendars = computed(() => {
      return allServices.value.filter((s) => s.type_id === 1 && !s.is_permanent)
    })

    const other = computed(() => {
      return allServices.value.filter((s) => s.type_id === 2 && !s.is_permanent)
    })

    const toggleService = (id) => {
      if (!selectedServices.value.includes(id)) {
        selectedServices.value.push(id)
      } else {
        selectedServices.value = selectedServices.value.filter((s) => s !== id)
      }
    }

    const saveSelectedServices = async () => {
      const param = new URLSearchParams({
        user_id: getUserId.value
      })

      try {
        const response = await axios.post('/v1/popular/save/', {
          services: selectedServices.value
        }, {
          params: param,
          headers: {
            Authorization: `Bearer ${userToken.value}`,
            'Content-Type': 'application/json'
          }
        })
        if (response.data.status === 'ok') {
          emit('updateServices')
          closeModal()
          toast.success(
            {
              component: CustomToast,
              props: {
                title: '«Популярные сервисы» добавлены',
                icon: 'success'
              }
            },
            toastOptions.value
          )
        } else {
          closeModal()
          toast.success(
            {
              component: CustomToast,
              props: {
                title: '«Популярные сервисы» не добавлены',
                text: 'Попробуйте еще раз',
                icon: 'error'
              }
            },
            toastOptions.value
          )
        }
      } catch (err) {
        throw new Error()
      }
    }

    const closeModal = () => {
      emit('closeModal')
      selectedServices.value = enabledServices.value.map(s => s.id)
    }

    watch(
      () => enabledServices.value,
      () => {
        selectedServices.value = enabledServices.value.map(s => s.id)

        if (enabledServices.value.length && getUserId.value) {
          enabledServices.value.forEach((s) => {
            if (!selectedServices.value.includes(s.id)) {
              selectedServices.value.push(s.id)
            }
          })
        }
      }
    )

    watchEffect(() => {
    })

    return {
      selectedServices,
      calendars,
      other,
      colors,
      closeModal,
      toggleService,
      saveSelectedServices
    }
  }
}
</script>

<style scoped lang="scss">
.modal-wrapper {
  position: fixed;
  background: #00285680;
  backdrop-filter: blur(1px);
  top: 0;
  height: 140vh;
  width: 100vw;
  z-index: 201;
  overflow-y: scroll;
  overflow-x: hidden;

}

.pop-services__wrapper {
  z-index: 202;
  position: absolute;
  top: 24px;
  transform: translateX(-50%);
  left: 50%;
  transition: left 0.3s;
}

.pop-services__menu {
  padding-bottom: 6px;
}

.pop-services__modal-content {
  max-height: calc(100vh - 80px);
  width: 342px;
  overflow-y: auto;
  box-shadow: 0 5px 15px 0 #00000026;
  background: #ffffff;
  border-radius: 6px;
  padding-top: 24px;
  padding-left: 6px;
  padding-right: 6px;

}

.pop-services__modal-content::-webkit-scrollbar {
  background-color: #f5f5f500;
  border-radius: 10px;
  width: 4px;
}

.pop-services__modal-content::-webkit-scrollbar-thumb {
  background-color: #002856;
  border-radius: 10px;
}

.pop-services__header {
  padding-left: 28px;
  padding-right: 28px;
}

.pop-services__header-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #002856;
  margin-bottom: 8px;
}

.pop-services__header-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: #002856;
}

.pop-services__calendars {
  margin-top: 12px;

  h4 {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: #002856;
  }
}

.pop-services__other {
  margin-top: 16px;

  h4 {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: #002856;
  }
}

.pop-services__text {
  margin-top: 16px;
  font-size: 12px;
  font-weight: 700;
  line-height: 13px;
  text-align: center;
  color: #002856;
}

.pop-services__list {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
}

.pop-services__item {
  cursor: pointer;
  padding-top: 16px;
  width: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pop-services__btn {
  margin-top: 60px;
  margin-bottom: 60px;
}

.pop-services__menu {
  display: flex;
  justify-content: end;
}

.pop-services__icon-selected {
  border: 2px solid #00000000;
  background-clip: padding-box;
  box-shadow: 0 0 0 1px #002856;
}

.overlay-enter-active,
.overlay-leave-active {
  transition: opacity 0.3s ease;
}

.overlay-enter-from,
.overlay-leave-to {
  opacity: 0;
}

.wrapper-enter-active,
.wrapper-leave-active {
  transition: transform 0.4s ease;
}

.wrapper-enter-from {
  transform: translateX(400%);
}

.wrapper-enter-to {
  transform: 0;
}

.wrapper-leave-from {
  transform: 0;
}

.wrapper-leave-to {
  transform: translateX(400%);
}

.pop-services__exit-btn {
  cursor: pointer;
}
</style>
