<script>
import ModalBsWrapper from '@/components/modals/ModalBsWrapper.vue'
import CustomButton from '@/components/ui/buttons/CustomButton.vue'

export default {
  name: 'ModalQR',
  components: { CustomButton, ModalBsWrapper },
  props: {
    name: {
      type: String,
      required: true,
      default: ''
    },
    number: {
      type: String,
      required: true,
      default: ''
    },
    img: {
      type: String,
      required: true,
      default: ''
    }
  }
}
</script>

<template>
  <ModalBsWrapper id="modal-QR" title="" class="modal-QR">
    <div class="modal-QR__wrapper">
      <div class="modal-QR__header">
        <img
          src="@/assets/uploads/icon/common/logo-vlue.svg"
          alt="логотип k31"
        />
        <div class="modal-QR__desc">
          <h4 class="modal-QR__title">Пропуск на парковку</h4>
          <div class="modal-QR__name">{{ name }}</div>
          <div class="modal-QR__number">{{ number }}</div>
        </div>
      </div>
      <div v-if="img" class="modal-QR__middle">
        <img :src="img" alt="qr код" />
      </div>
      <!--      <custom-button white text-transform="initial" tag="button" type="button">-->
      <!--        Сохранить в Wallet-->
      <!--      </custom-button>-->
      <custom-button
        href="https://www.k31.ru/info/dir-scheme/"
        target="_blank"
        white
        text-transform="initial"
      >
        Как доехать до парковки?
      </custom-button>
    </div>
  </ModalBsWrapper>
</template>

<style scoped lang="scss">
.modal-QR {
}

.modal-QR__wrapper {
}

.modal-QR__header {
  margin-bottom: 32px;
  padding-top: 26px;
  display: flex;
  gap: 35px;
  align-items: start;
}

.modal-QR__desc {
}

.modal-QR__title {
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}

.modal-QR__name {
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}

.modal-QR__number {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
}

.modal-QR__middle {
  margin: 0 auto 32px auto;
  display: flex;
  justify-content: center;
  max-width: 255px;
}
</style>
