<template>
  <li class="history__item">
    <router-link
      :to="`/my-schedule/${appointment.id}/${appointment.resource_id}/${appointment.ex_doctor_id}`"
      class="history__link"
    >
      <div class="history__top">
        <img
          src="@/assets/uploads/images/home-page/my-appointment/clock.svg"
          alt="картинка часов"
        />
        <div class="history__time" v-if="timeAppointment">
          {{ timeAppointment }}
        </div>
        <div class="history__date">
          {{ dateAppointment }}
        </div>
        <div class="history__type">
          {{ type }}
        </div>
      </div>
      <div class="history__middle">
        <div class="history__picture" v-if="doctorImg">
          <img :src="doctorImg" :alt="doctorName"/>
        </div>
        <div class="history__info">
          <p class="history__department">
            {{ appointment.title }}
          </p>
          <h4 class="history__title">
            {{ appointment.doctor_name }}
          </h4>
        </div>
        <div class="history__favicon" v-if="appointment.is_favorite !== null">
        <img v-if="!appointment.is_favorite" src="@/assets/uploads/images/favorite-empty.svg" alt="" @click.prevent="addDoctorToFavorites"/>
        <img v-if="appointment.is_favorite" src="@/assets/uploads/images/favorite-active.svg" alt="" @click.prevent="removeDoctorToFavorites"/>
        </div>
      </div>
    </router-link>

    <div class="history__bottom" v-if="appointment.clinic_name">
      <img
        src="@/assets/uploads/images/home-page/my-appointment/marker.svg"
        alt="знак метки на карте"
      />
      <p class="history__filial">Филиал "{{ appointment.clinic_name }}"</p>
      <!--      <a href="#" class="appointment-list__calendar">-->
      <!--        Записаться снова-->
      <!--      </a>-->
    </div>
    <div class="history__review" v-if="isNps">
      <h3>Ваше впечатление от приема</h3>
      <div class="rate">
        <template v-for='score in 5' :key="score">
          <input type="radio" :id="`star-${score}-${appointment.id}`" :name="`star-${appointment.id}`"
                 :value="`star-${score}-${appointment.id}`"/>
          <label :for="`star-${score}-${appointment.id}`" title="text" @click="openReviewModal(score)">
            <img src="@/assets/uploads/icon/common/star-light.svg" alt="star">
          </label>
        </template>
      </div>
    </div>
  </li>
</template>

<script>
import { useUserStore } from '@/stores/UserStore'
import { useDateStore } from '@/stores/date'
import { storeToRefs } from 'pinia'
import { computed, ref, toRefs, watchEffect } from 'vue'
import CustomToast from '@/components/ui/CustomToast.vue'
import axios from 'axios'
import { useToast } from 'vue-toastification'

export default {
  name: 'AppointmentHistoryListItem',
  props: {
    appointment: {
      type: [Object, null],
      require: true,
      default: null
    },
    date: {
      type: String,
      require: true,
      default: null
    },
    type: {
      type: String,
      require: true,
      default: 'Прием'
    },
    doctorPhoto: {
      type: String,
      require: false,
      default: ''
    },
    calendarLink: {
      type: String,
      require: true,
      default: null
    },
    doctorName: {
      type: String,
      require: false,
      default: null
    },
    doctorTitle: {
      type: String,
      require: true,
      default: null
    },
    filial: {
      type: String,
      require: false,
      default: null
    },
    resourceId: {
      type: Number,
      require: false,
      default: null
    },
    isNps: {
      type: Number,
      require: false,
      default: 0
    },
    doctorsData: {
      type: [Object]
    }
  },
  emits: ['rateSelected', 'updateDoctors'],
  setup (props, { emit }) {
    const { date, resourceId, doctorPhoto, appointment, type, doctorsData } = toRefs(props)
    const dateStore = useDateStore()
    const dictMonthRod = dateStore.dictMonthRod
    const scoreRate = ref(null)
    const isFavorite = ref(null)
    const doctorId = ref(null)
    const userStore = useUserStore()
    const { userToken, getUserId } = storeToRefs(userStore)
    const toast = useToast()
    const toastOptions = ref({
      position: 'bottom-center',
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 0.38,
      timeout: 2000,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: 'button',
      icon: false,
      rtl: false,
      toastClassName: 'custom-toast'
    })

    const timeAppointment = computed(() => {
      if (!date.value) return null

      return date.value.split('T')[1].slice(0, 5)
    })

    const dateAppointment = computed(() => {
      if (!date.value) return null

      const [year, month, day] = date.value.split('T')[0].split('-')
      const formatMonth = month.startsWith(0) ? month[1] : month

      return `${day} ${dictMonthRod[parseInt(formatMonth) - 1]} ${year} г.`
    })

    const doctorImg = computed(() => {
      if (doctorPhoto.value) return doctorPhoto.value
      if (resourceId.value && resourceId.value !== 'null') {
        return (
          'https://www.k31.ru/specialisty/photo/' +
          resourceId.value +
          '_295x295.jpg'
        )
      }
      return 'https://www.k31.ru/specialisty/photo/0_295x295.jpg'
    })

    const openReviewModal = (score) => {
      if (score && appointment.value) {
        emit('rateSelected', {
          score: score,
          appointment: appointment.value,
          date: dateAppointment.value,
          doctorImg: doctorImg.value,
          time: timeAppointment.value,
          type: type.value
        })
      }
    }

    watchEffect(() => {
      const doctor = doctorsData.value.find(doctor => parseInt(appointment.value.ex_doctor_id) === parseInt(doctor.ex_id))
      doctorId.value = doctor ? doctor.res_id : null
    })

    const addDoctorToFavorites = async () => {
      try {
        const response = await axios.post(
          `v1/favorite/doctor-add/?user_id=${getUserId.value}`,
          {
            res_id: doctorId.value
          },
          {
            headers: {
              Authorization: `Bearer ${userToken.value}`,
              'Content-Type': 'multipart/form-data'
            }
          }
        )

        if (response.data.status === 'ok') {
          toast.success(
            {
              component: CustomToast,
              props: {
                title: 'Врач добавлен в «Избранноe»',
                icon: 'success'
              }
            },
            toastOptions.value
          )
          isFavorite.value = true
          emit('updateDoctors', appointment.value.ex_doctor_id, true)
        } else {
          toast.success(
            {
              component: CustomToast,
              props: {
                title: 'Не удалось добавить врача в «Избранноe»',
                text: 'Попробуйте еще раз',
                icon: 'error'
              }
            },
            toastOptions.value
          )
        }
      } catch (err) {
        throw new Error()
      }
    }
    const removeDoctorToFavorites = async () => {
      try {
        const response = await axios.delete(
          `/v1/favorite/doctor-delete/?user_id=${getUserId.value}&res_id=${doctorId.value}`,
          {
            headers: {
              Authorization: `Bearer ${userToken.value}`
            }
          }
        )

        if (response.data.status === 'ok') {
          toast.success(
            {
              component: CustomToast,
              props: {
                title: 'Врач удален из «Избранноe»',
                icon: 'success'
              }
            },
            toastOptions.value
          )
          isFavorite.value = false
          emit('updateDoctors', appointment.value.ex_doctor_id, false)
        } else {
          toast.success(
            {
              component: CustomToast,
              props: {
                title: 'Не удалось удалить врача из «Избранноe»',
                text: 'Попробуйте еще раз',
                icon: 'error'
              }
            },
            toastOptions.value
          )
        }
      } catch (err) {
        throw new Error()
      }
    }

    return {
      timeAppointment,
      dateAppointment,
      doctorImg,
      isFavorite,
      openReviewModal,
      scoreRate,
      addDoctorToFavorites,
      removeDoctorToFavorites
    }
  }
}
</script>

<style lang="scss">
.history {
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: #7f8da9;
  &__item {
    padding: 8px;
    background: #ffffff;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    gap: 13px;
  }

  &__top {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &__time {
    margin-right: 10px;
    font-weight: 700;
    color: #222222;
  }

  &__date {
    margin-right: auto;
    color: #7f8da9;
  }

  &__type {
    padding: 2px 8px;
    background: #002856;
    border-radius: 20px;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
  }

  &__link {
    display: flex;
    flex-direction: column;
    gap: 13px;
  }

  &__middle {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__picture {
    max-width: 45px;

    & img {
      border-radius: 50%;
    }
  }

  &__department {
    margin-bottom: 4px;
  }

  &__title {
    color: #002856;
  }

  &__bottom {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__filial {
    margin-right: auto;
    color: #7f8da9;
  }

  &__calendar {
    text-decoration-line: underline;
    color: #002856;
  }

  &__favicon {
    margin-left: auto;
    margin-right: 0px;
    align-self: self-start;
}

  &__review {
    border-top: 1px solid $blue-light;
    text-align: center;
    padding: 12px 0 4px;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      margin-bottom: 12px;
    }
  }
}

.rate {
  display: flex;
  align-items: center;
  gap: 16px;

  label img {
    filter: invert(8%) sepia(96%) saturate(2856%) hue-rotate(202deg) brightness(95%) contrast(102%);
  }

  &:not(:hover) {
    input:indeterminate + label img {
      filter: invert(96%) sepia(1%) saturate(5289%) hue-rotate(184deg) brightness(97%) contrast(98%);
    }

    input:checked ~ input + label img {
      filter: invert(96%) sepia(1%) saturate(5289%) hue-rotate(184deg) brightness(97%) contrast(98%);
    }
  }

  input:hover ~ input + label img {
    filter: invert(96%) sepia(1%) saturate(5289%) hue-rotate(184deg) brightness(97%) contrast(98%);
  }

  input {
    display: none;
  }
}
</style>
