import axios from 'axios'
import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'

export const authApi = async (
  path,
  method = 'get',
  data = {},
  headers = {}
) => {
  const userStore = useUserStore()
  const { userToken } = storeToRefs(userStore)

  console.log('USER STORE', userStore.userToken)

  try {
    const response = await axios({
      method: method,
      url: path,
      data: data,
      headers: {
        Authorization: `Bearer ${userToken.value}`,
        ...headers
      }
    })

    if (!response.data.status) {
      return [null, response.data]
    }

    if (response.data?.status === 'ok') {
      return [null, response.data]
    } else {
      return [response.data.message, null]
    }
  } catch (err) {
    return [new Error('Нет доступа к серверу. Попробуйте позже.'), null]
  }
}
