<template>
  <div class="appointment__wrapper" v-if="appointmentUserList && !loading">
    <div class="appointment__top">
      <p class="my-appointment__text">
        Скоро у Вас
        <span>
          {{ appointmentUserList.length }}
        </span>
        {{ numberOfAppointment }}
      </p>
      <a @click.prevent="reloadAppointmentList" class="appointment__reload">
        <img
          class="appointment-list__mark"
          src="@/assets/uploads/icon/common/reload.svg"
          alt="перезагрузка"
        />
      </a>
    </div>
    <ul class="appointment-list">
      <AppointmentListItem
        v-for="appointment in appointmentUserList"
        :key="appointment.id"
        :date="appointment.date"
        :doctorInfo="appointment.resource_name"
        :doctorWorkTitle="appointment.work_title"
        :calendarLink="appointment.ics_url"
        :appointmentId="appointment.id"
        :resourceId="appointment.resource_id"
        :appointment="appointment"
        @delete-appointment="deleteAppoint"
        @toggleFavoriteDoc="toggleFavoriteDoc"
        :user="user"
      />
    </ul>
  </div>

  <div class="my-appointment__bottom">
    <p v-if="!appointmentUserList && !loading">У вас нет активных записей</p>

    <TheLoader v-if="loading"> Загрузка ваших записей</TheLoader>
  </div>

  <CustomButton v-if="!appointmentUserList && !loading">
    <router-link to="/online-appointment"> выбрать специалиста</router-link>
  </CustomButton>
</template>

<script>
import axios from 'axios'
import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'
import { ref, computed, watch, onActivated } from 'vue'
import AppointmentListItem from '@/components/pages/home/AppointmentListItem.vue'
import TheLoader from '@/components/ui/TheLoader.vue'
import CustomButton from '@/components/ui/buttons/CustomButton.vue'
import cloneDeep from 'lodash.clonedeep'
import { postLog } from '@/api/postLog'

export default {
  name: 'AppointmentList',
  components: { TheLoader, AppointmentListItem, CustomButton },
  props: {
    user: {
      type: Object,
      required: true,
      default: null
    }
  },

  setup () {
    const userStore = useUserStore()
    const { userToken, getUserId, userAppoints } = storeToRefs(userStore)
    const appointmentList = ref(null)
    const loading = ref(false)

    const appointmentUserList = computed(() => {
      if (appointmentList.value && appointmentList.value.length > 0) {
        const cloneAppointmentList = cloneDeep(appointmentList.value)

        return cloneAppointmentList.sort((appointA, appointB) => {
          return new Date(appointA.date) > new Date(appointB.date) ? 1 : -1
        })
      }

      return null
    })

    const numberOfAppointment = computed(() => {
      if (appointmentList.value) {
        return appointmentList.value.length > 1 ? 'приема' : 'прием'
      }

      return null
    })
    const deleteAppoint = async (appointmentId) => {
      try {
        loading.value = true
        const response = await axios({
          method: 'delete',
          url: `v1/element/schedule/reserve/delete/?user_id=${getUserId.value}`,
          data: {
            reserve_id: appointmentId.value
          },
          headers: {
            Accept: '*/*',
            Authorization: `Bearer ${userToken.value}`,
            'Cache-Control': 'no-cache'
          }
        })

        if (response.data.status === 'ok') {
          await getUserAppointment(getUserId.value)
        }
      } catch (err) {
        postLog(err)
      } finally {
        loading.value = false
      }
    }

    const getUserAppointment = async (id) => {
      try {
        loading.value = true
        const response = await axios.get(`/v2/element/event/?user_id=${id}`, {
          headers: {
            Authorization: `Bearer ${userToken.value}`
          }
        })

        if (response.data.status === 'ok') {
          appointmentList.value = response.data.data
          userAppoints.value = response.data.data
        } else {
          appointmentList.value = null
          userAppoints.value = null
        }
      } catch (err) {
        throw new Error()
      } finally {
        loading.value = false
      }
    }

    const reloadAppointmentList = async () => {
      await getUserAppointment(getUserId.value)
    }

    const toggleFavoriteDoc = (favDoc, favStatus) => {
      const index = appointmentList.value.findIndex(a => a.resource_id === favDoc)
      appointmentList.value[index].is_favorite = favStatus
    }

    watch(
      () => getUserId.value,
      async () => {
        if (getUserId.value) {
          await getUserAppointment(getUserId.value)
        }
      },
      {
        immediate: true
      }
    )

    onActivated(async () => {
      if (getUserId.value) {
        await getUserAppointment(getUserId.value)
      }
    })

    return {
      appointmentUserList,
      loading,
      numberOfAppointment,
      deleteAppoint,
      reloadAppointmentList,
      toggleFavoriteDoc
    }
  }
}
</script>

<style scoped lang="scss">
.appointment__reload {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
}

.my-appointment__text {
  margin-bottom: 10px;
}

.my-appointment__bottom {
  margin-bottom: 20px;

  & span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: #f1842c;
    border-radius: 50%;
    width: 15px;
    height: 15px;
  }

  p {
    color: #002856;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.14px;
  }
}

.appointment-list {
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 14px;
  color: #7f8da9;
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__item {
    padding: 8px;
    background: #ffffff;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    gap: 13px;
  }

  &__top {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &__time {
    margin-right: 10px;
    font-weight: 700;
    color: #222222;
  }

  &__date {
    margin-right: auto;
  }

  &__type {
    padding: 2px 8px;
    background: #002856;
    border-radius: 20px;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
  }

  &__middle {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__picture {
  }

  &__info {
  }

  &__department {
    margin-bottom: 4px;
  }

  &__title {
    color: #002856;
  }

  &__bottom {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__filial {
    margin-right: auto;
  }

  &__calendar {
    text-decoration-line: underline;
    color: #002856;
  }
}
</style>
