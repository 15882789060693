<template>
  <div class="activity-chart">
    <div class="activity-chart__menu">
      <div @click="$emit('openCalendarModal')">
        <img
          class="activity-chart__menu-calendar-img"
          src="@/assets/uploads/icon/profile/calendar.svg"
          alt="календарь"
        />
        <span>Календарь активности</span>
      </div>
      <div @click="$emit('openSettings')">
        <img src="@/assets/uploads/icon/common/settings.svg" alt="настройки" />
        <span>Настройки</span>
      </div>
    </div>
    <div v-if="!chartData || chartData.length === 0" class="activity-chart__no-data">
      <span v-if="platform === 'android' || platform === 'ios'">Нет доступа к истории вашей активности, предоставьте доступ приложению к показателям активности в настройках устройства</span>
      <span v-else>Нет доступа к истории вашей активности, поробуйте открыть приложение на мобильном устройстве</span>
    </div>

    <div v-else>
      <div class="activity-chart__description">
        <div class="activity-chart__description-date">
          <img
            class="activity-chart__menu-calendar-img"
            src="@/assets/uploads/icon/profile/calendar.svg"
            alt="календарь"
          />
          <span>{{ formatDateHeader(currentDay.date_at) }}</span>
        </div>
        <div
          class="activity-chart__description-main"
          :style="{ color: getColor(currentDay.steps) }"
        >
          <span>{{ formatSteps(currentDay.steps) }}</span>
          <span>{{ formatStepsWord(currentDay.steps) }}</span>
        </div>
        <div
          class="activity-chart__description-details"
          :style="{ color: getColor(currentDay.steps) }"
        >
          <div>
            <span>{{ formatDistance(currentDay.distance) + " " + "км" }}</span>
            <span>пройдено</span>
          </div>
          <!-- <div>
            <span>{{
              currentDay.active_time.toFixed(1) +
              " " +
              formatHours(currentDay.active_time)
            }}</span>
            <span>время активности</span>
          </div> -->
          <div>
            <span>{{ Math.round(currentDay.burned_cal) + " " + "ккал" }}</span>
            <span>потрачено</span>
          </div>
        </div>
      </div>

      <div class="activity-chart-wrapper">
        <div class="activity-chart-wrapper__chart-goal">
          <div class="activity-chart-wrapper__chart-goal-line">
            <span class="activity-chart-wrapper__chart-goal-text">{{
              formatSteps(stepsGoal)
            }}</span>
          </div>
          <div :style="{ height: goalLineHeight + '%' }"></div>
        </div>

        <div class="activity-chart-wrapper__chart">
          <div
            v-for="bar in chartData"
            @click="selectDay(bar.date_at)"
            ref="chartRefs"
            :id="bar.id"
            :key="bar.date_at"
            :style="{
              height: bar.height + '%',
              color: getColor(bar.steps),
              backgroundColor: getColor(bar.steps),
            }"
            class="activity-chart__chart-bar"
          >
            <div class="activity-chart__steps">
              {{ formatSteps(bar.steps) }}
            </div>
            <div
              class="activity-chart__stepsFold"
              v-if="bar.steps / stepsGoal >= 2"
            >
              {{ "x" + Math.round(bar.steps / stepsGoal) }}
            </div>
            <div
              class="activity-chart__date"
              :class="{
                'activity-chart__date--selected':
                  bar.date_at === currentDay.date_at,
              }"
            >
              {{ formatDateChart(bar.date_at) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, nextTick, ref, toRefs, watch } from 'vue'
import { useDateStore } from '@/stores/date.js'

export default {
  name: 'ActivityChart',
  emits: ['openSettings', 'openCalendarModal', 'selectDay'],
  props: {
    stepsGoal: Number,
    chartData: {
      type: [Object],
      required: true
    },
    currentDay: Object,
    minValue: Number,
    maxValue: Number
  },

  components: {},

  setup (props, { emit }) {
    const { stepsGoal, maxValue, minValue, currentDay } = toRefs(props)
    const platform = window?.device?.platform.toLowerCase()
    const { dictMonthRod } = useDateStore()
    const chartRefs = ref([])

    const goalLineHeight = computed(() => {
      return (
        2 +
        ((stepsGoal.value - minValue.value) * 98) /
          (maxValue.value - minValue.value)
      )
    })

    const selectDay = (dayString) => {
      emit('selectDay', dayString)
    }

    const formatDateHeader = (dateString) => {
      if (!dateString) return ''
      const date = new Date(dateString)

      const day = date.getDate()
      const month = date.getMonth()
      const year = date.getFullYear()

      const monthName = dictMonthRod[month]

      const formattedDate = `${day} ${monthName.toLowerCase()} ${year}`

      return formattedDate
    }

    const formatDateChart = (dateString) => {
      const today = new Date()
      const date = new Date(dateString)

      if (date.toDateString() === today.toDateString()) {
        return 'сегодня'
      }

      const yesterday = new Date(today)
      yesterday.setDate(yesterday.getDate() - 1)
      if (date.toDateString() === yesterday.toDateString()) {
        return 'вчера'
      }

      const day = date.getDate().toString().padStart(2, '0')
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      return `${day}.${month}`
    }

    const getColor = (steps) => {
      if (steps >= stepsGoal.value) return '#2FCB3F'
      if (stepsGoal.value / 2 > steps) return '#FF1F00'
      return '#F1842C'
    }

    const formatSteps = (number) => {
      const rounded = Math.round(number)
      return new Intl.NumberFormat('ru-RU').format(rounded)
    }

    const formatDistance = (number) => {
      return (number / 1000).toFixed(1)
    }

    const formatHours = (number) => {
      if (number % 10 === 1 && number % 100 !== 11) {
        return 'час'
      } else if (
        [2, 3, 4].includes(number % 10) &&
        ![12, 13, 14].includes(number % 100)
      ) {
        return 'часа'
      } else {
        return 'часов'
      }
    }

    const formatStepsWord = (number) => {
      if (number % 10 === 1 && number % 100 !== 11) {
        return 'шаг'
      } else if (
        [2, 3, 4].includes(number % 10) &&
        ![12, 13, 14].includes(number % 100)
      ) {
        return 'шага'
      } else {
        return 'шагов'
      }
    }

    watch(
      currentDay,
      async (newVal) => {
        await nextTick()
        chartRefs.value.forEach((item, index) => {
          if (+item.id === +newVal.id) {
            chartRefs.value[index].scrollIntoView({ behavior: 'smooth' })
          }
        })
      },
      { immediate: true }
    )

    return {
      goalLineHeight,
      platform,
      chartRefs,
      formatDateChart,
      getColor,
      formatSteps,
      formatDistance,
      formatHours,
      formatStepsWord,
      formatDateHeader,
      selectDay
    }
  }
}
</script>

<style scoped lang="scss">
.activity-chart {
  position: relative;
}
.activity-chart-wrapper {
  width: 100%;
  flex-direction: row-reverse;
  display: flex;
  overflow-x: auto;
}
.activity-chart-wrapper__chart {
  height: 368px;
  width: fit-content;
  display: flex;
  align-items: end;
  gap: 8px;
  white-space: nowrap;
  position: relative;

  margin-top: 12px;
  margin-bottom: 30px;

  padding-left: 8px;
  padding-right: 8px;
}

.activity-chart-wrapper__chart-goal {
  position: absolute;
  height: 368px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;

  margin-top: 12px;
  margin-bottom: 30px;
}
.activity-chart-wrapper__chart-goal-line {
  height: 1px;
  border-top: 1px dashed #002856;
  z-index: 10;
  position: relative;
  margin-left: 8px;
}

.activity-chart-wrapper__chart-goal-text {
  top: 100%;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  color: #002856;
  position: absolute;
  transform: translateY(-100%);
}

.activity-chart__chart-bar {
  width: 42px;
  position: relative;
  border-radius: 6px 6px 0 0;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.activity-chart__stepsFold {
  margin-top: 6px;
  padding-bottom: 3px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 0.02em;
  text-align: center;
  @media (max-width: 990px) {
    border-bottom: 4px solid #ffffff;
  }
  border-bottom: 4px solid #eef2f8;
}

.activity-chart__steps {
  position: absolute;
  transform: translateY(-100%);
  text-align: center;
  width: 100%;

  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 0.02em;
}
.activity-chart__date {
  position: absolute;
  margin-top: 8px;
  top: 100%;
  text-align: center;
  width: 100%;

  color: #002856;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
}

.activity-chart__menu {
  margin-top: 2px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  span {
    text-decoration: underline;
    cursor: pointer;
  }

  img {
    margin-right: 3px;
  }
}
.activity-chart__menu-calendar-img {
  filter: brightness(0) saturate(100%) invert(12%) sepia(44%) saturate(3520%)
    hue-rotate(197deg) brightness(94%) contrast(103%);
}
.activity-chart__description {
  margin-bottom: 36px;
}
.activity-chart__description-date {
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
  img {
    margin-right: 6px;
  }
  span {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
  }
}

.activity-chart__description-main {
  display: flex;
  flex-direction: column;
  padding-bottom: 18px;
  margin-bottom: 18px;
  border-bottom: 1px solid #e1e8f4;

  span:first-child {
    font-size: 40px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
  }
  span:last-child {
    font-size: 12px;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 0.02em;
    text-align: center;
  }
}

.activity-chart__description-details {
  display: flex;
  justify-content: space-between;
  div {
    display: flex;
    flex: 1;
    flex-direction: column;
    span:first-child {
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
      text-align: center;
    }
    span:last-child {
      font-size: 12px;
      font-weight: 700;
      line-height: 12px;
      letter-spacing: 0.02em;
      text-align: center;
    }
  }
}

.activity-chart__date--selected {
  text-decoration: underline;
}

.activity-chart__no-data {
  text-align: center;
  margin-top: 50%;
  font-size: 16px;
}
</style>
