export const getPlatform = () => {
  const platform = window?.device?.platform.toLowerCase()

  if (platform === 'ios') {
    return 'ios'
  }

  if (platform === 'android') {
    return 'android'
  }

  return 'web'
}
