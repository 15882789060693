<template>
  <div v-if="loading" class="loading-wrapper">
    <TheLoader />
  </div>

  <div v-else class="chat-room">

    <ArrowTitleSimple title="Чат с колл-центром"/>

    <MessagesList
      :messages="processedMessages"
      :partnerImg="partnerImg"
      :isDoctorChat="false"
      :chatLoading="chatLoading"
      :chatPadding="chatPadding"
      :isSubscribtionOver="false"
      :allMessagesLoaded="true"
      :userName="userName"
      :error="error"
      @openModal="openModal"
      @loadMoreMessages="loadMoreMessages"
    />

    <ChatInput
      :isDoctorChat="false"
      :roomId="roomId"
      :clientId="clientId"
      @applyError="applyError"
      @updateChatPadding="updateChatPadding"
    />
  </div>
</template>

<script>
import MessagesList from '@/components/pages/chat/MessagesList.vue'
import ChatInput from '@/components/pages/chat/ChatInput.vue'
import TheLoader from '@/components/ui/TheLoader.vue'
import ArrowTitleSimple from '@/components/ui/typography/ArrowTitleSimple.vue'
import { useUserStore } from '@/stores/UserStore'
import axios from 'axios'
import { storeToRefs } from 'pinia'
import { computed, onMounted, onUnmounted, ref, watch, watchEffect } from 'vue'
import { Centrifuge } from 'centrifuge'

export default {
  name: 'ChatCallCenterView',
  components: {
    MessagesList,
    ChatInput,
    TheLoader,
    ArrowTitleSimple
  },
  props: {
    isApp: {
      type: Boolean,
      required: true,
      default: false
    },
    user: {
      type: [Object, null],
      required: true,
      default: null
    }
  },
  setup () {
    const error = ref(null)
    const loading = ref(false)
    const reviewModalIsOpen = ref(false)
    const selectedRate = ref(null)
    const chatLoading = ref(false)
    const userStore = useUserStore()
    const { getUserId, userToken, user } = storeToRefs(userStore)
    const roomId = ref(null)
    const messages = ref(null)
    const clientId = ref(null)
    const client = ref(null)
    const chatPadding = ref(117)
    const partnerImg = ref(null)

    const userName = computed(() => {
      return user.value?.last_name + ' ' + user.value?.first_name + ' ' + user.value?.middle_name
    })

    const openModal = (rate) => {
      selectedRate.value = rate
      reviewModalIsOpen.value = true
    }

    const pushNewMessage = (message) => {
      messages.value.push(message)
    }

    const applyError = (err) => {
      error.value = err
    }

    const updateChatPadding = (newHeignt) => {
      console.log('inside func', newHeignt)
      chatPadding.value = newHeignt
    }

    const getToken = async () => {
      error.value = null
      const params = new URLSearchParams({
        user_id: getUserId.value,
        DEV: 'YY'
      }).toString()

      try {
        const response = await axios.get(`/v1/chat/auth/?${params}`, {
          headers: {
            Authorization: `Bearer ${userToken.value}`
          }
        })
        if (response.data.status === 'ok') {
          return response.data.data.token
        } else {
          error.value = response.data.message
        }
      } catch (err) {
        error.value = err
      }
    }

    const getWsClientId = async (jwtToken) => {
      return new Promise((resolve) => {
        client.value = new Centrifuge('wss://k31centr.wfst.ru/connection/websocket', {
          token: jwtToken
        })

        client.value.connect()

        client.value.on('connected', (ctx) => {
          console.log('CONNECTED!', ctx)
          clientId.value = ctx.client
          resolve(true)
        })

        client.value.on('error', (error) => {
          console.log(error)
        })
      })
    }

    const connectToPatient = async () => {
      return new Promise((resolve) => {
        const patient = client.value.newSubscription(`patient:${getUserId.value}`)

        patient.subscribe()

        patient.on('subscribed', () => {
          console.log('patient subscribed!')

          resolve(true)
        })
      })
    }

    const connectToRoom = async (roomId) => {
      return new Promise((resolve) => {
        const room = client.value.newSubscription(`room:${roomId}`)

        room.subscribe()

        room.on('publication', (ctx) => {
          pushNewMessage(ctx.data.message)
        })

        room.on('subscribed', () => {
          console.log('room subscribed!')

          resolve(true)
        })
      })
    }

    const getUserChats = async (clientId) => {
      const params = new URLSearchParams({
        client: clientId
      })
      try {
        const response = await axios.get(
          `https://k31centr.wfst.ru/api/my?${params}`
        )
        if (response.data.status === 'ok') {
          roomId.value = response.data.data.callcenter.id
          partnerImg.value = response.data.data.callcenter.users.find(u => u.name === 'Клиника К+31').avatar
        } else {
          console.log(response.data.message)
        }
      } catch (err) {
        console.log(err)
      } finally {
        loading.value = false
      }
    }

    const getRoomMessages = async (clientId, roomId) => {
      error.value = null
      try {
        const response = await axios.get(
          `https://k31centr.wfst.ru/api/chat/room?client=${clientId}&id=${roomId}`
        )
        if (response.data.status === 'ok') {
          messages.value = response.data.data.messages
        } else {
          error.value = response.data.message
        }
      } catch (err) {
        error.value = err
      }
    }

    const fetchChatData = async () => {
      loading.value = true

      const jwtToken = await getToken()
      await getWsClientId(jwtToken)
      await connectToPatient()
      await getUserChats(clientId.value)
      await connectToRoom(roomId.value)
      await getRoomMessages(clientId.value, roomId.value)

      loading.value = false
    }

    const processedMessages = computed(() => {
      if (messages.value === null) return null

      let lastDate = null
      const processedMessages = []
      const clonedMessages = structuredClone(messages.value)
      const reversedMessages = clonedMessages
        .sort((a, b) => a.id - b.id)
        .reverse()
      const tempDayMessages = []
      const walkedIds = new Set()

      reversedMessages.forEach((message) => {
        if (walkedIds.has(message.id)) {
          return
        }
        walkedIds.add(message.id)

        const messageDate = message.created_at.slice(0, 10)

        if (messageDate !== lastDate) {
          if (tempDayMessages.length > 0) {
            processedMessages.push(...tempDayMessages)
            processedMessages.push({
              type: 'date',
              date: lastDate,
              id: `date-${lastDate}`
            })
            tempDayMessages.length = 0
          }
          lastDate = messageDate
        }

        tempDayMessages.push({
          type: 'message',
          ...message
        })
      })

      if (tempDayMessages.length > 0) {
        processedMessages.push(...tempDayMessages)
        processedMessages.push({
          type: 'date',
          date: lastDate,
          id: `date-${lastDate}`
        })
      }

      return processedMessages
    })

    const loadMoreMessages = () => {
      // chatLoading.value = true
      console.log('LOADING MORE MESSAGES')
      // setTimeout(() => {
      //   messages.value = messages.value.concat(messagesHistory.value)
      //   chatLoading.value = false
      // }, 2500)
    }

    onMounted(async () => {
      await fetchChatData()
    })

    onUnmounted(() => {
      client.value.disconnect()
      client.value.on('disconnected', () => {
        console.log('DISCONECTED')
      })
    })

    watch(
      () => getUserId.value,
      async () => {
        await fetchChatData()
      }
    )

    watchEffect(() => {
      // console.log('route', route.params)
      // console.log('messages', processedMessages.value)
      // console.log('jwt', jwtToken.value)
      // console.log('user name', user.value)
    })

    return {
      processedMessages,
      loading,
      loadMoreMessages,
      chatLoading,
      chatPadding,
      updateChatPadding,
      reviewModalIsOpen,
      selectedRate,
      openModal,
      userName,
      clientId,
      roomId,
      error,
      applyError,
      partnerImg
    }
  }
}
</script>

<style scoped lang="scss">
.loading-wrapper {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 122px);
  background: #fff;
  border-radius: 8px 8px 0 0;
  padding: 12px 0;
  z-index: 10;
}

.chat-room {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 122px);
  overflow-y: hidden;
  @include desktop {
    height: calc(100vh - 108px);
  }
  background: #fff;
  border-radius: 8px;
  z-index: 10;
}

</style>
