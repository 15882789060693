<template>
  <section class="section actual" v-if="actualList">

    <Teleport to="body">
      <TheStories v-if="showStories" :currentIndex="selectedStories" :duration="5000" :stories="itemsStories"
        :showInnerContent="true" @closeStories="showStories = false">

        <template v-slot:innerContent="{ story }">
          <div class="story__content" v-if="story.storiesItems && story.storiesItems.type !== 'Статья'">
            <div class="story__description">
              <div class="story__type" v-html="story.storiesItems.type"></div>
              <div class="story__title" v-html="story.storiesItems.name"></div>
              <div class="story__text" v-html="story.storiesItems.text"></div>
              <a v-if="story.storiesItems.link" :href="story.storiesItems.link" target="_blank" class="story__link">Подробнее</a>
            </div>
          </div>

          <div class="story__content" v-if="story.storiesItems && story.storiesItems.type === 'Статья'">
            <div class="story__type" v-html="story.storiesItems.type"></div>
            <div class="story__title-article" v-html="story.storiesItems.name"></div>
            <div class="story__text-article" v-html="story.storiesItems.text"></div>
          </div>
        </template>
      </TheStories>
    </Teleport>

    <div class="actual__swiper">
      <Swiper v-bind="swiperOptions" :loop="isLoop" class="actual__swiper-wrapper">
        <swiper-slide v-for="(actual, index) in actualList" :key="actual.name">
          <PregnancyStoryElement :img="actual.img" :text="actual.name"
            @click="handleOpenStory(actual.type, index, actual.link)" />
        </swiper-slide>
        <div class="swiper-custom__pagination"></div>
      </Swiper>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import PregnancyStoryElement from '@/components/pages/calendar/pregnancy/PregnancyStoryElement.vue'
import { computed, ref, toRefs, watch } from 'vue'
import { Pagination } from 'swiper'
import TheStories from '@/components/ui/TheStories.vue'
import { useRouter } from 'vue-router'

export default {
  name: 'ActualSection',
  components: {
    PregnancyStoryElement,
    Swiper,
    SwiperSlide,
    TheStories
  },
  props: {
    stories: {
      type: [Array, null],
      required: true,
      default: null
    }
  },
  setup (props) {
    const { stories } = toRefs(props)
    const router = useRouter()
    const selectedStories = ref(0)
    const showStories = ref(false)
    const itemsStories = ref([])
    const swiperOptions = ref({
      modules: [Pagination],
      pagination: {
        el: '.swiper-custom__pagination',
        clickable: true
      },
      spaceBetween: 0,
      breakpoints: {
        0: {
          slidesPerView: 3.2
        },
        400: {
          slidesPerView: 3.2
        },
        440: {
          slidesPerView: 3.4
        },
        480: {
          slidesPerView: 3.7
        },
        550: {
          slidesPerView: 4.2
        },
        710: {
          slidesPerView: 5.5
        },
        820: {
          slidesPerView: 6
        },
        991: {
          slidesPerView: 4,
          slidesPerGroup: 4
        },
        1200: {
          slidesPerView: 6,
          slidesPerGroup: 6
        }
      }
    })
    const actualList = ref(null)
    const isLoop = computed(() => {
      if (!actualList.value) return false

      return actualList.value.length > 6
    })

    const handleOpenStory = (storyType, storyIndex, href) => {
      if (storyType === 'story') {
        let skipLink = 0

        for (let i = 0; i < storyIndex; i++) {
          if (actualList.value[i].type === 'link') {
            skipLink++
          }
        }

        selectedStories.value = storyIndex - skipLink
        showStories.value = true
      } else {
        router.push(href)
      }
    }

    watch(showStories, () => {
      if (showStories.value) {
        document.body.style.height = '100%'
        document.body.style.overflowY = 'hidden'
      } else {
        document.body.style.height = '100vh'
        document.body.style.overflowY = 'auto'
      }
    })

    watch(stories, () => {
      if (stories.value) {
        actualList.value = stories.value
        itemsStories.value = actualList.value
          .filter((el) => el.type === 'story')
          .map((el) => el.item)
      }
    }, { immediate: true })

    return {
      isLoop,
      actualList,
      swiperOptions,
      selectedStories,
      showStories,
      itemsStories,
      handleOpenStory
    }
  }
}
</script>

<style scoped lang="scss">
.actual {
  margin-bottom: 24px;

  &__swiper {
    padding-left: 0 !important;
    overflow: hidden;

    .swiper-custom__pagination {
      text-align: center;
      margin-top: 20px;

      @include phone-xs {
        display: none;
      }

      .swiper-pagination-bullet {
        border: 1px solid #002856;
        background: none;
        opacity: 1;

        &.swiper-pagination-bullet-active {
          background: #002856;
        }
      }
    }
  }
}

.story__title-article {
  font-size: 24px;
  font-weight: 700;
  line-height: 28.8px;
  text-align: left;
  color: #002856;
  margin-bottom: 16px;
}
</style>
<style>
.story__text-article {
  color: #002856;

  h1 {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;

  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    margin: 8px 0 8px 0;
  }

  span {
    margin-top: 8px;
    display: block;
    text-align: end;
  }

  span:first-of-type {
    margin-top: 16px;
  }
}
</style>
