<script>
import { computed, onActivated, onDeactivated, ref, watchEffect } from 'vue'

import CustomButton from '@/components/ui/buttons/CustomButton.vue'

import DatePicker from 'vue-datepicker-next'
import TheLoader from '@/components/ui/TheLoader.vue'
import FormRadio from '@/components/ui/form/FormRadio.vue'
import ArrowTitle from '@/components/ui/typography/ArrowTitle.vue'
import SectionWrapper from '@/components/ui/SectionWrapper.vue'
import axios from 'axios'
import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'
import router from '@/router'

export default {
  name: 'PregnancySettings',
  components: {
    DatePicker,
    TheLoader,
    CustomButton,
    FormRadio,
    ArrowTitle,
    SectionWrapper
  },
  props: {
    isApp: {
      type: Boolean,
      required: true,
      default: false
    },
    user: {
      type: [Object, null],
      required: true,
      default: null
    }
  },
  setup () {
    const loading = ref(false)
    const errorMsg = ref('')
    const userStore = useUserStore()
    const { getUserId, userToken } = storeToRefs(userStore)
    const pregnancyCalcMethod = ref(null)
    const chosenDate = ref(null)
    const today = new Date()

    const conceptionTrueDate = computed(() => {
      if (chosenDate.value !== null && pregnancyCalcMethod.value !== null) {
        if (pregnancyCalcMethod.value === 1) {
          const conceptionDate = new Date(chosenDate.value)
          conceptionDate.setDate(conceptionDate.getDate() + 14)

          return formatDate(conceptionDate)
        } else if (pregnancyCalcMethod.value === 3) {
          const conceptionDate = new Date(chosenDate.value)
          conceptionDate.setDate(conceptionDate.getDate() - 280)

          return formatDate(conceptionDate)
        } else {
          return chosenDate.value
        }
      } else {
        return null
      }
    })

    const disabledRange = (date) => {
      if (pregnancyCalcMethod.value === 3) return date <= today.setDate(today.getDate())
      return date >= today.setDate(today.getDate())
    }

    const formattedDate = computed(() => {
      const today = new Date()
      const year = today.getFullYear()
      const month = String(today.getMonth() + 1).padStart(2, '0')
      const day = String(today.getDate()).padStart(2, '0')

      return `${year}-${month}-${day}`
    })

    const formatDate = (date) => {
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')

      return `${year}-${month}-${day}`
    }

    const submitForm = async () => {
      if (pregnancyCalcMethod.value === null) {
        errorMsg.value = 'Выберете метод рассчета срока беременности'
      }
      if (chosenDate.value === null) {
        errorMsg.value = 'Выберете дату'
      }
      if (chosenDate.value !== null && pregnancyCalcMethod.value !== null) {
        loading.value = true
        try {
          const response = await axios.post(
            `v1/events/pregnant/set?user_id=${getUserId.value}`,
            {
              pregnant_at: conceptionTrueDate.value
            },
            {
              headers: {
                Authorization: `Bearer ${userToken.value}`,
                'Content-Type': 'multipart/form-data'
              }
            }
          )

          if (response.data.status === 'ok') {
            router.push({ name: 'calendar-pregnancy' })
          } else {
            errorMsg.value = response.data.message
            loading.value = false
            console.log('FAIL', response)
          }
        } catch (err) {
          throw new Error()
        }
      }
    }

    onActivated(() => {
      pregnancyCalcMethod.value = 1
    })

    onDeactivated(() => {
      loading.value = false
    })

    watchEffect(() => {
      if (pregnancyCalcMethod.value || chosenDate.value) errorMsg.value = ''
    })

    return {
      disabledRange,
      submitForm,
      chosenDate,
      formattedDate,
      pregnancyCalcMethod,
      loading,
      errorMsg
    }
  }
}
</script>

<template>
  <ArrowTitle :is-app="isApp" title="Беременность" extra-back></ArrowTitle>
  <SectionWrapper stretched>
    <TheLoader v-if="loading"/>
    <div v-else class="pregnancy-form">
      <div class="pregnancy-form__wrapper">
        <div class="pregnancy-form__header">
          <img
            class="pregnancy-form__header-woman"
            src="@/assets/uploads/images/calendar/pregnancy/woman.svg"
          />
          <img
            class="pregnancy-form__header-background"
            src="@/assets/uploads/images/calendar/pregnancy/background.svg"
          />
        </div>
        <h4 class="pregnancy-form__title">
          Выберите метод рассчета срока беременности
        </h4>
        <form
          class="pregnancy-form__form"
          @submit.prevent="submitForm"
          @keyup.enter.prevent="submitForm"
        >
          <div class="pregnancy-form__method-radio-wrapper">
            <FormRadio
              label="Первый день последней менструации"
              :value="1"
              v-model="pregnancyCalcMethod"
            />
            <FormRadio
              label="Дата зачатия"
              :value="2"
              v-model="pregnancyCalcMethod"
            />
            <FormRadio
              label="Предполагаемая дата родов"
              :value="3"
              v-model="pregnancyCalcMethod"
            />
          </div>
          <div class="pregnancy-form__datepicker">
            <div class="pregnancy-form__item-label">Дата</div>
            <div class="pregnancy-form__datepicker-phone">
              <DatePicker
                :popup-style="{
                  position: 'fixed',
                  top: '10%',
                  left: '50%',
                  transform: 'translateX(-50%)',
                }"
                class="text-input login patient-date"
                v-model:value="chosenDate"
                placeholder="Выберите в календаре"
                value-type="YYYY-MM-DD"
                format="DD.MM.YYYY"
                :disabled-date="disabledRange"
                :monday-first="true"
                :full-month-name="true"
                :lang="'ru'"
                :editable = "false"
              />
            </div>
            <div class="pregnancy-form__datepicker-desck">
              <DatePicker
                class="text-input login patient-date"
                v-model:value="chosenDate"
                placeholder="Выберите в календаре"
                value-type="YYYY-MM-DD"
                format="DD.MM.YYYY"
                :disabled-date="disabledRange"
                :monday-first="true"
                :full-month-name="true"
                :lang="'ru'"
                :editable = "false"
              />
            </div>
          </div>
          <div>
            <CustomButton tag="button" type="submit"
              >Сохранить дату
            </CustomButton>
          </div>
          <div class="pregnancy-form__error-block">
            {{ errorMsg }}
          </div>
        </form>
      </div>
    </div>
  </SectionWrapper>
</template>

<style scoped lang="scss">

.pregnancy-form__header {
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.pregnancy-form__header-background {
  position: absolute;
  top: 50%;
  transform: translateY(-12%);
  z-index: 0;
}

.pregnancy-form__header-woman {
  z-index: 1;
}

.pregnancy-form__method-radio-wrapper {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-size: 14px;
  font-weight: 350;
  line-height: 16px;
  text-align: left;
}

.help-block {
  color: darkred;
  font-size: 14px;
  margin-top: 8px;
}

.pregnancy-form__datepicker-phone {
  display: none;

  @include phone-xs {
    display: block;
  }
}

.pregnancy-form__datepicker-desck {
  display: block;

  @include phone-xs {
    display: none;
  }
}

.pregnancy-form__form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.text-input:deep(input) {
  margin-bottom: 0;
}

.form-group {
  gap: 3px;
  display: flex;
  flex-direction: column;
}

.pregnancy-form__title {
  margin-top: 24px;
  color: #002856;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
}

.pregnancy-form__item-label {
  color: #062e4f;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 8px;
}

.pregnancy-form__radio-list {
  display: flex;
  gap: 8px;
  align-items: center;
  flex-wrap: wrap;
}

.pregnancy-form__radio-item {
  input {
    opacity: 0;
    position: absolute;
  }

  input:checked ~ label {
    background-color: #002856;
    color: #fff;
  }

  input:hover ~ label {
    background-color: #002856;
    color: #fff;
  }

  label {
    color: #062e4f;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    border-radius: 30px;
    background: #e1e8f4;
    height: 34px;
    width: 36px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    transition: 0.2s;
  }
}

.pregnancy-form__error-block {
  color: #062e4f;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #FF0000;
  text-align: center;
}
</style>
