<template>
  <ArrowTitle title="Моя активность" :is-app="isApp" />
  <SectionWrapper stretched>
    <TheLoader v-if="isLoading"></TheLoader>
    <div v-else class="activity-settings">
      <h1 class="activity-settings__headline">Настройте вашу цель на день</h1>
      <div v-if="errorMessage" class="activity-settings__errorMsg">{{ errorMessage }}</div>
      <div class="activity-settings__card">
        <h1>Шаги</h1>
        <div class="activity-settings__card-wrapper">
          <div class="activity-settings__card-info">
            <span>{{ stepsGoal }}</span>
            <span>{{ activityIntensity }} интенсивность</span>
          </div>
          <div class="activity-settings__steps-controller">
            <button @click="subtractSteps">–</button>
            <button @click="addSteps">+</button>
          </div>
        </div>
      </div>

      <Transition name="fade">
        <div
         v-if="
            !isHealthDataAvailable &&
            (platform === 'ios' || platform === 'android')
          "
          class="activity-settings__warning"
        >
          <div class="activity-settings__warning-text">
            <img
              src="@/assets/uploads/icon/common/blue-warning.svg"
              alt="важно"
            />
            <span v-if="!isSetupManual"
              >Дистанция, время активности и потраченные килокалории
              рассчитываются исходя из параметров среднестатистического человека.
              Чтобы рассчитывать индивидуальные данные, необходимо дать доступ к
              вашему росту и весу</span
            >
            <span v-else>
              Дистанция, время активности и потраченные килокалории рассчитываются
              исходя из параметров среднестатистического человека. Чтобы
              рассчитывать индивидуальные данные, предоставьте доступ данному
              приложению к своему росту и весу в настройках устройства
            </span>
          </div>
          <div v-if="!isSetupManual">
            <button @click.prevent="getAccess" class="activity-settings__btn-access">Предоставить доступ</button>
          </div>
        </div>
      </Transition>
      <div
        v-for="card in data"
        :key="card.title"
        class="activity-settings__card"
        :class="{ 'activity-settings__card--disabled': isDataDisabled }"
      >
        <h1>{{ card.title }}</h1>
        <div class="activity-settings__card-wrapper">
          <div>
            <span class="activity-settings__card-info-data">{{ card.data }}</span>
          </div>
        </div>
      </div>
      <button @click.prevent="applySettings" class="activity-settings__btn">ПРИМЕНИТЬ</button>
      <CustomButton
        v-if="platform !== 'ios' && platform !== 'android'"
        white
        @click.prevent="deleteData"
        >Удалить данные</CustomButton
      >
      <div><span>* Предварительный расчет сделан для человека с ростом {{ Math.round(height * 100)}} см, весом {{ weight }} кг</span></div>
    </div>
  </SectionWrapper>
  </template>

<script>
import SectionWrapper from '@/components/ui/SectionWrapper.vue'
import ArrowTitle from '@/components/ui/typography/ArrowTitle.vue'
import { computed, nextTick, onActivated, onMounted, ref, watchEffect } from 'vue'
import CustomButton from '@/components/ui/buttons/CustomButton.vue'
import CustomToast from '@/components/ui/CustomToast.vue'
import {
  checkIfAuthorized,
  checkIfAvailable,
  getHealthQuery,
  requestAuthorization
} from '@/utilits/getHealth'
import { useToast } from 'vue-toastification'
import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'
import axios from 'axios'
import TheLoader from '@/components/ui/TheLoader.vue'
import { useRouter } from 'vue-router'

export default {
  name: 'ActivitySettings',
  emits: ['applySettings'],
  props: {
    isApp: {
      type: Boolean,
      required: true,
      default: false
    },
    user: {
      type: [Object, null],
      required: true,
      default: null
    }
  },
  components: { CustomButton, TheLoader, SectionWrapper, ArrowTitle },

  setup () {
    const STEPS_TRESHOLD = { min: 7500, max: 12500 }
    const activityHistory = ref(null)
    const stepsGoal = ref(9500)
    const dataTypes = ['weight', 'height']
    const platform = window?.device?.platform.toLowerCase()
    const isDataDisabled = ref(true)
    const isHealthDataAvailable = ref(true)
    const toast = useToast()
    const { user } = useUserStore()
    const isLoading = ref(false)
    const errorMessage = ref(null)
    const healthDataHeight = ref(null)
    const healthDataWeight = ref(null)
    const weightSaved = ref(null)
    const heightSaved = ref(null)
    const isSetupManual = ref(false)
    const userStore = useUserStore()
    const { userToken, getUserId, isActivityBannerAvailable } = storeToRefs(userStore)
    const router = useRouter()

    const formatDate = (date) => {
      const year = date.getFullYear()
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const day = date.getDate().toString().padStart(2, '0')
      return `${year}-${month}-${day}`
    }

    const getActivityHistory = async () => {
      const monthFromToday = new Date(
        new Date().getTime() - 31 * 24 * 60 * 60 * 1000
      )

      const params = new URLSearchParams({
        user_id: getUserId.value,
        date_from: formatDate(monthFromToday)
      }).toString()
      try {
        isLoading.value = true
        const response = await axios.get(`/v1/events/activity/?${params}`, {
          headers: {
            Authorization: `Bearer ${userToken.value}`
          }
        })
        if (response.data.status === 'ok') {
          isLoading.value = false
          return response.data.data
        } else {
          isLoading.value = false
          return []
        }
      } catch (err) {}
    }

    const height = computed(() => {
      if (healthDataHeight.value?.at(-1)) { return healthDataHeight.value.at(-1).value }

      if (heightSaved.value) return heightSaved.value

      if (user.gender === 'Мужской') {
        return 1.76
      } else {
        return 1.64
      }
    })

    const weight = computed(() => {
      if (healthDataWeight.value?.at(-1)) { return healthDataWeight.value.at(-1).value }

      if (weightSaved.value) return weightSaved.value

      if (user.gender === 'Мужской') {
        return 75
      } else {
        return 72
      }
    })

    const stepLength = computed(() => {
      return height.value / 4 + 0.3
    })

    const data = computed(() => {
      const distance = (stepsGoal.value * stepLength.value) / 1000
      const time = distance / 5
      const calories = distance * (weight.value * 0.81)

      return [
        { title: 'Желаемая дистанция', data: distance.toFixed(1) + ' ' + 'км' },
        { title: 'Время активности', data: time.toFixed(1) + ' ' + 'часа' },
        {
          title: 'Потрачено калорий',
          data: calories.toFixed(1) + ' ' + 'ккал'
        }
      ]
    })

    const toastOptions = {
      position: 'bottom-center',
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 0.38,
      timeout: 2000,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: 'button',
      icon: false,
      rtl: false,
      toastClassName: 'custom-toast'
    }

    const activityIntensity = computed(() => {
      if (stepsGoal.value < STEPS_TRESHOLD.min) return 'низкая '
      if (stepsGoal.value > STEPS_TRESHOLD.max) return 'высокая '
      return 'средняя '
    })

    const addSteps = () => {
      stepsGoal.value += 500
    }
    const subtractSteps = () => {
      if (stepsGoal.value === 500) return
      stepsGoal.value -= 500
    }

    const applySettings = async () => {
      errorMessage.value = null
      isLoading.value = true
      const params = new URLSearchParams({
        user_id: getUserId.value
      }).toString()
      try {
        const response = await axios.post(
            `/v1/events/activity/setup/?${params}`,
            {
              step_num: stepsGoal.value,
              weight: weight.value,
              height: height.value * 100
            },
            {
              headers: {
                Authorization: `Bearer ${userToken.value}`
              }
            }
        )
        if (response.data.status === 'ok') {
          isDataDisabled.value = false
          isLoading.value = false
          router.push({ name: 'calendar-activity' })
        } else if (response.data.status === 'error') {
          errorMessage.value = response.data.message
          isLoading.value = false
        }
      } catch (err) {}
    }

    const deleteData = async () => {
      const params = new URLSearchParams({
        user_id: getUserId.value
      }).toString()
      try {
        isLoading.value = true
        const response = await axios.delete(
            `/v1/events/activity/delete/?${params}`,
            {
              headers: {
                Authorization: `Bearer ${userToken.value}`
              }
            }
        )
        if (response.data.status === 'ok') {
          isDataDisabled.value = true
          isLoading.value = false
          toast.success(
            {
              component: CustomToast,
              props: {
                title: '',
                text: response.data.message,
                icon: 'success'
              }
            },
            toastOptions
          )
        } else {
          isLoading.value = false
          toast.success(
            {
              component: CustomToast,
              props: {
                title: '',
                text: 'Не удалось удалить данные, попробуйте позже',
                icon: 'error'
              }
            },
            toastOptions
          )
        }
      } catch (err) {}
    }

    const getAccess = async () => {
      try {
        const isAvailable = await checkIfAvailable()
        const isAuthorized = await checkIfAuthorized(dataTypes)
        if (isAvailable === true && isAuthorized === true) {
          healthDataWeight.value = await getHealthQuery('weight')
          healthDataHeight.value = await getHealthQuery('height')
          await nextTick()
          isDataDisabled.value = false
          isHealthDataAvailable.value = true
        } else if (isAuthorized !== true && isAvailable === true) {
          const res = await requestAuthorization(dataTypes)
          const isAuthorized = await checkIfAuthorized(dataTypes)

          if (res === true && isAuthorized === true) {
            healthDataWeight.value = await getHealthQuery('weight')
            healthDataHeight.value = await getHealthQuery('height')
            await nextTick()
            isDataDisabled.value = false
            isHealthDataAvailable.value = true
            isActivityBannerAvailable.value = true
          } else {
            await nextTick()
            isSetupManual.value = true
          }
        }
      } catch (e) {
        if (platform === 'android') {
          toast.success(
            {
              component: CustomToast,
              props: {
                title: '',
                text: 'Чтобы получить доступ к активности, установите приложение Google Fit или Google Health Connect'
              }
            },
            Object.assign({}, toastOptions, { timeout: 5000 })
          )
        }
      }
    }

    onMounted(async () => {
      try {
        if (platform === 'android' || platform === 'ios') {
          const isAvailable = await checkIfAvailable()
          const isAuthorized = await checkIfAuthorized(dataTypes)

          if (platform === 'android') {
            if (isAvailable === true && isAuthorized === true) {
              healthDataWeight.value = await getHealthQuery('weight')
              healthDataHeight.value = await getHealthQuery('height')

              isDataDisabled.value = false
              isHealthDataAvailable.value = true
            } else {
              isHealthDataAvailable.value = false
            }
          } else if (platform === 'ios') {
            if (isAvailable === true) {
              healthDataWeight.value = await getHealthQuery('weight')
              healthDataHeight.value = await getHealthQuery('height')
              isDataDisabled.value = false
              isHealthDataAvailable.value = true
            } else {
              isHealthDataAvailable.value = false
            }
          }
        }
      } catch (e) {
        isHealthDataAvailable.value = false
      }
    })

    onActivated(async () => {
      activityHistory.value = await getActivityHistory()
      if (platform === 'ios' || platform === 'android') {
        if (!isHealthDataAvailable.value && isSetupManual.value) {
          await getAccess()
        }
      }
    })

    watchEffect(() => {
      if (activityHistory.value?.setup) {
        stepsGoal.value = activityHistory.value.setup.step_num
        weightSaved.value = activityHistory.value.setup.weight
        heightSaved.value = activityHistory.value.setup.height / 100
      }
      if (weightSaved.value && heightSaved.value) {
        isDataDisabled.value = false
      }
    })

    return {
      data,
      activityIntensity,
      isHealthDataAvailable,
      isDataDisabled,
      platform,
      isSetupManual,
      isLoading,
      height,
      weight,
      stepsGoal,
      errorMessage,
      applySettings,
      deleteData,
      getAccess,
      addSteps,
      subtractSteps
    }
  }
}
</script>

  <style scoped lang="scss">
  .activity-settings {
    position: relative;
    color: #002856;
    h1 {
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.02em;
    }
  }
  .activity-settings__headline {
    text-align: center;
    margin-bottom: 20px;
  }
  .activity-settings__card {
    padding: 12px 16px 16px 16px;
    border-radius: 6px;
    box-shadow: 0 5px 15px 0 #00000026;
    height: 112px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;
    @media (max-width: 990px) {
      background-color: transparent;
    }
    background-color: #ffffff;
  }
  .activity-settings__card--disabled {
    opacity: 0.5;
  }
  .activity-settings__card-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .activity-settings__card-info {
    @media (max-width: 990px) {
      background-color: transparent;
    }
    background-color: #ffffff;
    span {
      display: block;
    }
    span:first-child {
      font-size: 30px;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: 0.02em;
      text-align: left;
    }
    span:last-child {
      font-size: 12px;
      font-weight: 700;
      line-height: 12px;
      letter-spacing: 0.02em;
    }
  }
  .activity-settings__steps-controller {
    width: 106px;
    height: 44;
    display: flex;

    button {
      all: unset;
      flex: 1;
      text-align: center;
      cursor: pointer;
    }
    button:first-child {
      font-size: 30px;
      font-weight: 550;
      background-color: #e1e8f4;
      border-radius: 30px 0 0 30px;
      padding-top: 2px;
      &:active {
        background-color: #ebeff4;
      }
    }
    button:last-child {
      font-size: 36px;
      font-weight: 500;
      background-color: #bac7de;
      border-radius: 0 30px 30px 0;
      padding-top: 5px;
      &:active {
        background-color: #d3def1;
      }
    }
  }

  .activity-settings__warning {
    background-color: #e1e8f4;
    border-radius: 5px;
    padding: 15px 21px 12px 15px;
    margin-bottom: 16px;
  }
  .activity-settings__warning-text {
    display: flex;
    gap: 12px;
    align-items: start;
    margin-bottom: 8px;

    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.02em;
    text-align: left;
  }
  .activity-setting__btn {
    border: 1px solid #002856;
  }
  .activity-settings__card-info-data {
    font-size: 30px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.02em;
    text-align: left;
  }

  .activity-settings__btn {
    width: 100%;
    padding: 12px;
    border-radius: 30px;
    border: none;
    background-color: #BAC7DE;
    margin-bottom: 16px;
    text-align: center;
    line-height: 16px;
  }
  .activity-settings__btn:active {
    background-color: #d4dae3;
  }

  .activity-settings__btn-access {
    width: 100%;
    padding: 8px 12px 8px 12px;
    border-radius: 30px;
    border: 1px solid #002856;
    text-align: center;
    line-height: 16px;
  }

  .activity-settings__btn-access:active {
    background-color: #002856;
    color: #ffffff;
  }
  .activity-settings__errorMsg {
    color: #ff0000;
    text-align: center;
    margin-bottom: 16px;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
    transform: translateY(-120%);
  }
  </style>
