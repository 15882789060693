<template>
  <div class="modal-payment" :class="{ active: paymentData.success }">
    <div class="container">
      <div class="modal-payment__title">
        <a class="section-title__back" @click.prevent="closePayment">
          <img
            src="@/assets/uploads/icon/common/arrow-back.svg"
            alt="стрелка"
          />
        </a>
        <h3>Подтверждение оплаты</h3>
      </div>
      <div class="modal-payment__content">
        <img src="@/assets/uploads/images/finance.svg" alt="" />
        <p class="modal-payment__content-head">Оплата счета</p>
        <p class="modal-payment__content-clinic">{{ paymentData.clinic }}</p>
        <p class="modal-payment__content-sum">
          на сумму {{ isDeposit ? paymentData.payment : formatDeposit(paymentData.payment) }} рублей
        </p>
        <!-- <p class="modal-payment__content-payment-method">
          Выберите способ оплаты
        </p>
        <div class="modal-payment__content-payment-banners">
          <div class="modal-payment__content-mir-pay" @click="paymentMethod = true" :class="{ active: paymentMethod }">
            <img src="@/assets/uploads/images/payment/mir-logo.svg" alt="мир" />
          </div>
          <div class="modal-payment__content-other-pay" @click="paymentMethod = false" :class="{ active: !paymentMethod }">
            <img
              src="@/assets/uploads/images/payment/visa-logo.svg"
              alt="виза"
            />
            <img
              src="@/assets/uploads/images/payment/mastercard-logo.svg"
              alt="мастеркард"
            />
            <img
              src="@/assets/uploads/images/payment/unionPay-logo.svg"
              alt="юнионпэй"
            />
          </div>
        </div> -->
        <a
          @click.prevent="goPay"
          :href="paymentData.link"
          target="_blank"
          class="btn"
          >Оплатить</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, toRefs } from 'vue'
import { gosuslugiListner } from '@/utilits/gosuslugiListner'
import { postLog } from '@/api/postLog'
import { useRouter } from 'vue-router'
import { useYandexMetrikaStore } from '@/stores/YandexMetrikaStore'
import { useToast } from 'vue-toastification'
import CustomToast from '@/components/ui/CustomToast.vue'

export default {
  name: 'ModalPayment',
  emits: ['closePayment'],
  props: {
    paymentData: {
      type: [Object, null],
      required: true,
      default: null
    },
    currentFilial: {
      type: Number,
      required: true
    },
    isDeposit: {
      type: Boolean,
      required: false
    }
  },
  setup (props, { emit }) {
    const metrikaStore = useYandexMetrikaStore()
    const paymentMethod = ref(true)
    const { paymentData, currentFilial, isDeposit } = toRefs(props)
    const router = useRouter()
    const toast = useToast()

    const toastOptions = {
      position: 'bottom-center',
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 0.38,
      timeout: 2000,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: 'button',
      icon: false,
      rtl: false,
      toastClassName: 'custom-toast'
    }

    const isPhone = computed(() => {
      const platform = window?.device?.platform.toLowerCase()
      return platform === 'ios' || platform === 'android' ? 1 : 0
    })

    const formatDeposit = (sum) => {
      return new Intl.NumberFormat('ru-RU', {
        maximumSignificantDigits: 6
      })
        .format(Math.abs(sum) / 100)
        .toLocaleString('en', { useGrouping: true })
        .replaceAll(',', ' ')
    }

    const goPay = () => {
      metrikaStore.sendGoal('finance_pay_submit', {
        filial: currentFilial.value,
        summ: paymentData.value.payment
      })
      if (!isPhone.value) {
        window.location = paymentData.value.link
        return
      }

      gosuslugiListner(paymentData.value.link)
        .then((event) => {
          let token

          if (event.data.match(/^oauth::/)) {
            token = JSON.parse(event.data.substring(7))
          }

          postLog({
            token: token.result
          })

          if (token === 'success') {
            toast.success(
              {
                component: CustomToast,
                props: {
                  title: isDeposit.value ? 'Аванс внесен' : 'Счета оплачены',
                  text: '',
                  icon: 'success'
                }
              },
              toastOptions
            )
            router.push('/finance')
          }
          if (token === 'failed') {
            toast.success(
              {
                component: CustomToast,
                props: {
                  title: isDeposit.value ? 'Не удалось внести аванс' : 'Не удалось оплатить счета',
                  text: 'Попробуйте еще раз',
                  icon: 'error'
                }
              },
              toastOptions
            )
            router.push('/finance')
          }
        })
        .catch(() => {
          toast.success(
            {
              component: CustomToast,
              props: {
                title: isDeposit.value ? 'Не удалось внести аванс' : 'Не удалось оплатить счета',
                text: 'Попробуйте еще раз',
                icon: 'error'
              }
            },
            toastOptions
          )
          router.push('/finance')
        })
        .finally(() => {
          closePayment()
        })
    }
    const closePayment = () => {
      emit('closePayment')
    }
    return {
      paymentMethod,
      goPay,
      closePayment,
      formatDeposit
    }
  }
}
</script>

<style scoped lang="scss">
.modal-payment {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 0;
  background: #fff;
  border-radius: 8px 8px 0px 0px;
  padding: 12px 0;
  transition: all 0.5s ease-in-out;
  opacity: 0;
  z-index: 2;
  &.active {
    top: 0;
    opacity: 1;
    height: 100%;
  }
}

.modal-payment__title {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  margin-bottom: v-bind('mb');

  & h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #082b53;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }

  img {
    filter: invert(11%) sepia(31%) saturate(5093%) hue-rotate(198deg)
      brightness(102%) contrast(103%);
  }
}

.modal-payment__content {
  text-align: center;

  img {
    margin-bottom: 32px;
  }

  a {
    color: #062e4f;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 120%;
    text-transform: uppercase;
    border-radius: 30px;
    background: var(--blue-1, #bac7de);
    padding: 11px;
    width: 100%;
    border: none;
    margin-top: 16px;
  }
  margin-top: 24px;
}

.modal-payment__content-head {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
}

.modal-payment__content-clinic {
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  text-align: center;
  color: #002856;
}

.modal-payment__content-sum {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
}

.modal-payment__content-payment-method {
  margin-top: 24px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #002856;
}

.section-title__back {
  cursor: pointer;
}

.modal-payment__content-payment-banners {
  display: flex;
  gap: 8px;
  margin-top: 24px;
  img {
    margin: 0;
  }
}
.modal-payment__content-mir-pay {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 54px;
  border: 2px solid #e1e8f4;
  border-radius: 8px;
  &.active {
    border: 2px solid #002856;
  }
}

.modal-payment__content-other-pay {
  display: flex;
  flex-grow: 1;
  height: 54px;
  justify-content: space-evenly;
  align-items: center;
  border: 2px solid #e1e8f4;
  border-radius: 8px;
  &.active {
    border: 2px solid #002856;
  }
}
</style>
