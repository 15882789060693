<template>
  <div
    class="section-wrapper"
    :class="{
      'section-wrapper--stretched': stretched,
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'SectionWrapper',
  props: {
    stretched: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.section-wrapper {
  background: #ffffff;
  border-radius: 8px;
  padding: 14px 14px 70px 14px;
  margin-bottom: 12px;

  //min-height: 800px;
  @include desktop {
    background: none;
    padding: 0;
  }
  &.hidden {
    overflow: hidden;
    max-height: 1150px;
  }
  &--stretched {
    margin-bottom: 0;
    flex: 1 1 auto;
    min-height: 600px;
    @include desktop {
      position: relative;
    }
  }
}
</style>
