<template>
  <div class="file__item"
       :class="{ 'file__item--choice': isChoice, 'file__item--active': isChoice && isActive}"
  >
    <div class="file__img" v-if="file.file_type">
      <img src="@/assets/uploads/images/files/doc.svg" alt="иконка" v-if="file.file_type==='doc'">
      <img src="@/assets/uploads/images/files/pdf.svg" alt="иконка" v-if="file.file_type==='pdf'">
      <img :src="file.url" :alt="file.orig_name" v-else>
    </div>
    <div class="file__name">
      {{ file.orig_name }}
    </div>
  </div>
</template>

<script>

export default {
  name: 'FileItem',
  props: {
    file: {
      type: [Object, null],
      required: true,
      default: null
    },
    isChoice: {
      type: Boolean,
      required: true,
      default: false
    },
    isActive: {
      type: Boolean,
      required: true,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.file__item {
}
.file__name {
  color: #002856;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-top: 6px;
  overflow: hidden;
  word-break: break-word;
}

.file__img {
  border-radius: 6px;
  background: #E1E8F4;
  width: 108px;
  height: 108px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  img {
    height: 90%;
    object-fit: cover;
  }
}
.file__item--choice {
  cursor: pointer;
  .file__img {
    &:before {
      position: absolute;
      content: '';
      width: 16px;
      height: 16px;
      background: #fff;
      border-radius: 2px;
      border: 1px solid #BAC7DE;
      bottom: 10px;
      right: 10px;
    }
  }
}
.file__item--active {
  .file__img {
    &:after {
      position: absolute;
      content: '';
      width: 16px;
      height: 16px;
      background: #fff;
      border-radius: 2px;
      bottom: 10px;
      right: 10px;
      border: 1px solid #BAC7DE;
      background-image: url('~@/assets/uploads/icon/common/check.svg');
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}
</style>
