<script>
export default {
  props: {
    bannerLink: String
  },
  name: 'UpdateAppBanner'
}
</script>

<template>
  <div class="banner">
    <div class="banner__wrapper">
      <div class="banner__column">
        <h4 class="banner__title">
          Мы исправили ошибки <br />
          и расширили функционал!
        </h4>
        <a :href="bannerLink" target="_blank" class="banner__link">
          обновить приложение
        </a>
      </div>
      <div class="banner__picture">
        <img
          src="@/assets/uploads/images/home/banner.svg"
          alt="изображение приложения"
        />
      </div>
      <a
        @click.prevent="$emit('closeBanner')"
        class="banner__close btn-close"
      ></a>
    </div>
  </div>
</template>

<style scoped lang="scss">
.banner {
  margin-bottom: 16px;
}

.banner__wrapper {
  position: relative;
  display: flex;
  gap: 30px;
  justify-content: space-between;
  border-radius: 6px;
  background: #E1E8F4;
  padding: 20px 40px 25px 16px;
}

.banner__close {
  cursor: pointer;
  position: absolute;
  right: 9px;
  top: 9px;
}

.banner__column {
  position: relative;
  z-index: 3;
}

.banner__title {
  margin-bottom: 14px;
  color: $blue;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
}

.banner__link {
  cursor: pointer;
  padding: 3px 16px;
  border-radius: 12px;
  background: $blue;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;

  &:hover {
    background: $blue-6;
  }
}

.banner__picture {
  z-index: 2;
  position: absolute;
  bottom: 0;
  right: 40px;

  & img {
    //width: 100px;
  }
}
</style>
