<template>
  <div class="review-block">
    <div class="warning-block">
      <div class="warning-block__text">
        <img
          src="@/assets/uploads/icon/common/payment-warning.svg"
          alt="важно"
        />
        <div>
          <span>У вас нет активной подписки на сегодня</span>
          <span>Оплатите чат с врачом, чтобы продолжить</span>
        </div>
      </div>
      <CustomButton
        transparent
        :style="{ marginBottom: 0 + 'px', marginTop: 16 + 'px' }"
        @click.prevent="$emit('openChatOptions')"
        >Перейти к оплате</CustomButton
      >
    </div>
    <div class="review">
      <h3>Ваше впечатление от чата с врачом</h3>
      <div class="rate">
        <template v-for="score in 5" :key="score">
          <input
            type="radio"
            :id="`star-${score}-${roomId}`"
            :name="`star-${roomId}`"
            :value="`star-${score}-${roomId}`"
          />
          <label
            :for="`star-${score}-${roomId}`"
            title="text"
            @click="openReviewModal(score)"
          >
            <img src="@/assets/uploads/icon/common/star-light.svg" alt="star" />
          </label>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import CustomButton from '@/components/ui/buttons/CustomButton.vue'

export default {
  name: 'SubscribtionOverMessage',
  props: {
    roomId: Number
  },
  components: { CustomButton },
  emits: ['rateSelected', 'openChatOptions'],
  setup (props, { emit }) {
    const scoreRate = ref(null)

    const openReviewModal = (score) => {
      if (score) {
        emit('rateSelected', score)
      }
    }

    return {
      scoreRate,
      openReviewModal
    }
  }
}
</script>

<style scoped lang="scss">
.review-block {
 padding-bottom: 0;
 @include phone-xs {
  padding-bottom: 113px;
 }
}
.review {
  width: 100%;
  height: fit-content;
  text-align: center;
  padding: 12px 0 4px;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    margin-bottom: 12px;
  }
}

.rate {
  display: flex;
  align-items: center;
  gap: 16px;

  label img {
    filter: invert(8%) sepia(96%) saturate(2856%) hue-rotate(202deg)
      brightness(95%) contrast(102%);
  }

  &:not(:hover) {
    input:indeterminate + label img {
      filter: invert(96%) sepia(1%) saturate(5289%) hue-rotate(184deg)
        brightness(97%) contrast(98%);
    }

    input:checked ~ input + label img {
      filter: invert(96%) sepia(1%) saturate(5289%) hue-rotate(184deg)
        brightness(97%) contrast(98%);
    }
  }

  input:hover ~ input + label img {
    filter: invert(96%) sepia(1%) saturate(5289%) hue-rotate(184deg)
      brightness(97%) contrast(98%);
  }

  input {
    display: none;
  }
}

.warning-block {
  border-radius: 5px;
  padding: 16px 11px;
  background: #f1842c4d;
}

.warning-block__text {
  display: flex;
  gap: 12px;
  align-items: start;
  div {
    span:first-child {
      font-size: 13px;
      font-weight: 700;
      line-height: 18px;
      text-align: left;
    }
    span:last-child {
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
    }
    span {
      display: block;
    }
  }
}
</style>
