import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useNotifyStore = defineStore('notifyStore', () => {
  const fromBackground = ref(false)
  const fromNotifyView = ref(false)
  const notificationList = ref([])

  const setFromBackground = (isBackground) => {
    fromBackground.value = isBackground
  }
  const setNotification = (payload) => {
    const notifyData = {
      title: payload.title,
      message: payload.body,
      routerUrl: payload.routerUrl,
      externalUrl: payload.externalUrl,
      pid: payload.pid,
      wasChecked: false
    }
    notificationList.value.unshift(notifyData)
  }

  const checkNotify = () => {
    notificationList.value.forEach((notify) => {
      notify.wasChecked = true
    })
  }

  return {
    fromBackground,
    setFromBackground,
    setNotification,
    notificationList,
    checkNotify,
    fromNotifyView
  }
})
