<template>
  <div class="gosuslugi">
    <h3 class="gosuslugi__title">Регистрация через Госуслуги</h3>

    <TheLoader v-if="loading" />

    <div v-if="error" class="gosuslugi__wrapper">
      <div class="gosuslugi__error">
        <p class="error help-block">
          {{ errorMessage }}
        </p>

        <router-link to="/auth">
          <p style="text-align: center"><br />Назад</p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import TheLoader from '@/components/ui/TheLoader.vue'
import { inject, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import axios from 'axios'
import { useUserStore } from '@/stores/UserStore'

export default {
  name: 'GosuslugiWithTokenView',
  components: { TheLoader },

  setup () {
    const toast = inject('toast')
    const userStore = useUserStore()
    const { setUserToken, setUser } = userStore
    const route = useRoute()
    const router = useRouter()
    const loading = ref(false)
    const error = ref(true)
    const errorMessage = ref('Что-то пошло не так, попробуйте позже')

    const showHelloToaster = () => {
      toast.success('Добро пожаловать!', {
        position: 'top-right',
        type: 'success'
      })
    }

    const authUser = async () => {
      try {
        loading.value = true
        const response = await axios(
          `/v2/element/user/token-otp/?token=${route.params.token}`
        )

        if (response.data.status === 'ok') {
          error.value = false
          const token = response.data.data.token
          setUserToken(token)
          await setUser(token)
          await router.push({ name: 'home' })
          showHelloToaster()
        } else {
          errorMessage.value = response.data?.message
          error.value = true
        }
      } catch (e) {
        error.value = true
      } finally {
        loading.value = false
      }
    }

    onMounted(async () => {
      await authUser()
    })

    return {
      loading,
      error,
      errorMessage
    }
  }
}
</script>

<style scoped lang="scss">
.gosuslugi__title {
  margin-bottom: 20px;
  text-align: center;
  color: #082b53;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.36px;
}

.gosuslugi__inputs {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}
</style>
