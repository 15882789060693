<script>
import { computed, ref } from 'vue'
import VueMultiselect from 'vue-multiselect'
import CustomButton from '@/components/ui/buttons/CustomButton.vue'
import ErrorText from '@/components/ui/ErrorText.vue'

export default {
  name: 'TimePickerDesktop',
  emits: ['addTime'],
  components: { ErrorText, CustomButton, VueMultiselect },
  setup (props, { emit }) {
    const error = ref(false)
    const hours = ref([
      '08',
      '09',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
      '21',
      '22',
      '23',
      '00',
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07'
    ])
    const minutes = ref([
      '55',
      '00',
      '05',
      '10',
      '15',
      '20',
      '25',
      '30',
      '35',
      '40',
      '45',
      '50'
    ])
    const currentHours = ref(null)
    const currentMinutes = ref(null)
    const currentTime = computed(() => {
      return `${currentHours.value}:${currentMinutes.value}`
    })
    const addTime = () => {
      if (!currentHours.value || !currentMinutes.value) {
        error.value = true
        return
      }
      error.value = false
      emit('addTime', currentTime.value)
      currentHours.value = null
      currentMinutes.value = null
    }

    return {
      hours,
      minutes,
      currentHours,
      currentMinutes,
      addTime,
      error
    }
  }
}
</script>

<template>
  <div class="time-picker">
    <div class="time-picker__wrapper doc-call__radio doc-call__address">
      <VueMultiselect v-model="currentHours" :options="hours" :close-on-select="true" :clear-on-select="false"
        placeholder="Часы" selectLabel="" deselectLabel="Удалить" class="arrow time-picker__select" />
      <VueMultiselect v-model="currentMinutes" :options="minutes" :close-on-select="true" :clear-on-select="false"
        placeholder="Минуты" selectLabel="" deselectLabel="Удалить" class="arrow time-picker__select" />
      <CustomButton @click="addTime" tag="button" type="button" white bg-color="#eef2f8" marginBottom="0">
        Добавить время приема
      </CustomButton>
    </div>
    <ErrorText v-if="error" class="time-picker__error" error="Необходимо выбрать время" :show-btn="false" />
  </div>
</template>

<style scoped lang="scss">
.time-picker__wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.time-picker__select {
  margin: 0;
}

.time-picker__error {
  display: block;

  @include phone-xs {
    display: none;
  }
}
</style>

<style lang="scss">
.time-picker__wrapper {
  .time-picker__select {
    display: block !important;

    .multiselect__content-wrapper {
      transform: translateY(0) !important;
    }

    .multiselect__tags {
      padding: 0 !important;
      height: 100%;
      display: block;
      border-radius: 8px;

      .multiselect__input {
        padding: 10px !important;
        border: none !important;
        border-radius: 8px !important;
      }

      .multiselect__single {
        padding: 10px !important;
        height: 40px;
        border-radius: 8px;
      }
    }
  }
}
</style>
