<template>
  <ArrowTitle title="Вакцинация" :is-app="isApp"></ArrowTitle>
  <SectionWrapper stretched>
    <TheLoader v-show="loading" />
    <ErrorText v-if="errorResponse && !loading" :error="errorResponse" />
    <ModalWithArrow
      @modalClose="activeVaccine = null"
      :modalIsActive="Boolean(activeVaccine)"
      :title="activeVaccine?.title"
    >
      <div class="vaccine-modal">
        <h4>Название вакцины: {{ activeVaccine?.title }}</h4>
        <p>{{ activeVaccine?.target }}</p>
        <p>Кратность: {{ activeVaccine?.frequency || "Не указано" }}</p>
        <p>Серия: {{ activeVaccine?.serie || "Не указано" }}</p>
        <p>Адрес: {{ activeVaccine?.where || "Не указано" }}</p>
        <div class="vaccination__item-date">
          <img
            src="@/assets/uploads/icon/profile/calendar.svg"
            alt="иконка календаря"
          />
          <p>
            Дата:
            <span>{{ dateVaccine }}</span>
          </p>
        </div>
      </div>
    </ModalWithArrow>
    <div v-if="!loading" class="calendar">
      <div v-if="activeYear" class="calendar__tag-list">
        <label
          v-for="(year, index) in yearTags"
          :key="index"
          :for="year"
          class="calendar__tag"
          :class="{ active: activeYear === year }"
        >
          {{ year }}
          <input
            v-model="activeYear"
            :value="year"
            type="radio"
            name="age-tags"
            :id="year"
          />
        </label>
      </div>

      <div v-if="vaccinList.length !== 0" class="calendar__vaccination-list">
        <VaccinationItem
          v-for="vaccine in currentYearVaccineList"
          :key="vaccine.id"
          :vaccine="vaccine"
          @click="activeVaccine = vaccine"
        />
      </div>
      <router-link to="/vaccination-add" class="calendar__link">
        <img
          src="@/assets/uploads/icon/common/plus-vaccine.svg"
          alt="иконка добавления"
        />
        <p>Добавить прививки, сделанные в других клиниках</p>
      </router-link>
      <CustomButton tag="router-link" to="/calendar-national" white>
        смотреть Календарь прививок
      </CustomButton>
    </div>
  </SectionWrapper>
</template>

<script>
import ArrowTitle from '@/components/ui/typography/ArrowTitle.vue'
import SectionWrapper from '@/components/ui/SectionWrapper.vue'
import VaccinationItem from '@/components/pages/calendar/VaccinationItem.vue'
import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'
import { computed, onActivated, ref, watchEffect } from 'vue'
import TheLoader from '@/components/ui/TheLoader.vue'
import { authApi } from '@/api/authApi'
import ErrorText from '@/components/ui/ErrorText.vue'
import ModalWithArrow from '@/components/modals/ModalWithArrow.vue'
import { useDateStore } from '@/stores/date'
import CustomButton from '@/components/ui/buttons/CustomButton.vue'
import { useRouter } from 'vue-router'

export default {
  name: 'VaccinationCalendarView',
  components: {
    CustomButton,
    ModalWithArrow,
    ErrorText,
    TheLoader,
    SectionWrapper,
    ArrowTitle,
    VaccinationItem
  },
  props: {
    isApp: {
      type: Boolean,
      required: true,
      default: false
    },
    user: {
      type: [Object, null],
      required: true,
      default: null
    }
  },
  setup () {
    const router = useRouter()
    const userStore = useUserStore()
    const { getUserId } = storeToRefs(userStore)
    const dateStore = useDateStore()
    const dictMonthRod = dateStore.dictMonthRod
    const activeVaccine = ref(null)
    const loading = ref(false)
    const activeYear = ref(null)
    const errorResponse = ref('')
    const yearTags = computed(() => {
      if (vaccinList.value.length === 0) return []

      const years = vaccinList.value.map((vaccine) => {
        return new Date(vaccine.date_at).getFullYear()
      })

      return Array.from(new Set(years))
    })
    const vaccinList = ref([])

    const dateVaccine = computed(() => {
      if (!activeVaccine.value?.date_at) return null

      const [year, month, day] = activeVaccine.value.date_at.split('-')
      const formatMonth = month.startsWith(0) ? month[1] : month

      return `${day} ${dictMonthRod[parseInt(formatMonth) - 1]} ${year} г.`
    })

    const currentYearVaccineList = computed(() => {
      if (vaccinList.value.length === 0) return []
      return vaccinList.value.filter((vaccine) => {
        return new Date(vaccine.date_at).getFullYear() === activeYear.value
      })
    })

    const getUserVaccines = async (id) => {
      try {
        loading.value = true

        const [err, response] = await authApi(
          `/v2/events/vaccination/?user_id=${id}`
        )
        if (err) {
          errorResponse.value = err
          return
        }

        if (response.data.length === 0) {
          if (router.options.history.state.forward === '/vaccination-add') {
            router.go(-1)
            return
          }
          router.push('/vaccination-add')
        }
        vaccinList.value = response.data
      } catch (err) {
        errorResponse.value = err
      } finally {
        loading.value = false
      }
    }

    watchEffect(async () => {
      if (getUserId.value) {
        await getUserVaccines(getUserId.value)
      }
    })
    watchEffect(() => {
      if (yearTags.value.length !== 0) {
        activeYear.value = yearTags.value[0]
      }
    })

    onActivated(async () => {
      errorResponse.value = ''
      if (getUserId.value) {
        await getUserVaccines(getUserId.value)
      }
    })

    return {
      loading,
      getUserVaccines,
      yearTags,
      errorResponse,
      vaccinList,
      activeYear,
      activeVaccine,
      dateVaccine,
      currentYearVaccineList
    }
  }
}
</script>

<style scoped lang="scss">
.calendar {
  margin-bottom: 22px;
}

.calendar__tag-list {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}

.calendar__tag {
  padding: 8px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background: $blue-3;
  color: $blue-6;
  transition: all 0.3s ease-in-out;

  &.active {
    background: $blue;
    color: #fff;
  }

  & input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

.calendar__vaccination-list {
  margin-bottom: 22px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.vaccination__item-date {
  display: flex;
  gap: 8px;
  align-items: center;

  & p {
    color: $blue;

    & span {
      color: rgba(0, 40, 86, 0.5);
    }
  }
}

.vaccine-modal {
  text-align: start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: $blue;
  font-size: 16px;
  line-height: 100%;
}

.calendar__link {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 16px;
  color: $blue;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
}
</style>
