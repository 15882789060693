<template>
  <SectionWrapper stretched>
    <MyAppointment v-if="user" :user="user" is-app />
  </SectionWrapper>
</template>

<script>
import MyAppointment from '@/components/pages/my-schedule/MyAppointment.vue'
import SectionWrapper from '@/components/ui/SectionWrapper.vue'

export default {
  name: 'MyScheduleView',
  components: { SectionWrapper, MyAppointment },
  props: {
    user: {
      type: [Object, null],
      required: true,
      default: null
    }
  }
}
</script>

<style scoped lang="scss"></style>
