<template>
    <div class="pin">
        <TheLoader v-show="loading" :white="true" />
        <ErrorText
            class="pin-error"
            v-if="responseError"
            :error="responseErrorMessage"
            :show-btn="false"
          />
        <div v-if="!loading">
          <h3 class="pin__title">Вход по код-паролю</h3>
          <form
            action=""
            class="pin__form"
          >
              <OtpPinForm
                v-model="pin"
                :digit-count="6"
                :error="pinError"
                @is-completed="submitPin"
                :isAutofocus="true"
              />
              <div v-if="pinErrorMessage.error" class="error pin-help-block">
                {{ pinErrorMessage.msg }}
              </div>
              <div class="pin-help-block" @click="goToSmsForm">
                Код утерян, отправить СМС для входа
              </div>
              <CellPhoneKeypad :isLogin="true" :pinLength="pin.length" class="pin-keypad" @change-pin="changePin"/>
              <button class="pin-help-block pin-skip-btn">
                <a
                  href="https://www.k31.ru/upload/doc/useterms.pdf"
                  target="_blank"
                >
                  Пользовательское соглашение
                </a>
              </button>
            <div v-if="platform !== 'web'" class="pin-help-block pin-version-info">
              <span v-if="platform === 'ios'">
                Версия приложения {{ appVersionIos }} {{ platform }}
              </span>
              <span v-if="platform === 'android'">
                Версия приложения {{ appVersionAndroid }} {{ platform }}
              </span>
            </div>
          </form>
      </div>
    </div>
</template>

<script>
import OtpPinForm from '@/components/pages/auth/OtpPinForm.vue'
import CellPhoneKeypad from '@/components/pages/auth/CellPhoneKeypad.vue'
import TheLoader from '@/components/ui/TheLoader.vue'
import axios from 'axios'
import { onActivated, ref, inject, computed, toRefs } from 'vue'
import ErrorText from '@/components/ui/ErrorText.vue'
import { useRouter } from 'vue-router'
import { useAuthUser } from '@/composables/useAuthUser'
import { useUserStore } from '@/stores/UserStore'
import { showFingerPrint } from '@/utilits/showFingerPrint'
import { storeToRefs } from 'pinia'
import { getPlatform } from '@/utilits/getPlatform'
import { deleteCookieToken } from '@/utilits/deleteCookieToken'
import { useNotifyStore } from '@/stores/NotifyStore'

export default {
  name: 'PinCodeView',
  components: { ErrorText, TheLoader, OtpPinForm, CellPhoneKeypad },
  emits: ['forgotPin'],
  props: {
    userPhone: {
      type: String,
      required: true,
      default: ''
    },
    hasPinCode: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  setup (props, { emit }) {
    const { userPhone, hasPinCode } = toRefs(props)
    const userStore = useUserStore()
    const { setHasPinCode } = userStore
    const { userToken, user, isBiometryActive } = storeToRefs(userStore)
    const { setUserToken, setUser, setBiometry } = userStore
    const notifyStore = useNotifyStore()
    const { fromBackground } = storeToRefs(notifyStore)
    const toast = inject('toast')
    const router = useRouter()
    const loading = ref(false)
    const responseError = ref(false)
    const responseErrorMessage = ref('Произошла ошибка, попробуйте позже.')
    const pinError = ref(false)
    const pinErrorMessage = ref({
      msg: '',
      error: false
    })
    const pin = ref('')
    const platform = getPlatform()
    const appVersionIos = process.env.VUE_APP_IOS_VERSION
    const appVersionAndroid = process.env.VUE_APP_ANDROID_VERSION

    const showHelloToaster = () => {
      toast.success('Добро пожаловать!', {
        position: 'top-right',
        type: 'success'
      })
    }

    onActivated(() => {
      pin.value = ''
    })

    const isPhone = computed(() => {
      const platform = window?.device?.platform.toLowerCase()
      return platform === 'ios' || platform === 'android'
    })

    const submitPin = async () => {
      responseError.value = false
      try {
        loading.value = true
        const response = await axios.post('/v2/element/user/auth-otp', {
          phone: userPhone.value,
          code: pin.value,
          device_uuid: window?.device?.uuid
        })
        if (response.data.status === 'ok') {
          const action = response.data.data.action
          responseError.value = false

          if (action === 'login') {
            await useAuthUser(userPhone.value, pin.value)
            if (hasPinCode.value || !isPhone.value) {
              setHasPinCode(hasPinCode.value)
              await router.push({ name: 'home' })
              showHelloToaster()
              return
            }
            await router.push({ name: 'auth-pin-code' })
          }
        } else {
          if (response.data.error_code === 'reset_pincode') goToSmsForm()
          pin.value = ''
          pinError.value = true
          pinErrorMessage.value.error = true
          pinErrorMessage.value.msg = response.data.message
          return
        }
      } catch (err) {
        responseError.value = true
        responseErrorMessage.value = 'Нет доступа к серверу. Попробуйте позже.'
        return
      } finally {
        loading.value = false
      }

      if (userToken.value && !user.is_pin) {
        showFingerPrint(userToken.value)
      }
    }

    const sendSmsCode = async () => {
      try {
        loading.value = true

        const response = await axios.post('/v2/element/user/request-otp', {
          phone: `${userPhone.value}`
        })

        if (response.data.status === 'ok') {
          responseError.value = false
        } else {
          responseErrorMessage.value =
            response.data?.message ??
            'Нет доступа к серверу. Попробуйте позже.'
          responseError.value = true
        }
      } catch (err) {
        loading.value = false
      } finally {
        loading.value = false
      }
    }

    const goToSmsForm = () => {
      sendSmsCode()
      emit('forgotPin')
      setBiometry(false)
    }

    const noAuthLogin = async () => {
      await router.push({ name: 'home' })
    }

    const initBiometry = () => {
      new Promise((resolve, reject) => {
        window.Fingerprint.isAvailable(
          () => {
            window.Fingerprint.loadBiometricSecret(
              {
                description:
                  'Для работы с личным кабинетом надо пройти аутентификацию',
                disableBackup: true // always disabled on Android
              },
              (suc) => {
                setBiometry(true)
                resolve(suc)
              },
              (err) => {
                reject(err)
              }
            )
          },
          (err) => {
            setBiometry(false)
            reject(err)
          }
        )
      })
        .then((secret) => {
          setUserToken(secret)
          return setUser(secret)
        })
        .then(() => {
          if (fromBackground.value) {
            return
          }
          router.push({
            name: 'home'
          })
          showHelloToaster()
        })
        .catch(() => {
          deleteCookieToken()
        })
    }

    const changePin = (currNum) => {
      if (currNum === -1 && pin.value.length === 0 && isBiometryActive.value) initBiometry()

      if (currNum === '') noAuthLogin()
      if (pin.value.length === 0 && currNum === -1) return
      if (pin.value.length > 6) return
      if (pinError.value) pinError.value = false
      if (currNum !== -1 && pin.value.length < 6) pin.value += currNum
      if (currNum === -1) pin.value = pin.value.slice(0, -1)
    }

    return {
      loading,
      responseError,
      responseErrorMessage,
      pin,
      pinError,
      pinErrorMessage,
      platform,
      appVersionAndroid,
      appVersionIos,
      submitPin,
      goToSmsForm,
      changePin
    }
  }
}
</script>

<style scoped lang="scss">
.pin {
  &__title {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: center;
  }
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__logo {
    margin-bottom: 32px;
    display: flex;
    justify-content: center;
    @include desktop {
      display: none;
    }

    &.pin__logo--desktop {
      display: none;
      @include desktop {
        display: flex;
        img {
          width: 100px;
        }
      }
    }
  }
  &__form {
    display: flex;
    flex-direction: column;
    margin-top: 28px;
  }
}

.pin-keypad {
  margin-top: 40px;
}

.pin-help-block {
  padding: 0 30px;
  margin-bottom: 8px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
}
.pin-skip-btn {
  all: unset;
  margin-top: 37px;
  text-align: center;
}

.pin-version-info {
  margin-top: 16px;
  color: #7F8DA9;
}

.error {
  color: #FF1F00
}

.pin-error {
  margin: 0;
}

p {
  font-size: 14px;
  font-weight: 350;
  line-height: 14px;
  text-align: center;
  margin-bottom: 24px;
  color: #002856;
}
</style>
