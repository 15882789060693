<template>
    <div class="actual-elem">
        <div class="actual-elem__wrapper">
            <div class="actual-elem__image" :style="{ backgroundImage: 'url(' + img + ')' }">
            </div>
        </div>
        <div class="actual-elem__text">
            {{ text }}
        </div>
    </div>
</template>

<script>
export default {
  name: 'ActualSwiperElement',
  props: ['img', 'text', 'href']
}
</script>

<style scoped lang="scss">
.actual-elem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    &__wrapper {
        width: 96px;
        height: 96px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 6px;
        border: 1px solid #F1842C;
        border-radius: 100%;
        padding: 2px;
    }
}

.actual-elem {

    &__wrapper {
        padding: 2px;
    }

    &__image {
        width: 88px;
        height: 88px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        border-radius: 100%;
        display: flex;
        align-items: flex-end;
        padding: 5px;
    }
}

.actual-elem__text {
    width: 96px;
    color: #002856;
    font-size: 11px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0.02em;
    text-align: center;

    /* 109.091% */
    @include desktop {
        color: #002856;
        font-weight: 400;
    }
}
</style>
