<template>
  <div class="modal-payment" :class="{ active: modalIsOpen }">
    <div class="container modal-payment__wrapper">
      <div class="modal-payment__title">
        <a class="section-title__back" @click.prevent="$emit('modalClose')">
          <img
            src="@/assets/uploads/icon/common/arrow-back.svg"
            alt="стрелка"
          />
        </a>
        <h3>{{ title }}</h3>
      </div>
      <div class="modal-payment__content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalWrapper',
  emits: ['modalClose'],
  props: {
    modalIsOpen: {
      type: Boolean,
      required: true,
      default: false
    },
    title: {
      type: String,
      required: false,
      default: 'Файл'
    }
  }
}
</script>

<style scoped lang="scss">
.modal-payment {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 0;
  background: #fff;
  border-radius: 8px 8px 0px 0px;
  padding: 12px 0;
  transition: all 0.5s ease-in-out;
  opacity: 0;

  &.active {
    top: 0;
    opacity: 1;
    z-index: 10000;
    height: 100%;
  }
}

.modal-payment__wrapper {
  display: none;
}

.modal-payment.active .modal-payment__wrapper {
  display: block;
}

.section-title__back {
  cursor: pointer;
}

.modal-payment__title {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  margin-bottom: v-bind("mb");

  & h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #082b53;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }

  img {
    filter: invert(11%) sepia(31%) saturate(5093%) hue-rotate(198deg)
      brightness(102%) contrast(103%);
  }
}

.modal-payment__content {
  text-align: center;

  img {
    margin-bottom: 32px;
  }

  a {
    color: #062e4f;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 120%;
    text-transform: uppercase;
    border-radius: 30px;
    background: var(--blue-1, #bac7de);
    padding: 11px;
    width: 100%;
    border: none;
    margin-top: 16px;
  }

  p {
    color: #002856;
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }

  padding: 0 15px;
  margin-top: 24px;
}
</style>
