<template>
  <component
    :is="tag"
    class="custom-btn"
    :class="{
      'custom-btn--white': white,
      'custom-btn--blue': blue,
      'custom-btn--light-blue': lightBlue,
      'custom-btn--small': small,
      'custom-btn--gray': disabled,
      'custom-btn--transparent': transparent
    }"
    :href="tag === 'a' ? href : null"
    :type="type"
    :target="tag === 'a' ? target : ''"
  >
    <div class="custom-btn__wrapper">
      <slot name="img"></slot>

      <slot />
    </div>
  </component>
</template>

<script>
export default {
  name: 'CustomButton',
  props: {
    transparent: {
      type: Boolean,
      required: false,
      default: false
    },
    white: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    blue: {
      type: Boolean,
      required: false,
      default: false
    },
    lightBlue: {
      type: Boolean,
      required: false,
      default: false
    },
    tag: {
      type: String,
      required: false,
      default: 'a'
    },
    bgColor: {
      type: String,
      required: false,
      default: '#BAC7DE'
    },
    marginBottom: {
      type: String,
      required: false,
      default: '10px'
    },
    marginTop: {
      type: String,
      required: false,
      default: '0px'
    },
    type: {
      type: [String, null],
      default: null,
      required: false
    },
    color: {
      type: String,
      default: '#002856',
      required: false
    },
    href: {
      type: String,
      default: '',
      required: false
    },
    target: {
      type: String,
      default: '',
      required: false
    },
    textTransform: {
      type: String,
      default: 'uppercase',
      required: false
    },
    small: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="scss">
.custom-btn {
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  width: 100%;
  padding: 12px;
  text-align: center;
  text-transform: v-bind(textTransform);
  color: v-bind("color");
  border-radius: 30px;
  background-color: v-bind(bgColor);
  margin-top: v-bind(marginTop);
  margin-bottom: v-bind("marginBottom");
  line-height: 16px;
  border: unset;
  transition: 0.2s;

  &:active {
    background: #e1e8f4;
    text-decoration: none;
    color: #062e4f;
  }

  &:hover {
    outline: 1px solid v-bind(bgColor);
    background: #e1e8f4;
    text-decoration: none;
    color: #062e4f;
  }

  &--white {
    background: #ffffff;
    border: 1px solid #002856;

    &:active {
      background: #002856;
      color: #fff;
    }

    &:hover {
      background: #002856;
      color: #fff;
    }
  }

  &--blue {
    color: #fff !important;
    background: $blue;
    border: 1px solid #002856;

    &:active {
      background: #002856;
      color: #fff;
    }

    &:hover {
      background: #002856;
      color: #fff;
    }
  }

  &--light-blue {
    background: $blue-extra-light;

    &:active {
      background: #002856;
      color: #fff;
    }

    &:hover {
      background: #002856;
      color: #fff;
    }
  }

  &--transparent {
    background: transparent;
    border: 1px solid #002856;
    color: #002856;

    &:active {
      background: #002856;
      color: #fff;
    }

    &:hover {
      background: #002856;
      color: #fff;
    }
  }

  &--gray {
    background: #c4c4c4;
    color: #fff;
    pointer-events: none;

    &:active {
      background: #c4c4c4;
      color: #fff;
    }

    &:hover {
      background: #c4c4c4;
      color: #fff;
    }
  }

  &--small {
    padding: 8px 12px;
  }

  &__wrapper {
    display: grid;
    align-items: center;
    gap: 5px;
    grid-template-columns: auto 1fr;
  }
}
</style>
