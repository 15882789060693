<template>
  <div v-show="isIOSPlatform" class="status-bar" ref="statusBar"></div>
  <TheHeader v-if="hiddenView" :user="user" :headerPadding="headerPadding" />
  <main
    class="main"
    :class="{ 'main--small': isSmallMain, hidden: menuIsActive }"
  >
    <div :class="{ main__container: hiddenView }">
      <TheMenu :show="true" @close="closeMenu" v-show="hiddenView"></TheMenu>
      <div class="main__content">
        <router-view v-slot="{ Component }">
          <keep-alive :exclude="KEEP_ALIVE_EXCEPTIONS">
            <component :is="Component" :user="user" :isApp="isIOSPlatform" />
          </keep-alive>
        </router-view>
      </div>
    </div>
  </main>
  <TheFooter v-if="hiddenView" :footerPadding="footerPadding" />
  <DesktopFooter v-if="hiddenView" />
</template>

<script>
// глобальные стили для свайпера
import 'swiper/css'
import 'swiper/css/pagination'

import TheHeader from '@/components/layout/header/TheHeader.vue'
import TheFooter from '@/components/layout/footer/TheFooter.vue'
import TheMenu from '@/components/layout/menu/TheMenu.vue'
import DesktopFooter from '@/components/layout/footer/DesktopFooter.vue'
import { useMenuStore } from '@/stores/MenuStore'
import { storeToRefs } from 'pinia'
import { useUserStore } from '@/stores/UserStore'
import { computed, inject, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { authDevelopment } from '@/utilits/authDevelopment'
import { authBrowser } from '@/utilits/authBrowser'
import { getStatusBarHeight } from '@/utilits/getStatusBarHeight'
import axios from 'axios'
import { useAppInfoStore } from '@/stores/AppInfoStore'
import { postLog } from '@/api/postLog'
import { useYandexMetrikaStore } from '@/stores/YandexMetrikaStore'
import { useNotifyStore } from '@/stores/NotifyStore'
export default {
  components: {
    TheHeader,
    TheFooter,
    TheMenu,
    DesktopFooter
  },

  setup () {
    const router = useRouter()
    const notifyStore = useNotifyStore()
    const yandexStore = useYandexMetrikaStore()
    const { metrikaIsActive } = storeToRefs(yandexStore)
    const toast = inject('toast')
    const menuStore = useMenuStore()
    const { menuHeight } = storeToRefs(menuStore)
    const appStore = useAppInfoStore()
    const statusBar = ref(null)
    const menuContainerHeight = ref(920)
    const { closeMenu, toggleMenu } = menuStore
    const { menuIsActive } = storeToRefs(menuStore)
    const userStore = useUserStore()
    const { user } = storeToRefs(userStore)
    const route = useRoute()

    const KEEP_ALIVE_EXCEPTIONS = ['ChatCallCenterView', 'Chats', 'ChatRoom']
    const specialRoute = [
      'login',
      'finger',
      'register-new',
      'register-options',
      'auth-phone',
      'auth-members',
      'auth-gosuslugi',
      'auth-pin-code'
    ]
    const footerPadding = ref('10px')
    const headerPadding = ref('')

    const menuContainerHeightPx = computed(() => {
      const height = menuHeight.value + 20

      return height < 920 ? '920px' : `${height}px`
    })

    const isSmallMain = computed(() => {
      return specialRoute.includes(route.name) || route.name === 'error'
    })

    const hiddenView = computed(() => {
      return !specialRoute.includes(route.name)
    })

    const isIOSPlatform = computed(() => {
      const platform = window?.device?.platform.toLowerCase()
      // return platform === 'ios' || platform === 'android'
      return platform === 'ios'
    })
    footerPadding.value = '0px'
    const setStatusBarHeight = async () => {
      const { statusBarHeight, error } = await getStatusBarHeight()

      if (error) {
        statusBar.value.style.height = '20px'
        return
      }

      if (statusBarHeight) {
        statusBar.value.style.height = '0px'
        headerPadding.value = '5px'
        footerPadding.value = '20px'
        if (statusBarHeight > 40) {
          footerPadding.value = '10px'
        }
      } else {
        footerPadding.value = '0px'
      }
    }

    const getAppInfo = async () => {
      try {
        const response = await axios.get('/v1/info')
        if (response.data) {
          appStore.setStore(response.data)
        }
      } catch (e) {
        appStore.setStore(null)
      }
    }

    const showHelloToaster = (body, title) => {
      toast.success(
        `
      ${body}
      ${title}
      `,
        {
          position: 'top-right',
          type: 'success'
        }
      )
    }

    const firebaseNotify = () => {
      const platform = window?.device?.platform.toLowerCase()
      if (platform !== 'ios' && platform !== 'android') {
        return
      }
      window.cordova.plugins.firebase.messaging.onMessage(function (payload) {
        showHelloToaster('', payload.title)
        notifyStore.setNotification(payload)
        postLog({
          payload_onMessage: payload
        })
      })
      window.cordova.plugins.firebase.messaging.onBackgroundMessage(function (
        payload
      ) {
        notifyStore.setFromBackground(true)
        notifyStore.setNotification(payload)
        postLog({
          payload_onBackgroundMessage: payload
        })

        router.push({
          name: 'notifications'
        })
      })
    }
    const yandexMetrikaListner = () => {
      const callBackMetrika = () => {
        postLog({
          from: 'app vue callBackMetrika'
        })
        metrikaIsActive.value = true
        document.removeEventListener(
          'yacounter65064187inited',
          callBackMetrika
        )
      }
      document.addEventListener('yacounter65064187inited', callBackMetrika)
    }

    const linkIOSOpenSystem = (event) => {
      const platform = window?.device?.platform.toLowerCase()
      if (platform !== 'ios' && platform !== 'android') {
        return
      }

      const parentLink = event.target.closest('a')
      if (!parentLink) return

      const attributeTarget = parentLink.getAttribute('target')

      if (attributeTarget !== '_blank') {
        return
      }

      event.preventDefault()
      const href = parentLink.getAttribute('href')
      window.cordova.InAppBrowser.open(href, '_system', 'location=yes')
    }

    onMounted(async () => {
      yandexMetrikaListner()
      document.addEventListener('click', linkIOSOpenSystem)

      await Promise.all([authDevelopment(), authBrowser(), getAppInfo()])

      if (isIOSPlatform.value) {
        await setStatusBarHeight()
      }
      firebaseNotify()
    })

    return {
      user,
      isSmallMain,
      hiddenView,
      menuIsActive,
      closeMenu,
      toggleMenu,
      footerPadding,
      headerPadding,
      isIOSPlatform,
      statusBar,
      menuContainerHeightPx,
      menuContainerHeight,
      KEEP_ALIVE_EXCEPTIONS
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style lang="scss">
@import "@/assets/scss/main.scss";
</style>

<style>
.multiselect__tags {
  flex: 1 1 auto;
}
</style>

<style lang="scss">
body,
html {
  height: 100vh;
  font-family: "Circe", Circe, sans-serif;
}

.container {
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto;
}

.c-toast-container {
  margin-top: 40px;
}

body {
  background: #082b53;
  @include desktop {
    background: #eef2f8;
  }
}

.status-bar {
  position: fixed;
  display: block;
  width: 100%;
  height: 30px;
  left: 0px;
  top: 0px;
  background: #082b53;
  z-index: 1000;
  @include desktop {
    display: none;
  }
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: "Circe", Circe, sans-serif;
  font-weight: 400;
  font-size: 14px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background: #082b53;
  @include desktop {
    background: #eef2f8;
  }
}

.main {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding-bottom: 50px;
  background: #082b53;
  overflow-y: hidden;
  z-index: 150;
  @include desktop {
    background: #eef2f8;
    padding-top: 28px;
    overflow-y: unset;
  }
}

.main--small {
  padding-bottom: 0;
}

.main:has(.filters__sidebar.active) {
  overflow: hidden;
  height: 100vh;
}

.main__content:has(.modal-payment.active) {
  height: 80vh;
  overflow: hidden;
}

.main__content:has(.modal-glucose.active) {
  height: 80vh;
  overflow: hidden;
}

.main.hidden {
  overflow: hidden;
  height: v-bind(menuContainerHeightPx);
  //height: 920px;
  //height: auto;
  @include desktop {
    height: auto;
  }

  &.main__app {
    height: 1040px;
  }
}

.main__container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  .modal-mask {
    display: none;
  }

  @include desktop {
    max-width: 1216px;
    padding: 0 15px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: minmax(320px, 380px) minmax(620px, 800px);
    column-gap: 20px;
    grid-auto-rows: max-content;
    grid-template-rows: 1fr;
    .modal-mask {
      display: flex;
    }
  }
}

.modal-backdrop.show {
  display: none;
}

.main:has(.modal.show) {
  z-index: 201;
}

.swiper-pagination-bullet {
  border: 1px solid #062e4f;
  background-color: #fff;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background-color: #062e4f;
}

.main__content {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  @include desktop {
    gap: 32px;
  }
}

.input-disabled {
  pointer-events: none;
  background-color: #ebebeb !important;
  background-size: 15px 15px;
  background-repeat: no-repeat;
  background-position: 98% center;
  background-image: url("~@/assets/uploads/icon/common/padlock.svg");

  &::placeholder {
    opacity: 0.5;
  }
}

.mx-datepicker.disabled input {
  background-color: #ebebeb;
  background-size: 15px 15px;
  background-repeat: no-repeat;
  background-position: 98% center;
  background-image: url("~@/assets/uploads/icon/common/padlock.svg");

  &::placeholder {
    opacity: 0.5;
  }
}

.mx-datepicker.disabled .mx-icon-calendar {
  display: none;
}

.mx-datepicker.disabled {
  pointer-events: none;
}

.multiselect.disabled {
  pointer-events: none;
  background-color: #ebebeb !important;
  background-size: 15px 15px;
  background-repeat: no-repeat;
  background-position: 98% center;
  background-image: url("~@/assets/uploads/icon/common/padlock.svg");

  &::placeholder {
    opacity: 0.5;
  }
}

.multiselect.disabled .multiselect__single {
  background-color: #ebebeb;
}

.multiselect.disabled .multiselect__tags {
  background-color: #ebebeb;
}

.multiselect.disabled:before {
  background-image: url("~@/assets/uploads/icon/common/padlock.svg") !important;
}
</style>

<!-- multiselect style-->
<style>
.multiselect.arrow {
  position: relative;
}

.multiselect.arrow:before {
  position: absolute;
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background-image: url("~@/assets/uploads/icon/common/arrow-down.svg");
  background-repeat: no-repeat;
  top: 50%;
  z-index: 5;
  right: 7px;
  background-position: center;
  transform: translateY(-50%);
}

.doc-call__address .multiselect {
  width: 100%;
  padding: 0;
  background-color: #ffffff;
  border: 1px solid #bac7de;
  border-radius: 8px;
  margin-bottom: 5px;
  display: flex;
  margin-right: 5px;
}

.doc-call__address .help-block .multiselect {
  border-color: #ff1f00;
}

.doc-call__address .help-block .multiselect__placeholder {
  color: #ff1f00;
}

.doc-call__address .multiselect__select {
  display: none;
}

.doc-call__address .multiselect__placeholder {
  padding: 0 !important;
  margin: 0 !important;
  color: #062e4f;
  font-size: 14px;
  font-weight: 350;
  line-height: 100%;
}

.doc-call__address .multiselect__input {
  padding: 0 !important;
  margin: 0 !important;
  color: #062e4f;
  font-size: 14px;
  font-weight: 350;
  line-height: 100%;
  border: none;
  height: 100%;
  width: 100%;
}

.doc-call__address .multiselect__option--highlight {
  background: #bac7de;
  outline: none;
  color: #002856;
}

.doc-call__address .multiselect__tags {
  padding-left: 13px;
  padding-right: 0;
  border: 0;
  min-height: auto !important;
  padding-top: 0;
  display: flex;
  align-self: center;
}

.doc-call__address .multiselect__content-wrapper {
  left: 0;
  transform: translateY(44px);
  border-left: 2px solid #bac7de;
  border-bottom: 2px solid #bac7de;
  border-right: 2px solid #bac7de;
}

.doc-call__address .multiselect__content {
  max-width: 100%;
}

.doc-call__address .multiselect__option--highlight.multiselect__option:after {
  display: none;
}

.doc-call__address .multiselect__option--selected.multiselect__option:after {
  display: none;
}

.doc-call__address .multiselect__single {
  margin-bottom: 0;
  padding: 0;
}

.program-selector .multiselect {
  width: 100%;
  padding: 0;
  background-color: #ffffff;
  border: 1px solid #bac7de;
  border-radius: 8px;
  margin-bottom: 5px;
  display: flex;
  margin-right: 5px;
}

.program-selector .help-block .multiselect {
  border-color: #ff1f00;
}

.program-selector .help-block .multiselect__placeholder {
  color: #ff1f00;
}

.program-selector .multiselect__select {
  display: none;
}

.program-selector .multiselect__placeholder {
  padding: 0 !important;
  margin: 0 !important;
  color: #062e4f;
  font-size: 14px;
  font-weight: 350;
  line-height: 100%;
}

.program-selector .multiselect__input {
  padding: 0 !important;
  margin: 0 !important;
  color: #062e4f;
  font-size: 14px;
  font-weight: 350;
  line-height: 100%;
  border: none;
  height: 100%;
  width: 100%;
}

.program-selector .multiselect__option--highlight {
  background: #bac7de;
  outline: none;
  color: #002856;
}

.program-selector .multiselect__tags {
  padding-left: 13px;
  padding-right: 0;
  border: 0;
  min-height: auto !important;
  padding-top: 0;
  display: flex;
  align-self: center;
}

.program-selector .multiselect__content-wrapper {
  left: 0;
  //transform: translateY(44px);
  border-left: 2px solid #bac7de;
  border-bottom: 2px solid #bac7de;
  border-right: 2px solid #bac7de;
}

.program-selector .multiselect__content {
  max-width: 100%;
}

.program-selector .multiselect__option--highlight.multiselect__option:after {
  display: none;
}

.program-selector .multiselect__option--selected.multiselect__option:after {
  display: none;
}

.program-selector .multiselect__single {
  margin-bottom: 0;
  padding: 0;
}

.dp__input_wrap {
  height: 61px;
}

.dp__pointer {
  height: 100%;
  font-size: 20px;
  color: #111111;
  border: 1px solid #f4f4f4;
}

.mx-datepicker.doc-call__datepicker {
  width: 100%;
}

.mx-datepicker .mx-input {
  padding: 15px 25px;
  width: 100%;
  line-height: 1;
  height: 100%;

  font-size: 14px;
  color: #002856;
  border-radius: 8px;
  border: 1px solid #bac7de;
  background: #fff;
}

.help-block .mx-input {
  border-color: #ff1f00;
}

.help-block .mx-input::placeholder {
  color: #ff1f00;
}

.doc-call__calendar-select .modal-schedule__content {
  padding: 90px 170px;
}

.doc-call__calendar-select .dp__calendar {
  padding: 20px;
}

.doc-call__calendar-select .dp__month_year_row {
  padding: 25px 20px;
}

.doc-call__calendar-select .dp__calendar_item {
  margin: 5px;
}

.doc-call__calendar-select .dp__today {
  border: 1px solid #949494;
}

.doc-call__calendar-select .dp__month_year_select {
  font-weight: 700;
  font-size: 20px;
  color: #323232;
}

.doc-call__calendar-select .dp__pointer {
  font-family: Circe-Regular, sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
}

.doc-call__calendar-select .dp__cell_inner {
  border-radius: 50%;
  height: 32px;
  width: 32px;
}

.doc-call__calendar-select .dp__active_date {
  background: #002856;
  color: rgba(255, 255, 255, 0.87);
}

.doc-call__calendar-select .dp__calendar_header_item {
  font-family: Circe-Regular, sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  width: 48px;
}

.doc-call__calendar-select .dp__month_year_row {
  padding: 25px 20px;
}

.doc-call__calendar-select .dp__month_year_wrap {
  justify-content: center;
  gap: 15px;
}

.doc-call__calendar-select .dp__outer_menu_wrap {
  box-shadow: 0px 10px 20px 0px rgba(51, 51, 51, 0.15);
}

.doc-call__calendar-select .dp__menu {
  border: unset;
}

.doc-call__calendar-select .dp__month_year_select {
  flex: 0;
}

.doc-call__calendar-select .dp__today {
  border: 1px solid #002856;
}

.doc-call__calendar-select .dp__active_date {
  background: #bac7de;
  color: #002856;
}

.doc-call__calendar-select .dp__calendar {
  padding: 0;
}

.dp__calendar_header_separator {
  width: 100%;
  height: 0;
  background: var(--dp-border-color);
}

.doc-call__calendar-select .dp__calendar_header_item {
  font-size: 16px;
}

.doc-call__calendar-select .dp__pointer {
  font-family: Circe-Regular, sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
}

@media (max-width: 900px) {
  .doc-call__calendar-select .dp__calendar_item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 575px) {
  .doc-call__calendar-select .dp__calendar_item {
    margin: 5px;
  }
}
</style>

<!--vue date picker style-->
<style>
.calendar-form .modal-schedule__content {
  padding: 90px 170px;
}

.calendar-form .dp__calendar {
  padding: 20px;
}

.calendar-form .dp__month_year_row {
  padding: 25px 20px;
}

.calendar-form .dp__calendar_item {
  margin: 5px;
}

.calendar-form .dp__today {
  border: 1px solid #949494;
}

.calendar-form .dp__month_year_select {
  font-weight: 700;
  font-size: 20px;
  color: #323232;
}

.calendar-form .dp__pointer {
  font-family: Circe-Regular, sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
}

.calendar-form .dp__cell_inner {
  border-radius: 50%;
  height: 48px;
  width: 48px;
}

.calendar-form .dp__active_date {
  background: #002856;
  color: rgba(255, 255, 255, 0.87);
}

.calendar-form .dp__calendar_header_item {
  font-family: Circe-Regular, sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  width: 48px;
}

.calendar-form .dp__month_year_row {
  padding: 25px 20px;
}

.calendar-form .dp__month_year_wrap {
  justify-content: center;
  gap: 15px;
}

.calendar-form .dp__outer_menu_wrap {
  /*padding: 2px;*/
  border-radius: 6px;
  box-shadow: 0px 10px 20px 0px rgba(51, 51, 51, 0.15);
}

.calendar-form .dp__menu {
  border: unset;
}

.calendar-form .dp__month_year_select {
  flex: 0;
}

.calendar-form .dp__today {
  border: 1px solid #002856;
}

.calendar-form .dp__active_date {
  background: #bac7de;
  color: #002856;
}

.calendar-form .dp__calendar {
  padding: 0;
}

.dp__calendar_header_separator {
  width: 100%;
  height: 0;
  background: var(--dp-border-color);
}

.calendar-form .dp__calendar_header_item {
  font-size: 16px;
}

.calendar-form .dp__cell_inner {
  border-radius: 50%;
  height: 48px;
  width: 48px;
}

.calendar-form .dp__pointer {
  font-family: Circe-Regular, sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
}

@media (max-width: 900px) {
  .calendar-form .dp__cell_inner {
    height: 32px;
    width: 32px;
  }

  .calendar-form .dp__calendar_item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 575px) {
  .calendar-form .dp__calendar_item {
    margin: 5px;
  }
}
</style>

<!-- VueMultiSelect style-->
<style lang="scss">
.multiselect * {
  scrollbar-color: $blue #fff;
  scrollbar-width: thin;
  scrollbar-gutter: stable;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $blue;
    border-radius: 10px;
  }
}

.doctor-search__picture {
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
  background-image: url("~@/assets/uploads/icon/common/arrow-right.svg");
  margin-left: 10px;
}

.doctor-search .multiselect {
  /*max-width: 700px;*/
  display: flex;
  width: 100%;
  padding: 0 0 0 50px;
  background-color: #ffffff;
  border: 1px solid #bac7de;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-position: 15px center;
  background-image: url("~@/assets/uploads/images/header/search.svg");
}

.doctor-search .multiselect--active input {
  margin-bottom: 0;
  font-size: 14px;
  padding-left: 0;
}

.doctor-search .multiselect__placeholder {
  padding: 0 !important;
  margin: 0 !important;
  color: #062e4f;
  font-size: 14px;
  font-weight: 350;
  line-height: 100%;
}

.doctor-search .multiselect__tags {
  min-height: auto !important;
  padding-top: 0;
  display: flex;
  align-self: center;
}

.doctor-search__desc {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.doctor-search .multiselect__select {
  display: none;
}

.doctor-search .multiselect__option {
  padding: 16px 15px 16px 5px;
}

.multiselect__option--highlight.multiselect__option:after {
  display: none;
}

.multiselect__option--selected.multiselect__option:after {
  display: none;
}

.doctor-search .multiselect__option--highlight {
  background: #bac7de;
  outline: none;
  color: #002856;
}

.doctor-search .multiselect__tags {
  padding-left: 0;
  padding-right: 0;
  border: 0;
}

.doctor-search .multiselect__content-wrapper {
  transform: translateY(55px);
  display: block !important;
  left: 0;
  border: unset;
  background: #eef2f8;

  @include phone-xs {
    background: white;
  }
}

.doctor-search .multiselect__element {
  border-bottom: 1px solid $blue-extra-light;
}

.doctor-search .doctor-search__picture {
  background-image: url("~@/assets/uploads/icon/calendar/arrow-right.svg");
  background-position: center;
}

.doctor-search .multiselect__single {
  padding: 0;
  margin: 0;
}
</style>
