<template>
  <SectionWrapper stretched v-if="requestItem" class="request__wrapper">
    <section class="request__section" v-if="!loading">
      <ArrowTitle
        :darkTitle="true"
        title="Техподдержка"
        :is-app="isApp"
        :container="false"
      ></ArrowTitle>
      <div class="request">
        <div class="request__title">
          #{{ requestItem.id }} {{ requestItem.title }}
        </div>
        <div class="request__status" :class="{ 'request__status--closed': requestItem.status === 'Закрыто' }">
          Статус: {{ requestItem.status }}
        </div>
        <div class="request__type">
          <span>Раздел:</span> {{ requestItem.type }}
        </div>
        <div class="request__date" v-if="dateRequest">{{ dateRequest }}</div>
        <div class="request__answer" v-if="requestItem.answers">
          <ul>
            <li>
              <span>{{ userFullName }}</span> {{ requestItem.description }}
              <div v-if="requestItem.file">
                Файл:
                <a
                  :href="requestItem.file.url"
                  :download="requestItem.file.title"
                  target="_blank"
                  >{{ requestItem.file.title }}</a
                >
              </div>
            </li>
            <li v-for="item in requestItem.answers" :key="item.id">
              <div class="answer__date">
                {{ getDate(item.created_at) }}
              </div>
              <span>{{ item.author_name }}:</span> {{ item.text }}
              <div v-if="item.file">
                Файл:
                <a
                  :href="item.file.url"
                  :download="item.file.title"
                  target="_blank"
                  >{{ item.file.title }}</a
                >
              </div>
            </li>
          </ul>
        </div>
        <div class="request__form">
          <TextAreaUi
            name-id=""
            placeholder="Введите сообщение"
            v-model="userMessage.message"
          />
          <div v-if="v$.message.$error" class="error help-block">
            Необходимо заполнить поле
          </div>
          <div class="form-group">
            <div class="support__file-label">
              <input
                type="file"
                id="file"
                @change="handleFilesUpload($event.target.files[0])"
              />
              <p>
                {{ fileName ? fileName : "Загрузить файл" }}
              </p>
            </div>
            <div v-if="v$.file.$error" class="error help-block">
              Необходимо загрузить файл
            </div>
          </div>
          <div class="form-group">
            <CustomButton
              @click.prevent="submitForm()"
              tag="button"
              type="submit"
            >
              Отправить
            </CustomButton>
            <button type="submit" class="request__close-btn" @click.prevent="closeTicket()" v-if="requestItem.status === 'Открыто'">Закрыть заявку</button>
          </div>
          <div class="" v-if="successMessage">{{ successMessage }}</div>
        </div>
      </div>
    </section>
    <TheLoader v-if="loading">Загрузка</TheLoader>
  </SectionWrapper>
</template>
<script>
import CustomButton from '@/components/ui/buttons/CustomButton.vue'
import TextAreaUi from '@/components/ui/form/TextAreaUi.vue'
import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'
import { computed, reactive, ref, watch } from 'vue'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import axios from 'axios'
import TheLoader from '@/components/ui/TheLoader.vue'
import SectionWrapper from '@/components/ui/SectionWrapper.vue'
import ArrowTitle from '@/components/ui/typography/ArrowTitle.vue'
import { useRoute } from 'vue-router'

export default {
  name: 'RequestView',
  components: {
    ArrowTitle,
    SectionWrapper,
    TheLoader,
    TextAreaUi,
    CustomButton
  },
  props: {
    user: {
      type: [Object, null],
      required: true,
      default: null
    },
    isApp: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  setup () {
    const requestItem = ref(null)
    const userStore = useUserStore()
    const { userFullName, userToken, getUserId } = storeToRefs(userStore)
    const loading = ref(false)
    const errorMessage = ref(false)
    const successMessage = ref(false)
    const route = useRoute()
    const userMessage = reactive({
      message: '',
      file: ''
    })
    const rules = {
      message: { required },
      file: {}
    }
    const v$ = useVuelidate(rules, userMessage)
    const submitForm = async () => {
      v$.value.$touch()
      if (v$.value.$invalid) {
        errorMessage.value = true
      } else {
        const supportInfo = {
          file: userMessage.file,
          text: userMessage.message,
          author_id: getUserId.value,
          ticket_id: requestItem.value.id
        }
        try {
          const formData = new FormData()
          if (supportInfo.file) {
            formData.append('file', supportInfo.file)
          }
          formData.append('text', supportInfo.text)
          formData.append('author_id', supportInfo.author_id)
          formData.append('ticket_id', supportInfo.ticket_id)
          const postSupport = await axios.post('/v1/support/create', formData, {
            headers: {
              Authorization: `Bearer ${userToken.value}`
            }
          })
          if (postSupport.data.status === 'ok') {
            v$.value.$reset()
            successMessage.value =
              'Ваш ответ к обращению принят. Мы ответим на него в ближайшее время'
            userMessage.message = ''
            userMessage.file = ''
            successMessage.value = ''
            requestItem.value = await getUserRequests(getUserId.value)
          }
        } catch (err) {
          console.log(err)
        }
      }
    }
    const closeTicket = async () => {
      try {
        loading.value = true
        const response = await axios.get(
          `v2/support/close/?id=${requestId.value}&author_id=${getUserId.value}`,
          {
            headers: {
              Authorization: `Bearer ${userToken.value}`
            }
          }
        )

        if (response.data.status === 'ok') {
          requestItem.value = await getUserRequests(getUserId.value)
          return response.data.data
        } else {
          return null
        }
      } catch (err) {
        console.log(err)
      } finally {
        loading.value = false
      }
    }
    const handleFilesUpload = (f) => {
      userMessage.file = f
    }
    const fileName = computed(() => {
      if (!userMessage.file) return null

      return userMessage.file.name
    })
    const dateRequest = computed(() => {
      if (!requestItem.value.date) return null

      const [year, month, day] = requestItem.value.date
        .split(' ')[0]
        .split('-')

      return `${day}.${month}.${year}`
    })
    const getDate = (date) => {
      const [year, month, day] = date.split(' ')[0].split('-')
      return `${day}.${month}.${year}`
    }
    const requestId = computed(() => {
      return route.params.id
    })
    const getUserRequests = async (id) => {
      try {
        loading.value = true
        const response = await axios.get(
          `v2/support/view/?id=${requestId.value}&author_id=${id}`,
          {
            headers: {
              Authorization: `Bearer ${userToken.value}`
            }
          }
        )

        if (response.data.status === 'ok') {
          return response.data.data
        } else {
          return null
        }
      } catch (err) {
        console.log(err)
      } finally {
        loading.value = false
      }
    }
    watch(
      () => requestId.value && getUserId.value,
      async () => {
        if (requestId.value && getUserId.value) {
          successMessage.value = ''
          requestItem.value = await getUserRequests(getUserId.value)
        }
      },
      {
        immediate: true
      }
    )
    return {
      loading,
      getUserRequests,
      dateRequest,
      handleFilesUpload,
      submitForm,
      userMessage,
      v$,
      successMessage,
      requestItem,
      userFullName,
      getDate,
      fileName,
      closeTicket
    }
  }
}
</script>
<style lang="scss" scoped>
.request {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  margin-top: 24px;
}

.request__wrapper {
  margin-bottom: 0;
}
.request__close-btn {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
  text-transform: uppercase;
  background: #002856;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  width: 100%;
  padding: 12px;
  border-radius: 30px;
  border: unset;
  transition: 0.2s;
  border: 1px solid #002856;
  &:hover {
   background: none;
    color: #002856;
  }
}
.request__form .error {
  margin-top: 5px;
}

.request__title {
  font-size: 18px;
  font-weight: 700;
  color: #082b53;
}

.request__type {
  color: #7f8da9;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;

  span {
    font-weight: 600;
  }
}

.request__date {
  font-size: 16px;
  color: #082b53;
}

.request__message {
  font-size: 16px;
  color: #082b53;
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.request__answer {
  padding-top: 16px;
  border-top: 1px solid #bac7de;
  width: 100%;
}

.request__answer {
  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 14px;

    li {
      font-size: 14px;
      color: #082b53;
      display: flex;
      flex-direction: column;
      gap: 7px;

      span {
        font-weight: 700;
        font-size: 14px;
      }
    }
  }
}

.request__form {
  width: 100%;
}
.request__status {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: #FFFFFF;
  padding: 4px 8px;
  background: #F1842C;
  border-radius: 20px;
  &--closed {
    background: #002856;
  }
}
.support__file-label {
  position: relative;
  margin: 14px 0;
  max-width: 167px;

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  p {
    cursor: pointer;
    color: #002856;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.26px;
    text-transform: uppercase;
    border-radius: 30px;
    border: 1px solid #002856;
    padding: 10px;
  }
}

.answer__date {
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  margin-bottom: 8px;
  padding: 2px 4px;
  border-radius: 20px;
  background: #bac7de;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  max-width: 75px;
}
</style>
