import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useMenuStore = defineStore('menu', () => {
  const menuIsActive = ref(false)
  const menuHeight = ref(920)

  const setMenuHeight = (height) => {
    menuHeight.value = height
  }

  const closeMenu = () => {
    menuIsActive.value = false
  }

  const toggleMenu = () => {
    menuIsActive.value = !menuIsActive.value
  }

  return {
    menuIsActive,
    closeMenu,
    toggleMenu,
    setMenuHeight,
    menuHeight
  }
})
