import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useUserGlucoseStore = defineStore('glucose', () => {
  const currentGlucose = ref(null)
  const glucoseSetup = ref(null)
  const glucoseMeasureUnit = ref({
    id: null,
    name: null
  })

  const measureTypeOnWelcome = ref(null)

  const setSetup = (setup, measureType) => {
    glucoseSetup.value = setup
    measureTypeOnWelcome.value = measureType
  }
  const setCurrentGlucose = (pressure) => {
    currentGlucose.value = pressure
  }
  const setMeasureUnit = (unit) => {
    glucoseMeasureUnit.value = { id: unit, name: unit === 1 ? 'мг/дл' : 'ммоль/л' }
  }

  return {
    currentGlucose,
    glucoseSetup,
    glucoseMeasureUnit,
    measureTypeOnWelcome,
    setSetup,
    setCurrentGlucose,
    setMeasureUnit
  }
})
