<template>
  <div
    :style="{
      paddingBottom: isSubscribtionOver ? 0 : chatPadding + 'px',
      scrollBehavior: smoothScroll ? 'smooth' : 'auto'
    }"
    class="chat-room__chat-wrapper container"
    ref="chatWrapper"
  >

    <ErrorText
      v-if="error"
      :showBtn="false"
      :error="error"
      :textAlign="'center'"
    />
    <TransitionGroup v-else name="messagesList">

      <template v-for="item in messages" :key="item.id">

        <SubscriptionOverMessage v-if="item.type === 'subscribtionMessage'" @openChatOptions="$emit('openChatOptions')"/>

        <DateMessage v-if="item.type === 'date'" :date="item.date" />

        <UserMessage
          v-if="item.type === 'message' && (isDoctorChat ? localChatUserId === item.user_id : item.name.toLowerCase() === userName.toLowerCase())"
          :text="item.text"
          :attach="item.attach"
          :createdAt="item.created_at"
        />

        <PartnerMessage
          v-if="item.type === 'message' && (isDoctorChat ? localChatUserId !== item.user_id : item.name.toLowerCase() !== userName.toLowerCase())"
          :text="item.text"
          :attach="item.attach"
          :createdAt="item.created_at"
          :partnerImg="partnerImg"
          :isCallCenter="isDoctorChat ? false : item.name === 'Клиника К+31'"
        />

      </template>

    </TransitionGroup>
    <WomanDoctor v-if="messages?.length === 0" :isSubscribtionOver="isSubscribtionOver"/>
    <TheLoader v-if="chatLoading" />
  </div>
</template>

<script>
import DateMessage from '@/components/pages/chat/messages/DateMessage.vue'
import UserMessage from '@/components/pages/chat/messages/UserMessage.vue'
import PartnerMessage from '@/components/pages/chat/messages/PartnerMessage.vue'
import SubscriptionOverMessage from '@/components/pages/chat/messages/SubscribtionOverMessage.vue'
import WomanDoctor from '@/components/pages/chat/WomanDoctor.vue'
import ErrorText from '@/components/ui/ErrorText.vue'
import TheLoader from '@/components/ui/TheLoader.vue'
import { onBeforeUnmount, ref, toRefs, watch } from 'vue'

export default {
  name: 'MessagesList',
  components: {
    TheLoader,
    ErrorText,
    DateMessage,
    UserMessage,
    PartnerMessage,
    WomanDoctor,
    SubscriptionOverMessage

  },
  emits: ['loadMoreMessages', 'openModal', 'openChatOptions'],
  props: {
    messages: [Object],
    isDoctorChat: Boolean,
    partnerImg: {
      type: String,
      required: false,
      default: null
    },
    userName: String,
    error: [Object, null],
    chatLoading: Boolean,
    chatPadding: Number,
    isSubscribtionOver: Boolean,
    allMessagesLoaded: Boolean,
    localChatUserId: {
      type: Number,
      required: false,
      default: null
    }
  },
  setup (props, { emit }) {
    const chatWrapper = ref(null)
    const smoothScroll = ref(false)
    const { chatPadding, chatLoading, messages } = toRefs(props)

    const rateSelected = (score) => {
      emit('openModal', score)
    }

    const scrollToBottom = async () => {
      // console.log('SCROLL TO BOTTOM....')
      chatWrapper.value.scrollTop = 0
    }

    const handleScroll = () => {
      // console.log('scroll listener working, SCROLL TOP', (+chatWrapper.value.scrollTop))
      // console.log('put against', chatWrapper.value.scrollHeight - chatWrapper.value.clientHeight)
      // console.log('window inner heigt', chatWrapper.value.clientHeight)
      // if (
      //   Math.abs(chatWrapper.value.scrollTop - 10) >=
      //   chatWrapper.value.scrollHeight - chatWrapper.value.clientHeight
      // ) {
      //   if (!props.allMessagesLoaded) emit('loadMoreMessages')
      // }
    }

    watch(chatPadding, async () => {
      await scrollToBottom()
      smoothScroll.value = true
    })

    watch(chatWrapper, async () => {
      if (chatWrapper.value) {
        chatWrapper.value.addEventListener('scroll', handleScroll)
      }
    })

    watch(messages, async (newMessages, oldMessages) => {
      if (messages.value !== null) {
        if (newMessages?.length - oldMessages?.length === 1) {
          await scrollToBottom()
        }
      }
    })

    watch(chatLoading, async () => {
      if (chatLoading.value === false) {
        // +114 - размер TheLoader
      }
    })

    onBeforeUnmount(() => {
      chatWrapper.value.removeEventListener('scroll', handleScroll)
    })

    return {
      chatWrapper,
      smoothScroll,
      rateSelected
    }
  }
}
</script>

<style scoped lang="scss">
.chat-room__chat-wrapper {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  gap: 16px;
  color: #002856;
  overflow-y: scroll;
  height: 100%;
  @include desktop {
    padding-bottom: 16px !important;
  }
}

.chat-room__chat-wrapper::-webkit-scrollbar {
  display: none;
}

.chat-room__chat-wrapper {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.messagesList-enter-active:not(.chat-room__date),
.messagesList-leave-active:not(.chat-room__date) {
  transition: all 0.5s ease;
}
.messagesList-enter-from:not(.chat-room__date),
.messagesList-leave-to:not(.chat-room__date) {
  opacity: 0;
  transform: translate(-200px, 50px) scale(0);
}
</style>
