<template>
  <div class="search">
    <input
      @focus="isFocus = true"
      @blur="isFocus = false"
      class="search__input"
      type="text"
      :placeholder="placeholder"
      @input="updateValue($event)"
    />
  </div>
</template>

<script>
import { ref, watch } from 'vue'

export default {
  name: 'TheSearch',
  emits: ['changeFocusState', 'input'],
  props: {
    placeholder: {
      type: String,
      required: false,
      default: 'Доктора, отделения, услуги'
    }
  },
  setup (props, { emit }) {
    const model = ref('')
    const isFocus = ref(false)

    watch(isFocus, () => {
      emit('changeFocusState', isFocus.value)
    })
    const updateValue = (value) => {
      emit('input', value)
    }
    return {
      updateValue,
      model,
      isFocus
    }
  }
}
</script>

<style scoped lang="scss">
.search {
  flex: 1 1 auto;

  &__input {
    padding: 16px 25px 16px 50px;
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #bac7de;
    border-radius: 8px;
    background-image: url("~@/assets/uploads/images/header/search.svg");
    background-repeat: no-repeat;
    background-position: 15px center;

    &::placeholder {
      font-weight: 350;
      font-size: 16px;
      line-height: 100%;
      color: #062e4f;
    }

    &:focus {
      outline: 2px solid rgb(225, 232, 244);
    }
  }
}
</style>
