<template>
  <router-link
    @click="goToNotify"
    to="/notifications"
    class="notification-bell"
  >
    <div class="notification-bell__counter" v-if="notifyCount">
      {{ notifyCount }}
    </div>
    <IconInCircle :bg-color="colorNotify" width="32" height="32">
      <img
        src="@/assets/uploads/images/header/bell-notify.svg"
        alt="картинка колокольчика"
      />
    </IconInCircle>
  </router-link>
</template>

<script>
import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'
import IconInCircle from '@/components/ui/IconInCircle.vue'
import { computed, onBeforeUnmount, ref, watch } from 'vue'
import axios from 'axios'
import { useNotifyStore } from '@/stores/NotifyStore'
import { useRoute } from 'vue-router'

export default {
  name: 'NotificationBell',
  components: { IconInCircle },
  setup () {
    const route = useRoute()
    const notifyStore = useNotifyStore()
    const { notificationList, fromNotifyView } = storeToRefs(notifyStore)
    const userStore = useUserStore()
    const { userToken } = storeToRefs(userStore)
    const notify = ref(0)
    let refreshNotify

    const notifyCount = computed(() => {
      const notifyNative = notificationList.value.filter(
        (notify) => !notify.wasChecked
      ).length
      return notify.value + notifyNative
    })

    const colorNotify = computed(() => {
      if (notifyCount.value) return '#F1842C'

      return '#E1E8F4'
    })

    const getNotify = async () => {
      try {
        const response = await axios('/v2/push/check', {
          headers: {
            Authorization: `Bearer ${userToken.value}`
          }
        })

        if (response.data.status === 'ok') {
          notify.value = parseInt(response.data.data)
        }
      } catch (err) {
        notify.value = null
      }
    }

    const goToNotify = () => {
      notify.value = null
      if (route.name === 'notifications' && notifyCount.value) {
        fromNotifyView.value = true
      }
    }

    watch(
      () => userToken.value,
      async () => {
        if (!userToken.value) return
        await getNotify()

        refreshNotify = setInterval(async () => {
          await getNotify()
        }, 1000 * 60 * 3)
      },
      { immediate: true }
    )

    onBeforeUnmount(() => {
      clearInterval(refreshNotify)
    })

    return {
      userToken,
      colorNotify,
      notify,
      notifyCount,
      goToNotify
    }
  }
}
</script>

<style scoped lang="scss">
.notification-bell {
  position: relative;
  width: 32px;
  height: 32px;
  display: block;

  &__counter {
    right: -3px;
    top: -4px;
    width: 14px;
    height: 14px;
    font-family: "Open Sans", serif;
    position: absolute;
    background: #d60000;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    line-height: 12px;
    color: #ffffff;
  }
}
</style>
