<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
      default: ''
    },
    link: {
      type: String,
      required: false,
      default: ''
    },
    text: {
      type: String,
      required: false,
      default: ''
    },
    image: {
      type: String,
      required: true,
      default: ''
    }
  },
  name: 'AppBanner'
}
</script>

<template>
  <div class="banner">
    <div class="banner__wrapper">
      <div class="banner__column">
        <h4 class="banner__title" v-html="title">
        </h4>
        <p v-if="text">
          {{ text }}
        </p>
        <a v-if="link" :href="link" target="_blank" class="banner__link">
          обновить приложение
        </a>
      </div>
      <div class="banner__picture">
        <img
          :src="require(`@/assets/uploads/sprite/${image}.svg`)"
          alt="изображение приложения"
        />
      </div>
      <a
        @click.prevent="$emit('closeBanner')"
        class="banner__close btn-close"
      ></a>
    </div>
  </div>
</template>

<style scoped lang="scss">
.banner {
  height: 110px;
  margin-bottom: 24px;
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #002856;
  }
}

.banner__wrapper {
  position: relative;
  display: flex;
  gap: 30px;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  background: $blue-light;
  padding: 12px 26px 16px 16px;
  min-height: 108px;
}

.banner__close {
  cursor: pointer;
  position: absolute;
  right: 9px;
  top: 9px;
}

.banner__column {
  position: relative;
  z-index: 3;
  max-width: 65%;
}

.banner__title {
  margin-bottom: 14px;
  color: $blue;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
}

.banner__link {
  cursor: pointer;
  padding: 3px 16px;
  border-radius: 12px;
  background: $blue;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;

  &:hover {
    background: $blue-6;
  }
}

.banner__picture {
  z-index: 2;
  display: flex;
  align-items: center;

  & img {
    max-height: 100%;
    max-width: 100px;
  }
}
</style>
