<template>
  <section class="my-schedule">
    <div class="my-schedule__wrapper">
      <TheLoader v-show="loading" />
      <div v-if="!loading" class="my-schedule__wrapper">
        <ArrowTitle
          :is-app="isApp"
          :title="doctorType ? doctorType : ''"
          :dark-title="true"
          marginBottom="32px"
          :container="false"
        ></ArrowTitle>
        <div class="my-schedule__content">
          <div class="my-schedule__middle">
            <div class="my-schedule__picture">
              <img :src="doctorImg" :alt="doctorName" />
            </div>
            <div class="my-schedule__doc-info">
              <h4 class="my-schedule__doc-title">{{ doctorName }}</h4>
              <p class="my-schedule__doc-desc">
                {{ doctorType }}
              </p>
            </div>
            <div class="my-schedule__date">
              <div class="my-schedule__time">
                <img
                  src="@/assets/uploads/images/home-page/my-appointment/clock.svg"
                  alt="картинка часов"
                />
                <p class="my-schedule__clock">{{ timeAppointment }}</p>
              </div>
              <p class="my-schedule__date-full">{{ dateAppointment }}</p>
            </div>
          </div>

          <div
            class="medcard__content-block"
            v-if="scheduleInfo && scheduleInfo.diagnoses_names"
          >
            <span class="medcard__subtitle">Диагнозы:</span>
            <span
              v-for="(value, key) in scheduleInfo.diagnoses_names"
              :key="key"
            >
              {{ value
              }}{{ key === scheduleInfo.diagnoses_names.length - 1 ? "" : "," }}
            </span>
          </div>

          <template
            v-if="
              scheduleInfo &&
              scheduleInfo.diags &&
              scheduleInfo.diags.content !== null
            "
          >
            <div class="medcard__subtitle">{{ scheduleInfo.diags.title }}</div>
            <div class="medcard__inner-block">
              <div
                class="medcard__main"
                v-html="scheduleInfo.diags.content.title"
              ></div>
              <div v-html="scheduleInfo.diags.content.value"></div>
            </div>
            <template v-if="scheduleInfo.diags.content.length > 0">
              <div
                class="medcard__inner-block"
                v-for="item in scheduleInfo.diags.content"
                :key="item.id"
              >
                <div class="medcard__main" v-html="item.title"></div>
                <div v-html="item.value"></div>
              </div>
            </template>
          </template>

          <template v-if="scheduleInfo && scheduleInfo.details">
            <div v-for="item in scheduleInfo.details" :key="item.id">
              <div v-if="item.content" class="medcard__content-block">
                <div class="medcard__subtitle" v-html="item.title"></div>
                <template v-if="item.content.length > 0">
                  <div
                    class="medcard__inner-block"
                    v-for="(scheduleInfoDes, index) in item.content"
                    :key="index"
                  >
                    <div
                      class="medcard__main"
                      v-html="scheduleInfoDes.title"
                    ></div>
                    <div v-html="scheduleInfoDes.value"></div>
                  </div>
                </template>
              </div>
            </div>
          </template>

          <template v-if="scheduleInfo?.file && scheduleInfo.file.length !== 0">
            <div class="medcard__content-block">
              <div class="medcard__subtitle">Документы</div>
              <div class="medcard__links">
                <a
                  :href="`${item.url}`"
                  class="medcard__link medcard__link--download"
                  target="_blank"
                  v-for="item in scheduleInfo.file"
                  :key="item.id"
                >
                  <p class="medcard__pdf">pdf</p>
                  <div class="medcard__container">
                    <div
                      class="medcard__link-title"
                      v-html="item.description"
                    ></div>
                    <div v-if="item.date" class="medcard__date">
                      {{ formatDate(item.date) }}
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </template>

          <div class="medcard__content-block" v-if="scheduleInfo?.next_visit">
            <div class="medcard__subtitle">Следующий визит</div>
            <div v-html="scheduleInfo.next_visit"></div>
          </div>

          <CustomButton
            v-if="scheduleInfo?.protocol_file"
            :href="scheduleInfo?.protocol_file?.url"
            class="medcard__btn"
            target="_blank"
          >
            <template #img>
              <img
                src="@/assets/uploads/images/med-card/download.svg"
                alt="знак скачивания"
              />
            </template>
            <template #default>
              <p>Сохранить на своем устройстве</p>
            </template>
          </CustomButton>

          <div>
            <div
              v-if="scheduleInfo?.dicom.length !== 0"
              class="my-schedule__btns"
            >
              <div
                v-if="isPhone"
              >
                <p>Просмотр исследований в мобильном приложении временно не работает.<br />Пожалуйста, воспользуйтесь версией на сайте.</p>
              </div>
              <CustomButton
                v-else
                type="button"
                tag="button"
                @click="openDicom(scheduleInfo?.dicom[0])"
              >
                <template #img>
                  <img
                    src="@/assets/uploads/images/med-card/download.svg"
                    alt="знак скачивания"
                  />
                </template>
                <template #default>
                  <p>Открыть</p>
                </template>
              </CustomButton>
            </div>
          </div>
        </div>
      </div>

      <ModalWithArrow
        @modalClose="modalIsOpen = false"
        title="Приложение просмотра снимков"
        :modal-is-active="modalIsOpen"
      >
        <a
          :href="'javascript:window.open(\'' + dicomUrl + '\',\'_blank\')'"
          class="btn btn-primary btn__dicom"
          >Запустить</a
        >
      </ModalWithArrow>
    </div>
  </section>
</template>

<script>
import CustomButton from '@/components/ui/buttons/CustomButton.vue'
import axios from 'axios'
import { useUserStore } from '@/stores/UserStore'
import TheLoader from '@/components/ui/TheLoader.vue'
import { useDateStore } from '@/stores/date'
import ArrowTitle from '@/components/ui/typography/ArrowTitle.vue'
import { useRoute, useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import { computed, inject, onActivated, onDeactivated, onMounted, onUnmounted, ref } from 'vue'
import { authApi } from '@/api/authApi'
import { postLog } from '@/api/postLog'
import ModalWithArrow from '@/components/modals/ModalWithArrow.vue'

export default {
  name: 'MyAppointment',
  components: {
    ModalWithArrow,
    ArrowTitle,
    TheLoader,
    CustomButton
  },
  props: {
    user: {
      type: [Object, null],
      required: true,
      default: null
    },
    isApp: {
      type: Boolean,
      required: true,
      default: false
    }
  },

  setup () {
    const router = useRouter()
    const userStore = useUserStore()
    const { userToken, getUserId } = storeToRefs(userStore)
    const route = useRoute()
    const scheduleInfo = ref(null)
    const loading = ref(false)
    const dicomUrl = ref('')
    const modalIsOpen = ref(false)
    const dateStore = useDateStore()
    const dictMonthRod = dateStore.dictMonthRod
    const toast = inject('toast')
    const dicomTimer = ref(null)
    const isPhone = computed(() => {
      const platform = window?.device?.platform.toLowerCase()
      return platform === 'ios' || platform === 'android' ? 1 : 0
    })

    const showErrorToaster = () => {
      toast.error('Функционал в разработке', {
        position: 'top-right'
      })
    }

    const getExtraInfoDoctor = async () => {
      if (!route.params.exId) return
      try {
        loading.value = true
        const [err, response] = await authApi(
          `/v1/service/doctor/?ex_id=${route.params.exId}`
        )
        if (err) return
        if (response.data[0]) {
          scheduleInfo.value.name = response.data[0].name
          scheduleInfo.value.photo = response.data[0].photo
        }
      } catch (error) {
        postLog(error)
      } finally {
        loading.value = false
      }
    }

    const geScheduleInfo = async () => {
      try {
        loading.value = true
        const response = await axios.get(
          `/v2/element/patient-history/view/?user_id=${getUserId.value}&id=${route.params.id}`,
          {
            headers: {
              Authorization: `Bearer ${userToken.value}`
            }
          }
        )

        if (response.data.status === 'ok') {
          scheduleInfo.value = response.data.data
        } else {
          router.push({ name: 'med-card' })
          scheduleInfo.value = null
        }
        getExtraInfoDoctor()
      } catch (e) {
        scheduleInfo.value = null
        router.push({ name: 'med-card' })
      } finally {
        loading.value = false
      }
    }

    const doctorName = computed(() => {
      if (scheduleInfo.value?.name) return scheduleInfo.value.name
      if (!scheduleInfo.value?.doctor_name) return null
      return scheduleInfo.value.doctor_name
    })
    const doctorType = computed(() => {
      if (!scheduleInfo.value?.type) return null
      return scheduleInfo.value.type
    })

    const timeAppointment = computed(() => {
      if (!scheduleInfo.value?.date) return null

      return scheduleInfo.value.date.split(' ')[1].slice(0, 5)
    })

    const dateAppointment = computed(() => {
      if (!scheduleInfo.value?.date) return null

      const [year, month, day] = scheduleInfo.value.date
        .split(' ')[0]
        .split('-')
      const formatMonth = month.startsWith(0) ? month[1] : month

      return `${day} ${dictMonthRod[parseInt(formatMonth) - 1]} ${year} г.`
    })

    const formatDate = (date) => {
      return date.split('T')[0].split('-').reverse().join('.')
    }

    const doctorImg = computed(() => {
      if (scheduleInfo.value?.photo) return scheduleInfo.value.photo
      if (route.params.resourceId !== 'null') {
        return (
          'https://www.k31.ru/specialisty/photo/' +
          route.params.resourceId +
          '_295x295.jpg'
        )
      }
      return 'https://www.k31.ru/specialisty/photo/0_295x295.jpg'
    })
    const openDicom = async (uid) => {
      try {
        const response = await axios.get('v1/dicom/key/?uid=' + uid, {
          headers: {
            Authorization: `Bearer ${userToken.value}`
          }
        })

        if (response.data.status === 'ok') {
          const pingParams = new URLSearchParams({
            sessionId: response.data.id,
            sessionKey: response.data.key
          }).toString()

          dicomTimer.value = setInterval(async () => {
            try {
              await axios.get(`https://dicom.lk.k31.ru/v2/session/keepAlive?dummy=0.8471040208596969&sessionId=${pingParams}`)
            } catch (e) {}
          }, 2000)

          const params = new URLSearchParams({
            studyUid: uid,
            sessionId: response.data.id,
            sessionKey: response.data.key,
            login: 'user'
          }).toString()

          dicomUrl.value = `https://dicom.lk.k31.ru/open-study.html?${params}`
          modalIsOpen.value = true
          // window.cordova.InAppBrowser.open(dicomUrl.value, '_blank', 'location=no,clearsessioncache=yes,clearcache=yes')
          window.open(dicomUrl.value, '_blank', 'location=no')
        } else {
          postLog(response.data)
        }
      } catch (err) {
        postLog(err)
      }
    }

    onActivated(async () => {
      modalIsOpen.value = false
      if (getUserId.value) {
        await geScheduleInfo()
      }
    })

    onMounted(async () => {
      if (getUserId.value) {
        await geScheduleInfo()
      }
    })

    onDeactivated(() => {
      clearInterval(dicomTimer.value)
    })

    onUnmounted(() => {
      clearInterval(dicomTimer.value)
    })

    return {
      doctorName,
      loading,
      doctorType,
      timeAppointment,
      dateAppointment,
      showErrorToaster,
      scheduleInfo,
      modalIsOpen,
      openDicom,
      dicomUrl,
      doctorImg,
      formatDate,
      isPhone
    }
  }
}
</script>

<style scoped lang="scss">
.medcard__btn {
  margin-bottom: 30px;
}

.btn__dicom {
  color: white;
}

.my-schedule {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  &__wrapper {
    flex: 1 1 auto;
    margin-bottom: 0;
  }

  &__top {
    margin-bottom: 25px;
    display: grid;
    grid-template-columns: auto 1fr;
  }

  &__back {
    align-self: start;
    justify-self: start;
    transition: transform 0.2s ease-in-out;

    &:hover {
      transform: translateX(-2px);
    }
  }

  &__title {
    justify-self: center;
  }

  &__middle {
    margin-bottom: 12px;
    display: flex;
    gap: 8px;
    padding-bottom: 12px;
    border-bottom: 1px solid $blue-light;
  }

  &__picture {
    max-width: 45px;

    & img {
      border-radius: 50%;
    }
  }

  &__doc-info {
    max-width: 170px;
    margin-right: auto;
  }

  &__doc-title {
    color: $blue;
    font-size: 14px;
    font-style: normal;
    line-height: 16px;
    margin-bottom: 5px;
  }

  &__doc-desc {
    color: $grey;
    font-size: 12px;
  }

  &__date {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__time {
    align-self: end;
    display: flex;
    gap: 5px;
    align-items: center;

    color: $black;
    font-weight: 700;
  }

  &__clock {
  }

  &__date-full {
    color: $blue;
    font-size: 12px;
  }

  &__subtitle {
    & h5 {
      margin-bottom: 4px;
      line-height: 14px;
    }
  }

  &__bottom {
  }

  &__diagnosis {
    margin-bottom: 4px;
    color: $blue;
    font-size: 14px;
    line-height: 16px;
  }

  &__text {
    margin-bottom: 12px;
    padding-left: 16px;
    color: $black;
    font-size: 12px;
    line-height: 16px;
  }

  &__list {
    margin-bottom: 12px;
    padding-left: 16px;
  }

  &__item {
    color: $black;
    font-size: 12px;
    line-height: 16px;
    list-style-type: decimal;
  }

  &__btns {
  }
}

// ======
.medcard__content-block {
  margin-bottom: 25px;

  span:not(:last-child) {
    margin-right: 3px;
  }
}

.my-schedule__content {
  padding: 20px;
  border-radius: 6px;
  background: #fff;
}

.medcard__subtitle {
  margin-bottom: 12px;
  color: $blue;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  margin-right: 5px;

  @include tablets-md {
    font-size: 16px;
  }

  @include phone-xs {
    font-size: 12px;
  }
}

.medcard__main {
  margin-bottom: 10px;
  color: $blue;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */

  @include tablets-md {
    font-size: 14px;
  }

  @include phone-xs {
    font-size: 14px;
  }
}

.medcard__inner-block {
  margin-bottom: 15px;
  margin-left: 10px;
  line-height: 1.7;
}

.medcard__title {
  margin-right: 15px;
}

.medcard__link {
  display: flex;
  gap: 8px;
  align-items: start;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.medcard__pdf {
  text-align: center;
  width: 55px;
  border-radius: 8px;
  background: #e03f3f;
  padding: 6px 10px;
  color: #fff;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px; /* 127.273% */
  height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}

.medcard__container {
}

.medcard__link:hover {
  color: #666;
}

.medcard__links {
  display: flex;
  flex-wrap: wrap;
}

.medcard__link-title {
  margin-bottom: 2px;
  color: #002856;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
}

.medcard__date {
  color: #c4c4c4;
  font-family: Circe;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 116.667% */
}
</style>
