<template>
  <div class="actual-elem">
    <div class="actual-elem__wrapper"
    >
      <div class="actual-elem__picture" :style="{ backgroundImage: 'url(' + img + ')' }">
        <div class="actual-elem__text">
          {{ text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ActualSwiperElement',
  props: ['img', 'text', 'href']
}
</script>

<style scoped lang="scss">
.actual-elem {
  display: block;
  max-width: 120px;

  &__wrapper {
    margin-bottom: 6px;
    border: 1px solid #F1842C;
    border-radius: 8px;
    padding: 2px;
  }
}
</style>

<style lang="scss">
.actual-elem {

  &__wrapper {
    padding: 2px;
  }

  &__picture {
    min-width: 100%;
    min-height: 114px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    border-radius: 6px;
    display: flex;
    align-items: flex-end;
    padding: 5px;
  }
}

.actual-elem__text {
  color: #002856;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 109.091% */
  @include desktop {
    color: #002856;
    font-weight: 400;
  }
}

</style>
