<template>

  <TheLoader v-if="loading" />

  <div v-else>

  <PhoneForm v-if="!userPhone && hasPinCode === false" @set-code="setPhone" />

  <SmsForm v-if="userPhone && hasPinCode === false" :userPhone="userPhone" :hasPinCode="hasPinCode" />

  <PinForm v-if="userPhone && hasPinCode" :userPhone="userPhone" :hasPinCode="hasPinCode" @forgot-pin="hasPinCode = false"/>

  <div v-if="isBrowser && !userPhone" class="download">
    <a target="_blank" :href="isoAppLink" class="login__app-link">
      <img
        src="@/assets/uploads/images/register/app-store.jpg"
        alt="Скачать из AppStore"
      />
    </a>
    <a target="_blank" :href="androidAppLink" class="login__app-link">
      <img
        src="@/assets/uploads/images/register/google-play.jpg"
        alt="Скачать из Google Play"
      />
    </a>
  </div>

  </div>
</template>

<script>
import PhoneForm from '@/components/pages/auth/PhoneForm.vue'
import SmsForm from '@/components/pages/auth/SmsForm.vue'
import PinForm from '@/components/pages/auth/PinForm.vue'
import TheLoader from '@/components/ui/TheLoader.vue'
import { computed, onBeforeMount, ref } from 'vue'
import { useAppInfoStore } from '@/stores/AppInfoStore'
import { storeToRefs } from 'pinia'
import axios from 'axios'
import { useUserStore } from '@/stores/UserStore'

export default {
  name: 'LoginPhoneView',
  components: { PhoneForm, SmsForm, PinForm, TheLoader },
  setup () {
    const loading = ref(true)

    const hasPinCode = ref(null)
    const userPhone = ref(null)

    const userStore = useUserStore()
    const appStore = useAppInfoStore()

    const { appInfo } = storeToRefs(appStore)
    const { setHasPinCode } = userStore

    const isBrowser = computed(() => {
      const platform = window?.device?.platform.toLowerCase()
      return platform !== 'ios' && platform !== 'android'
    })

    const isoAppLink = computed(() => {
      return appInfo.value
        ? appInfo.value.ios_url_web
        : 'https://apps.apple.com/app/id1508496917'
    })

    const androidAppLink = computed(() => {
      return appInfo.value
        ? appInfo.value.android_url_web
        : 'https://play.google.com/store/apps/details?id=com.k31.lk'
    })

    const setPhone = (payload) => {
      userPhone.value = payload.phone
      hasPinCode.value = payload.hasPinCode
    }

    const checkSavedPhone = async () => {
      const savedPhone = window.localStorage.getItem('user_phone')

      if (savedPhone) {
        userPhone.value = savedPhone
        try {
          const response = await axios.get('/v6/element/user/is-pin', {
            params: {
              phone: savedPhone,
              device_uuid: window?.device?.uuid
            }
          })
          if (response.data.status === 'ok') {
            if (response.data.data.pin === 1) {
              hasPinCode.value = true
              setHasPinCode(true)
            } else {
              hasPinCode.value = false
              await getUserSmsCode()
            }
          }
        } catch (error) {
        } finally {
          loading.value = false
        }
      } else {
        loading.value = false
        hasPinCode.value = false
      }
    }

    const getUserSmsCode = async () => {
      try {
        await axios.post('/v2/element/user/request-otp', {
          phone: userPhone.value
        })
      } catch (error) {
      }
    }

    onBeforeMount(async () => {
      await checkSavedPhone()
    })

    return {
      userPhone,
      isBrowser,
      isoAppLink,
      androidAppLink,
      setPhone,
      hasPinCode,
      loading
    }
  }
}
</script>

<style scoped>
.download {
  display: flex;
  margin-top: 15px;
  gap: 15px;
  justify-content: center;
}
</style>
