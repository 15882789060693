<template>
  <div class="error">
    <div class="container">
      <p class="error__text">
        {{ errorMsg }}
      </p>
      <CustomButton v-if="showBtn" tag="router-link" to="/">
        На главную
      </CustomButton>
    </div>
  </div>
</template>

<script>
import CustomButton from '@/components/ui/buttons/CustomButton.vue'
import { computed, toRefs } from 'vue'

export default {
  name: 'ErrorText',
  components: { CustomButton },
  props: {
    error: {
      type: String,
      required: true,
      default: 'Произошла ошибка, попробуйте позже'
    },
    showBtn: {
      type: Boolean,
      required: false,
      default: true
    },
    textAlign: {
      type: String,
      required: false,
      default: 'left'
    }
  },

  setup (props) {
    const { error } = toRefs(props)

    const errorMsg = computed(() => {
      if (typeof error.value === 'string') return error.value
      if (typeof error.value === 'object') {
        const errMsg = error.value?.response?.data?.message
        return `Произошла ошибка. ${
          errMsg ?? error.value?.message ?? JSON.stringify(error.value)
        }`
      }

      return error.value
    })

    return {
      errorMsg
    }
  }
}
</script>

<style scoped lang="scss">
.error {
  padding-top: 2px;
  margin-bottom: 20px;
}

.error__text {
  color: $red;
  margin-bottom: 20px;
  text-align: v-bind(textAlign);
}
</style>
