<template>
  <ArrowTitle title="Финансы" :is-app="isApp"></ArrowTitle>
  <SectionWrapper stretched>
    <TheLoader v-if="loading"> Загрузка</TheLoader>
    <div v-if="!loading" class="finance-menu">
      <span class="finance-menu-info"
        >В этом разделе вы можете внести аванс за медицинские услуги или
        оплатить выставленные счета</span
      >
      <div class="finance-menu__options">
        <button class="finance-menu__options-btn" @click="isDepositOpen = true">
          <div class="finance-menu__options-btn-circle">
            <img
              src="@/assets/uploads/icon/common/icon-money-bag.svg"
              alt="депозит"
            />
          </div>
          <span>Внесение депозита</span>
          <img
            class="finance-menu__options-btn-arrow"
            src="@/assets/uploads/icon/common/right-small-arrow.svg"
            alt="стрелка"
          />
        </button>
        <button
          class="finance-menu__options-btn"
          :class="{ 'gray-btn': !isThereDebt }"
          @click="isBillsOpen = true"
          :disabled="!isThereDebt"
        >
          <div class="finance-menu__options-btn__img-wrapper">
            <div class="finance-menu__options-btn-circle">
              <img
                src="@/assets/uploads/icon/common/icon-bills.svg"
                alt="счета"
              />
              <img
                v-if="isThereDebt"
                class="warning-icon"
                src="@/assets/uploads/icon/common/payment-warning.svg"
                alt="счета"
              />
            </div>
          </div>
          <div class="finance-menu__options-btn__bills-info">
            <span>Оплата счетов</span>
            <span class="finance-menu__no-debt-text" v-if="!isThereDebt"
              >У вас нет задолженности</span
            >
            <span class="finance-menu__debt-text" v-if="isThereDebt"
              >Имеется задолженность</span
            >
          </div>
          <img
            class="finance-menu__options-btn-arrow"
            src="@/assets/uploads/icon/common/right-small-arrow.svg"
            alt="стрелка"
          />
        </button>
      </div>
    </div>
    <ModalDeposit
      v-if="finance"
      :isDepositOpen="isDepositOpen"
      :finance="finance"
      @close-deposit="isDepositOpen = false"
    />
    <ModalBills
      v-if="finance"
      :isBillsOpen="isBillsOpen"
      :isThereDebt="isThereDebt"
      :finance="finance"
      @close-deposit="isBillsOpen = false"
    />
  </SectionWrapper>
</template>

<script>
import ArrowTitle from '@/components/ui/typography/ArrowTitle.vue'
import SectionWrapper from '@/components/ui/SectionWrapper.vue'
import ModalDeposit from '@/components/pages/finance/ModalDeposit.vue'
import ModalBills from '@/components/pages/finance/ModalBills.vue'
import { ref, watch } from 'vue'
import axios from 'axios'
import { storeToRefs } from 'pinia'
import { useUserStore } from '@/stores/UserStore'
import TheLoader from '@/components/ui/TheLoader.vue'

export default {
  name: 'FinanceView',
  components: {
    TheLoader,
    ArrowTitle,
    SectionWrapper,
    ModalDeposit,
    ModalBills
  },
  props: {
    user: {
      type: [Object, null],
      required: true,
      default: null
    },
    isApp: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  setup () {
    const currentFilial = ref(1)
    const legalEntity = ref(1)
    const paymentResult = ref(null)
    const userStore = useUserStore()
    const { userToken, getUserId } = storeToRefs(userStore)
    const finance = ref(null)
    const paymentLink = ref('')
    const sumInput = ref(null)
    const bottom = ref(null)
    const loading = ref(false)
    const errorUrl = ref('')
    const errorMessage = ref('')
    const isThereDebt = ref(false)
    const isDepositOpen = ref(false)
    const isBillsOpen = ref(false)

    const fetchDeposit = async (id) => {
      try {
        loading.value = true
        const params = new URLSearchParams({
          user_id: id
        }).toString()
        const response = await axios.get(`/v2/element/deposit/?${params}`, {
          headers: {
            Authorization: `Bearer ${userToken.value}`
          }
        })

        if (response.data.status === 'ok') {
          finance.value = response.data.data
          response.data.data.forEach((clinic) => {
            if (clinic.invoice.length > 0) isThereDebt.value = true
          })
        } else {
          return null
        }
      } catch (err) {
        throw new Error()
      } finally {
        loading.value = false
      }
    }

    watch(
      () => getUserId.value,
      async () => {
        if (getUserId.value) {
          await fetchDeposit(getUserId.value)
        }
      },
      {
        immediate: true
      }
    )

    return {
      finance,
      loading,
      errorMessage,
      paymentLink,
      sumInput,
      bottom,
      errorUrl,
      paymentResult,
      currentFilial,
      legalEntity,
      getUserId,
      isThereDebt,
      isDepositOpen,
      isBillsOpen,
      fetchDeposit
    }
  },
  beforeRouteEnter (to, from, next) {
    next((vm) => {
      if (from.name !== 'finance-result') {
        vm.paymentResult = null
        return
      }
      const result = from.params.result

      if (result === 'success') {
        vm.paymentResult = 'success'
      }

      if (result === 'failed') {
        vm.paymentResult = 'failed'
      }
      vm.fetchDeposit(vm.getUserId)
    })
  }
}
</script>

<style scoped lang="scss">
.finance-menu {
  @media (min-width: 991px) {
   padding-top: 2px;
  }
}
.finance-menu-info {

  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #002856;
}

.finance-menu__options {
  margin-top: 15px;
  box-shadow: 0 5px 15px 0 #0000001a;
  border-radius: 8px;
  padding: 16px 13px;
}
.finance-menu__options-btn {
  cursor: pointer !important;
  all: unset;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 12px;
  span {
    font-size: 12px;
    font-weight: 700;
    line-height: 13px;
    text-align: left;
  }
}

.finance-menu__options-btn:first-child {
  border-bottom: 1px solid #e1e8f4;
  padding-bottom: 24px;
}
.finance-menu__options-btn:last-child {
  padding-top: 24px;
}
.finance-menu__options-btn-arrow {
  margin-left: auto;
}

.finance-menu__options-btn__bills-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.finance-menu__debt-text {
  opacity: 1;
  font-size: 12px;
  font-weight: 700;
  line-height: 13px;
  text-align: left;
  color: #f1842c;
}
.finance-menu__no-debt-text {
  opacity: 1;
  font-size: 12px;
  font-weight: 700;
  line-height: 13px;
  text-align: left;
  color: #009c10;
}

.finance-menu__options-btn__img-wrapper {
  position: relative;
}
.warning-icon {
  position: absolute;
  right: 0;
  transform: translateY(-100%);
}

.gray-btn {
  opacity: 0.4;
}

.finance-menu__options-btn-circle {
  width: 64px;
  height: 64px;
  background-color: #E1E8F4;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 991px) {
    background-color: #FFFFFF;
  }
}
</style>
