<template>
  <div class="pregnancy-info-about-baby">
    <div class="pregnancy-info-about-baby__text" v-html="text"></div>
  </div>
</template>

<script>
export default {
  name: 'PregnancyInfoAboutBaby',
  props: {
    text: String
  },
  setup (props, { emit }) {
    return {}
  }
}
</script>

<style>
.pregnancy-info-about-baby__text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #111111;
  p {
    margin-bottom: 24px;
  }
  ol {
    margin-bottom: 24px;
  }
  li {
    margin-left: 22px;
    list-style-image: url('@/assets/uploads/icon/calendar/blue-check-icon.svg');
    margin-bottom: 12px;
  }
}

.pregnancy-info-about-baby__size-numbers-only {
  width: 100%;
  background-color: #e1e8f4;
  padding: 20px 24px 20px 24px;
  border-radius: 8px;
  display: flex;
}

.pregnancy-info-about-baby__length {
  width: 50%;
}

.pregnancy-info-about-baby__weight {
  width: 50%;
}

.pregnancy-info-about-baby__size {
  margin-bottom: 24px;
  display: flex;
  gap: 8px;
  span {
    display: block;
  }
}

.pregnancy-info-about-baby2__size {
  margin-bottom: 24px;
  gap: 8px;
  span {
    display: block;
  }
}

.pregnancy-info-about-baby__size-numbers {
  flex: 1;
  background-color: #e1e8f4;
  padding: 20px 24px 20px 24px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  div:first-child {
    margin-bottom: 20px;
  }
}

.pregnancy-info-about-baby__label {
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 0.02em;
  text-align: left;
  margin-bottom: 4px;
  color: #002856;
}

.pregnancy-info-about-baby__data {
  font-size: 30px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #002856;
}

.pregnancy-info-about-baby__size-visual {
  flex: 1;
  background-color: #fbe1cd;
  padding: 20px 24px 20px 24px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.pregnancy-info-about-baby__title {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #002856;
}

.pregnancy-info-about-baby__img {
  width: 105px;
  align-self: center;
}
</style>
