<script>
import cloneDeep from 'lodash.clonedeep'
import GlucoseListItem from '@/components/pages/calendar/glucose/GlucoseListItem.vue'
import { computed, inject, ref, toRefs, watchEffect } from 'vue'
import { useDateStore } from '@/stores/date'
import TheBanner from '@/components/ui/announce/TheBanner.vue'

export default {
  name: 'GlucoseList',
  components: { TheBanner, GlucoseListItem },
  emits: ['updateGlucose', 'openAddForm'],
  props: {
    glucoseList: {
      type: Array,
      required: true
    }
  },

  setup (props) {
    const bannerIsActive = ref(true)
    const { glucoseList } = toRefs(props)
    const storeDate = useDateStore()
    const dictMonth = storeDate.dictMonthRod
    const dayjs = inject('dayjs')

    const nearDayOfRescipt = (date) => {
      const nearDateOfDoctor = dayjs(date)
      const dayOfMonth = nearDateOfDoctor.date()
      const month = dictMonth[nearDateOfDoctor.month()].toLowerCase()
      return `${dayOfMonth} ${month}`
    }

    const checkIfPastCurrentTime = (glucoseTime) => {
      return glucoseTime.find(g => dayjs() > dayjs(g.time_at))
    }

    const glucoseListFiltred = computed(() => {
      const glucoseListClone = cloneDeep(glucoseList.value)
      return glucoseListClone.sort((pressureA, pressureB) =>
        new Date(pressureA.date) > new Date(pressureB.date) ? -1 : 1
      )
    })

    watchEffect(() => {
      // console.log('GLUCOSE LIST', glucoseListFiltred.value)
    })

    return {
      nearDayOfRescipt,
      glucoseListFiltred,
      bannerIsActive,
      dayjs,
      checkIfPastCurrentTime
    }
  }
}
</script>

<template>
  <div class="pressure-list">
    <div v-for="(glucose, index) in glucoseListFiltred" :key="glucose.id">
      <div v-if="checkIfPastCurrentTime(glucose.time)" class="pressure-list__wrapper">
        <div class="pressure-list__date">
          {{ nearDayOfRescipt(glucose.date) }}
        </div>
        <div class="pressure-list__list">
          <GlucoseListItem
            v-for="glucoseItem in glucose.time"
            :key="glucoseItem.id"
            :glucose="glucoseItem"
            @updateGlucose="$emit('updateGlucose')"
            @openAddForm="$emit('openAddForm')"
          />
        </div>
      </div>

      <TheBanner
        v-if="index === 0 && bannerIsActive"
        class="pressure-list__banner"
        title="Календарь приема лекарств"
        text="Незаменимый помощник в контроле за здоровьем"
        link="/calendar-medications"
        @closeBanner="bannerIsActive = false"
      >
        <template #img>
          <img
            src="@/assets/uploads/images/calendar/pills.png"
            alt="календарь лекарств"
          />
        </template>
      </TheBanner>
    </div>
  </div>
</template>

<style scoped lang="scss">
.pressure-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.pressure-list__wrapper {
  background: white;
  border-radius: 6px;
  padding: 12px 16px;
  box-shadow: 0px 5px 15px 0px #00000026;
}

.pressure-list__date {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  padding-bottom: 16px;
}

.pressure-list__list {
}

.pressure-list__banner {
  padding-top: 20px;
}
</style>
