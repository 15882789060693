import { computed } from 'vue'
import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'
import { useAppInfoStore } from '@/stores/AppInfoStore'

export const useTemplateVisibleAll = (template) => {
  const appStore = useAppInfoStore()
  const userStore = useUserStore()
  const { appInfo } = storeToRefs(appStore)
  const { isAdmin } = storeToRefs(userStore)

  const templateVisible = computed(() => {
    if (!appInfo.value) return false

    return appInfo.value[template] !== 1 ? isAdmin.value : true
  })

  return {
    templateVisible
  }
}
