import { defineStore } from 'pinia'
import { getCurrentInstance, ref, watch } from 'vue'

export const useYandexMetrikaStore = defineStore('yandexMetrikaStore', () => {
  const metrikaIsActive = ref(false)
  const metrikaStack = []
  const app = getCurrentInstance()
  const metrika = ref(null)
  metrika.value = app.appContext.config.globalProperties?.$metrika

  const sendGoal = (title, options = {}) => {
    if (!metrikaIsActive.value) {
      metrikaStack.push({
        title,
        options
      })

      return
    }
    metrika.value.reachGoal(title, options)
  }

  watch(
    metrikaIsActive,
    () => {
      if (metrikaIsActive.value) {
        metrika.value = app.appContext.config.globalProperties?.$metrika
        metrikaStack.forEach((item) => {
          sendGoal(item.title, item?.options)
        })
      }
    },
    {
      immediate: true
    }
  )

  return {
    metrikaIsActive,
    sendGoal
  }
})
