<template>
  <ModalWithArrow
    :title="title"
    :modal-is-active="modalIsActive"
    @modalClose="modalClose"
  >
    <template v-if="!successSend && !errorSend">
      <TransitionGroup class="file-list" name="file-list" tag="div">
        <div class="file-list__item" v-for="file in fileData" :key="file.id">
          <div class="file-list__desc">
            <div class="file-list__name">
              {{ file.orig_name }}
            </div>
            <a
              class="file-list__delete"
              @click.prevent.stop="deleteFile(file.id)"
            >
              <img
                src="@/assets/uploads/icon/common/delete.svg"
                alt="удаление"
              />
            </a>
          </div>
        </div>
      </TransitionGroup>
      <div class="file-select">
        <div v-if="errorValid" class="help-block error">
          Необходимо выбрать врача
        </div>
        <TheMultiselect
          v-model:searchedDoctor="searchedDoctor"
          searchLabel="Начните вводить или выберете из списка"
          :options="doctorsList"
          :label="null"
        />
      </div>
      <div class="file-textarea">
        <TextAreaUi
          v-model="userComment"
          name-id="user-comment"
          label-title="Комментарий"
          placeholder="Пояснение к фото в свободной форме"
        />
      </div>
      <CustomButton @click.stop="sendFile" tag="button" type="button">
        Отправить
      </CustomButton>
    </template>

    <div v-if="successSend || errorSend" class="file-suc">
      <div class="file-suc__top">
        <img
          v-if="successSend"
          src="@/assets/uploads/icon/common/succsess-schedule.svg"
          alt="значок успеха"
        />
        <img
          v-if="errorSend"
          src="@/assets/uploads/icon/common/error-schedulesvg.svg"
          alt="значок ошибки"
        />
        <h4 v-if="successSend">Файл отправлен доктору!</h4>
        <h4 v-if="errorSend">Отправка не удалась</h4>
      </div>

      <p v-if="successSend">Комментарии можно получить на следующем приеме</p>
      <p v-if="errorSend" class="error">{{ errorSendMsg }}</p>
    </div>
  </ModalWithArrow>
</template>

<script>
import { authApi } from '@/api/authApi'
import { ref, toRefs, watch } from 'vue'
import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'
import ModalWithArrow from '@/components/modals/ModalWithArrow.vue'
import TheMultiselect from '@/components/pages/doctors/TheMultiselect.vue'
import CustomButton from '@/components/ui/buttons/CustomButton.vue'
import TextAreaUi from '@/components/ui/form/TextAreaUi.vue'
import { useYandexMetrikaStore } from '@/stores/YandexMetrikaStore'

export default {
  name: 'ModalDoctorSend',
  components: { TextAreaUi, CustomButton, TheMultiselect, ModalWithArrow },
  emits: ['modalClose', 'deleteFile'],
  props: {
    title: {
      type: String,
      required: true,
      default: ''
    },
    modalIsActive: {
      type: Boolean,
      required: true,
      default: false
    },
    fileData: {
      type: [Object, null],
      required: false,
      default: null
    },
    doctorsList: {
      type: [Array, null],
      required: true,
      default: null
    }
  },
  setup (props, { emit }) {
    const metrikaStore = useYandexMetrikaStore()
    const userStore = useUserStore()
    const { getUserId } = storeToRefs(userStore)
    const { fileData } = toRefs(props)
    const errorValid = ref(false)
    const searchedDoctor = ref('')
    const userComment = ref('')
    const successSend = ref(false)
    const errorSend = ref(false)
    const errorSendMsg = ref('')

    const sendFile = async () => {
      if (!searchedDoctor.value) {
        errorValid.value = true
        return
      }
      metrikaStore.sendGoal('file_send')

      const filesId = fileData.value.map((file) => file.id)
      const dataFiles = {
        file_id: filesId,
        doctors: [searchedDoctor.value],
        message: userComment.value
      }

      const [responseError, responseFile] = await authApi(
        `v1/user-file/send?user_id=${getUserId.value}`,
        'post',
        dataFiles
      )

      if (responseFile?.status === 'ok') {
        successSend.value = true
        errorValid.value = false
        errorSend.value = false
      }

      if (responseError) {
        errorSendMsg.value = responseError ?? 'Произошла ошибка'
        errorSend.value = true
      }

      userComment.value = ''
    }
    const deleteFile = (id) => {
      if (fileData.value.length === 1) {
        modalClose()
      }

      emit('deleteFile', id)
    }

    const modalClose = () => {
      userComment.value = ''
      errorSendMsg.value = ''
      errorSend.value = false
      successSend.value = false
      errorValid.value = false
      searchedDoctor.value = ''
      emit('modalClose')
    }

    watch(searchedDoctor, () => {
      errorValid.value = false
    })

    return {
      deleteFile,
      searchedDoctor,
      userComment,
      sendFile,
      errorSend,
      modalClose,
      successSend,
      errorSendMsg,
      errorValid
    }
  }
}
</script>

<style scoped lang="scss">
.file-list-enter-active,
.file-list-leave-active {
  transition: all 0.5s ease;
}

.file-list-enter-from,
.file-list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.file-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 20px;
  margin-bottom: 16px;
}

.file-list__item {
}

.file-list__desc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.file-list__name {
  color: #002856;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.28px;
  display: flex;
  align-items: center;
}

.file-list__icon {
  background: #002856;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;

  img {
    max-width: 95%;
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg)
      brightness(102%) contrast(102%);
  }
}

.file-list__delete {
  cursor: pointer;
}

.file-textarea {
  margin-bottom: 16px;
}

.file-select .error {
  text-align: start;
  margin-bottom: 5px;
}

.file-suc {
}

.file-suc__top {
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  gap: 12px;
  align-items: center;

  & h4 {
    color: $blue;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  & p {
    text-align: center;
    color: $blue;
    line-height: 20px;
  }

  & p.error {
    color: #ff1f00;
  }
}
</style>
