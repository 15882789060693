<template>
    <div class="pregnancy-info-slider">
      <div class="pregnancy-info-slider__container">
        <Swiper v-bind="swiperOptions" class="pregnancy-info-slider__slider-wrapper">
          <swiper-slide
            v-for="button in buttons"
            :key="button.id"
            @click="selectInfoSection(button.id)"
            class="pregnancy-info-slider__item"
            :class="{
              'pregnancy-info-slider__item--selected': selectedInfoSectionId === button.id
            }"
          >
            <div class="pregnancy-info-slider__item-content">
              <span class="pregnancy-info-slider__item-text">{{
               button.title
              }}</span>
            </div>
          </swiper-slide>
        </Swiper>
      </div>
    </div>
  </template>

<script>
import { ref } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { auto } from '@popperjs/core'

export default {
  name: 'PregnancyInfoButtons',
  components: { Swiper, SwiperSlide },
  emits: ['selectInfoSection'],
  props: {
    buttons: {
      type: [Object]
    },
    selectedInfoSectionId: Number
  },
  setup (props, { emit }) {
    const swiperOptions = ref({
      initialSlide: 0,
      spaceBetween: 12,
      slidesPerView: auto
    })

    const selectInfoSection = (id) => {
      emit('selectInfoSection', id)
    }

    return {
      swiperOptions,
      selectInfoSection
    }
  }
}
</script>

  <style scoped lang="scss">
  .pregnancy-info-slider {
    margin-bottom: 24px;
  }

  .pregnancy-info-slider__title {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
    color: #002856;
  }

  .pregnancy-info-slider__item {
    cursor: pointer;
    display: flex;
    height: 34px;
    width: fit-content;
    padding: 9px 16px;
    background-color: #e1e8f4;
    border-radius: 30px;
    color: #062e4f;
    font-size: 11px;
    font-weight: 700;
    line-height: 11px;
    text-align: left;
  }
  .pregnancy-info-slider__item-text {
    display: inline-flex;
    align-items: center;
  }
  .pregnancy-info-slider__item--selected {
    color: #ffffff;
    background-color: #002856;
  }
  .pregnancy-info-slider__item-content {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  </style>
