import axios from 'axios'

export const postLog = async (msg) => {
  if (process.env.VUE_APP_DEBUG) {
    try {
      await axios.post('/v1/support/log', { data: JSON.stringify(msg) })
    } catch (err) {
      console.log(err)
    }
  }
}
