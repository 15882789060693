<template>
  <div class="pregnancy-info-about-mom">

    <div class="pregnancy-info-about-mom__progress">
      <span>{{ timeSinceConception }}</span>
      <span>{{ timeUntillBirth }}</span>
    </div>

    <div class="pregnancy-info-about-mom__progress-bar-wrapper">
        <div :style="{width: progressBarPercent + '%'}" class="pregnancy-info-about-mom__progress-bar"></div>
    </div>

    <div class="pregnancy-info-about-mom__text" v-html="text"></div>

    <router-link to="/calendar-medications">
      <AppBanner
        v-if="bannerIsActive"
        @closeBanner="bannerIsActive = false"
        class="pregnancy-info-about-mom__banner"
        title="Календарь приема лекарств"
        image="med-announce"
        text="Незаменимый помощник в контроле за здоровьем"/>
    </router-link>

    <h1 class="pregnancy-info-about-mom__header">Выберите своего специалиста</h1>

    <Swiper class="swiper-docs" v-if="doctors" v-bind="swiperOptions">
        <swiper-slide
          v-for="doc in doctors"
          :key="doc.res_id"
          class="doc-slide"
        >
          <SmallDoctorCard
            :doctor="doc"
            :user="user"/>
        </swiper-slide>
        <div class="swiper-custom__pagination"></div>
      </Swiper>
  </div>
</template>

<script>
import AppBanner from '@/components/ui/announce/AppBanner.vue'
import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { reactive, ref } from 'vue'
import SmallDoctorCard from '../../doctors/SmallDoctorCard.vue'
import { Pagination } from 'swiper'
export default {
  name: 'PregnancyInfoAboutMom',
  components: { AppBanner, Swiper, SmallDoctorCard, SwiperSlide },
  props: {
    timeSinceConception: String,
    timeUntillBirth: String,
    progressBarPercent: String,
    text: String,
    doctors: [Object]
  },
  setup () {
    const userStore = useUserStore()
    const { user } = storeToRefs(userStore)
    const bannerIsActive = ref(true)
    const swiperOptions = reactive({
      breakpoints: {
        0: {
          slidesPerView: 1
        },
        991: {
          slidesPerView: 2
        }
      },
      spaceBetween: 10,
      pagination: {
        el: '.swiper-custom__pagination',
        clickable: true,
        dynamicBullets: true
      },
      loop: false,
      modules: [Pagination]
    })

    return {
      bannerIsActive,
      swiperOptions,
      user
    }
  }
}
</script>

<style scoped lang="scss">
.pregnancy-info-about-mom__progress {
margin-bottom: 12px;
  span:first-child {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: left;
    margin-bottom: 4px;
  }
  span:last-child {
    display: block;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #7F8DA9;
  }
}
.pregnancy-info-about-mom__progress-bar {
    height: 8px;
    background-color: #002856;
    border-radius: 2px;
}
.pregnancy-info-about-mom__progress-bar-wrapper {
    width: 100%;
    height: 8px;
    background-color: #E1E8F4;
    border-radius: 2px;
    margin-bottom: 24px;
}

.pregnancy-info-about-mom__text {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #111111;
}

.pregnancy-info-about-mom__banner {
    margin-bottom: 24px;
}

.pregnancy-info-about-mom__header {

font-size: 20px;
font-weight: 700;
line-height: 24px;
text-align: left;
margin-bottom: 24px;
}

.doc-slide {
  display: flex;
  justify-content: center;
}
.swiper {
  max-width: 100%;
}
.swiper-slide {
  padding: 10px 10px;
  .specialists {
    width: 100%;
  }
}

.swiper-docs {
  display: flex;
  flex-direction: column;
}

.swiper-custom__pagination {
  align-self: center;
  transform: translateX(0) !important;
}
</style>

<style>
.pregnancy-info-about-mom__text {

    p {
     margin-bottom: 24px;
    }
    ol {
     margin-bottom: 24px;
    }
    li {
     margin-left: 22px;
     list-style-image: url("@/assets/uploads/icon/calendar/blue-check-icon.svg");
     margin-bottom: 12px;
    }
}
</style>
