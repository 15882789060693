<script>
import { authApi } from '@/api/authApi'
import CustomButton from '@/components/ui/buttons/CustomButton.vue'
import FormRadio from '@/components/ui/form/FormRadio.vue'
import { useUserGlucoseStore } from '@/stores/UserGlucoseStore'
import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'
import { inject, ref, watch } from 'vue'
import SectionWrapper from '@/components/ui/SectionWrapper.vue'
import ArrowTitle from '@/components/ui/typography/ArrowTitle.vue'
import { useRouter } from 'vue-router'

export default {
  name: 'GlucoseEmpty',
  components: {
    CustomButton,
    FormRadio,
    ArrowTitle,
    SectionWrapper
  },
  props: {
    isApp: {
      type: Boolean,
      required: true,
      default: false
    },
    user: {
      type: [Object, null],
      required: true,
      default: null
    }
  },
  setup () {
    const userGlucoseStore = useUserGlucoseStore()
    const { setMeasureUnit, setSetup } = userGlucoseStore
    const unitValue = ref(1)
    const userStore = useUserStore()
    const { getUserId } = storeToRefs(userStore)
    const toast = inject('toast')
    const router = useRouter()

    setMeasureUnit(1)

    const showHelloToaster = (body, title, type = 'success') => {
      toast.show(
        `
      ${body}
      ${title}
      `,
        {
          position: 'bottom',
          type
        }
      )
    }

    const sendSetup = async (isScheduled) => {
      try {
        const [err, response] = await authApi(
          `/v1/events/diabet/setup/?user_id=${getUserId.value}`,
          'post',
          {
            hint: '',
            unit_id: unitValue.value,
            time: []
          }
        )
        if (response.status === 'ok') {
          setSetup(response.data.setup, isScheduled)
          router.push('calendar-glucose-settings')
        } else {
          showHelloToaster('', err, 'error')
        }
      } catch (err) {
        showHelloToaster('', err, 'error')
      }
    }

    watch(unitValue, () => {
      setMeasureUnit(unitValue.value)
    })
    return {
      unitValue,
      sendSetup
    }
  }
}
</script>

<template>
  <ArrowTitle title="Календарь глюкозы" :is-app="isApp" />
  <SectionWrapper stretched>
    <div class="glucose-empty">
      <div class="glucose-empty__picture">
        <img
          src="@/assets/uploads/images/calendar/glucose/glucose-empty.svg"
          alt="изображение глюкометра"
        />
      </div>

      <h4 class="glucose-empty__title">
        Вы измеряете глюкозу по графику или в произвольное время?
      </h4>

      <p class="glucose-empty__text">
        В последствии вы сможете изменить способ измерения в настройках
      </p>

      <custom-button
        marginBottom="0"
        @click.prevent="sendSetup('1')"
      >
        по графику
      </custom-button>
      <custom-button
        @click.prevent="sendSetup('')"
        marginBottom="0"
      >
        произвольно
      </custom-button>
      <form action="" class="glucose-empty__measure-value-form">
        <h4 class="glucose-empty__measure-value-form__title">
          Единицы измерения:
        </h4>
        <div class="glucose-empty__measure-value-form__wrapper">
          <FormRadio
            class="glucose-empty__measure-value-form__wrapper-item"
            color="#000000"
            label="мг/дл"
            :value="1"
            v-model="unitValue"
          />
          <FormRadio
            class="glucose-empty__measure-value-form__wrapper-item"
            color="#000000"
            label="ммоль/л"
            :value="2"
            v-model="unitValue"
          />
        </div>
      </form>
    </div>
  </SectionWrapper>
</template>

<style scoped lang="scss">
.glucose-empty {
  padding-top: 46px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  text-align: center;
}

.glucose-empty__title {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
}

.glucose-empty__measure-value-form {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.glucose-empty__measure-value-form__title {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
}

.glucose-empty__measure-value-form__wrapper {
  display: flex;
  align-items: center;
  gap: 26px;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  margin-right: 14px;

  &:last-child {
    margin-left: auto;
  }
}
</style>
<style>
.glucose-empty__measure-value-form__wrapper-item {
  label {
    margin-bottom: 3.5px !important;
  }
}
</style>
