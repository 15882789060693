<template>
  <div class="modal-arrow" :class="{ active: modalIsActive }">
    <div class="modal-arrow__wrapper" :class="{ active: modalIsActive }">
      <div class="container">
        <div class="modal-arrow__title">
          <WhiteBlockRounded class="modal-arrow__block-rounded" />
          <a class="modal-arrow__back" @click.prevent="$emit('modalClose')">
            <img
              src="@/assets/uploads/icon/common/arrow-back.svg"
              alt="стрелка"
            />
          </a>
          <h3>{{ title }}</h3>
        </div>
        <div class="modal-arrow__content">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { nextTick, onActivated, onMounted } from 'vue'
import WhiteBlockRounded from '@/components/ui/WhiteBlockRounded.vue'

export default {
  name: 'ModalWithArrow',
  components: { WhiteBlockRounded },
  emits: ['modalClose'],
  props: {
    title: {
      type: String,
      required: true,
      default: ''
    },
    modalIsActive: {
      type: Boolean,
      required: true,
      default: false
    }
  },

  setup () {
    onMounted(() => {
      nextTick().then(() => {
        window.scrollTo({
          top: 100,
          left: 0,
          behavior: 'smooth'
        })
      })
    })

    onActivated(() => {
      nextTick().then(() => {
        window.scrollTo({
          top: 100,
          left: 0,
          behavior: 'smooth'
        })
      })
    })
  }
}
</script>

<style scoped lang="scss">
.modal-arrow {
  left: 0;
  width: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  transition: all 0.5s ease-in-out;
  height: 100%;
  overflow: hidden;

  &.active {
    z-index: 1000;
    //
    //overflow-y: auto;
  }
}

.modal-arrow:before {
  display: none;
  content: "";
  position: absolute;
  border-radius: 8px;
  background: white;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.modal-arrow.active:before {
  display: block;
}

.modal-arrow__back {
  cursor: pointer;
  padding: 5px;
}

.modal-arrow__wrapper {
  position: absolute;
  left: 0;
  width: 100%;
  background: #fff;
  height: auto;
  border-radius: 8px 8px 0px 0px;
  padding: 0 0 60px 0px;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  transform: translateY(150%);

  &.active {
    transform: translateY(0);
    opacity: 1;
    //height: 100%;
  }
}

.modal-arrow__title {
  position: sticky;
  top: 0;
  z-index: 2000;
  background: white;
  padding-bottom: 10px;
  padding-top: 10px;

  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  margin-bottom: v-bind("mb");

  & h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #082b53;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }

  img {
    filter: invert(11%) sepia(31%) saturate(5093%) hue-rotate(198deg)
      brightness(102%) contrast(103%);
  }

  &:before {
    position: absolute;
    content: "";
    display: block;
    left: -15px;
    top: 0;
    z-index: -1;
    background: white;
    width: calc(100% + 30px);
    height: 100%;
    border-radius: 6px;
  }
}

.modal-arrow__block-rounded {
  width: calc(100% + 30px);
  top: 0;
  left: -15px;
}

.modal-arrow__content {
  text-align: center;

  img {
    margin-bottom: 32px;
  }

  a {
    color: #062e4f;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 120%;
    text-transform: uppercase;
    border-radius: 30px;
    background: var(--blue-1, #bac7de);
    padding: 11px;
    width: 100%;
    border: none;
    margin-top: 16px;
  }

  p {
    color: #002856;
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }

  margin-top: 24px;
}
</style>
