import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useAppInfoStore = defineStore('appStore', () => {
  const appInfo = ref(null)

  const setStore = (data) => {
    appInfo.value = data
  }

  const removePatientForm = () => {
    appInfo.value.is_banner_patient = 0
    appInfo.value.is_form_patient = 0
  }

  return {
    appInfo,
    setStore,
    removePatientForm
  }
})
