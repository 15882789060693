<script>

export default {
  name: 'CustomToast',
  props: {
    title: {
      type: String,
      required: true,
      default: ''
    },
    text: {
      type: String,
      required: false,
      default: ''
    },
    icon: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>

<template>
  <div class="custom-toast__content">
    <div class="custom-toast__icon">
      <img v-if="icon" :src="require(`@/assets/uploads/icon/toasts/${icon}.svg`)" alt="" />
    </div>
    <div class="custom-toast__text">
      <p>{{ title }}</p>
      <p><span>{{ text }}</span></p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.custom-toast__text {
  p {
    span {
      font-weight: 500;
    }
  }
}
</style>
