<template>
  <ModalWithArrow
    class="modal-payment modal-calendar"
    :style="{ overflow: 'scroll' }"
    :title="'Календарь активности'"
    :modal-is-active="modalIsActive"
    @modalClose="$emit('modalClose')"
  >
    <div class="activity-container">
      <div v-if="chartData" class="calendar-form activity-calendar">
        <VueDatePicker
          :class="getActiveDateClass()"
          @update:model-value="handleDate"
          :enableTimePicker="false"
          :autoApply="true"
          :inline="true"
          :allowed-dates="allowedDates"
          modelType="yyyy-MM-dd"
          format="dd/MM/yyyy"
          locale="ru-RU"
          hide-offset-dates
          :day-class="getDayClass"
          :month-change-on-scroll="false"
        />
      </div>
      <ul class="activity-calendar__list">
        <li><span>31</span>Норма выполнена</li>
        <li><span>31</span>Норма почти выполнена, средняя активность</li>
        <li><span>31</span>Низкая активность</li>
      </ul>
    </div>
  </ModalWithArrow>
</template>

<script>
import ModalWithArrow from '@/components/modals/ModalWithArrow.vue'
import VueDatePicker from '@vuepic/vue-datepicker'
import { computed, toRefs, watch } from 'vue'

export default {
  name: 'ActivityCalendarModal',
  components: {
    VueDatePicker,
    ModalWithArrow
  },
  emits: ['modalClose', 'selectDay'],
  props: {
    modalIsActive: {
      type: Boolean,
      required: true,
      default: false
    },
    chartData: {
      type: Object,
      required: true
    },
    stepsGoal: Number,
    currentDay: Object
  },
  setup (props, { emit }) {
    const { stepsGoal, chartData, currentDay } = toRefs(props)

    const getDayClass = (date) => {
      const targetDate = chartData.value.find(
        (d) => new Date(d.date_at).toDateString() === date.toDateString()
      )

      if (targetDate) {
        if (targetDate.date_at === currentDay.value.date_at) { return 'dp__active_date' }
        if (targetDate.steps >= stepsGoal.value) return 'green-activity'
        if (stepsGoal.value / 2 > targetDate.steps) return 'red-activity'
        return 'orange-activity'
      }

      return ''
    }

    const getActiveDateClass = () => {
      if (currentDay.value.steps >= stepsGoal.value) return 'green-activity'
      if (stepsGoal.value / 2 > currentDay.value.steps) return 'red-activity'
      return 'orange-activity'
    }

    const handleDate = (day) => {
      emit('selectDay', day)
      emit('modalClose')
    }

    const allowedDates = computed(() => {
      return chartData.value.map((day) => new Date(day.date_at))
    })

    watch(currentDay, (newVal) => {
      if (newVal !== null) {
        emit('selectDay', newVal.date_at)
      }
    })

    return {
      handleDate,
      getDayClass,
      getActiveDateClass,
      allowedDates
    }
  }
}
</script>

<style scoped lang="scss">
.activity-calendar {
  display: block;
}

.activity-calendar__list {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  li {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    display: flex;
    align-items: center;
    gap: 12px;
    color: #002856;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      border-radius: 50%;
      height: 32px;
      width: 32px;
      color: #ffffff;
    }

    &:nth-child(1) {
      span {
        background: #2fcb3f;
      }
    }

    &:nth-child(2) {
      span {
        background: #f1842c;
      }
    }

    &:nth-child(3) {
      span {
        background: #ff1f00;
      }
    }
  }
}
</style>
<style>
.activity-calendar .dp__main {
  background-color: white !important;
}
.red-activity {
  background-color: #ff1f00;
  color: #ffffff !important;
}
.green-activity {
  background-color: #2fcb3f;
  color: #ffffff !important;
}
.orange-activity {
  background-color: #f1842c;
  color: #ffffff !important;
}
.selected-activity {
  border: 1px solid #082b53;
  color: #ffffff !important;
}

.activity-calendar .red-activity .dp__active_date {
  background-color: #ff1f00;
  border: 1px solid #082b53;
  color: #ffffff !important;
}
.activity-calendar .green-activity .dp__active_date {
  background-color: #2fcb3f;
  border: 1px solid #082b53;
  color: #ffffff !important;
}
.activity-calendar .orange-activity .dp__active_date {
  background-color: #f1842c;
  border: 1px solid #082b53;
  color: #ffffff !important;
}

.activity-calendar .dp__today {
  border: none;
}
</style>
