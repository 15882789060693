<template>
  <div
    class="icon-in-circle"
    :style="{
      'background-color': bgColor,
      width: width + 'px',
      height: height + 'px'
    }"
  >
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: 'IconInCircle',
  emits: ['activateRemoveMode'],
  props: {
    bgColor: {
      type: String,
      required: true,
      default: '#fff'
    },
    width: {
      type: String || Number,
      required: false,
      default: null
    },
    height: {
      type: String || Number,
      required: false,
      default: null
    }
  }
}
</script>

<style scoped lang="scss">
.icon-in-circle {
  position: relative;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.2s ease-in-out;
}

</style>
