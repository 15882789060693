<template>
  <div
    class="form-group"
    :class="{ 'form-group--error': validError, light: lightColor }"
  >
    <div class="form-group__top">
      <label v-if="labelTitle" :for="nameId"
        >{{ labelTitle }}{{ required ? "" : " (необязательное поле)" }}</label
      >
      <div v-if="validError" class="error help-block">
        {{ errorMsg }}
      </div>
    </div>
    <input
      :disabled="disabled"
      :type="type"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value.trim())"
      :placeholder="placeholder"
      :maxlength="maxlength"
      class="form-group__input"
      :class="{
        'validation-error': validError,
        disabled: disabled,
      }"
      :id="nameId"
      :name="nameId"
      :step="step"
      autocomplete="on"
    />
    <div v-if="description" class="form-group__description">
      {{ description }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormInput',
  props: {
    validError: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    lightColor: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: true
    },
    errorMsg: {
      type: String,
      required: false,
      default: 'Необходимо заполнить поле.'
    },
    maxlength: {
      type: String,
      required: false,
      default: '200'
    },
    description: {
      type: String,
      required: false,
      default: ''
    },
    modelValue: {
      type: [String, null, Number],
      required: true,
      default: null
    },
    nameId: {
      type: String,
      required: true
    },
    labelTitle: {
      type: String,
      required: false
    },
    type: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    step: {
      type: Number,
      required: false
    }
  }
}
</script>

<style scoped lang="scss">
.form-group.light label {
  color: $blue;
}

.form-group {
  display: flex;
  gap: 3px;
  flex-direction: column;

  &__input {
    padding: 9px;
    background-color: #ffffff;
    border: 1px solid #bac7de;
    border-radius: 8px;

    &::placeholder {
      opacity: 0.5;
    }

    &.disabled {
      background-color: #ebebeb;
      background-size: 15px 15px;
      background-repeat: no-repeat;
      background-position: 98% center;
      background-image: url("~@/assets/uploads/icon/common/padlock.svg");

      &::placeholder {
        opacity: 0.5;
      }
    }

    &:focus {
      border: 1px solid #002856;
    }

    &::placeholder {
      color: $blue !important;
    }
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;

    label {
      color: #062e4f;
      font-size: 14px;
      font-weight: 400;
    }
  }
}

.form-group__description {
  font-size: 12px;
}

.help-block.error {
  flex: 1 0 auto;
  font-size: 12px;
  color: #ff1f00;
}

.validation-error {
  border-color: #ff1f00;
  color: #ff1f00;

  &:focus {
    color: $blue;
  }
}

.validation-error::placeholder {
  color: #ff1f00;
}
</style>
