<template>
  <div class="finger">
    <img src="@/assets/uploads/images/header/logo.svg" alt="Логотип к31" />
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import { useUserStore } from '@/stores/UserStore'
import { inject, onMounted } from 'vue'
import { deleteCookieToken } from '@/utilits/deleteCookieToken'
import { useNotifyStore } from '@/stores/NotifyStore'
import { storeToRefs } from 'pinia'

export default {
  name: 'FingerView',

  setup () {
    const notifyStore = useNotifyStore()
    const { fromBackground } = storeToRefs(notifyStore)
    const router = useRouter()
    const userStore = useUserStore()
    const { setUserToken, setUser, setBiometry } = userStore
    const toast = inject('toast')

    const showHelloToaster = () => {
      toast.success('Добро пожаловать!', {
        position: 'top-right',
        type: 'success'
      })
    }

    onMounted(() => {
      const isBiometryActive = JSON.parse(window.localStorage.getItem('is_biometry_active'))

      if (isBiometryActive) {
        setBiometry(true)
        new Promise((resolve, reject) => {
          window.Fingerprint.isAvailable(
            () => {
              window.Fingerprint.loadBiometricSecret(
                {
                  description:
                  'Для работы с личным кабинетом надо пройти аутентификацию',
                  disableBackup: true // always disabled on Android
                },
                (suc) => {
                  resolve(suc)
                },
                (err) => {
                  reject(err)
                }
              )
            },
            (err) => {
              reject(err)
            }
          )
        })
          .then((secret) => {
            setUserToken(secret)
            return setUser(secret)
          })
          .then(() => {
            if (fromBackground.value) {
              return
            }
            router.push({
              name: 'home'
            })
            showHelloToaster()
          })
          .catch(() => {
            deleteCookieToken()
            router.push({
              name: 'auth-phone'
            })
          })
      } else {
        setBiometry(false)
        deleteCookieToken()
        router.push({
          name: 'auth-phone'
        })
      }
    })
  }
}
</script>

<style scoped lang="scss">
.finger {
  display: flex;
  flex-direction: column;
  padding: 15px;
  min-height: 100vh;
  justify-content: center;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    box-shadow: 1px 1px 2.5rem 1px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 20px;
    background: #ffffff;
  }
}
</style>
