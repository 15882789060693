<template>
  <div class="block-appointment" id="offerForm">
    <div class="block-appointment__title">Получить консультацию</div>
    <div class="block-appointment__text">
      Заполните форму, наши менеджеры свяжуться с Вами в течение 15 минут
    </div>
    <div class="block-appointment__form">
      <FormInput
        disabled
        v-model="userPhone"
        name-id="register-new-phone"
        label-title="Номер телефона"
        :lightColor="true"
      />

      <div class="block-appointment__inputs">
        <FormInputCheckbox
          id="profile-personal"
          v-model="userInfo.isTerms"
          label="Я согласен на"
          linkLabel="обработку моих персональных данных"
          href="https://www.k31.ru/info/soglasie/"
          white
          :validError="v$.isTerms.$error"
        />
        <FormInputCheckbox
          id="profile-rules"
          v-model="userInfo.rules"
          label="Я ознакомлен с"
          linkLabel="правилами внутреннего распорядка"
          href="https://www.k31.ru/upload/doc/useterms.pdf"
          white
          :validError="v$.rules.$error"
        />
      </div>

      <div class="error" v-if="error">
        Пожалуйста, проверьте, что все поля заполнены верно.
      </div>
      <CustomButton tag="button" @click.prevent="submitForm">
        Сохранить
      </CustomButton>
    </div>
    <ModalWrapper
      :modal-is-open="modalIsOpen"
      @modal-close="modalIsOpen = false"
      title="Участие в акции"
    >
      <img src="@/assets/uploads/images/modal-call.svg" alt="" />
      <p class="modal-text--success">
        Наши специалисты свяжутся с вами в ближайшее время
        <span>Ожидайте звонка</span>
      </p>
    </ModalWrapper>
  </div>
</template>
<script>
import FormInput from '@/components/ui/form/FormInput.vue'
import { computed, reactive, ref, toRefs } from 'vue'
import FormInputCheckbox from '@/components/ui/form/FormInputCheckbox.vue'
import CustomButton from '@/components/ui/buttons/CustomButton.vue'
import axios from 'axios'
import { useVuelidate } from '@vuelidate/core'
import { sameAs } from '@vuelidate/validators'
import { storeToRefs } from 'pinia'
import { useUserStore } from '@/stores/UserStore'
import ModalWrapper from '@/components/Common/ModalWrapper.vue'

export default {
  name: 'BlockAppointment',
  components: { ModalWrapper, CustomButton, FormInputCheckbox, FormInput },
  props: {
    user: {
      type: [Object, null],
      required: true,
      default: null
    },
    offerId: {
      type: Number,
      required: true,
      default: null
    }
  },
  setup (props) {
    const userStore = useUserStore()
    const loading = ref(false)
    const error = ref(false)
    const modalIsOpen = ref(false)
    const { userFullName, userToken } = storeToRefs(userStore)
    const { user, offerId } = toRefs(props)
    const userInfo = reactive({
      isTerms: false,
      rules: false
    })
    const rules = {
      isTerms: { sameAs: sameAs(true) },
      rules: { sameAs: sameAs(true) }
    }
    const userPhone = computed(() => {
      if (!user.value) return

      return user.value.username
    })
    const v$ = useVuelidate(rules, userInfo)
    const submitForm = async () => {
      v$.value.$touch()

      if (v$.value.$error) {
        return
      }
      try {
        loading.value = true
        const data = {
          promo_id: offerId.value,
          name: userFullName.value,
          phone: userPhone.value,
          is_terms: 1,
          is_rules: 1
        }
        const response = await axios.post('/v2/form-record/save', data, {
          headers: {
            Accept: '*/*',
            Authorization: `Bearer ${userToken.value}`,
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache'
          }
        })
        if (response.data.status === 'ok') {
          modalIsOpen.value = true
          window.scrollTo(0, 0)
        } else {
          error.value = true
        }
      } catch (e) {
        error.value = true
      } finally {
        loading.value = false
      }
    }
    return {
      userPhone,
      userInfo,
      submitForm,
      loading,
      error,
      modalIsOpen,
      v$
    }
  }
}
</script>

<style lang="scss" scoped>
.block-appointment {
  background: #002856;
  border-radius: 6px;
  padding: 24px 29px;
}

.block-appointment__title {
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.36px;
  margin-bottom: 8px;
}

.block-appointment__text {
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.28px;
  margin-bottom: 24px;
}

.block-appointment__inputs {
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: white;

  & a {
    color: white !important;
  }
}

.block-appointment__form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.modal-text--success {
  color: #062e4f;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  margin-top: 24px;

  span {
    display: block;
    color: #002856;
    font-size: 14px;
    margin-top: 5px;
    font-weight: 400;
  }
}
</style>
