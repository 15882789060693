<template>
  <section class="section my-appointment">
    <div class="container">
      <SectionWrapper class="my-appointment__wrapper">
        <SectionTitle title="Ваши записи" marginBottom="4px"></SectionTitle>
        <AppointmentList v-if="user" :user="user"/>
        <TheText v-else>
          Для того, чтобы просмотреть записи, необходимо
          зарегистрироваться</TheText
        >
      </SectionWrapper>
    </div>
  </section>
</template>

<script>
import SectionTitle from '@/components/ui/typography/SectionTitle.vue'
import AppointmentList from '@/components/pages/home/AppointmentList.vue'
import SectionWrapper from '@/components/ui/SectionWrapper.vue'
import TheText from '@/components/ui/typography/TheText.vue'

export default {
  name: 'MyAppointmentSection',
  components: { TheText, SectionWrapper, AppointmentList, SectionTitle },
  props: {
    user: {
      type: [Object, null],
      required: true,
      default: null
    }
  }
}
</script>

<style scoped lang="scss">
.my-appointment {
  padding-bottom: 25px;
  //padding-top: 45px;
  @include desktop {
    grid-column: 2;
  }
}

.my-appointment__wrapper {
  position: relative;
  padding: 16px 8px 10px;
}
</style>
