<template>
  <div
    class="modal fade modal-custom down time-picker"
    id="time-picker-mobile"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-custom__dialog">
      <div class="modal-content">
        <div class="modal-header modal-custom__header">
          <a
            data-bs-dismiss="modal"
            aria-label="Close"
            class="time-picker__back"
          >
            <img
              class="arrow-title__img"
              src="@/assets/uploads/icon/common/arrow-back.svg"
              alt="стрелка"
            />
            <h3 id="exampleModalLabel">Добавить время приема</h3>
          </a>
          <!--          <button-->
          <!--              type="button"-->
          <!--              class="btn-close"-->
          <!--              data-bs-dismiss="modal"-->
          <!--              aria-label="Close"-->
          <!--          ></button>-->
        </div>
        <div class="modal-body modal-custom__body">
          <div class="time-picker__wrapper">
            <Swiper
              ref="swiperHours"
              v-bind="swiperOptions"
              @slideChange="changeSlideHours"
              class="time-picker__slider"
            >
              <swiper-slide
                v-for="hour in hours"
                :key="hour"
                v-slot="{ isActive }"
              >
                <div class="time-picker__slide" :class="{ isActive: isActive }">
                  {{ hour }}
                </div>
              </swiper-slide>
            </Swiper>
            <Swiper
              v-bind="swiperOptions"
              @slideChange="changeSlideMinute"
              class="time-picker__slider"
            >
              <swiper-slide
                v-for="minute in minutes"
                :key="minute"
                v-slot="{ isActive }"
              >
                <div class="time-picker__slide" :class="{ isActive: isActive }">
                  {{ minute }}
                </div>
              </swiper-slide>
            </Swiper>
          </div>

          <custom-button
            @click="addTime"
            type="button"
            tag="button"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            Сохранить
          </custom-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { computed, reactive, ref } from 'vue'
import CustomButton from '@/components/ui/buttons/CustomButton.vue'

export default {
  name: 'TimePickerMobile',
  emits: ['addTime'],
  components: { CustomButton, Swiper, SwiperSlide },
  props: {},
  setup (props, { emit }) {
    const swiperHours = ref(null)
    const hours = ref([
      '08',
      '09',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
      '21',
      '22',
      '23',
      '00',
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07'
    ])
    const minutes = ref([
      '55',
      '00',
      '05',
      '10',
      '15',
      '20',
      '25',
      '30',
      '35',
      '40',
      '45',
      '50'
    ])
    const swiperOptions = reactive({
      spaceBetween: 10,
      direction: 'vertical',
      slidesPerView: 3,
      slideToClickedSlide: true,
      loop: true,
      centeredSlides: true,
      centeredSlidesBounds: true,
      navigation: {
        nextEl: '.time-picker__next',
        prevEl: '.time-picker__prev'
      }
    })

    const currentHours = ref('')
    const currentMinutes = ref('')
    const currentTime = computed(() => {
      return `${currentHours.value}:${currentMinutes.value}`
    })

    const changeSlideHours = (e) => {
      currentHours.value = hours.value[e.realIndex]
    }
    const changeSlideMinute = (e) => {
      currentMinutes.value = minutes.value[e.realIndex]
    }
    const addTime = () => {
      emit('addTime', currentTime.value)
    }
    return {
      swiperOptions,
      hours,
      changeSlideHours,
      changeSlideMinute,
      minutes,
      addTime,
      swiperHours
    }
  }
}
</script>

<style scoped lang="scss">
.time-picker__wrapper {
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
  gap: 12px;
}

.time-picker__slider {
  max-width: 72px;
  height: 120px;
  margin: 0;
}

.modal-custom__header {
  display: block;
}

.time-picker__back {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  gap: 10px;
}

.time-picker__slide {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  border-radius: 100px;
  opacity: 30%;

  &.isActive {
    opacity: 1;
    color: #323232;
    border: 1px solid $blue-light;
  }
}

.modal-custom:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  opacity: 0.5;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 40, 86, 0.5);
}

.modal-custom__dialog {
  display: flex;
  justify-content: end;
  max-width: 100%;
  z-index: 1050;
  padding: 0;
  margin: 0;
  align-items: end;
  height: 100%;
}

.modal.fade.down .modal-custom__dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, 150px);
}

.modal.show.down .modal-dialog {
  transform: none;
}

.modal-custom__header {
  border-bottom: 0;

  & h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #082b53;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
}

.modal-custom__body {
  padding-top: 0;
  margin-top: 0;
}

.modal-content {
  border: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
</style>
<style lang="scss"></style>
