<template>
  <div class="filials-slider">
    <h3 class="filials-slider__title">Филиал</h3>
    <div class="filials-slider__container">
      <Swiper v-bind="swiperOptions" class="filials-slider__slider-wrapper">
        <swiper-slide
          v-for="clinic in filteredClinics"
          :key="clinic.id"
          @click="chooseClinic(clinic.id)"
          class="filials-slider__item"
          :class="{
            'filials-slider__item--selected': selectedClinic === clinic.id
          }"
        >
          <div class="filials-slider__item-content">
            <img
              v-if="clinic.isThereDebt && clinic.id !== 0 && !isDeposit"
              src="@/assets/uploads/icon/common/payment-warning.svg"
              alt="внимание"
            />
            <span class="filials-slider__item-text">{{
              clinic.name.toUpperCase()
            }}</span>
          </div>
        </swiper-slide>
      </Swiper>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { auto } from '@popperjs/core'

export default {
  name: 'FilialsForm',
  components: { Swiper, SwiperSlide },
  emits: ['selectClinic'],
  props: {
    isDeposit: Boolean,
    finance: {
      type: [Object]
    }
  },
  setup (props, { emit }) {
    const filteredClinics = computed(() => {
      const clinics = [
        { name: 'Все', id: 0 },
        { name: 'К+31 на Лобачевского', id: 1 },
        { name: 'К+31 петровские ворота. Поликлиника', id: 2 },
        { name: 'К+31 петровские ворота. Хирургия', id: 3 },
        { name: 'К+31 запад', id: 5 }
      ]

      const processClinic = (clinic, i) => {
        if (i === 0) {
          const isThereDebt = props.finance?.some((c) => c?.invoice?.length > 0)
          return { ...clinic, isThereDebt }
        } else {
          const currClinic = props.finance?.find((c) => c.id === clinic.id)
          return { ...clinic, isThereDebt: currClinic?.invoice?.length > 0 }
        }
      }

      return props.isDeposit
        ? clinics.slice(1).map(processClinic)
        : clinics.map(processClinic)
    })

    const selectedClinic = ref(props.isDeposit ? 1 : 0)
    const swiperOptions = ref({
      initialSlide: 0,
      spaceBetween: 14,
      slidesPerView: auto
    })

    const chooseClinic = (id) => {
      emit('selectClinic', id)
      selectedClinic.value = id
    }

    return {
      swiperOptions,
      filteredClinics,
      selectedClinic,
      chooseClinic
    }
  }
}
</script>

<style scoped lang="scss">
.filials-slider {
  margin-top: 16px;
}
.filials-slider__title {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  color: #002856;
}
.filials-slider__container {
  margin-top: 16px;
}

.filials-slider__item {
  display: flex;
  height: 34px;
  width: fit-content;
  padding: 9px 16px;
  background-color: #e1e8f4;
  border-radius: 30px;
  color: #062e4f;
  font-size: 11px;
  font-weight: 700;
  line-height: 11px;
  text-align: left;
}
.filials-slider__item-text {
  display: inline-flex;
  align-items: center;
}
.filials-slider__item--selected {
  color: #ffffff;
  background-color: #002856;
}
.filials-slider__item-content {
  display: flex;
  gap: 10px;
  align-items: center;
}
</style>
