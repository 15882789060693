<script>
export default {
  name: 'PatientFormBanner'
}
</script>

<template>
  <router-link to="/patient-form" class="banner">
    <div class="banner__wrapper">
      <div class="banner__column">
        <h4 class="banner__title">
          Сберегите до 20 минут <br />
          при первом визите!
        </h4>

        <p class="banner__link">заполните данные online</p>
      </div>
      <div class="banner__picture">
        <img
          src="@/assets/uploads/icon/common/patient-form-pic.svg"
          alt="изображение анкеты"
        />
      </div>
    </div>
  </router-link>
</template>

<style scoped lang="scss">
.banner {
  display: block;
  margin-bottom: 16px;
}

.banner__wrapper {
  position: relative;
  display: flex;
  gap: 30px;
  justify-content: space-between;
  border-radius: 6px;
  background: $blue;
  padding: 20px 40px 25px 16px;
}

.banner__close {
  cursor: pointer;
  position: absolute;
  right: 9px;
  top: 9px;
}

.banner__column {
  position: relative;
  z-index: 3;
}

.banner__title {
  margin-bottom: 14px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
}

.banner__link {
  cursor: pointer;
  padding: 3px 16px;
  border-radius: 12px;
  background: $orange;
  color: $blue;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;

  &:hover {
    background: $blue-light;
  }
}

.banner__picture {
  z-index: 2;
  position: absolute;
  bottom: 0;
  right: 15px;

  & img {
    //width: 100px;
  }
}
</style>
