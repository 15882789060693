<template>
  <ArrowTitle title="Уведомления" :is-app="isApp"></ArrowTitle>
  <SectionWrapper stretched>
    <TheLoader v-if="loading" />

    <div v-if="notifyAppAndNative.length !== 0" class="notify">
      <div
        v-for="item in notifyAppAndNative"
        :key="item.id"
        class="notify__item"
      >
        <a
          v-if="item.url || item.externalUrl"
          :href="item.url || item.externalUrl"
          target="_blank"
          class="notify__link"
        >
          <h3 class="notify__title">{{ item.title }}</h3>

          <p class="notify__text">
            {{ item.message }}
          </p>
        </a>
        <router-link
          v-else-if="item.routerUrl"
          :to="item.routerUrl"
          class="notify__link"
        >
          <h3 class="notify__title">{{ item.title }}</h3>

          <p class="notify__text">
            {{ item.message }}
          </p>
        </router-link>

        <div v-else class="notify__link">
          <h3 class="notify__title">
            {{ item.title }}
          </h3>

          <p class="notify__text">
            {{ item.message }}
          </p>
        </div>
      </div>
    </div>

    <TheText v-if="!notify && !loading"> У вас нет уведомлений</TheText>
  </SectionWrapper>
</template>

<script>
import SectionWrapper from '@/components/ui/SectionWrapper.vue'
import ArrowTitle from '@/components/ui/typography/ArrowTitle.vue'
import TheLoader from '@/components/ui/TheLoader.vue'
import TheText from '@/components/ui/typography/TheText.vue'
import { computed, onActivated, ref, toRefs, watch } from 'vue'
import axios from 'axios'
import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'
import { useNotifyStore } from '@/stores/NotifyStore'

export default {
  name: 'MedCardView',
  components: {
    TheText,
    TheLoader,
    SectionWrapper,
    ArrowTitle
  },
  props: {
    user: {
      type: [Object, null],
      required: true,
      default: null
    },
    isApp: {
      type: Boolean,
      required: true,
      default: false
    }
  },

  setup () {
    const notifyStore = useNotifyStore()
    const { notificationList, fromNotifyView } = toRefs(notifyStore)
    const userStore = useUserStore()
    const { userToken } = storeToRefs(userStore)
    const loading = ref(false)
    const notify = ref([])

    const notifyAppAndNative = computed(() => {
      const allNotify = [...notificationList.value, ...notify.value]
      const uniquePid = {}
      for (let i = 0; i < allNotify.length; i++) {
        uniquePid[allNotify[i].pid] = allNotify[i]
      }

      return Object.values(uniquePid).sort((notifyA, notifyB) =>
        notifyA.pid > notifyB.pid ? -1 : 1
      )
    })

    const getNotifyApp = async () => {
      try {
        loading.value = true
        const response = await axios('/v3/push', {
          headers: {
            Authorization: `Bearer ${userToken.value}`
          }
        })

        if (response.data.status === 'ok') {
          notify.value = response.data.data
        } else {
          notify.value = []
        }
      } catch (e) {
        notify.value = []
      } finally {
        loading.value = false
      }
    }

    onActivated(() => {
      notifyStore.checkNotify()
      getNotifyApp()
    })

    watch(
      fromNotifyView,
      () => {
        if (fromNotifyView.value) {
          notifyStore.checkNotify()
          getNotifyApp()
          fromNotifyView.value = false
        }
      },
      { immediate: true }
    )

    return {
      loading,
      notify,
      notifyAppAndNative
    }
  }
}
</script>

<style lang="scss" scoped>
.notify {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.notify__link {
  display: block;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
  padding: 8px 12px;
}

.notify__title {
  color: $blue;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
}

.notify__text {
  color: $blue;
  font-size: 14px;
}
</style>
