<template>
  <ArrowTitle title="Программы" :is-app="isApp"></ArrowTitle>
  <SectionWrapper stretched>
    <TheLoader v-if="loading"> Загрузка</TheLoader>
    <div v-if="!loading && !resultIsVisible" class="programs">
      <div class="programs__banner">
        <BannerItem
          title="Комплексы услуг  специально для вас"
          color="blue"
          :img="require(`@/assets/uploads/images/program/banner-icon.svg`)"
          subtitle="Комплекс программ по необходимым услугам"
        />
      </div>
      <SectionTitle title="Ответьте на несколько вопросов, а мы подберем вам индивидуальную программу в режиме  онлайн"
                    marginBottom="16px"></SectionTitle>
      <div class="program-selector">
        <div class="question-list">
          <div
            v-for="(question, index) in questionHistory"
            :key="index"
            :ref="'question-' + index"
            class="question-block"
          >
            <h3>{{ question.next_title }}</h3>
            <ul v-if="index < 2">
              <li v-for="(answer, aIndex) in question.items" :key="aIndex">
                <button
                  @click="handleAnswerClick(index, answer)"
                  :class="{ active: question.selectedAnswer?.id === answer.id, 'btn-icon': answer.icon_code}"
                >
                  <IconInCircle
                    bg-color="#E1E8F4"
                    width="64"
                    height="64"
                    v-if="answer.icon_code"
                  >
                    <svg class="question-block__icon">
                      <use
                        :xlink:href="
                          require('@/assets/uploads/sprite-k31/sprite.svg') +
                          `#${answer.icon_code}`
                        "
                      ></use>
                    </svg>
                  </IconInCircle>
                  <span class="question-block__text" v-if="answer.icon_code">
                    {{ answer.title }}
                  </span>
                  <img v-else-if="answer.icon" :src="answer.icon" alt="" />
                </button>
              </li>
            </ul>
            <VueMultiselect
              v-else
              v-model="question.selectedAnswer"
              :options="question.items"
              label="title"
              track-by="id"
              placeholder="Выберите..."
              :searchable="false"
              @select="handleAnswerClick(index, question.selectedAnswer)"
              :close-on-select="true"
              class="arrow"
            >
              <template v-slot:noResult>
                <span>По вашему запросу ничего не найдено...</span>
              </template>
            </VueMultiselect>
          </div>
          <button v-if="result" class="btn-result" @click="showResult">
            Подобрать
          </button>
        </div>
      </div>
    </div>
    <div v-if="resultIsVisible && !loading">
      <SectionTitle v-if="result.length !== 0" title="Готово! Ваши программы:"
                    marginBottom="16px"></SectionTitle>
      <TheProgram v-for="program in result" :key="program.id" :program="program"/>
      <SectionTitle v-if="result.length === 0" title="К сожалению, по вашим параметрам программы не найдены"
                    marginBottom="16px"></SectionTitle>
      <CustomButton v-if="result.length === 0" bg-color="#002856" color="#fff" tag="router-link" to="/program/all"> Все программы </CustomButton>
      <CustomButton tag="button" @click="reset" marginTop="10px">
        новый подбор программ
      </CustomButton>
    </div>
  </SectionWrapper>
</template>

<script>
import ArrowTitle from '@/components/ui/typography/ArrowTitle.vue'
import SectionWrapper from '@/components/ui/SectionWrapper.vue'
import { nextTick, onActivated, onMounted, ref } from 'vue'
import TheLoader from '@/components/ui/TheLoader.vue'
import BannerItem from '@/components/pages/home/BannerItem.vue'
import SectionTitle from '@/components/ui/typography/SectionTitle.vue'
import axios from 'axios'
import IconInCircle from '@/components/ui/IconInCircle.vue'
import VueMultiselect from 'vue-multiselect'
import TheProgram from '@/components/pages/profile/profile-programs/TheProgram.vue'
import CustomButton from '@/components/ui/buttons/CustomButton.vue'
export default {
  name: 'ProgramsSelectionView',
  components: {
    CustomButton,
    TheProgram,
    IconInCircle,
    SectionTitle,
    BannerItem,
    TheLoader,
    ArrowTitle,
    SectionWrapper,
    VueMultiselect
  },
  props: {
    user: {
      type: [Object, null],
      required: true,
      default: null
    },
    isApp: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  setup () {
    const loading = ref(false)
    const data = ref(null)
    const questionHistory = ref([])
    const result = ref(null)
    const resultIsVisible = ref(false)
    const showResult = () => {
      resultIsVisible.value = true
      window.scrollTo(0, 0)
    }
    const updateQuestionHistory = (index, answer) => {
      questionHistory.value[index].selectedAnswer = answer
      if (answer.items && answer.items.length > 0) {
        questionHistory.value.splice(index + 1, questionHistory.value.length - index - 1)
        questionHistory.value.push({
          next_title: answer.next_title,
          items: answer.items,
          selectedAnswer: null
        })
      } else {
        questionHistory.value.splice(index + 1, questionHistory.value.length - index - 1)
        result.value = answer.programs
      }
    }
    const handleSelectChange = (index, event) => {
      const selectedAnswer = questionHistory.value[index].items.find(
        (item) => item.id === parseInt(event.target.value)
      )
      if (selectedAnswer) {
        updateQuestionHistory(index, selectedAnswer)
      }
    }
    const reset = () => {
      result.value = null
      resultIsVisible.value = false
      questionHistory.value.splice(1)
      questionHistory.value[0].selectedAnswer = null
    }
    const handleAnswerClick = (index, answer) => {
      updateQuestionHistory(index, answer)
      scrollToNextQuestion()
    }
    const fetchQuestions = async () => {
      try {
        const response = await axios.get(
          'https://lk.k31.ru/api/v6/checkup/'
        )

        if (response.data.status === 'ok') {
          data.value = response.data.data[0]
          questionHistory.value.push({
            ...response.data.data[0],
            selectedAnswer: null
          })
        }
      } catch (err) {
        console.log(err)
      }
    }
    const scrollToNextQuestion = () => {
      nextTick(() => {
        window.scrollTo(0, document.body.scrollHeight)
      })
    }
    onMounted(async () => {
      loading.value = true
      await fetchQuestions()
      loading.value = false
    })
    onActivated(() => {
      questionHistory.value.splice(1)
      if (questionHistory.value[0] && questionHistory.value[0].selectedAnswer) {
        questionHistory.value[0].selectedAnswer = null
      }
    })
    return {
      loading,
      data,
      questionHistory,
      handleAnswerClick,
      result,
      handleSelectChange,
      reset,
      resultIsVisible,
      showResult
    }
  }
}
</script>
<style scoped lang="scss">
.question-block {
  h3 {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #002856;
    margin-bottom: 10px;
  }
  ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
    li {
      .btn-icon {
        padding: 16px 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
      span {
        font-size: 12px;
        font-weight: 700;
        line-height: 13px;
        color: #002856;
        margin-top: 12px;
      }
      button {
        border: none;
        padding: 0;
        width: 100%;
        box-shadow: 0px 5px 15px 0px #0000001A;
        border-radius: 8px;
        overflow: hidden;
        background: #FFFFFF;
        &.active {
          border: 1px solid #002856;
        }
        img {
          width: 100%;
        }
      }
    }
  }
}
.question-block__icon {
  width: 36px;
  height: 36px;
}
.question-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.btn-result {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #FFFFFF;
  background: #002856;
  text-transform: uppercase;
  width: 100%;
  padding: 14px;
  border-radius: 30px;
  border: none;
}
.programs__banner {
  margin-bottom: 18px;
}
</style>
