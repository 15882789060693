<template>
  <div class="pregnancy-settings" :class="{ active: isModalOpen }">
    <div class="container">
      <div class="pregnancy-settings__title">
        <a class="section-title__back" @click.prevent="closeModal">
          <img
            src="@/assets/uploads/icon/common/arrow-back.svg"
            alt="стрелка"
          />
        </a>
        <h3>{{ isDeleteFormOpen ? 'Настройки' : 'Обнуление календаря' }}</h3>
      </div>
    </div>
    <TheLoader :style="{ marginTop: 24 + 'px' }" v-show="loading" />
    <div v-show="!loading">
      <div v-show="isDeleteFormOpen" class="container">
        <h1 class="pregnancy-settings__subtitle">Обнуление календаря</h1>
        <div class="pregnancy-settings__warning-block">
          <img
            class="pregnancy-settings__warning-block-img"
            src="@/assets/uploads/icon/common/blue-warning.svg"
            alt=""
          />
          <div class="pregnancy-settings__warning-block-text">
            После обнуления календаря беременности вы не сможете восстановить
            данные
          </div>
        </div>
        <CustomButton @click.prevent="deleteCalendar"
          >Обнулить календарь</CustomButton
        >
        <div class="pregnancy-settings__error-block">
          {{ errorMsg }}
        </div>
      </div>
      <div v-show="!isDeleteFormOpen" class="container">
        <div class="pregnancy-form__header">
          <img
            class="pregnancy-form__header-woman"
            src="@/assets/uploads/images/payment/doctor-woman.svg"
          />
          <img
            class="pregnancy-form__header-background"
            src="@/assets/uploads/images/calendar/pregnancy/background.svg"
          />
        </div>
        <h1 class="pregnancy-settings__title-delete-form">
          Календарь беременности обнулен
        </h1>
        <CustomButton
        @click.prevent="goToSettings"
        >Ввести новые данные</CustomButton
        >
        <CustomButton
        @click.prevent="goToSettings"
          white
        >Вернуться на главную</CustomButton
        >
      </div>
    </div>
  </div>
</template>

<script>
import TheLoader from '@/components/ui/TheLoader.vue'
import CustomButton from '@/components/ui/buttons/CustomButton.vue'
import router from '@/router'
import axios from 'axios'
import { onDeactivated, ref, toRefs } from 'vue'

export default {
  components: {
    CustomButton,
    TheLoader
  },
  emits: ['closeModal'],
  props: {
    isModalOpen: {
      type: Boolean,
      required: true,
      default: false
    },
    userToken: String,
    getUserId: Number
  },
  setup (props, { emit }) {
    const loading = ref(false)
    const isDeleteFormOpen = ref(true)
    const errorMsg = ref('')
    const { userToken, getUserId } = toRefs(props)

    const goToSettings = () => {
      router.push({ name: 'calendar-pregnancy-settings' })
    }

    const goToCalendars = () => {
      router.push({ name: 'calendar' })
    }

    const closeModal = () => {
      if (!isDeleteFormOpen.value) router.push({ name: 'calendar-pregnancy-settings' })
      else emit('closeModal')
    }

    const deleteCalendar = async () => {
      loading.value = true
      try {
        const response = await axios.delete(
          `v1/events/pregnant/delete?user_id=${getUserId.value}`,
          {
            headers: {
              Authorization: `Bearer ${userToken.value}`,
              'Content-Type': 'multipart/form-data'
            }
          }
        )

        if (response.data.status === 'ok') {
          isDeleteFormOpen.value = false

          loading.value = false
        } else {
          errorMsg.value = 'Не удалось обнулить календарь, попробуйте позже'
        }
      } catch (err) {
        throw new Error()
      }
    }

    onDeactivated(() => {
      isDeleteFormOpen.value = true
    })
    return {
      deleteCalendar,
      goToSettings,
      goToCalendars,
      closeModal,
      isDeleteFormOpen,
      loading,
      errorMsg
    }
  }
}
</script>

<style scoped lang="scss">
.pregnancy-settings {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 0;
  background: #fff;
  border-radius: 8px 8px 0px 0px;
  padding: 12px 0;
  transition: all 0.5s ease-in-out;
  opacity: 0;
  z-index: 10;
  &.active {
    top: 0;
    opacity: 1;
    height: 100%;
  }
}

.pregnancy-settings__title {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  margin-bottom: v-bind('mb');

  & h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #082b53;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }

  img {
    filter: invert(11%) sepia(31%) saturate(5093%) hue-rotate(198deg)
      brightness(102%) contrast(103%);
  }
}

.section-title__back {
  cursor: pointer;
}

.pregnancy-settings__subtitle {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  margin-bottom: 16px;
  margin-top: 16px;
}

.pregnancy-settings__warning-block {
  background-color: #e1e8f4;
  display: flex;
  height: 104px;
  padding: 16px;
  gap: 12px;
  border-radius: 5px;
  margin-bottom: 24px;
}
.pregnancy-settings__warning-block-img {
  margin-top: 4px;
  height: 16px;
  width: 16px;
}
.pregnancy-settings__warning-block-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.pregnancy-form__header {
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.pregnancy-form__header-background {
  position: absolute;
  top: 50%;
  transform: translateY(-10%);
  z-index: 0;
}

.pregnancy-form__header-woman {
  z-index: 1;
}
.pregnancy-settings__title-delete-form {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  margin-bottom: 24px;
  margin-top: 32px;
}
.pregnancy-settings-btn {
  margin-bottom: 16px;
}

.pregnancy-settings__error-block {
  color: #062e4f;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #ff0000;
  text-align: center;
}
</style>
