<template>
  <div class="password-input">
    <input
        ref="input"
        v-model="model"
        :maxlength="digitCount"
        type="text"
        aria-describedby="password-description"
        autocomplete="one-time-code"
        inputmode="numeric"
        pattern="[0-9]*"
        :autofocus="isAutofocus"
    />
    <div class="square-container" aria-hidden="true" role="presentation">
      <div
          v-for="(digit, index) in digitCount"
          :key="digit"
          class="square"
          :class="{filled: squareIsFocused(index), flicker: squareIsFlicker(index), competed: squareIsCompeted(index)}"
      >
        {{ getCurrentValue(index) }}
      </div>
    </div>
  </div>
  <p id="password-description" class="sr-only">Пожалуйста введите телефон или код</p>
</template>

<script>

import { computed, onMounted, ref, toRefs, watch } from 'vue'

export default {
  emits: ['isCompleted', 'update:modelValue'],
  name: 'OTPSingleInput',
  props: {
    digitCount: {
      type: Number,
      required: false,
      default: 4
    },
    modelValue: {
      type: String,
      required: false,
      default: null
    },
    isAutofocus: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  setup (props, { emit }) {
    const { modelValue, digitCount, isAutofocus } = toRefs(props)
    const input = ref(null)
    const model = computed({
      get () {
        return modelValue.value
      },
      set (value) {
        emit('update:modelValue', value)
      }
    })

    const modelToArray = computed(() => {
      return modelValue.value.split('')
    })

    const getCurrentValue = (index) => {
      return modelToArray.value[index] ?? ''
    }

    const squareIsFocused = (index) => {
      if (index === 0) return true
      return !!modelToArray.value[index - 1] ?? false
    }

    const squareIsFlicker = (index) => {
      return modelToArray.value.length === index
    }

    const squareIsCompeted = (index) => {
      return modelValue.value.length === digitCount.value && index + 1 === digitCount.value
    }

    watch(modelValue, () => {
      if (!modelValue.value) return

      if (modelValue.value.length === digitCount.value) {
        emit('isCompleted')
      }
    })

    onMounted(() => {
      if (isAutofocus.value) {
        input.value.focus()
      }
    })

    return {
      model,
      modelToArray,
      getCurrentValue,
      squareIsFocused,
      squareIsFlicker,
      squareIsCompeted,
      input
    }
  }
}
</script>
<style scoped lang="scss">
.password-input {
  position: relative;
  display: flex;
  align-items: center;
}

.password-input input {
  opacity: 0;
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
}

.square-container {
  display: flex;
}

.square {
  margin: 0 1px;
  padding: 0;
  width: 24px;
  height: 32px;
  border: 1px solid #BAC7DE;
  border-radius: 8px;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;
}

.password-input input:focus ~ .square-container .square.flicker {
  animation: flickerAnimation 1.6s infinite;
}

.square.filled {
  border: 1px solid #002856;
}

.password-input input:focus ~ .square-container .square.competed {
  outline: 2px solid #BAC7DE;
}

@keyframes flickerAnimation {
  0% {
    border: 1px solid #002856;
  }
  50% {
    border: 1px solid #BAC7DE;
  }
  100% {
    border: 1px solid #002856;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

</style>
