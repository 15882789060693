<template>
    <div class="swiper-wrapper">
      <Swiper v-bind="swiperOptions" class="swiper-wrapper__weeks-swiper-wrapper">
        <swiper-slide
          :class="{'swiper-slide-active': selectedWeek === week}"
          v-for="week in Array.from({ length: 40 }, (_, i) => i + 1)"
          :key="week"
        >
          <div
            class="swiper-wrapper__weeks-swiper-item"
            :class="{
              'swiper-wrapper__weeks-swiper-item--next': currentWeek < week,
              'swiper-wrapper__weeks-swiper-item--curr': currentWeek === week,
              'swiper-wrapper__weeks-swiper-item--selected':
              selectedWeek === week,
              'swiper-wrapper__weeks-swiper-item--prev': currentWeek > week
            }"
            @click.stop="$emit('selectWeek', week)"
          >
            <span class="swiper-wrapper__weeks-swiper-item-header">{{ week }}</span
            ><span class="swiper-wrapper__weeks-swiper-item-text"></span>НЕД
          </div>
        </swiper-slide>
      </Swiper>
    </div>
</template>

<script>

import { auto } from '@popperjs/core'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { ref, toRefs } from 'vue'

export default {
  name: 'PregnancyWeeksSwiper',
  props: {
    selectedWeek: Number,
    currentWeek: Number
  },
  emits: ['selectWeek'],
  components: { Swiper, SwiperSlide },
  setup (props) {
    const { currentWeek } = toRefs(props)

    const swiperOptions = ref({
      initialSlide: currentWeek.value - 1,
      spaceBetween: 14,
      slidesPerView: auto
    })
    return {
      swiperOptions
    }
  }
}
</script>

<style scoped lang="scss">

.swiper-wrapper {
  margin-bottom: 24px;

  &__weeks-swiper-item-header {
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
  }
  &__weeks-swiper-item-text {
    font-size: 10px;
    font-weight: 400;
    line-height: 10px;
    text-align: center;
  }
  &__weeks-swiper-item {
    cursor: pointer;
    width: 56px;
    height: 56px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    &--next {
      background-color: transparent;
      border: 1px solid #bac7de;
      color: #bac7de;
    }
    &--curr {
      background-color: #e1e8f4;
      border: 1px solid #bac7de;
      color: #002856;
    }
    &--prev {
      background-color: transparent;
      border: 1px solid #002856;
      color: #002856;
    }
  }
}
.swiper-wrapper__weeks-swiper-item--selected {
      border: 1px solid #f1842c;
}

.swiper-slide {
  width: fit-content;
}

</style>
