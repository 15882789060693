<template>
  <div class="files-empty">
    <div class="files-empty__picture">
      <img
          src="@/assets/uploads/images/files/women-with-file.svg"
          alt="картинка женщины с файлами"
      />
    </div>
    <p class="files-empty__text">
      В этом разделе вы можете загрузить файлы для последующей отправки лечащему
      врачу
    </p>

    <CustomButton tag="button" type="button" @click="$emit('triggerAddFile')">
      Добавить файлы
    </CustomButton>
  </div>
</template>

<script>
import CustomButton from '@/components/ui/buttons/CustomButton.vue'

export default {
  name: 'FilesEmptyPreview',
  components: { CustomButton },
  emits: ['triggerAddFile']
}
</script>

<style scoped lang="scss">
.files-empty {
  padding-top: 80px;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.files-empty__picture {
  margin-bottom: 16px;
}

.files-empty__text {
  margin-bottom: 65px;
  color: $blue;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
}
</style>
