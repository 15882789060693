<template>
  <div class="banners-wrapper">
    <Swiper v-bind="swiperOptions">
      <swiper-slide v-for="banner in banners" :key="banner.title">
        <BannerItem
          @click="handleBannerClick(banner.router_url, banner.external_url)"
          :title="banner.title"
          :subtitle="banner.preview"
          :color="banner.preset"
          :img="banner.image_url"
          :action="banner.external_url"
          :type="banner.type"
        />
      </swiper-slide>
      <div class="swiper-banner__pagination"></div>
    </Swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { ref } from 'vue'
import BannerItem from '@/components/pages/home/BannerItem.vue'
import { Pagination } from 'swiper'
import router from '@/router'

export default {
  name: 'BannerSection',
  props: {
    banners: [Object]
  },
  components: { Swiper, SwiperSlide, BannerItem },
  setup () {
    const handleBannerClick = (routerLink, externalLink) => {
      if (!routerLink && !externalLink) return
      if (routerLink) {
        router.push({ name: routerLink })
      } else {
        window.location.href = externalLink
      }
    }

    const swiperOptions = ref({
      initialSlide: 0,
      spaceBetween: 20,
      pagination: {
        el: '.swiper-banner__pagination',
        clickable: true
      },
      breakpoints: {
        0: {
          slidesPerView: 1
        },
        768: {
          slidesPerView: 2
        },
        991: {
          slidesPerView: 1
        },
        1145: {
          slidesPerView: 2
        }
      },
      modules: [Pagination]
    })

    return {
      swiperOptions,
      handleBannerClick
    }
  }
}
</script>

<style scoped lang="scss">
.banners-wrapper {
 margin-bottom: 16px;
}
.swiper-banner__pagination {
  margin-top: 24px;
  text-align: center;
}

</style>

<style>
.swiper-banner__pagination .swiper-pagination-bullet {
    background-color: #00000000;
    border: 1px solid #FFFFFF;
    @media (min-width: 991px) {
      border: 1px solid #002856
    }
}
.swiper-banner__pagination .swiper-pagination-bullet-active {
    background-color: #FFFFFF;
    @media (min-width: 991px) {
      background-color: #002856
    }
}
</style>
