<template>
  <ArrowTitle :is-app="isApp" title="Мои записи"></ArrowTitle>
  <SectionWrapper stretched>
    <AppointmentList  v-if="user" :user="user"/>
  </SectionWrapper>
</template>

<script>

import ArrowTitle from '@/components/ui/typography/ArrowTitle.vue'
import SectionWrapper from '@/components/ui/SectionWrapper.vue'
import AppointmentList from '@/components/pages/home/AppointmentList.vue'

export default {
  name: 'CalendarView',
  components: { AppointmentList, SectionWrapper, ArrowTitle },
  props: {
    user: {
      type: [Object, null],
      required: true,
      default: null
    },
    isApp: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  setup () {
  }
}
</script>

<style scoped lang="scss">

</style>
