<template>
  <ArrowTitle :is-app="isApp" title="Акции"></ArrowTitle>
  <SectionWrapper stretched>
    <TheSearch placeholder="Поиск" @input="search" v-if="!loading"/>
    <div class="section__top" v-if="!loading">
      <!--      <TheFilters @filtersOpened="filtersActive" @clinicsChecked="clinicsCheckedList"></TheFilters>-->
    </div>
    <ul class="offers__list" v-if="!loading">
      <li v-for="offer in searchedOffersList" :key="offer.id">
        <router-link :to="'/offers/' + offer.alias" class="offer__link">
          <img :src="offer.preview_img" :alt="offer.title">
          <div class="offer__link-content">
            <div class="offer__link-title">
              {{ offer.title }}
            </div>
            <template v-for="clinic in offer.clinics" :key="clinic.id">
              <div class="offer__address" :style="{color: clinicsId.color}">
                <img src="@/assets/uploads/icon/common/pin.svg" alt="pin">
                {{ clinic.title }}
              </div>
            </template>
          </div>
        </router-link>
      </li>
    </ul>
    <TheLoader v-if="loading"> Загрузка акций</TheLoader>
  </SectionWrapper>
</template>
<script>
import ArrowTitle from '@/components/ui/typography/ArrowTitle.vue'
import SectionWrapper from '@/components/ui/SectionWrapper.vue'
import TheSearch from '@/components/Common/TheSearch.vue'
import { computed, inject, onMounted, ref } from 'vue'
import axios from 'axios'
import TheLoader from '@/components/ui/TheLoader.vue'

export default {
  name: 'OffersView',
  components: { TheLoader, TheSearch, SectionWrapper, ArrowTitle },
  data () {
    return {
      clinicsId: [
        {
          id: 3,
          variantColor: '#004F61'
        },
        {
          id: 1,
          variantColor: '#002856'
        }
      ]
    }
  },
  props: {
    user: {
      type: [Object, null],
      required: true,
      default: null
    },
    isApp: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  setup () {
    const offersData = ref(null)
    const loading = ref(false)
    const isTitle = ref(true)
    const checkedClinics = ref(null)
    const searchQuery = ref('')
    const toast = inject('toast')
    const filtersActive = (value) => {
      isTitle.value = !value
    }
    const offersList = computed(() => {
      if (offersData.value && offersData.value.length > 0) return offersData.value

      return null
    })

    const searchedOffersList = computed(() => {
      if (!searchQuery.value) {
        return offersList.value
      } else {
        return offersList.value.filter((item) => {
          return (
            item.title.toLowerCase().indexOf(searchQuery.value.toLowerCase()) !== -1
          )
        })
      }
    })
    const showErrorToaster = () => {
      toast.error('Функционал в разработке', {
        position: 'top-right'
      })
    }
    const clinicsCheckedList = (value) => {
      checkedClinics.value = value
    }
    const getUserOffers = async (id) => {
      try {
        loading.value = true
        const response = await axios.get('/v1/stock')

        if (response.data.status === 'ok') {
          return response.data.data
        } else {
          return null
        }
      } catch (err) {
        throw new Error()
      } finally {
        loading.value = false
      }
    }
    const search = (el) => {
      searchQuery.value = el.target.value
    }

    onMounted(async () => {
      offersData.value = await getUserOffers()
    })

    return {
      offersList,
      loading,
      isTitle,
      filtersActive,
      clinicsCheckedList,
      checkedClinics,
      showErrorToaster,
      search,
      searchedOffersList
    }
  }
}
</script>

<style scoped lang="scss">
.offer__title {
  color: #002856;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
}

.offer__link {
  display: flex;
  align-items: center;

  img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 4px;
    margin-right: 16px;
  }
}

.offer__link-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80px;
}

.offer__address {
  color: #004F61;
  font-size: 10px;
  font-weight: 400;
  line-height: 1;
  display: flex;
  align-items: center;
  img {
    width: 12px;
    height: 12px;
    margin-right: 8px;
  }
  @include desktop {
    font-size: 14px;
    img {
      width: 14px;
      height: 14px;
    }
  }
}

.offers__list {
  margin-top: 16px;

  li {
    border-radius: 6px;
    background: #FFF;
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
    padding: 8px 12px;

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
}

.section__top {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  column-gap: 56px;
}

.offer__link-title {
  color: #002856;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  @include desktop {
    font-size: 17px;
  }
}
</style>
