import { postLog } from '@/api/postLog'

export const getStatusBarHeight = () => {
  postLog({
    m: 'start 1'
  })
  return new Promise((resolve, reject) => {
    window.StatusBarHeight.getValue((value) => {
      postLog({
        m: 'start 2',
        v: value
      })
      resolve(value)
    },
    (error) => {
      postLog({
        m: 'start 3',
        v: error
      })
      reject(error)
    })
  })
    .then((height) => {
      postLog({
        m: 'start 4',
        v: height
      })
      return { statusBarHeight: height, error: null }
    })
    .catch((error) => {
      postLog({
        m: 'start 5',
        v: error
      })
      return { statusBarHeight: null, error: error }
    })
}
