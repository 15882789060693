<template>
  <div class="otp-form">
    <h4 v-if="title" class="label">
      {{ title }}
    </h4>
    <div class="wrapper">
      <p class="default-num" v-if="preCode">
        {{ preCode }}
      </p>
      <OtpPinSingleInput
        v-model="bindModal"
        :digit-count="digitCount"
        @isCompleted="$emit('isCompleted')"
        :isAutofocus="isAutofocus"
        :error="error"
      />
    </div>
  </div>
</template>

<script>
import { computed, toRefs } from 'vue'
import OtpPinSingleInput from '@/components/pages/auth/OtpPinSingleInput.vue'

export default {
  name: 'OtpForm',
  components: {
    OtpPinSingleInput
  },
  emits: ['update:modelValue', 'otpCompleted', 'isCompleted'],
  props: {
    modelValue: {
      type: String,
      required: true,
      default: ''
    },
    preCode: {
      type: String,
      required: false,
      default: ''
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    error: Boolean,
    digitCount: {
      type: Number,
      required: true
    },
    isAutofocus: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  setup (props, { emit }) {
    const { modelValue } = toRefs(props)

    const bindModal = computed({
      get () {
        return modelValue.value
      },
      set (value) {
        emit('update:modelValue', value)
      }
    })

    return {
      bindModal
    }
  }
}
</script>

<style>
.otp-input {
  margin: 0 1px;
  padding: 0;
  width: 24px;
  height: 32px;
  border: 1px solid #bac7de;
  border-radius: 8px;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;
}

/* Background colour of an input field with value */
.otp-input.is-complete {
  /*background-color: #BAC7DE;*/
}

.otp-input:focus {
  outline: 1.5px solid #bac7de;
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.otp-input::placeholder {
  font-size: 15px;
  text-align: center;
  font-weight: 600;
}
</style>

<style scoped lang="scss">
.label {
  color: #FFFFFF;
  font-weight: 400;
  line-height: 100%;
  margin-bottom: 5px;
  text-align: center;
  &.error {
    color: $red;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 28px;
  justify-content: center;
}

.default-num {
  font-size: 20px;
  margin-right: 5px;
  margin-top: 3px;
}
</style>
