<template>
  <li v-if="program" class="the-program__item">
    <component
      :is="currentComponent"
      :href="currentComponent === 'a' ? program.url : ''"
      :target="currentComponent === 'a' ? '_blank' : ''"
      :to="currentComponent !== 'a' && program.id ? `/services/${program.id}/program` : ''"
    >
      <div class="the-program__top">
        <div class="the-program__left-col">
          <h4 class="the-program__title" v-if="program.title">
            {{ program.title }}
          </h4>
          <div class="the-program__info" v-if="program.end_at">
            <img
              src="@/assets/uploads/icon/profile/calendar.svg"
              alt="иконка календаря"
              class="the-program__calendar"
            />
            <p class="the-program__date-to">Действует до:</p>
            <p class="the-program__date">
              {{ program.end_at }}
            </p>
          </div>
        </div>
        <div class="the-program__right-col">
          <IconInCircle
            bg-color="#E1E8F4"
            width="64"
            height="64"
            v-if="program.icon_code && icon"
          >
            <img
              :src="require(`@/assets/uploads/sprite/${icon}.svg`)"
              alt="иконка"
            />
          </IconInCircle>
          <IconInCircle
            bg-color="#E1E8F4"
            width="64"
            height="64"
            v-if="program.icon"
          >
            <img
              :src="require(`@/assets/uploads/sprite/${program.icon}.svg`)"
              alt="иконка"
            />
          </IconInCircle>
        </div>
      </div>
      <div class="the-program__preview" v-if="program.preview">
        {{ program.preview }}
      </div>
    </component>
  </li>

  <li v-else class="the-program__item">
    <div class="the-program__top">
      <div class="the-program__left-col">
        <h4 class="the-program__title" v-if="program.title">
          {{ program.title }}
        </h4>
        <div class="the-program__info" v-if="program.end_at">
          <img
            src="@/assets/uploads/icon/profile/calendar.svg"
            alt="иконка календаря"
            class="the-program__calendar"
          />
          <p class="the-program__date-to">Действует до:</p>
          <p class="the-program__date">
            {{ program.end_at }}
          </p>
        </div>
      </div>
      <div class="the-program__right-col">
        <IconInCircle
          bg-color="#E1E8F4"
          width="64"
          height="64"
          v-if="program.icon_code && icon"
        >
          <img
            :src="require(`@/assets/uploads/sprite/${icon}.svg`)"
            alt="иконка"
          />
        </IconInCircle>
        <IconInCircle
          bg-color="#E1E8F4"
          width="64"
          height="64"
          v-if="program.icon"
        >
          <img
            :src="require(`@/assets/uploads/sprite/${program.icon}.svg`)"
            alt="иконка"
          />
        </IconInCircle>
      </div>
    </div>
    <div class="the-program__preview" v-if="program.preview">
      {{ program.preview }}
    </div>
  </li>
</template>

<script>
import { computed, toRefs } from 'vue'
import IconInCircle from '@/components/ui/IconInCircle.vue'
import { useTemplateVisibleAll } from '@/composables/useTemplateVisibleAll'

export default {
  name: 'TheProgram',
  props: {
    program: {
      type: [Object, null],
      required: true,
      default: null
    }
  },
  components: { IconInCircle },
  setup (props) {
    const { templateVisible } = useTemplateVisibleAll('is_local_program')
    const { program } = toRefs(props)
    const icon = computed(() => {
      if (program.value.icon_code) {
        return program.value.icon_code.substr(5)
      }
      return null
    })

    const currentComponent = computed(() => {
      if (!program.value) return 'a'

      if (program.value.type === 'ext') {
        return 'a'
      }

      return templateVisible.value ? 'router-link' : 'a'
    })

    return {
      icon,
      currentComponent
    }
  }
}
</script>

<style scoped lang="scss">
.the-program {
  & .the-program__right-col img {
    width: 70%;
    height: 70%;
  }

  &__item {
    padding: 15px;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
    list-style: none;
  }

  &__top {
    display: flex;
    gap: 8px;
    justify-content: space-between;
    align-items: center;
    @include desktop {
      align-items: flex-start;
    }
  }

  &__left-col {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__right-col {
    img {
      width: 65%;
      height: 65%;
    }
  }

  &__title {
    color: $blue;
    font-size: 18px;
    line-height: 20px; /* 111.111% */
  }

  &__info {
    display: flex;
    gap: 5px;
    align-items: center;
  }

  &__calendar {
  }

  &__date-to {
    color: $blue;
  }

  &__date {
    color: rgba(0, 40, 86, 0.5);
  }

  &__right-col {
  }
}

.the-program__preview {
  color: rgba(0, 40, 86, 0.5);
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 8px;
}
</style>
