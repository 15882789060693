<template>
  <ul class="toggle-tab">
    <li
      v-for="(radioInfo, index) in data"
      :key="index"
      class="toggle-tab__item"
    >
      <div class="toggle-tab__wrapper">
        <input
          type="radio"
          class="toggle-tab__input"
          :id="radioInfo.label"
          name="radioSwitch"
          :checked="radioInfo.value === isModel"
          :value="radioInfo.value"
          v-model="isModel"
        />
        <label
          :for="radioInfo.label"
          class="toggle-tab__label"
          :class="{ active: radioInfo.value === isModel, 'view-tab': viewTab }"
        >
          {{ radioInfo.label }}
        </label>
      </div>
    </li>
  </ul>
</template>

<script>
import { computed, toRefs } from 'vue'

export default {
  name: 'ToggleTab',
  emits: ['update:modelValue'],
  props: {
    data: {
      type: Array,
      default: () => [],
      required: true
    },
    modelValue: {
      type: [Number, String],
      default: 0
    },
    rounded: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: String,
      required: false,
      default: '100%'
    },
    textTransform: {
      type: String,
      required: false,
      default: 'uppercase'
    },
    viewTab: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  setup (props, { emit }) {
    const { modelValue } = toRefs(props)

    const isModel = computed({
      get () {
        return modelValue.value
      },
      set (value) {
        emit('update:modelValue', value)
      }
    })

    return {
      isModel
    }
  }
}
</script>

<style scoped lang="scss">
.toggle-tab {
  width: 100%;
  display: flex;
  align-items: end;
  gap: 6px;
  margin-bottom: 15px;
  font-size: 12px;

  &__item {
    flex: 1;

    @include phone-xs {
      max-width: v-bind("maxWidth");
    }
  }

  &__input {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
  }

  &__wrapper {
  }

  &__label {
    display: flex;
    text-transform: v-bind("textTransform");
    border-radius: 30px;
    justify-content: center;
    transition: all 0.2s ease-in-out;
    text-align: center;
    color: $blue;
    padding: 11px;
    background: $blue-3;
    border: unset;

    &.active {
      border: unset;
      color: white;
      background: $blue;
    }
  }

  &__label.view-tab {
    background: transparent;
    border-radius: 0;
    padding-bottom: 5px;
    border-bottom: 2px solid #bac7de;
    color: #bac7de;

    &.active {
      color: #002856;
      border-bottom: 2px solid #002856;
    }

    @include phone-xs {
      color: $blue;
      padding: 11px;
      background: $blue-3;
      border-radius: 30px;
      border: unset;

      &.active {
        border: unset;
        color: white;
        background: $blue;
      }
    }
  }
}
</style>
