<template>
  <div class="arrow-title-simple container">
    <div>
      <a
        class="arrow-title-simple__back-btn"
        @click.prevent="$router.go(routerGo)"
      >
        <img src="@/assets/uploads/icon/common/arrow-back.svg" alt="стрелка" />
      </a>
      <h3>{{ title }}</h3>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    routerGo: {
      type: Number,
      required: false,
      default: -1
    }
  }
}
</script>

<style lang="scss" scoped>
.arrow-title-simple {
  padding-bottom: 16px;
  div {
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr;
    padding-top: 6px;
  }

  a {
    width: 15px;
  }

  h3 {
    color: #082b53;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }
}

.arrow-title-simple__back-btn {
  cursor: pointer;
}
</style>
