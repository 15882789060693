<template>
  <ArrowTitle :is-app="isApp" title="Календари"></ArrowTitle>
  <SectionWrapper stretched>
    <div class="calendar">
      <div class="calendar__text">
        <p>
          Уважаемый пациент, вы можете завести свои медицинские календари в
          личном кабинете К+31.
        </p>
        <p>Вам доступны календари для следующих задач:</p>
      </div>
      <div class="calendar__wrapper">
        <div class="calendar__title">Выбор календаря</div>
        <div class="calendar__filter" v-if="templateVisible">
          <ToggleTab
            class=""
            :data="filterCalendars"
            v-model="filterCalendarCurrent"
          />
        </div>
        <ul class="calendar__tabs">
          <template v-for="tab in filteredCalendar" :key="tab.to">
            <li v-if="tab.show">
              <router-link :to="tab.to">
                <div class="calendar__tabs-name">
                  <img
                    :src="
                      require(`@/assets/uploads/images/calendar/${tab.imgName}.svg`)
                    "
                    alt="иконка"
                  />
                  {{ tab.title }}
                </div>
                <div class="calendar__tabs-arrow">
                  <img
                    src="@/assets/uploads/icon/common/arrow-back.svg"
                    alt="стрелка"
                  />
                </div>
              </router-link>
            </li>
          </template>
        </ul>
      </div>
    </div>
  </SectionWrapper>
</template>

<script>
import ArrowTitle from '@/components/ui/typography/ArrowTitle.vue'
import SectionWrapper from '@/components/ui/SectionWrapper.vue'
import ToggleTab from '@/components/ui/form/ToggleTab.vue'
import { computed, ref } from 'vue'
import { useTemplateVisibleAll } from '@/composables/useTemplateVisibleAll'

export default {
  name: 'CalendarView',
  components: { SectionWrapper, ArrowTitle, ToggleTab },
  props: {
    isApp: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  setup () {
    const { templateVisible } = useTemplateVisibleAll('is_woman_calendar')
    const { templateVisible: templateVisibleCalendar } =
      useTemplateVisibleAll('is_ad_calendar')
    const { templateVisible: templateVisibleGlucoseCalendar } = useTemplateVisibleAll('is_sugar_calendar')
    const filterCalendars = [
      { value: 'all', label: 'Все календари' },
      { value: 'woman', label: 'Для женщин' }
    ]
    const filterCalendarCurrent = ref('all')
    const currentTab = ref('my-events')
    const calendarTabs = ref([
      {
        to: 'calendar-appointments',
        imgName: 'appointments',
        title: 'Мои приёмы',
        disabled: false,
        gender: 'all',
        show: true
      },
      {
        to: 'calendar-vaccination',
        imgName: 'vaccination',
        title: 'Вакцинация',
        disabled: false,
        gender: 'all',
        show: true
      },
      {
        to: 'calendar-medications',
        imgName: 'tablets-calendar',
        title: 'Прием лекарств',
        disabled: false,
        gender: 'all',
        show: true
      },
      {
        to: 'calendar-glucose',
        imgName: 'glucose',
        title: 'Календарь глюкозы',
        disabled: false,
        gender: 'all',
        show: computed(() => templateVisibleGlucoseCalendar.value)
      },
      {
        to: 'calendar-activity',
        imgName: 'activity',
        title: 'Моя активность',
        disabled: false,
        gender: 'all',
        show: true
      },
      {
        to: 'calendar-pressure',
        imgName: 'pressure',
        title: 'Измерение давления',
        disabled: false,
        gender: 'all',
        show: computed(() => templateVisibleCalendar.value)
      },
      {
        to: 'calendar-menstruation',
        imgName: 'menstruation-calendar',
        title: 'Женский календарь',
        disabled: false,
        gender: 'woman',
        show: computed(() => templateVisible.value)
      },
      {
        to: 'calendar-pregnancy',
        imgName: 'pregnancy',
        title: 'Беременность',
        disabled: false,
        gender: 'woman',
        show: true
      }
      // {
      //   to: 'calendar-national',
      //   imgName: 'vaccination',
      //   title: 'Национальный календарь профилактических прививок',
      //   disabled: false,
      //   show: true
      // }
    ])

    const filteredCalendar = computed(() => {
      if (filterCalendarCurrent.value === 'woman') {
        return calendarTabs.value.filter(
          (calendar) => calendar.gender === 'woman'
        )
      }
      return calendarTabs.value
    })

    return {
      templateVisible,
      currentTab,
      calendarTabs,
      filterCalendars,
      filterCalendarCurrent,
      filteredCalendar
    }
  }
}
</script>

<style scoped lang="scss">
.calendar__text {
  p {
    color: #002856;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.32px;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}

.calendar__title {
  margin-top: 12px;
  margin-bottom: 16px;
  color: #002856;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

.calendar__tabs {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
  padding: 16px 22px;
  @include phone-xs {
    box-shadow: none;
    padding: 0;
    border-radius: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 8px;
  }

  li {
    &:not(:last-child) {
      border-bottom: 1px solid #e1e8f4;
      padding-bottom: 24px;
      margin-bottom: 24px;
      @include phone-xs {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }

    a {
      color: #002856;
      font-size: 12px;
      font-weight: 700;
      line-height: 13px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include desktop {
        font-size: 16px;
      }
      @include phone-xs {
        padding: 16px 12px 24px;
        justify-content: center;
      }
    }

    @include phone-xs {
      border-radius: 8px;
      background: $white;
      box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
    }
  }
}

.calendar__tabs-name {
  display: flex;
  align-items: center;
  @include phone-xs {
    flex-direction: column;
  }

  img {
    margin-right: 12px;
    @include phone-xs {
      margin-right: 0;
      margin-bottom: 12px;
    }
  }
}

.calendar__tabs-arrow {
  transform: rotate(180deg);
  filter: invert(11%) sepia(35%) saturate(4709%) hue-rotate(200deg)
    brightness(91%) contrast(101%);
  @include phone-xs {
    display: none;
  }
}

.calendar__filter {
  white-space: nowrap;
  margin: 0 0 16px;
}
</style>
