<template>
  <div class="header-search" v-if="options && options.length !== 0">
    <VueMultiselect
      class="arrow"
      v-model="searchedDoctorModel"
      :options="options"
      :close-on-select="true"
      :clear-on-select="false"
      :placeholder="searchLabel"
      :label="label"
      selectLabel="Выбрать"
      deselectLabel="Удалить"
      @select="searchSelected"
      @open="$emit('openSelect')"
      @remove="removeSelect"
      openDirection="bottom"
    >
      <template v-slot:noResult>
        <span>По вашему запросу ничего не найдено...</span>
      </template>
    </VueMultiselect>
  </div>
</template>

<script>
import VueMultiselect from 'vue-multiselect'
import { computed, toRefs } from 'vue'

export default {
  emits: ['searchSelected', 'update:searchedDoctor', 'openSelect'],
  name: 'TheMultiselect',
  components: { VueMultiselect },
  props: {
    options: {
      type: [Array, null],
      required: true,
      default: null
    },
    searchLabel: {
      type: String,
      required: true,
      default: 'Найти врача'
    },
    label: {
      type: [String, null],
      required: true,
      default: 'name'
    },
    searchedDoctor: {
      type: [Object, null, String],
      required: true,
      default: null
    }
  },

  setup (props, { emit }) {
    const { searchedDoctor } = toRefs(props)
    const searchedDoctorModel = computed({
      get () {
        return searchedDoctor.value
      },

      set (value) {
        emit('update:searchedDoctor', value)
      }
    })

    const searchSelected = () => {
      emit('searchSelected', searchedDoctor.value?.val)
    }

    const removeSelect = () => {
      emit('searchSelected', null)
    }
    return {
      searchedDoctorModel,
      removeSelect,
      searchSelected
    }
  }
}
</script>

<style>
.header-search .multiselect {
  width: 100%;
  padding: 1px 0 1px 50px;
  background-color: #ffffff;
  border: 1px solid #bac7de;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-position: 10px center;
  background-image: url("~@/assets/uploads/images/header/search.svg");
  display: flex;
}

.header-search .multiselect__select {
  display: none;
}

.header-search .multiselect__placeholder {
  padding: 0 !important;
  margin: 0 !important;
  color: #062e4f;
  font-size: 14px;
  font-weight: 350;
  line-height: 100%;
}

.header-search .multiselect__input {
  padding: 0 !important;
  margin: 0 !important;
  color: #062e4f;
  font-size: 14px;
  font-weight: 350;
  line-height: 100%;
}

.header-search .multiselect__option--highlight {
  background: #bac7de;
  outline: none;
  color: #002856;
}

.header-search .multiselect__tags {
  padding-left: 0;
  padding-right: 0;
  border: 0;
  min-height: auto !important;
  padding-top: 0;
  display: flex;
  align-self: center;
}

.header-search .multiselect__content-wrapper {
  left: 0;
  transform: translateY(48px);
}

.header-search .multiselect__content {
  max-width: 100%;
}

.header-search .multiselect__option--highlight::after {
  background-color: #002856;
  color: white;
}

.header-search .multiselect__single {
  margin-bottom: 0;
  padding: 0;
}
</style>

<style scoped lang="scss">
.header-search {
  padding-right: 50px;
  margin-bottom: 20px;
}
</style>
