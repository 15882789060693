import { useUserStore } from '@/stores/UserStore'

export const showFingerPrint = (token) => {
  const userStore = useUserStore()
  const { setBiometry } = userStore

  window?.Fingerprint?.registerBiometricSecret(
    {
      description: 'Для работы с личным кабинетом надо пройти аутентификацию',
      secret: token,
      invalidateOnEnrollment: true,
      disableBackup: true // always disabled on Android
    },
    successCallback,
    errorCallback
  )

  function successCallback () {
    setBiometry(true)
    // alert('Authentication successful')
  }

  function errorCallback () {
    // alert('Authentication invalid ' + error.message)
  }
}
