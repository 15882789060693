<template>
  <div class="title" :class="{
    'title--no-margin': noMargin,
  }"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'TheTitle',
  props: {
    color: {
      type: String,
      default: '#002856',
      required: false
    },
    textAlign: {
      type: String,
      default: 'start',
      required: false
    },
    noMargin: {
      type: Boolean,
      default: false,
      required: false
    },
    whiteArrow: {
      type: Boolean,
      default: true,
      required: false
    },
    fontSize: {
      type: String,
      default: '18px',
      required: false
    },
    lineHeight: {
      type: String,
      default: '24px',
      required: false
    },
    fontWeight: {
      type: Number,
      required: false,
      default: 700
    },
    mb: {
      type: String,
      default: '8px',
      required: false
    }
  }
}
</script>

<style lang="scss">
.title {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  margin-bottom: v-bind('mb');
  gap: 10px;

  & h1, h2, h3, h4, h5, h6 {
    color: v-bind('color');
    justify-self: v-bind('textAlign');
    @include desktop {
      color: #002856;
    }
  }

  & h3, h3, h4, h5, h6 {
    font-weight: v-bind('fontWeight');
    font-size: v-bind('fontSize');
    line-height: v-bind('lineHeight');
    @include desktop {
      font-size: 22px;
    }
  }

  &--no-margin {
    margin-bottom: 0;
  }

  &__img {
    filter: invert(11%) sepia(31%) saturate(5093%) hue-rotate(198deg) brightness(102%) contrast(103%);

    &--white {
      filter: invert(97%) sepia(1%) saturate(501%) hue-rotate(197deg) brightness(116%) contrast(100%);
    }
  }
}
</style>
