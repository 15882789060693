
<script>
import { computed, inject, onActivated, reactive, ref, watchEffect } from 'vue'
import SectionWrapper from '@/components/ui/SectionWrapper.vue'
import ArrowTitle from '@/components/ui/typography/ArrowTitle.vue'
import TextAreaUi from '@/components/ui/form/TextAreaUi.vue'
import CustomButton from '@/components/ui/buttons/CustomButton.vue'
import { authApi } from '@/api/authApi'
import FormRadio from '@/components/ui/form/FormRadio.vue'
import TimePicker from '@/components/ui/form/TimePicker.vue'
import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'
import { useUserGlucoseStore } from '@/stores/UserGlucoseStore'
import { useRouter } from 'vue-router'
import TextBannerWarning from '@/components/Common/TextBannerWarning.vue'

export default {
  name: 'GlucoseSettingsModal',
  props: {
    isApp: {
      type: Boolean,
      required: true,
      default: false
    },
    user: {
      type: [Object, null],
      required: true,
      default: null
    }
  },
  components: {
    TimePicker,
    FormRadio,
    CustomButton,
    TextAreaUi,
    ArrowTitle,
    SectionWrapper,
    TextBannerWarning
  },
  setup (props, { emit }) {
    const router = useRouter()
    const userStore = useUserStore()
    const { getUserId } = storeToRefs(userStore)
    const userGlucoseStore = useUserGlucoseStore()
    const { glucoseSetup, currentGlucose, glucoseMeasureUnit, measureTypeOnWelcome } = storeToRefs(userGlucoseStore)
    const { setMeasureUnit } = userGlucoseStore
    const toast = inject('toast')
    const errorMsg = ref('')
    const isScheduled = ref('')

    const userInfo = reactive({
      listOfTime: [],
      comment: '',
      unitValue: null
    })

    const showHelloToaster = (body, title, type = 'success') => {
      toast.show(
        `
      ${body}
      ${title}
      `,
        {
          position: 'bottom',
          type
        }
      )
    }

    const formData = computed(() => {
      if (isScheduled.value) {
        return {
          time: userInfo.listOfTime,
          unit_id: userInfo.unitValue,
          hint: userInfo.comment
        }
      }
      return {
        time: [],
        unit_id: userInfo.unitValue,
        hint: null
      }
    })

    const sendSetup = async () => {
      if (!getUserId.value) return
      try {
        const [err, response] = await authApi(
          `/v6/events/diabet/setup/?user_id=${getUserId.value}`,
          'post',
          formData.value
        )
        if (err) {
          showHelloToaster('', err, 'error')
          return
        }

        return [err, response]
      } catch (err) {
        showHelloToaster('', err, 'error')
      }
    }

    const submitForm = async () => {
      try {
        const [err, response] = await sendSetup()
        if (err) {
          showHelloToaster('', err, 'error')
          return
        }
        if (response.status === 'ok') {
          setMeasureUnit(userInfo.unitValue)
          showHelloToaster('', 'Измерения успешно добавлены!')
          router.push('calendar-glucose')
        }
      } catch (err) {
        showHelloToaster('', err, 'error')
      } finally {
        userInfo.listOfTime = []
        userInfo.comment = ''
      }
    }

    onActivated(() => {
      if (measureTypeOnWelcome.value !== null) {
        isScheduled.value = measureTypeOnWelcome.value
      }
    })
    watchEffect(() => {
      userInfo.unitValue = glucoseMeasureUnit.value.id
    })

    return {
      errorMsg,
      userInfo,
      submitForm,
      isScheduled,
      showHelloToaster,
      currentGlucose,
      glucoseSetup,
      glucoseMeasureUnit
    }
  }
}
</script>

<template>
  <ArrowTitle title="Календарь глюкозы" :is-app="isApp" />
  <SectionWrapper stretched>

    <TextBannerWarning canClose text="Если вам не хочется больше получать уведомления о предстоящих измерениях - смените режим на «произвольный»." />

    <div class="settings__content">
        <div class="arbitrary-form">
            <h4 class="arbitrary-form__subtitle">Способ измерения</h4>
            <div class="arbitrary-form__wrapper">
              <FormRadio
                color="#002856"
                label="по графику"
                value="1"
                v-model="isScheduled"
              />
              <FormRadio
                color="#002856"
                label="произвольно"
                value=""
                v-model="isScheduled"
              />
            </div>
          <form>
            <h4 class="arbitarary-form__measure-value-form__title">
              Единицы измерения:
            </h4>
            <div class="arbitarary-form__measure-value-form__wrapper">
              <FormRadio
                class="arbitarary-form__measure-value-form__wrapper-item"
                color="#002856"
                label="мг/дл"
                :value="1"
                v-model="userInfo.unitValue"
              />
              <FormRadio
                class="arbitarary-form__measure-value-form__wrapper-item"
                color="#002856"
                label="ммоль/л"
                :value="2"
                v-model="userInfo.unitValue"
              />
            </div>
          </form>
          <form
            @submit.prevent="submitForm"
            @keyup.enter.prevent="submitForm"
            class="arbitrary-form__form"
          >
            <div v-if="isScheduled" class="arbitrary-form__chart">
              <h4 class="arbitrary-form__title">Составьте график измерений</h4>

              <p class="arbitrary-form__text">
                Вы будете получать напоминания в выбранные часы
              </p>

              <div class="arbitrary-form__time-picker">
                <TimePicker
                  title="Часы измерения"
                  :listOfTime="userInfo.listOfTime"
                  @change-time="userInfo.listOfTime = $event"
                  :payLoadChangeCourse="null"
                />
              </div>

              <TextAreaUi
                class="arbitrary-form__comment"
                v-model="userInfo.comment"
                name-id="arbitrary-comment"
                label-title="Комментарий (необязательное поле)"
                placeholder="Например, о самочувствии"
              />
            </div>

            <custom-button tag="button" type="submit" marginBottom="0">
              сохранить
            </custom-button>
          </form>
        </div>
      </div>
  </SectionWrapper>
</template>

<style scoped lang="scss">
.settings {
  width: 100%;
  background: #fff;
  border-radius: 8px 8px 0 0;
  padding: 12px 0;
}

.settings__title {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  margin-bottom: v-bind('mb');

  h3 {
    color: #082b53;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }

  img {
    filter: invert(11%) sepia(31%) saturate(5093%) hue-rotate(198deg)
      brightness(102%) contrast(103%);
  }
}

.settings__content {
  text-align: center;

  img {
    margin-bottom: 32px;
  }

  a {
    color: #062e4f;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 120%;
    text-transform: uppercase;
    border-radius: 30px;
    background: var(--blue-1, #bac7de);
    padding: 11px;
    width: 100%;
    border: none;
    margin-top: 16px;
  }
}
.arbitrary-form {
}

.arbitrary-form__form {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.arbitrary-form__chart {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.arbitrary-form__title {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: #002856;
}

.arbitrary-form__text {
  text-align: center;
}

.arbitrary-form__dates {
  display: flex;
  gap: 8px;
}

.arbitrary-form__arbitrary {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.arbitrary-form__date-block {
}

.arbitrary-form__label {
}

.text-input {
}

.login {
}

.vaccination-add-date {
}

.error {
}

.help-block {
}

.arbitrary-form__comment {
}

.arbitrary-form__subtitle {
  margin-bottom: 16px;
  text-align: start;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #002856;
}

.arbitrary-form__wrapper {
  display: flex;
  gap: 24px;
  margin-bottom: 16px;
}

.arbitarary-form__measure-value-form__title {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  color: #002856;
}
.arbitarary-form__measure-value-form__wrapper {
  margin-top: 16px;
  gap: 24px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  margin-bottom: 24px;
  &:last-child {
    margin-left: auto;
  }
}
</style>

<style lang="scss">
.arbitrary-form__time-picker {
  h5 {
    text-align: left;
  }
}
.time-picker__label {
  text-align: left;
}
</style>
