<template>
  <ArrowTitle title="Мои файлы" :is-app="isApp"></ArrowTitle>
  <SectionWrapper stretched>
    <ModalDoctorSend
      title="Отправить файл доктору"
      :doctorsList="doctorsList"
      :modal-is-active="openFileModal"
      :file-data="selectedItems"
      @modalClose="closeModal"
      @deleteFile="removeFileSending"
    />
    <TheLoader v-if="loading"> Загрузка</TheLoader>

    <ErrorText v-if="errorMessage" :error="errorMessage" />
    <FilesEmptyPreview
      v-if="!loading && fileData && fileData.files.length === 0"
      @triggerAddFile="triggerAddFile"
    />

    <div v-show="filesList && filesList.length !== 0 && !loading">
      <div class="files__choice-buttons" v-if="isChoice && btnsActive">
        <img
          class="files__back"
          @click.capture="goBackToFile"
          src="@/assets/uploads/icon/common/arrow-back.svg"
          alt="стрелка"
        />
        <a class="files__btn" @click.stop.prevent="openModal"
          >Отправить врачу</a
        >
        <div
          class="files__btn delete-btn"
          data-bs-toggle="modal"
          data-bs-target="#delete-file"
        >
          <img src="@/assets/uploads/icon/common/delete.svg" alt="удаление" />
          Удалить
        </div>
      </div>
      <p class="files__choice-text" v-if="isChoice && !btnsActive">
        Выберите файлы
      </p>
      <div class="files__buttons" v-if="!isChoice">
        <div class="files__loader">
          <input
            type="file"
            accept="image/*,application/pdf"
            ref="input"
            multiple
            @change="loadFile($event.target.files)"
          />
          <p>
            <img
              src="@/assets/uploads/icon/common/plus-file.svg"
              alt="иконка"
            />
            Добавить
          </p>
        </div>
        <div class="files__choice" @click="isChoice = !isChoice">Выбрать</div>
      </div>

      <div class="files__grid" v-if="!loading">
        <template v-for="(item, index) in filesList" :key="index">
          <FileItem
            :file="item"
            :isChoice="isChoice"
            :isActive="item.active"
            @click="toggleItem(index)"
          />
        </template>
      </div>
    </div>
    <ModalBsWrapper
      id="delete-file"
      title="Вы действительно хотите удалить файлы?"
    >
      <p class="files__choice-text">Восстановить их будет невозможно</p>

      <div class="files__delete-btn">
        <a
          class="files__btn"
          data-bs-dismiss="modal"
          aria-label="Close"
          @click.prevent="deleteFile"
        >
          Да
        </a>

        <a class="files__btn" data-bs-dismiss="modal" aria-label="Close">
          Нет
        </a>
      </div>
    </ModalBsWrapper>
  </SectionWrapper>
</template>

<script>
import ArrowTitle from '@/components/ui/typography/ArrowTitle.vue'
import SectionWrapper from '@/components/ui/SectionWrapper.vue'
import FileItem from '@/components/pages/files/FileItem.vue'
import { computed, ref, watch } from 'vue'
import TheLoader from '@/components/ui/TheLoader.vue'
import axios from 'axios'
import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'
import FilesEmptyPreview from '@/components/pages/files/FilesEmptyPreview.vue'
import ModalDoctorSend from '@/components/pages/files/ModalDoctorSend.vue'
import ModalBsWrapper from '@/components/modals/ModalBsWrapper.vue'
import ErrorText from '@/components/ui/ErrorText.vue'

export default {
  name: 'FilesView',
  components: {
    ErrorText,
    ModalBsWrapper,
    ModalDoctorSend,
    FilesEmptyPreview,
    TheLoader,
    ArrowTitle,
    SectionWrapper,
    FileItem
  },
  props: {
    user: {
      type: [Object, null],
      required: true,
      default: null
    },
    isApp: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  setup () {
    const userStore = useUserStore()
    const { userToken, getUserId } = storeToRefs(userStore)
    const loading = ref(false)
    const openLoader = ref(false)
    const fileData = ref(null)
    const isChoice = ref(false)
    const openFileModal = ref(false)
    const input = ref(false)
    const error = ref(false)
    const errorMessage = ref('')

    const setFilesActiveToFalse = () => {
      fileData.value.files.forEach((item) => (item.active = false))
    }
    const goBackToFile = (event) => {
      if (isChoice.value) {
        closeModal()
        event.stopPropagation()
      }
    }
    const triggerAddFile = () => {
      input.value.dispatchEvent(new MouseEvent('click', { bubbles: true }))
    }
    const closeLoader = () => {
      openLoader.value = false
    }
    const closeModal = () => {
      setFilesActiveToFalse()
      openFileModal.value = false
      isChoice.value = false
    }
    const toggleItem = (index) => {
      if (!isChoice.value) return
      filesList.value[index].active = !filesList.value[index].active
    }

    const btnsActive = computed(() => {
      return filesList.value.find((file) => file.active)
    })

    const selectedItems = computed(() => {
      if (filesList.value) {
        return filesList.value.filter((item) => item.active === true)
      } else {
        return null
      }
    })

    const deleteFile = async () => {
      try {
        isChoice.value = false
        const activeItems = filesList.value.filter(
          (item) => item.active === true
        )
        const ids = activeItems.map((item) => item.id)
        loading.value = true

        const response = await axios.delete('v1/user-file/delete', {
          params: {
            user_id: getUserId.value,
            file_id: ids
          },
          headers: {
            Authorization: `Bearer ${userToken.value}`
          }
        })

        if (response.data.status === 'ok') {
          fileData.value = await getUserFiles(getUserId.value)
        } else {
          return null
        }
      } catch (err) {
        const errMsg = err?.response?.data?.message
        errorMessage.value = `Произошла ошибка ${errMsg ?? err.message}`
      } finally {
        loading.value = false
      }
    }

    const removeFileSending = (id) => {
      filesList.value.find((file) => file.id === id).active = false
    }
    const openModal = () => {
      if (selectedItems.value.length > 0) {
        openFileModal.value = true
      } else {
        errorMessage.value = 'Выберите файлы, которые хотите отправить'
      }
    }
    const getUserFiles = async (id) => {
      try {
        loading.value = true

        const response = await axios.get(`/v1/user-file?user_id=${id}`, {
          headers: {
            Authorization: `Bearer ${userToken.value}`
          }
        })

        if (response.data.status === 'ok') {
          errorMessage.value = ''
          return response.data.data
        } else {
          errorMessage.value = response.data.message
          return null
        }
      } catch (err) {
        const errMsg = err?.response?.data?.message
        errorMessage.value = `Произошла ошибка ${errMsg ?? err.message}`
      } finally {
        loading.value = false
      }
    }
    const filesList = computed(() => {
      if (fileData.value) {
        return fileData.value.files
      }

      return null
    })

    const doctorsList = computed(() => {
      if (fileData.value) {
        return fileData.value.doctors
      }

      return null
    })
    const loadFile = async (f) => {
      const files = Array.from(f)

      if (files.length === 0) {
        return
      }

      const formData = new FormData()
      files.forEach((file) => {
        if (!(file instanceof File)) {
          return
        }
        formData.append('fileInput[]', file, file.name)
      })

      error.value = false
      loading.value = true
      try {
        const postFile = await axios.post(
          `v2/user-file/create?user_id=${getUserId.value}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${userToken.value}`,
              'Content-Type': 'multipart/form-data'
            }
          }
        )

        if (postFile.data.status === 'ok') {
          fileData.value = await getUserFiles(getUserId.value)
          errorMessage.value = ''
        } else {
          errorMessage.value = postFile.data.message
          error.value = true
        }
      } catch (err) {
        const errMsg = err?.response?.data?.message
        errorMessage.value = `Произошла ошибка ${errMsg ?? err.message}`
      } finally {
        loading.value = false
      }
    }
    watch(
      () => getUserId.value,
      async () => {
        if (!getUserId.value) return
        fileData.value = await getUserFiles(getUserId.value)
        if (fileData.value?.files) {
          fileData.value.files.forEach((item) => {
            item.active = false
          })
        }
      },
      {
        immediate: true
      }
    )
    return {
      filesList,
      fileData,
      loading,
      openLoader,
      closeLoader,
      closeModal,
      deleteFile,
      getUserFiles,
      doctorsList,
      openModal,
      openFileModal,
      loadFile,
      input,
      toggleItem,
      isChoice,
      selectedItems,
      errorMessage,
      triggerAddFile,
      btnsActive,
      goBackToFile,
      removeFileSending,
      getUserId
    }
  }
}
</script>

<style scoped lang="scss">
.error-message {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 10px;
}

.delete-btn {
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: center;
}

.files__back {
  cursor: pointer;
}

.files__choice-buttons {
  display: grid;
  align-items: center;
  grid-template-columns: 30px 1fr 1fr;
  gap: 8px;
  margin-bottom: 8px;
}

.files__btn {
  color: #002856;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-transform: uppercase;
  border-radius: 30px;
  border: 1px solid #002856;
  padding: 6px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:active {
    background: #002856;
    color: #fff;
  }

  &:hover {
    background: #002856;
    color: #fff;
  }
}

.delete-btn {
  &:active {
    background: #e1e8f4;
    color: #002856;
  }

  &:hover {
    background: #e1e8f4;
    color: #002856;
  }
}

.files__grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, 108px);
  gap: 12px;
  @include desktop {
    grid-template-columns: repeat(auto-fit, 108px);
    gap: 16px;
  }
}

.file__img {
  border-radius: 6px;
  background: #e1e8f4;
  width: 108px;
  height: 108px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.files__buttons {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  margin-bottom: 8px;
}

.files__loader {
  position: relative;
  cursor: pointer;

  &:active p {
    background: #e1e8f4;
    text-decoration: none;
    color: #062e4f;
  }

  &:hover p {
    background: #e1e8f4;
    text-decoration: none;
    color: #062e4f;
  }

  input {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  p {
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
    color: #002856;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-transform: uppercase;
    border-radius: 30px;
    background: #bac7de;
    padding: 5px;
    transition: all 0.3s ease-in-out;
  }
}

.files__choice-text {
  margin-bottom: 8px;
  color: #002856;
  text-align: center;
  font-size: 14px;
}

.files__choice {
  color: #002856;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-transform: uppercase;
  border-radius: 30px;
  padding: 5px;
  border: 1px solid #002856;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:active {
    background: #002856;
    color: #fff;
  }

  &:hover {
    background: #002856;
    color: #fff;
  }
}

.files__delete-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  & a {
    padding: 6px 15px;
    display: block;
  }
}
</style>
