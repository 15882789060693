<template>
  <div class="vaccination__item">
    <h4 class="vaccination__item-type">
      {{ vaccine.title }}
    </h4>
    <p class="vaccination__item-count">
      Кратность: {{ vaccine.frequency || "Не указано" }}
    </p>

    <div class="vaccination__item-date">
      <img
        src="@/assets/uploads/icon/profile/calendar.svg"
        alt="иконка календаря"
      />
      <p>
        Дата:
        <span>{{ dateVaccine }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import { computed, toRefs } from 'vue'
import { useDateStore } from '@/stores/date'

export default {
  name: 'VaccinationItem',
  props: {
    vaccine: {
      type: Object,
      required: false,
      default: null
    }
  },
  setup (props) {
    const { vaccine } = toRefs(props)
    const dateStore = useDateStore()
    const dictMonthRod = dateStore.dictMonthRod

    const dateVaccine = computed(() => {
      if (!vaccine.value.date_at) return null

      const [year, month, day] = vaccine.value.date_at.split('-')
      const formatMonth = month.startsWith(0) ? month[1] : month

      return `${day} ${dictMonthRod[parseInt(formatMonth) - 1]} ${year} г.`
    })

    return {
      dateVaccine
    }
  }
}
</script>
<style scoped lang="scss">
.vaccination__item {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
  padding: 10px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: translateY(-2px);
  }
}

.vaccination__item-type {
  color: $blue;
  margin-bottom: 7px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

.vaccination__item-count {
  margin-bottom: 8px;
  color: $blue-light;
  font-weight: 400;
  line-height: 100%;
}

.vaccination__item-date {
  display: flex;
  gap: 8px;
  align-items: center;

  & p {
    color: $blue;

    & span {
      color: rgba(0, 40, 86, 0.5);
    }
  }
}
</style>
