<script>
import SectionWrapper from '@/components/ui/SectionWrapper.vue'
import ArrowTitle from '@/components/ui/typography/ArrowTitle.vue'
import TextBannerWarning from '../../../components/Common/TextBannerWarning.vue'
import IconInCircle from '@/components/ui/IconInCircle.vue'
export default {
  name: 'GlucoseMenuView',
  props: {
    isApp: {
      type: Boolean,
      required: true,
      default: false
    },
    user: {
      type: [Object, null],
      required: true,
      default: null
    }
  },
  components: {
    ArrowTitle,
    SectionWrapper,
    TextBannerWarning,
    IconInCircle
  },

  setup () {
    const TABS = [{
      to: 'calendar-glucose-measurment',
      imgName: 'blood-test',
      title: 'Глюкоза'
    },

    {
      to: 'calendar-glucose-meal',
      imgName: 'meal',
      title: 'Прием пищи'
    },
    {
      to: 'calendar-medications',
      imgName: 'tablets-icon',
      title: 'Прием лекарств'
    },
    {
      to: 'calendar-glucose-activity',
      imgName: 'activity-icon',
      title: 'Моя активность'
    }]

    return {
      TABS
    }
  }
}
</script>

<template>
  <ArrowTitle title="Календарь глюкозы" :is-app="isApp" />
  <SectionWrapper stretched>
    <TextBannerWarning
      text="В данном календаре вы можете вносить данные по измерениям глюкозы, свое питание, активность и прием лекарств. Таким образом формируя таймлайн дня" />
    <h5>Добавьте данные</h5>

    <ul class="calendar__tabs">
      <template v-for="tab in TABS" :key="tab.to">
        <li>
          <router-link :to="tab.to">
            <div class="calendar__tabs-name">
              <IconInCircle width="64" height="64" bgColor="#D1F9EF"><img :src="require(`@/assets/uploads/images/calendar/glucose/menu/${tab.imgName}.svg`)" alt="иконка" /></IconInCircle>
              <span>{{ tab.title }}</span>
            </div>
            <div class="calendar__tabs-arrow">
              <img src="@/assets/uploads/icon/common/arrow-back.svg" alt="стрелка" />
            </div>
          </router-link>
        </li>
      </template>
    </ul>
  </SectionWrapper>
</template>

<style scoped lang="scss">
h5 {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  margin-bottom: 12px;
  padding-top: 12px;
}

.calendar__tabs {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
  padding: 16px 22px;
  @include phone-xs {
    box-shadow: none;
    padding: 0;
    border-radius: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 8px;
  }

  li {
    &:not(:last-child) {
      border-bottom: 1px solid #e1e8f4;
      padding-bottom: 24px;
      margin-bottom: 24px;
      @include phone-xs {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }

    a {
      color: #002856;
      font-size: 12px;
      font-weight: 700;
      line-height: 13px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include desktop {
        font-size: 16px;
      }
      @include phone-xs {
        padding: 16px 12px 24px;
        justify-content: center;
      }
    }

    @include phone-xs {
      border-radius: 8px;
      background: $white;
      box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
    }
  }
}

.calendar__tabs-name {
  display: flex;
  align-items: center;
  gap: 16px;
  @include phone-xs {
    flex-direction: column;
  }

  span {
    margin-top: 12px;
  }
}

.calendar__tabs-arrow {
  transform: rotate(180deg);
  filter: invert(11%) sepia(35%) saturate(4709%) hue-rotate(200deg)
    brightness(91%) contrast(101%);
  @include phone-xs {
    display: none;
  }
}
</style>
