<template>
  <div class="chat-room__date">
    {{ formatDate(date) }}
  </div>
</template>

<script>
import { useDateStore } from '@/stores/date'
export default {
  props: {
    date: String
  },
  setup () {
    const { dictMonthRod } = useDateStore()
    const formatDate = (dateString) => {
      const [year, month, day] = dateString.split('-')
      return `${day} ${dictMonthRod[month - 1].toLowerCase()} ${year}`
    }
    return {
      formatDate
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-room__date {
  background-color: #bac7de;
  font-family: Circe;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  padding: 4px 8px 2px 8px;
  border-radius: 12px;
  align-self: center;
}
</style>
