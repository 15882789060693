<template>
  <div class="home-wrapper">
    <UpdateAppBanner
      class="container"
      v-if="bannerUpdateIsOpen"
      :bannerLink="bannerLink"
      @closeBanner="bannerUpdateIsOpen = false"
    />

    <ActualSection />

    <BannersSection :banners="bannersFiltered" class="container" />

    <PopularServicesSection
      :enabledServices="enabledServices"
      :areServicesLoading="areServicesLoading"
      :areSettingsOpen="areSettingsOpen"
      @openModal="isServicesModalOpen = true"
      @openSettings="areSettingsOpen = true"
      @closeSettings="closeSettings"
      @deleteOneService="deleteOneService"
      @saveSorting="saveSorting"
      class="container"
    />

    <ActivityBanner v-if="user && isActivityBannerAvailable"  class="container" />

    <PopularServicesModal
      :isServicesModalOpen="isServicesModalOpen"
      :allServices="allServices"
      :enabledServices="enabledServices"
      @updateServices="updateServices"
      @closeModal="closeModal"
    />

    <MyAppointmentSection :user="user" />

  </div>
</template>

<script>
import ActivityBanner from '@/components/pages/home/ActivityBanner.vue'
import PopularServicesSection from '@/components/pages/home/PopularServicesSection.vue'
import PopularServicesModal from '@/components/pages/home/PopularServicesModal.vue'
import MyAppointmentSection from '@/components/pages/home/MyAppointmentSection.vue'
import ActualSection from '@/components/pages/home/ActualSection.vue'
import BannersSection from '@/components/pages/home/BannersSection.vue'
import UpdateAppBanner from '@/components/pages/home/UpdateAppBanner.vue'
import { useUpdateBanner } from '@/composables/useUpdateBanner'
import { computed, onActivated, ref, watch, watchEffect } from 'vue'
import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'
import axios from 'axios'
import { useToast } from 'vue-toastification'
import CustomToast from '@/components/ui/CustomToast.vue'
import { checkIfAvailable, checkIfAuthorized } from '@/utilits/getHealth.js'

export default {
  name: 'HomeView',
  props: {
    user: {
      type: [Object, null],
      required: true,
      default: null
    },
    isApp: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  components: {
    UpdateAppBanner,
    ActualSection,
    MyAppointmentSection,
    PopularServicesSection,
    BannersSection,
    PopularServicesModal,
    ActivityBanner
  },
  setup () {
    const platform = window?.device?.platform.toLowerCase()
    const isServicesModalOpen = ref(false)
    const areSettingsOpen = ref(false)
    const banners = ref(null)
    const allServices = ref(null)
    const enabledServices = ref(null)
    const areServicesLoading = ref(true)
    const { bannerLink } = useUpdateBanner()
    const bannerUpdateIsOpen = ref(null)
    const userStore = useUserStore()
    const { hasPinCode, userToken, getUserId, hasHealth, isActivityBannerAvailable } = storeToRefs(userStore)
    const toast = useToast()

    const bannersFiltered = computed(() => {
      if (banners.value && banners.value.some(b => b.type === 'version')) {
        return banners.value.filter(b => b.type !== 'version')
      } else {
        return banners.value
      }
    })

    const toastOptions = {
      position: 'bottom-center',
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 0.38,
      timeout: 2000,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: 'button',
      icon: false,
      rtl: false,
      toastClassName: 'custom-toast'
    }

    const appVersion = computed(() => {
      if (platform === 'ios') {
        return process.env.VUE_APP_IOS_VERSION_RAW
      } else if (platform === 'android') {
        return process.env.VUE_APP_ANDROID_VERSION_RAW
      } else {
        return null
      }
    })

    const getBanners = async () => {
      const params = new URLSearchParams({
        user_id: getUserId.value,
        device_uuid: window?.device?.uuid,
        v: appVersion.value,
        device: platform
      }).toString()
      try {
        const response = await axios.get(`/v1/info/banner/?${params}`, {
          headers: {
            Authorization: `Bearer ${userToken.value}`
          }
        })
        if (response.data.success === 'ok') {
          banners.value = response.data.data
        } else {
          banners.value = null
        }
      } catch (err) {
        throw new Error()
      }
    }

    const getServices = async () => {
      const param = new URLSearchParams({
        user_id: getUserId.value
      }).toString()
      areServicesLoading.value = true
      try {
        const response = await axios.get(`/v6/popular/?${param}`, {
          headers: {
            Authorization: `Bearer ${userToken.value}`
          }
        })

        if (response.data.status === 'ok') {
          allServices.value = response.data.data.all.filter(s => s.icon_code)
          enabledServices.value = response.data.data.enabled.filter(s => s.icon_code)
        }
      } catch (err) {
        throw new Error()
      } finally {
        areServicesLoading.value = false
      }
    }

    const deleteOneService = async (id) => {
      const remainingIds = enabledServices.value.filter(s => s.id !== id).map(s => s.id).toSorted((a, b) => a - b)

      const param = new URLSearchParams({
        user_id: getUserId.value
      })

      try {
        const response = await axios.post('/v6/popular/save/', {
          services: remainingIds
        }, {
          params: param,
          headers: {
            Authorization: `Bearer ${userToken.value}`,
            'Content-Type': 'application/json'
          }
        })

        if (response.data.status === 'ok') {
          enabledServices.value = enabledServices.value.filter(s => s.id !== id)
        } else {
          toast.success(
            {
              component: CustomToast,
              props: {
                title: 'Не удалось удалить сервис',
                text: 'Попробуйте еще раз',
                icon: 'error'
              }
            },
            toastOptions
          )
        }
      } catch (err) {
        throw new Error()
      }
    }

    const saveSorting = async () => {
      const sortedServices = enabledServices.value.map(s => s.id)

      const param = new URLSearchParams({
        user_id: getUserId.value
      })

      const response = await axios.post('/v6/popular/save/', {
        services: sortedServices
      }, {
        params: param,
        headers: {
          Authorization: `Bearer ${userToken.value}`,
          'Content-Type': 'application/json'
        }
      })
      if (response.data.status !== 'ok') {
        toast.success(
          {
            component: CustomToast,
            props: {
              title: 'Не удалось сохранить сервисы',
              text: 'Попробуйте еще раз',
              icon: 'error'
            }
          },
          toastOptions.value
        )
      }
    }

    const closeModal = () => {
      areSettingsOpen.value = false
      isServicesModalOpen.value = false
    }

    const closeSettings = async () => {
      areSettingsOpen.value = false
      await saveSorting()
    }

    const updateServices = async () => {
      await getServices()
    }

    watchEffect(() => {
      isServicesModalOpen.value ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'auto'

      if (banners.value && banners.value.some(b => b.type === 'version')) {
        bannerUpdateIsOpen.value = true
      }
    })

    watch(
      () => getUserId.value,
      async () => {
        await getServices()
        await getBanners()
      },
      { immediate: true }
    )

    onActivated(async () => {
      areSettingsOpen.value = false
      if (!isActivityBannerAvailable.value) {
        const isAvailable = await checkIfAvailable()
        const isAuth = await checkIfAuthorized()
        if (platform === 'android' && isAuth && isAvailable) {
          isActivityBannerAvailable.value = true
        } else if (platform === 'ios' && isAvailable) {
          isActivityBannerAvailable.value = true
        }
      }
    })

    return {
      platform,
      bannerLink,
      hasPinCode,
      isServicesModalOpen,
      isActivityBannerAvailable,
      userToken,
      getUserId,
      hasHealth,
      allServices,
      enabledServices,
      areServicesLoading,
      bannerUpdateIsOpen,
      bannersFiltered,
      areSettingsOpen,
      saveSorting,
      updateServices,
      deleteOneService,
      closeModal,
      closeSettings
    }
  }
}
</script>

<style scoped lang="scss">
.home-wrapper {
  position: relative;
}
.modal-wrapper {
    position: fixed;
    background: #00285680;
    top: 0;
    height: 100vh;
    width: 100vw;
    z-index: 203;
  }
</style>
