<template>
  <div class="pregnancy-info-recommendations">
    <div class="pregnancy-info-recommendations__text" v-html="text"></div>
    <CustomButton @click.prevent="goToDoctorsPage">Записаться</CustomButton>
    <h1 v-if="programms.length" class="pregnancy-info-recommendations__header">
      Программы ведения беременности
    </h1>
    <div calss="pregnancy-info-recommendations__programm-wrapper">
      <div
        v-for="program in programms"
        :key="program.id"
        class="pregnancy-info-recommendations__programm"
      >
        <h2>{{ program.title }}</h2>
        <span>{{ program.preview }}</span>
        <IconInCircle
          class="pregnancy-info-recommendations__icon"
          bg-color="#E1E8F4"
          width="64"
          height="64"
        >
          <img
            :src="
              require(`@/assets/uploads/sprite/${program.icon_code.substr(
                5
              )}.svg`)
            "
            alt="иконка"
          />
        </IconInCircle>
        <router-link v-if="program.type === 'app'" :to="`/services/${program.id}/program`">
        <CustomButton
          class="pregnancy-info-recommendations__btn"
          >Выбрать</CustomButton
        >
        </router-link>
        <a v-else :href="program.url" target="_blank">
        <CustomButton
          class="pregnancy-info-recommendations__btn"
          >Выбрать</CustomButton
        >
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import IconInCircle from '@/components/ui/IconInCircle.vue'
import CustomButton from '@/components/ui/buttons/CustomButton.vue'
import router from '@/router'
export default {
  name: 'PregnancyInfoRecommendations',
  components: { CustomButton, IconInCircle },
  props: {
    text: String,
    programms: [Object]
  },
  setup (props, { emit }) {
    const goToDoctorsPage = () => {
      router.push({ name: 'online-appointment' })
    }

    return {
      goToDoctorsPage
    }
  }
}
</script>

<style scoped lang="scss">
.pregnancy-info-recommendations__text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #111111;
}

.pregnancy-info-recommendations__header {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  margin-top: 14px;
  margin-bottom: 16px;
}

.pregnancy-info-recommendations__programm-wrapper {
}

.pregnancy-info-recommendations__programm {
  padding: 24px 16px 24px 16px;
  box-shadow: 0 4px 15px 0 #0000001a;
  margin-bottom: 16px;

  h2 {
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin-bottom: 8px;
  }

  span {
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #00285680;
    margin-bottom: 64px;
  }
}
.pregnancy-info-recommendations__icon {
  margin-bottom: 24px;
}
.pregnancy-info-recommendations__btn {
  margin-bottom: 0;
}
</style>

<style>
.pregnancy-info-recommendations__text {
  p {
    margin-bottom: 24px;
  }
  ol {
    margin-bottom: 24px;
  }
  li {
    margin-left: 22px;
    list-style-image: url('@/assets/uploads/icon/calendar/blue-check-icon.svg');
    margin-bottom: 12px;
  }
}
</style>
