<template>
  <footer class="footer-desktop">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="footer-desktop__contacts">
            <p>
              Если у вас возникли вопросы по работе приложения или сервису К+31,
              обратитесь по телефону:
            </p>
            <a :href="`tel:+${footerPhoneLink}`">
              <img
                src="@/assets/uploads/images/header/phone.svg"
                alt="иконка телефона"
              />
              <span>{{ regexpPhone(footerPhoneLink) }}</span>
            </a>
          </div>
        </div>
        <div class="col-md-6">
          <div class="footer-desktop__info">
            <p>
              Цены действительны для потребителей — физических лиц. Оплата
              возможна за наличный расчёт, дебетовыми и кредитными картами, и
              банковскими переводами. Внести аванс онлайн.
            </p>
            <p>
              Материалы, размещённые на данной странице, носят информационный
              характер. АО «К+31» не несёт ответственности за возможные
              негативные последствия, возникшие в результате использования
              информации, размещённой на сайте k31.ru. У медицинских услуг
              имеются противопоказания, необходима консультация специалиста.
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { regexpPhone } from '@/utilits/regexpPhone'
import { usePhoneLink } from '@/composables/usePhoneLink'

export default {
  name: 'DesktopFooter',
  methods: { regexpPhone },
  setup () {
    const { phone: footerPhoneLink } = usePhoneLink(
      'ios_footer_phone',
      'android_footer_phone',
      'footer_phone',
      '74951626515'
    )

    return {
      footerPhoneLink
    }
  }
}
</script>

<style scoped lang="scss">
.footer-desktop {
  display: none;
  @include desktop {
    display: block;
  }
}

.footer-desktop .row {
  padding-top: 32px;
  padding-bottom: 25px;
  border-top: 1px solid #bac7de;
}

.footer-desktop__contacts {
  p {
    color: #002856;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 13px;
  }

  a {
    img {
      filter: invert(74%) sepia(67%) saturate(3894%) hue-rotate(340deg)
        brightness(97%) contrast(94%);
      margin-right: 12px;
      width: 16px;
      height: 16px;
    }

    span {
      color: #002856;
      font-size: 20px;
      font-weight: 400;
      line-height: 22px;
    }
  }
}

.footer-desktop__info {
  p {
    color: #7f8da9;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
}
</style>
