<template>
  <div class="gosuslugi">
    <h3 class="gosuslugi__title">Регистрация через Госуслуги</h3>

    <div class="gosuslugi__wrapper">
      <div class="gosuslugi__inputs">
        <FormInputCheckbox
          id="profile-personal"
          v-model="userInfo.isTerms"
          label="Я согласен на"
          linkLabel="обработку моих персональных данных"
          href="https://www.k31.ru/info/soglasie/"
          :validError="v$.isTerms.$error"
        />
        <FormInputCheckbox
          id="profile-rules"
          v-model="userInfo.rules"
          label="Я ознакомлен с"
          linkLabel="правилами внутреннего распорядка"
          href="https://www.k31.ru/upload/doc/useterms.pdf"
          :validError="v$.rules.$error"
        />
      </div>

      <CustomButton @click="submitForm" tag="button" type="button">
        Продолжить
      </CustomButton>

      <router-link to="/auth">
        <p style="text-align: center"><br />Назад</p>
      </router-link>
    </div>
  </div>
</template>

<script>
import FormInputCheckbox from '@/components/ui/form/FormInputCheckbox.vue'
import { computed, reactive } from 'vue'
import { sameAs } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import CustomButton from '@/components/ui/buttons/CustomButton.vue'
import { useRouter } from 'vue-router'
import { postLog } from '@/api/postLog'
import { gosuslugiListner } from '@/utilits/gosuslugiListner'

export default {
  name: 'LoginGosuslugiView',
  components: { CustomButton, FormInputCheckbox },

  setup () {
    const router = useRouter()
    const userInfo = reactive({
      isTerms: false,
      rules: false
    })
    const rules = {
      isTerms: { sameAs: sameAs(true) },
      rules: { sameAs: sameAs(true) }
    }
    const v$ = useVuelidate(rules, userInfo)
    const isPhone = computed(() => {
      const platform = window?.device?.platform.toLowerCase()
      return platform === 'ios' || platform === 'android'
    })

    const submitForm = async () => {
      v$.value.$touch()

      if (v$.value.$error) {
        return
      }

      if (isPhone.value) {
        gosuslugiListner('https://lk.k31.ru/esia/sign-phone')
          .then((event) => {
            let token

            if (event.data.match(/^oauth::/)) {
              token = JSON.parse(event.data.substring(7))
            } else {
              token = event.data
            }

            postLog({
              token: token.token
            })
            router.push('/auth/gosuslugi/' + token.token)
          })
          .catch((err) => {
            postLog({
              err: err
            })
          })

        return
      }

      window.location = 'https://lk.k31.ru/esia/sign/'
    }

    return {
      userInfo,
      submitForm,
      v$
    }
  }
}
</script>

<style scoped lang="scss">
.gosuslugi__title {
  margin-bottom: 20px;
  text-align: center;
  color: #082b53;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.36px;
}

.gosuslugi__inputs {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}
</style>
