<template>
  <div
    class="arrow-title-wrapper"
    :class="{
      'title--fixed': scrollY,
      'arrow-title--light': darkTitle,
    }"
  >
    <div class="container">
      <div
        class="arrow-title"
        :class="{
          'arrow-title--dark': darkTitle,
        }"
      >
        <div class="arrow-title__back">
          <img
            v-if="extraBack"
            @click="$router.go(-2)"
            class="arrow-title__img"
            src="@/assets/uploads/icon/common/arrow-back.svg"
            alt="стрелка"
          />
          <img
            v-else
            @click="$router.go(-1)"
            class="arrow-title__img"
            src="@/assets/uploads/icon/common/arrow-back.svg"
            alt="стрелка"
          />
        </div>
        <h3>{{ title }}</h3>
      </div>

      <WhiteBlockRounded v-show="scrollY" :top="darkTitle" />
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import WhiteBlockRounded from '@/components/ui/WhiteBlockRounded.vue'

export default {
  name: 'ArrowTitle',
  components: { WhiteBlockRounded },
  props: {
    title: {
      type: String,
      default: '',
      required: true
    },
    darkTitle: {
      type: Boolean,
      default: false,
      required: false
    },
    marginBottom: {
      type: String,
      default: '8px',
      required: false
    },
    container: {
      type: Boolean,
      required: false,
      default: true
    },
    isApp: {
      type: Boolean,
      required: true,
      default: false
    },
    extraBack: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup () {
    const scrollY = ref(false)

    onMounted(async () => {
      const onScroll = () => {
        scrollY.value = window.scrollY > 100
      }
      window.addEventListener('scroll', onScroll)
    })
    return {
      scrollY
    }
  }
}
</script>

<style lang="scss" scoped>
.arrow-title-wrapper {
  transition: all 0.2s linear;
  background: #eef2f8;

  @media (max-width: 991px) {
    background: #082b53;
  }
}

.container {
  @include desktop {
    padding: 0;
  }
}

.arrow-title--light {
  background: #eef2f8;

  @media (max-width: 991px) {
    background: #fff;
  }
}

@media (max-width: 991px) {
  .title--fixed {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    padding: 10px 0 15px 0 !important;
    left: 0;

    &.arrow-title--light {
      z-index: 110;
      border-radius: 9px 9px 0 0;

      .arrow-title__img {
        filter: invert(11%) sepia(31%) saturate(5093%) hue-rotate(198deg)
          brightness(102%) contrast(103%);
      }
    }

    h3 {
      color: #fff;
    }

    .arrow-title__img {
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg)
        brightness(102%) contrast(102%);
    }

    .arrow-title {
      margin-bottom: 0;
    }
  }
}

.arrow-title {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  gap: 10px;
  margin-bottom: v-bind("marginBottom");

  & h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    justify-self: center;
    padding-right: 26px;
    @include desktop {
      color: #002856;
      font-size: 22px;
    }
  }

  &__img {
    filter: invert(97%) sepia(1%) saturate(501%) hue-rotate(197deg)
      brightness(116%) contrast(100%);
  }

  @include desktop {
    &__img {
      filter: invert(11%) sepia(31%) saturate(5093%) hue-rotate(198deg)
        brightness(102%) contrast(103%);
    }
  }

  &--dark {
    & h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: #082b53;
    }

    img {
      filter: invert(11%) sepia(31%) saturate(5093%) hue-rotate(198deg)
        brightness(102%) contrast(103%);
    }
  }
}

.arrow-title__back {
  cursor: pointer;
}
</style>
